import React, { Component } from "react";
//import { render } from 'react-dom';
import "./modifier.scss";
import Checkbox from "../checkbox/modifiercheckbox";
import Radio from "../radionbutton/modifierradiobutton";
import uuid from "react-uuid";
import Currency from "../currency/index";
import Modal from "react-bootstrap/Modal";

//import Cart from '../../components/cart';
let modifiercartItemList = [];
let itemModifierRate = 0;
let cartList = {};
let taxList = [];
let chargeList = [];
let itemGroupId = 0;
//let itemTaxId = 0;
let itemId = 0;
let itemName = "";
let itemRate = 0;
let itemQtyId = "";
//let itemQty = 0;
let itemTotalAmout = 0;
let itemTotalItems = 0;
//let modifierDom = '';

export default class Modifier extends Component {
	constructor(props) {
		super(props);
		this.handleModifier = this.handleModifier.bind(this);
		this.state = {
			skipAddItems: "",
			showSkipItems: false,
			skipDiv: [],

			modifierList: [],
			modifierGroupIds: [],
			checkId: -1,
			checkboxstatus: false,
			checkedItems: new Map(),
			showActionFilterList: false,
			totalItems: 0,
			totalAmount: 0,
			modifierItemCQty: 0,
			modifierItemCRate: 0,
			modifierItemCQtyId: "",
			modifierItemCItemName: "",
			modifierItemCItemId: 0,
			modifierItemCFoodType: 0,
			modifierItemCGroupId: 0,
			modifierItemCtaxId: 0,
			modifiterItemModifierRate: 0,
			radiostatus: null,
			showModal: false,
			// level2 change
			enableGuestAppOrders: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleRadioChange = this.handleRadioChange.bind(this);
		this.handleAddItem = this.handleAddItem.bind(this);
	}

	componentWillMount() {
		this.setState({
			modifierItemCGroupId: this.props.modifierItemCGroupId,
			modifierItemCItemId: this.props.modifierItemCItemId,
			modifierItemCItemName: this.props.modifierItemCItemName,
			modifierItemCQtyId: this.props.modifierItemCQtyId,
			modifierItemCQty: this.props.modifierItemCQty,
			modifierItemCRate: this.props.modifierItemCRate,
			modifierItemCFoodType: this.props.modifierItemCFoodType,
			modifiterItemModifierRate: this.props.modifierItemCRate,
			showModal: this.props.showModal,
			radiostatus: null,
		});
		// console.log(this.props.showModal);
	}

	componentDidMount() {
		// console.log('CD');
		// console.log(this.state.checkedItems);

		if (sessionStorage.getItem("cartModifierList") != null) {
			modifiercartItemList = JSON.parse(
				sessionStorage.getItem("cartModifierList")
			);
		} else {
			modifiercartItemList = [];
			// this.setState({ totalAmount: this.props.modifierItemCRate });
		}
		//  console.log(modifiercartItemList);

		// level2 change

		const outletDetailsString = sessionStorage.getItem("user-outletDetails");
		let outletDetails = [];
		if (sessionStorage.getItem("user-outletDetails") != null) {
			outletDetails = JSON.parse(outletDetailsString);
		}
		// Use setState to update the state

		this.setState(
			{ enableGuestAppOrders: outletDetails.enableGuestAppOrders },
			() => {
				// This callback function will be executed after the state is updated
				//   console.log(this.state.outletDetailsEnable);
			}
		);
		//
	}

	handleModifier() {
		itemModifierRate = this.props.modifierItemCRate;
		this.setState({ modifiterItemModifierRate: this.props.modifierItemCRate });
		//  console.log(this.state.checkedItems);
		this.setState({ radiostatus: null });
		this.setState({ checkedItems: new Map(), showModal: this.props.showModal });
		//document.getElementById('div-modifier-body').innerHTML = '';
		this.handleGenerateModifierDom();
		// console.log(modifierDom);
		//document.getElementById('div-modifier-body').innerHTML = modifierDom === null ? '' : modifierDom;
		modifiercartItemList = [];
		//  console.log(this.props.showModal);
		//   console.log(this.state.showModal);
		//  console.log(this.state.radiostatus);
		this.inputElement.click();
		// alert();
		//this.props.callBackModifier();
		// added by dasprakash on 07-09-2023 jira-1302
		const modifierGroupIdToFilter = this.props.modifierGroupIds;
		const filteredItems = this.props.modifierList.filter((item) =>
			modifierGroupIdToFilter.includes(item.modifierGroupId)
		);

		// console.log(filteredItems)
		const fliterItemsLength = filteredItems.filter(
			(item) => item.minimumSelection > 0
		);
		// console.log(fliterItemsLength.length);
		this.setState({ skipAddItems: fliterItemsLength.length }, () => {
			// console.log(this.state.skipAddItems);
		});
		// if (fliterItemsLength.length !== 0) {
		//     this.setState({ showModal: false });
		// }
	}

	handleGenerateModifierDom() {
		//Added by vijay on 27-12-2023 Jira id -2036
		const sortedModifierGroups = this.props.modifierGroupIds
			.map((mid) =>
				this.props.modifierList.find(
					(mgrplst) => mgrplst.modifierGroupId === mid
				)
			)
			.filter((mgrp) => mgrp)
			.sort((a, b) => a.displayOrder - b.displayOrder); // Filter out undefined values

		return (
			<ul className="modifier-group-li">
				{/* {this.props.modifierGroupIds.map(mid =>               */}
				{sortedModifierGroups.map((mgrp) => (
					// <div key={"modifier-group-id-" + mid}>
					<div key={"modifier-group-id-" + mgrp.modifierGroupId}>
						{/* <li key={mid}> */}
						<li key={mgrp.modifierGroupId}>
							{/* {this.props.modifierList.filter(mgrplst => mgrplst.modifierGroupId === mid).map(mgrp => */}
							<div key={"modifier-group-" + mgrp.modifierGroupId}>
								<span className="group-name">
									{mgrp.minimumSelection > 0 && (
										<span className="minimum-selection">*</span>
									)}
									{mgrp.groupName}
								</span>
								{mgrp.maximumSelection === 0 && (
									<span className="group-name-itemcount_v2">
										{" "}
										(
										<span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>
											0
										</span>
										)
									</span>
								)}
								{mgrp.maximumSelection > 0 && (
									<span className="group-name-itemcount_v2">
										(
										<span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>
											0
										</span>
										/
										<span id={"max-group-modifier-cnt-" + mgrp.modifierGroupId}>
											{mgrp.maximumSelection}
										</span>
										)
									</span>
								)}

								<ul className="modifier-li">
									{/* //Added by vijay on 27-12-2023 Jira id -2036  */}
									{/* {mgrp.modifiers.map(mlst => */}
									{mgrp.modifiers
										.sort((a, b) => a.displayOrder - b.displayOrder)
										.map((mlst) => (
											<li key={"modifier-li-" + mlst.modifierId}>
												{/* {mgrp.maximumSelection === 2 && <input type="checkbox" checked={mlst.modifierId === this.state.checkId} id={'modifier-chk-' + mgrp.modifierGroupId-mlst.modifierId} ref={'ref-chk-' + mgrp.modifierGroupId-mlst.modifierId} onChange={this.handleModifierDetail.bind(this,mlst.modifierId,mgrp.modifierGroupId,mlst.modifierName,mlst.rate, "min-group-modifier-cnt-" + mgrp.modifierGroupId,"max-group-modifier-cnt-" + mgrp.modifierGroupId,mgrp.minimumSelection,mgrp.maximumSelection)} />}
                                                                    {mgrp.maximumSelection === 1 && <input type="radio" onChange={this.handleModifierDetail.bind(this,mlst.modifierId,mlst.modifierName,mlst.rate,"min-group-modifier-cnt-" + mgrp.modifierGroupId,"max-group-modifier-cnt-" + mgrp.modifierGroupId,mgrp.minimumSelection,mgrp.maximumSelection)} />}
                                                                    {mgrp.maximumSelection === 0 && <input type="checkbox" onChange={this.handleModifierDetail.bind(this,mlst.modifierId,mlst.modifierName,mlst.rate,"min-group-modifier-cnt-" + mgrp.modifierGroupId,"max-group-modifier-cnt-" + mgrp.modifierGroupId,mgrp.minimumSelection,mgrp.maximumSelection)} />} */}
												<div className="modifier-li-itemname_v2">
													<label
														htmlFor={
															"modifier-chk-grp-" +
															mgrp.modifierGroupId +
															"-" +
															mlst.modifierId
														}
														style={{
															marginBottom: "6px",
															marginLeft: "8px",
															position: "relative",
															top: "-2px",
															display: "inline",
														}}
													>
														{mlst.foodType === 1 && (
															<img
																className="food-type-img food-type-modifier-img "
																src={"/img/f-t-veg.png"}
																alt="item"
															/>
														)}
														{mlst.foodType === 2 && (
															<img
																className="food-type-img food-type-modifier-img "
																src={"/img/f-t-nonveg.png"}
																alt="item"
															/>
														)}
														<span style={{ color: "#4B4B4D" }}>
															{mlst.modifierName}
														</span>
													</label>
												</div>

												<div className="modifier-li-price_v2">
													<span
														className="rate-inr"
														style={{ fontWeight: "600" }}
													>
														{" "}
														<Currency />
														{mlst.rate}
													</span>
													{mgrp.maximumSelection > 1 && (
														<Checkbox
															name={
																"modifier-chk-grp-" +
																mgrp.modifierGroupId +
																"-" +
																mlst.modifierId
															}
															id={
																"modifier-chk-grp-" +
																mgrp.modifierGroupId +
																"-" +
																mlst.modifierId
															}
															checked={this.state.checkedItems.get(
																"modifier-chk-grp-" +
																	mgrp.modifierGroupId +
																	"-" +
																	mlst.modifierId
															)}
															access={mlst.outOfStock}
															onChange={(e) =>
																this.handleChange(
																	e,
																	mlst.modifierId,
																	mlst.modifierName,
																	mgrp.modifierGroupId,
																	mgrp.groupName,
																	this.props.modifierItemCItemId,
																	mlst.rate,
																	mgrp.minimumSelection,
																	mgrp.maximumSelection,
																	mlst.foodType,
																	mlst.taxId,
																	mlst.chargeIds
																)
															}
														></Checkbox>
													)}
													{mgrp.maximumSelection === 1 && (
														<Radio
															name={mgrp.modifierGroupId}
															id={
																"modifier-chk-grp-" +
																mgrp.modifierGroupId +
																"-" +
																mlst.modifierId
															}
															checked={this.state.radiostatus}
															access={mlst.outOfStock}
															onChange={(e) =>
																this.handleRadioChange(
																	e,
																	mlst.modifierId,
																	mlst.modifierName,
																	mgrp.modifierGroupId,
																	mgrp.groupName,
																	this.props.modifierItemCItemId,
																	mlst.rate,
																	mgrp.minimumSelection,
																	mgrp.maximumSelection,
																	mlst.foodType,
																	mlst.taxId,
																	mlst.chargeIds
																)
															}
														></Radio>
													)}
													{mgrp.maximumSelection === 0 && (
														<Checkbox
															name={
																"modifier-chk-grp-" +
																mgrp.modifierGroupId +
																"-" +
																mlst.modifierId
															}
															id={
																"modifier-chk-grp-" +
																mgrp.modifierGroupId +
																"-" +
																mlst.modifierId
															}
															checked={this.state.checkedItems.get(
																"modifier-chk-grp-" +
																	mgrp.modifierGroupId +
																	"-" +
																	mlst.modifierId
															)}
															access={mlst.outOfStock}
															onChange={(e) =>
																this.handleChange(
																	e,
																	mlst.modifierId,
																	mlst.modifierName,
																	mgrp.modifierGroupId,
																	mgrp.groupName,
																	this.props.modifierItemCItemId,
																	mlst.rate,
																	mgrp.minimumSelection,
																	mgrp.maximumSelection,
																	mlst.foodType,
																	mlst.taxId,
																	mlst.chargeIds
																)
															}
														></Checkbox>
													)}
												</div>
											</li>
										))}
								</ul>
							</div>

							{/* // )} */}
						</li>
					</div>
				))}
			</ul>
		);
		// }

		//document.getElementById('div-modifier-body').innerHTML = mdofierDom;
	}

	handleCheckSelectedValue() {
		let mgroup = {};
		let selectedgroup = [];
		let select = true;
		Array.prototype.forEach.call(this.props.modifierGroupIds, (mid) => {
			mgroup = this.props.modifierList.filter((x) => x.modifierGroupId === mid);

			selectedgroup = modifiercartItemList.filter(
				(m) => m.modifierGroupId === mid
			);

			// console.log(mgroup[0].minimumSelection);
			// console.log(selectedgroup.length);

			// commented by dasprakash on 28-10-2023 jira-1677
			// if (mgroup[0].minimumSelection > 0) {
			//     if (mgroup[0].minimumSelection > selectedgroup.length) {
			//         // added by dasprakash on 25-09-2023 jira-1302
			//         this.setState({ showSkipItems: false })
			//         //
			//         document.getElementById('modifier-alert-msg').innerHTML = "You have to select minimum " + mgroup[0].minimumSelection + " addons for this " + mgroup[0].groupName + "";
			//         setTimeout(function ()
			//          { document.getElementById('modifier-alert-msg').innerHTML = '' }, 3500);
			//         select = false;
			//         return select;
			//     }
			// }
			//

			// added by dasprakash on 28-10-2023 jira-1677
			if (mgroup[0].minimumSelection > 0) {
				if (mgroup[0].minimumSelection > selectedgroup.length) {
					this.setState({ showSkipItems: false });

					var modifierAlertMsg = document.getElementById("modifier-alert-msg");
					if (modifierAlertMsg) {
						modifierAlertMsg.innerHTML =
							"You have to select minimum " +
							mgroup[0].minimumSelection +
							" addons for this " +
							mgroup[0].groupName +
							"";
						setTimeout(function () {
							modifierAlertMsg.innerHTML = "";
						}, 3500);
					}
					select = false;
					return select;
				}
			}

			//  console.log(mgroup[0]);
			//  console.log(selectedgroup);
		});
		// console.log(select);
		return select;
	}

	handleAddItem() {
		if (!this.handleCheckSelectedValue()) {
			//  console.log('false');
			return false;
		}
		// console.log('jk');
		this.setState({ showSkipItems: false, skipDiv: [] });
		// console.log(itemQtyId);
		// console.log(modifiercartItemList);
		let exmodifierList =
			sessionStorage.getItem("modifierCartList") === null
				? []
				: JSON.parse(sessionStorage.getItem("modifierCartList"));
		cartList =
			sessionStorage.getItem("cartList") != null
				? JSON.parse(sessionStorage.getItem("cartList"))
				: (cartList = {
						mobileNo: 0,
						totalItems: 0,
						totalAmount: 0,
						itemList: [],
				  });
		taxList =
			sessionStorage.getItem("taxCartList") === null
				? []
				: JSON.parse(sessionStorage.getItem("taxCartList"));
		chargeList =
			sessionStorage.getItem("chargeCartList") === null
				? []
				: JSON.parse(sessionStorage.getItem("chargeCartList"));

		// merge Item
		// let exleng = [];
		// let leng = 0;
		// Array.prototype.forEach.call(modifiercartItemList, md => {
		//     leng = exmodifierList.filter(x => x.modifierGroupId === md.modifierGroupId && x.modifierId === md.modifierId && x.itemId === md.itemId).length;
		//     console.log("group:" + md.modifierGroupId + ", mid : " + md.modifierId + ", Iid : " + md.itemId);
		//     console.log(leng);
		//     if (leng > 0) {
		//         exleng.push({ modifierId: md.modifierId, itemId: md.itemId, modifierGroupId: md.modifierGroupId, modifierName: md.modifierName, rate: md.rate, foodType: md.foodType, ctrl: md.ctrl });
		//     }
		// });
		// console.log("EX MDO");
		// console.log(exleng);

		let uid = uuid();

		// if (modifiercartItemList.length !== exleng.length) {
		//     Array.prototype.forEach.call(modifiercartItemList, md => {
		//         exmodifierList.push({ modifierId: md.modifierId, itemId: md.itemId, modifierGroupId: md.modifierGroupId, modifierName: md.modifierName, rate: md.rate, foodType: md.foodType, ctrl: md.ctrl,uid: uid })
		//     });
		//     cartList.itemList.push({ id: "" + itemQtyId + "", itemId: itemId, groupId: itemGroupId, foodType: this.props.modifierItemCFoodType, qty: 1, rate: itemRate, itemName: itemName, isModifier: 1,uid: uid  });
		//     itemQty+=1;
		// }
		// else {
		//     itemQty +=1;
		//     let index = cartList.itemList.findIndex(x => x.id === "" + itemQtyId + "");
		//     if (index < 0) {
		//         cartList.itemList.push({ id: "" + itemQtyId + "", itemId: itemId, groupId: itemGroupId, foodType: this.props.modifierItemCFoodType, qty: itemQty, rate: itemRate, itemName: itemName, isModifier: 1 });
		//     }
		//     else {
		//         cartList.itemList[index].qty = (cartList.itemList[index].qty + 1);
		//     }
		// }

		//  console.log(modifiercartItemList);
		//   console.log(exmodifierList);

		// not merge the item
		Array.prototype.forEach.call(modifiercartItemList, (md) => {
			let txList = this.modifierItemTaxCalc(md.taxId, md.rate, 1, itemId, uid);
			let txAmount = txList.reduce((prev, next) => prev + next.taxAmount, 0);
			let chgList = this.modifierItemChargeCalc(
				md.chargeIds,
				md.rate,
				1,
				md.itemId,
				uid
			);
			let chgAmount = chgList.reduce((prev, next) => prev + next.taxAmount, 0);
			exmodifierList.push({
				itemId: md.itemId,
				modifierGroupId: md.modifierGroupId,
				modifierGroupName: md.modifierGroupName,
				modifierId: md.modifierId,
				modifierName: md.modifierName,
				rate: md.rate,
				foodType: md.foodType,
				taxes: txList,
				totalTaxAmount: txAmount,
				charges: chgList,
				totalChargeAmount: chgAmount,
				ctrl: md.ctrl,
				uid: uid,
				taxId: md.taxId,
				chargeIds: md.chargeIds,
			});
		});
		this.itemTaxCalc(this.props.modifierItemCTaxId, itemRate, 1, itemId, uid);
		this.itemChargeCalc(
			this.props.modifierItemCChargeIds,
			itemRate,
			1,
			itemId,
			uid
		);
		cartList.itemList.push({
			id: "" + itemQtyId + "",
			itemId: itemId,
			groupId: itemGroupId,
			foodType: this.props.modifierItemCFoodType,
			qty: 1,
			rate: itemRate,
			itemName: itemName,
			isModifier: 1,
			uid: uid,
			taxId: this.props.modifierItemCTaxId,
			chargeIds: this.props.modifierItemCChargeIds,
		});
		//itemQty+=1;

		// re calculate cart value
		let cartItemValue = 0;
		let cartItemQty = 0;
		let modifierCartValue = 0;
		Array.prototype.forEach.call(cartList.itemList, (itm) => {
			//  console.log(itm.qty);
			cartItemValue += itm.rate * itm.qty;
			cartItemQty += itm.qty;
			// console.log(cartItemValue);
			let mdcartItemList = exmodifierList.filter((x) => x.uid === itm.uid);
			//  console.log(mdcartItemList);
			Array.prototype.forEach.call(mdcartItemList, (md) => {
				modifierCartValue += md.rate * itm.qty;
			});
		});

		let totlaQty =
			document.getElementById("" + itemQtyId + "").innerHTML === ""
				? 0
				: JSON.parse(document.getElementById("" + itemQtyId + "").innerHTML);
		document.getElementById("" + itemQtyId + "").innerHTML = totlaQty + 1;

		itemTotalItems = cartItemQty;
		itemTotalAmout = cartItemValue + modifierCartValue;
		this.setState({ totalAmount: itemTotalAmout, totalItems: itemTotalItems });

		// add cart list

		cartList.totalItems = itemTotalItems;
		cartList.totalAmount = itemTotalAmout;

		sessionStorage.setItem("modifierCartList", JSON.stringify(exmodifierList));
		sessionStorage.setItem("cartList", JSON.stringify(cartList));
		sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
		sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));

		//console.log(JSON.parse(sessionStorage.getItem("cartList")));
		// console.log(JSON.parse(sessionStorage.getItem("modifierCartList")));
		// console.log(JSON.parse(sessionStorage.getItem("taxCartList")));

		// sessionStorage.setItem("cartModifierList", JSON.stringify(modifiercartList));
		// console.log(JSON.stringify(modifiercartList));

		// document.getElementById('modifier-modal').modal('hide');
		// document.getElementById("modifier-modal").classList.remove("show", "d-block");

		// document.getElementById("modifier-modal").classList.remove("show", "d-block");
		// document.getElementsByName("body").classList.remove("modal-open", "modal-open");
		// document.querySelectorAll(".modal-backdrop")
		//     .forEach(el => el.classList.remove("modal-backdrop"));

		document.body.classList.remove("modal-open");
		let modalPopup = document.querySelector(".modal-backdrop");

		if (modalPopup !== null) {
			modalPopup.classList.remove("modal-backdrop", "fade", "show");
		}

		this.setState({ showModal: false });

		this.setState({ checkedItems: new Map() });
		modifiercartItemList = [];
		this.props.callBackModifier();
	}

	itemTaxCalc(taxId, rate, qty, itemId, uid) {
		//  console.log(this.props.getTaxes);
		let cList = this.props.getTaxes.filter((x) => x.taxId === taxId);
		// console.log(cList);
		let taxObj = [];
		let taxAmt = 0;
		let itemValue = rate * qty;
		let bInsertTax = false;
		if (cList.length > 0) {
			Array.prototype.forEach.call(cList[0].structure, (tx) => {
				bInsertTax = false;
				taxAmt = 0;
				//Applicable on = 2 Discount Value  && applicableon = 1 direct value
				if (tx.applicableOn === 3) {
					// Tax on Tax
					let serialTax = taxObj.filter((x) => x.serialNo === tx.taxOnSerialNo);
					if (serialTax.length > 0) {
						if (tx.taxType === 1)
							// (%)
							taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
						else if (tx.taxType === 2)
							// Amount
							taxAmt = tx.taxValue * qty;

						bInsertTax = true;
					}
				} else {
					if (tx.taxType === 1)
						// (%)
						taxAmt = itemValue * tx.taxValue * 0.01;
					else if (tx.taxType === 2)
						// Amount
						taxAmt = tx.taxValue * qty; //

					bInsertTax = true;
				}

				if (bInsertTax === true) {
					taxObj.push({
						itemId: itemId,
						uid: uid,
						taxId: taxId,
						taxGroupName: cList[0].taxName,
						serialNo: tx.serialNo,
						taxCode: tx.taxCode,
						taxName: tx.taxName,
						applicableOn: tx.applicableOn,
						taxType: tx.taxType,
						taxValue: tx.taxValue,
						taxAmount: Number(taxAmt.toFixed(2)),
					});
					taxList.push({
						itemId: itemId,
						uid: uid,
						taxId: taxId,
						taxGroupName: cList[0].taxName,
						serialNo: tx.serialNo,
						taxCode: tx.taxCode,
						taxName: tx.taxName,
						applicableOn: tx.applicableOn,
						taxType: tx.taxType,
						taxValue: tx.taxValue,
						taxAmount: Number(taxAmt.toFixed(2)),
					});
				}
			});
		}
	}

	itemChargeCalc(chargeId, rate, qty, itemId, uid) {
		//   console.log(chargeId);
		if (chargeId.length > 0) {
			Array.prototype.forEach.call(chargeId, (cId) => {
				// filter based on Item wise & Item Qty wise => apply category
				let chargelist = this.props.getCharges.filter(
					(x) => x.chargeId === cId && x.applyTo === 1 && x.applyType === 1
				);
				let taxlist = [];
				let taxObj = [];
				let taxAmt = 0;
				let itemValue = rate * qty;
				//  console.log(chargelist);
				let chargeAmt = 0;
				if (chargelist.length > 0) {
					Array.prototype.forEach.call(chargelist, (chg) => {
						let rnTaxAmt = 0;
						if (chg.applyType === 2) {
							// slabe wise (%)   - only for slab base value from and value to (percentage =>itmvalue * per * 0.01) or amount=> direct slab amount)
							let cslab = chg.chargeSlab.filter(
								(x) => x.ValueFrom > itemValue && x.ValueTo < itemValue
							);
							if (cslab.length > 0) {
								if (cslab.chargeValueType === 1)
									chargeAmt = itemValue * chg.chargeValue * 0.01;
								else if (cslab.chargeValueType === 2)
									chargeAmt = cslab.ChargeValue;
							}
						} else if (chg.chargeValueType === 2)
							// Amount only qty based
							chargeAmt = chg.chargeValue * qty; //
						//   console.log(chargeAmt);
						// Tax
						taxlist = this.props.getTaxes.filter((x) => x.taxId === chg.taxId);
						if (taxlist.length > 0) {
							Array.prototype.forEach.call(taxlist[0].structure, (tx) => {
								taxAmt = 0;
								if (tx.applicableOn === 3) {
									let serialTax = taxObj.filter(
										(x) => x.serialNo === tx.taxOnSerialNo
									);
									if (serialTax.length > 0) {
										if (tx.taxType === 1)
											// (%)
											taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
										else if (tx.taxType === 2)
											// Amount
											taxAmt = tx.taxValue;
									}
								} else {
									if (tx.taxType === 1)
										// (%)
										taxAmt = chargeAmt * tx.taxValue * 0.01;
									else if (tx.taxType === 2)
										// Amount
										taxAmt = tx.taxValue; //
								}
								rnTaxAmt += taxAmt;
								taxObj.push({
									itemId: itemId,
									uid: uid,
									chargeId: chg.chargeId,
									taxId: chg.taxId,
									taxGroupName: taxlist[0].taxName,
									serialNo: tx.serialNo,
									taxCode: tx.taxCode,
									taxName: tx.taxName,
									applicableOn: tx.applicableOn,
									taxType: tx.taxType,
									taxValue: tx.taxValue,
									taxAmount: Number(taxAmt.toFixed(2)),
								});
							});
							//console.log(taxObj);
						}
						chargeList.push({
							itemId: itemId,
							uid: uid,
							chargeId: chg.chargeId,
							chargeName: chg.chargeName,
							chargeType: chg.chargeType,
							isMerchantCharge: false,
							chargeValueType: chg.chargeValueType,
							chargeValue: chg.chargeValue,
							chargeAmount: Number(chargeAmt.toFixed(2)),
							taxes: taxObj,
							totalTaxAmount: rnTaxAmt,
						});
					});
					// console.log(chargeList);
				}
			});
		}
	}
	//
	modifierItemTaxCalc(taxId, rate, qty, itemId, uid) {
		let cList = this.props.getTaxes.filter((x) => x.taxId === taxId);
		let taxObj = [];
		let taxAmt = 0;
		let itemValue = rate * qty;

		let bInsertTax = false;
		if (cList.length > 0) {
			Array.prototype.forEach.call(cList[0].structure, (tx) => {
				bInsertTax = false;
				taxAmt = 0;
				//Applicable on = 2 Discount Value  && applicableon = 1 direct value
				if (tx.applicableOn === 3) {
					// Tax on Tax
					let serialTax = taxObj.filter((x) => x.serialNo === tx.taxOnSerialNo);
					if (serialTax.length > 0) {
						if (tx.taxType === 1)
							// (%)
							taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
						else if (tx.taxType === 2)
							// Amount
							taxAmt = tx.taxValue * qty;

						bInsertTax = true;
					}
				} else {
					if (tx.taxType === 1)
						// (%)
						taxAmt = itemValue * tx.taxValue * 0.01;
					else if (tx.taxType === 2)
						// Amount
						taxAmt = tx.taxValue * qty; //

					bInsertTax = true;
				}

				if (bInsertTax === true) {
					taxObj.push({
						itemId: itemId,
						uid: uid,
						taxId: taxId,
						taxGroupName: cList[0].taxName,
						serialNo: tx.serialNo,
						taxCode: tx.taxCode,
						taxName: tx.taxName,
						applicableOn: tx.applicableOn,
						taxType: tx.taxType,
						taxValue: tx.taxValue,
						taxAmount: Number(taxAmt.toFixed(2)),
					});
				}
			});
		}
		return taxObj;
	}

	modifierItemChargeCalc(chargeId, rate, qty, itemId, uid) {
		//  console.log(chargeId);
		// Null handeled
		chargeId = chargeId === null ? [] : chargeId;

		let modifierChargeList = [];
		if (chargeId.length > 0) {
			Array.prototype.forEach.call(chargeId, (cId) => {
				// filter based on Item wise & Item Qty wise => apply category
				let chargelist = this.props.getCharges.filter(
					(x) => x.chargeId === cId && x.applyTo === 1 && x.applyType === 1
				);
				let taxlist = [];
				let taxObj = [];
				let taxAmt = 0;
				let itemValue = rate * qty;
				//console.log(chargelist);
				let chargeAmt = 0;
				if (chargelist.length > 0) {
					Array.prototype.forEach.call(chargelist, (chg) => {
						let rnTaxAmt = 0;
						if (chg.applyType === 2) {
							// slabe wise (%)   - only for slab base value from and value to (percentage =>itmvalue * per * 0.01) or amount=> direct slab amount)
							chg.chargeSlab = chg.chargeSlab === null ? [] : chg.chargeSlab;
							let cslab = chg.chargeSlab.filter(
								(x) => x.ValueFrom > itemValue && x.ValueTo < itemValue
							);
							if (cslab.length > 0) {
								if (cslab.chargeValueType === 1)
									chargeAmt = itemValue * chg.chargeValue * 0.01;
								else if (cslab.chargeValueType === 2)
									chargeAmt = cslab.ChargeValue;
							}
						} else if (chg.chargeValueType === 2)
							// Amount only qty based
							chargeAmt = chg.chargeValue * qty; //
						//  console.log(chargeAmt);
						// Tax
						taxlist = this.props.getTaxes.filter((x) => x.taxId === chg.taxId);
						if (taxlist.length > 0) {
							Array.prototype.forEach.call(taxlist[0].structure, (tx) => {
								taxAmt = 0;
								if (tx.applicableOn === 3) {
									//tax on tax
									let serialTax = taxObj.filter(
										(x) => x.serialNo === tx.taxOnSerialNo
									);
									if (serialTax.length > 0) {
										if (tx.taxType === 1)
											// (%)
											taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
										else if (tx.taxType === 2)
											// Amount
											taxAmt = tx.taxValue;
									}
								} else {
									if (tx.taxType === 1)
										// (%)
										taxAmt = chargeAmt * tx.taxValue * 0.01;
									else if (tx.taxType === 2)
										// Amount
										taxAmt = tx.taxValue; //
								}

								rnTaxAmt += taxAmt;
								taxObj.push({
									itemId: itemId,
									uid: uid,
									chargeId: chg.chargeId,
									taxId: chg.taxId,
									taxGroupName: taxlist[0].taxName,
									serialNo: tx.serialNo,
									taxCode: tx.taxCode,
									taxName: tx.taxName,
									applicableOn: tx.applicableOn,
									taxType: tx.taxType,
									taxValue: tx.taxValue,
									taxAmount: Number(taxAmt.toFixed(2)),
								});
							});
							// console.log(taxObj);
						}
						modifierChargeList.push({
							itemId: itemId,
							uid: uid,
							chargeId: chg.chargeId,
							chargeName: chg.chargeName,
							chargeType: chg.chargeType,
							isMerchantCharge: false,
							chargeValueType: chg.chargeValueType,
							chargeValue: chg.chargeValue,
							chargeAmount: Number(chargeAmt.toFixed(2)),
							taxes: taxObj,
							totalTaxAmount: rnTaxAmt,
						});
					});
					// console.log(modifierChargeList);
				}
			});
		}
		return modifierChargeList;
	}

	handleRadioChange(
		e,
		modifierId,
		modifierName,
		modifierGroupId,
		groupName,
		itemId,
		rate,
		minQty,
		maxQty,
		foodType,
		taxId,
		chargeIds
	) {
		const item = e.target.name;
		const isChecked = e.target.checked;
		//console.log(rate);
		// console.log(itemModifierRate);

		let modifierlst = modifiercartItemList.filter(
			(x) => x.modifierGroupId === modifierGroupId && x.itemId === itemId
		);
		// console.log(modifierlst);
		itemModifierRate =
			this.state.modifiterItemModifierRate === 0
				? this.props.modifierItemCRate
				: this.state.modifiterItemModifierRate;

		if (modifierlst.length === 0) {
			itemModifierRate += rate;
			modifiercartItemList.push({
				itemId: itemId,
				modifierGroupId: modifierGroupId,
				modifierGroupName: groupName,
				modifierId: modifierId,
				modifierName: modifierName,
				rate: rate,
				foodType: foodType,
				taxId: taxId,
				chargeIds: chargeIds,
				ctrl: 2,
			});
			this.setState((prevState) => ({
				checkedItems: prevState.checkedItems.set(item, isChecked),
			}));
			this.setState({ skipDiv: modifiercartItemList }, () => {
				// console.log(this.state.skipDiv);
			});
		} else if (modifierlst.length === 1) {
			let modifierindex = modifiercartItemList.findIndex(
				(x) => x.modifierGroupId === modifierGroupId && x.itemId === itemId
			);
			itemModifierRate -= modifiercartItemList[modifierindex].rate;
			modifiercartItemList.splice(modifierindex, 1);
			itemModifierRate += rate;
			modifiercartItemList.push({
				itemId: itemId,
				modifierGroupId: modifierGroupId,
				modifierGroupName: groupName,
				modifierId: modifierId,
				modifierName: modifierName,
				rate: rate,
				foodType: foodType,
				taxId: taxId,
				chargeIds: chargeIds,
				ctrl: 2,
			});
			this.setState((prevState) => ({
				checkedItems: prevState.checkedItems.set(item, isChecked),
			}));
			this.setState({ skipDiv: modifiercartItemList }, () => {
				// console.log(this.state.skipDiv);
			});
		}
		this.setState({ modifiterItemModifierRate: itemModifierRate });
		document.getElementById(
			"min-group-modifier-cnt-" + modifierGroupId
		).innerHTML = modifiercartItemList.filter(
			(x) =>
				x.modifierGroupId === modifierGroupId &&
				x.itemId === itemId &&
				x.ctrl === 2
		).length;
		//console.log(modifiercartItemList);
	}

	handleChange(
		e,
		modifierId,
		modifierName,
		modifierGroupId,
		groupName,
		itemId,
		rate,
		minQty,
		maxQty,
		foodType,
		taxId,
		chargeIds
	) {
		const item = e.target.name;
		const isChecked = e.target.checked;
		//const radionid = e.target.id;

		let modifierlst = modifiercartItemList.filter(
			(x) => x.modifierGroupId === modifierGroupId && x.itemId === itemId
		);
		// console.log(modifierlst);
		itemModifierRate =
			this.state.modifiterItemModifierRate === 0
				? this.props.modifierItemCRate
				: this.state.modifiterItemModifierRate;
		// console.log(itemModifierRate);

		if (isChecked) {
			// console.log("checked");
			if (maxQty > 0) {
				if (modifierlst.length + 1 > maxQty) {
					document.getElementById("modifier-alert-msg").innerHTML =
						"You have already selected max addons for this category";
					setTimeout(function () {
						document.getElementById("modifier-alert-msg").innerHTML = "";
					}, 1500);
					// alert("You have already selected max addons for this category");
					this.setState((prevState) => ({
						checkedItems: prevState.checkedItems.set(item, !isChecked),
					}));
					// added by dasprakash on 21/09/2023 jira-1302

					this.setState({ skipDiv: modifiercartItemList }, () => {
						// console.log(this.state.skipDiv);
					});
				} else {
					itemModifierRate += rate;
					modifiercartItemList.push({
						itemId: itemId,
						modifierGroupId: modifierGroupId,
						modifierGroupName: groupName,
						modifierId: modifierId,
						modifierName: modifierName,
						rate: rate,
						foodType: foodType,
						taxId: taxId,
						chargeIds: chargeIds,
						ctrl: 1,
					});
					this.setState((prevState) => ({
						checkedItems: prevState.checkedItems.set(item, isChecked),
					}));
					// added by dasprakash on 21/09/2023 jira-1302

					this.setState({ skipDiv: modifiercartItemList }, () => {
						// console.log(this.state.skipDiv);
					});
				}
			} else {
				itemModifierRate += rate;
				modifiercartItemList.push({
					itemId: itemId,
					modifierGroupId: modifierGroupId,
					modifierGroupName: groupName,
					modifierId: modifierId,
					modifierName: modifierName,
					rate: rate,
					foodType: foodType,
					taxId: taxId,
					chargeIds: chargeIds,
					ctrl: 1,
				});
				this.setState((prevState) => ({
					checkedItems: prevState.checkedItems.set(item, isChecked),
				}));
				// console.log(modifiercartItemList)
				// added by dasprakash on 21/09/2023 jira-1302

				this.setState({ skipDiv: modifiercartItemList }, () => {
					// console.log(this.state.skipDiv);
				});
			}
		} // Unchecked
		else {
			// console.log("Unchecked")
			let modifierindex = modifiercartItemList.findIndex(
				(x) =>
					x.modifierGroupId === modifierGroupId &&
					x.itemId === itemId &&
					x.modifierId === modifierId
			);
			itemModifierRate -= rate;
			modifiercartItemList.splice(modifierindex, 1);
			this.setState((prevState) => ({
				checkedItems: prevState.checkedItems.set(item, isChecked),
			}));
			// console.log(modifiercartItemList)
			// added by dasprakash on 21/09/2023 jira-1302
			this.setState({ skipDiv: modifiercartItemList }, () => {
				// console.log(this.state.skipDiv);
			});
		}
		this.setState({ modifiterItemModifierRate: itemModifierRate });
		document.getElementById(
			"min-group-modifier-cnt-" + modifierGroupId
		).innerHTML = modifiercartItemList.filter(
			(x) =>
				x.modifierGroupId === modifierGroupId &&
				x.itemId === itemId &&
				x.ctrl === 1
		).length;
	}

	// added by dasprakash on 13/09/2023 jira-1302
	skipAddItems = () => {
		this.setState({ showSkipItems: true, skipDiv: [] });
	};

	render() {
		// this.setState({modifierItemCRate: this.props.modifierItemCRate});
		//   itemModifierRate = itemModifierRate === 0 ? this.props.modifierItemCRate : itemModifierRate;
		itemModifierRate =
			this.state.modifiterItemModifierRate === 0
				? this.props.modifierItemCRate
				: this.state.modifiterItemModifierRate;
		// itemModifierRate = this.props.modifierItemCRate;
		itemGroupId =
			this.state.modifierItemCGroupId === 0
				? this.props.modifierItemCGroupId
				: this.state.modifierItemCGroupId;
		//itemTaxId = this.state.modifierItemCTaxId === 0 ? this.props.modifierItemCTaxId : this.state.modifierItemCTaxId;
		itemId =
			this.state.modifierItemCItemId === 0
				? this.props.modifierItemCItemId
				: this.state.modifierItemCItemId;
		itemName =
			this.state.modifierItemCItemName === ""
				? this.props.modifierItemCItemName
				: this.state.modifierItemCItemName;
		itemRate =
			this.state.modifierItemCRate === 0
				? this.props.modifierItemCRate
				: this.state.modifierItemCRate;
		itemQtyId =
			this.state.modifierItemCQtyId === ""
				? this.props.modifierItemCQtyId
				: this.state.modifierItemCId;
		//  itemQty = this.state.modifierItemCQty === 0 ? this.props.modifierItemCQty : this.state.modifierItemCQty;
		itemTotalAmout =
			this.state.totalAmount === 0
				? this.props.modifierTotalItemAmount
				: this.state.totalAmount;
		itemTotalItems =
			this.state.totalItems === 0
				? this.props.modifierTotalItems
				: this.state.totalItems;

		return (
			<>
				<div className="section-modifier">
					<div className="menu-goup">
						<button
							className="theme-button btn"
							style={{ display: "none" }}
							ref={(input) => (this.inputElement = input)}
							data-toggle="modal"
							data-target="#modifier-modal"
						>
							<i className="fa fa-utensils sidebar-icon"></i> MENU
						</button>
					</div>
					{/* Show Modifier*/}

					{this.state.showModal && (
						<div
							className="modal fade"
							id="modifier-modal"
							role="dialog"
							aria-labelledby="exampleModalLabel"
							aria-hidden="true"
							data-backdrop="static"
						>
							<div
								style={{ margin: "0px auto" }}
								className="modal-dialog modal-dialog-centered"
								role="document"
							>
								<div
									className="modal-content animate-bottom"
									style={{ bottom: "-2px" }}
								>
									{/* <button style={{ marginLeft: "auto", marginRight: "auto" }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span style={{ padding: "4px" }} aria-hidden="true">&times;</span>
                                    </button> */}
									<div className="modal-header">
										<h5 className="modal-title" id="exampleModalLabel">
											{/* commented by dasprakash on 19-08-2023 jira-1302 */}
											{/* {this.props.modifierItemCFoodType === 1 && <img className="food-type-img food-type-modifier-img " src={"/img/f-t-veg.png"} alt="item" />}
                                            {this.props.modifierItemCFoodType === 2 && <img className="food-type-img food-type-modifier-img " src={"/img/f-t-nonveg.png"} alt="item" />} */}
											{/*₹{this.props.modifierItemCRate} added by dasprakash on 19-08-2023 jira-1302  */}
											{this.props.modifierItemCItemName}.{" "}
											<span style={{ fontSize: "15px", fontWeight: "600" }}>
												<Currency />
												{this.props.modifierItemCRate}
											</span>
										</h5>

										{/* close button */}
										{/* added by dasprakash on 08-09-2023 jira-1302 */}
										{this.state.skipDiv.length >= 1 &&
										this.state.enableGuestAppOrders === true ? (
											<button
												onClick={this.skipAddItems}
												type="button"
												className="close"
											>
												<span aria-hidden="true">&times;</span>
											</button>
										) : (
											<button
												type="button"
												className="close"
												data-dismiss="modal"
												aria-label="Close"
											>
												<span aria-hidden="true">&times;</span>
											</button>
										)}

										{/*added by dasprakash on 19-08-2023 jira-1302 */}
										<div className="modal-header-wordings">
											<p>Customise as per your taste</p>
										</div>
									</div>
									<div className="modal-body" id="div-modifier-body">
										{this.handleGenerateModifierDom()}

										{/* added by dasprakash on 25-09-2023 jira-1302 */}
										<Modal
											show={this.state.showSkipItems}
											animation={true}
											keyboard={false}
											centered
										>
											<Modal.Body>
												<div>
													<div className="skipadditem_v2">
														<div
															style={{
																display: "flex",
																justifyContent: "space-between",
																padding: "0px 25px",
															}}
														>
															<div style={{ display: "flex" }}>
																{(this.props.modifierItemCFoodType === 1 ||
																	this.props.modifierItemCFoodType === 2) && (
																	<img
																		width="13"
																		height="13"
																		style={{ marginTop: "4px" }}
																		className="food-type-img food-type-modifier-img"
																		src="/img/f-t-veg.png"
																		alt="item"
																	/>
																)}
																<p
																	style={{
																		height: "1px",
																		fontSize: "16px",
																		fontWeight: "600",
																	}}
																>
																	{this.props.modifierItemCItemName}
																</p>
															</div>
															<div
																style={{ fontSize: "16px", fontWeight: "600" }}
															>
																<Currency />
																{itemModifierRate.toFixed(2)}
															</div>
														</div>

														<div
															style={{
																display: "flex",
																justifyContent: "space-evenly",
															}}
														>
															<div
																className="skip-button_v2"
																onClick={() => {
																	this.setState(
																		{ showSkipItems: false },
																		() => {
																			if (this.inputElement) {
																				this.inputElement.click();
																			}
																		}
																	);
																}}
															>
																<p>Skip</p>
															</div>

															<div
																onClick={this.handleAddItem}
																className="addToCart-button_v2"
															>
																<p>Add to Cart</p>
															</div>
														</div>
													</div>
												</div>
												.
											</Modal.Body>
										</Modal>
									</div>
									<div id="modifier-alert-msg"></div>
									<div className="modal-footer">
										<div className="cart">
											<div>
												<div className="c-left">
													<Currency />
													<span>{itemModifierRate.toFixed(2)}</span>
													{/* <p style={{ fontSize: "15px" }}>View Customised Items</p> */}
												</div>
											</div>
											{/* <div className="c-right" onClick={this.handleAddItem.bind()}>Add Item to Cart</div> */}
											{/* level2 change */}
											{this.state.enableGuestAppOrders === true ? (
												<div
													className="c-right"
													onClick={this.handleAddItem.bind()}
												>
													Add Item to Cart
												</div>
											) : (
												""
											)}
											{/*  */}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</>
		);
	}
}
