import React, { Component } from 'react';
import './request.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Config from '../../data/SiteConfig';
export default class Request extends Component {
    constructor(props) {
        super(props)
        this.handleRequest = this.handleRequest.bind(this);
        this.state = {
            //  loading: false,
            // alertMessage: '',
            //  msgShow: false,
            guestRequestList: [],
            requestMasterList: [],
            message: '',
            errorCode:0    //added by vijay correction hard coded message 
        }

    }

    handleRequest() {
        // added by dasprakash on 28-10-2023 jira-1677
        this.setState({ message: '' });
        this.getGuestRequestList();
        this.getRequestMasterList();
       this.inputElement.click();
    }

    getRequestMasterList() {
        // this.setState({ loading: true });
        let param = 'Module=POS&OutletId=' + this.props.propertyId + this.props.outletCode + '&ServiceType=' + this.props.serviceType;
        fetch(Config.baseGuestUrl + 'GetGuestRequestMasterData?' + param, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + this.props.token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                //  this.setState({ loading: false });
                //  console.log(response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        this.setState({ requestMasterList: response.response.requestNames });
                    }
                    else {
                        this.setState({ requestMasterList: [] });
                        //  this.showMessage(response.message, "danger");
                    }
                }
                else {
                    this.setState({ requestMasterList: [] });
                    //  this.showMessage(response.message, "danger");
                }

            })
            .catch(err => {
                this.setState({ requestMasterList: [] });
                // this.showMessage('Request Master List - Failed to fetch - ' + err, "danger");
            });
    };

    getGuestRequestList() {
        this.setState({ loading: true });
        let param = 'OutletId=' + this.props.propertyId + this.props.outletCode + '&ServiceType=' + this.props.serviceType + '&GuestMobileNo=' + this.props.mobileNo;
        fetch(Config.baseGuestUrl + 'GetGuestRequest?' + param, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + this.props.token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                // this.setState({ loading: false });
                //  console.log(response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        this.setState({ guestRequestList: response.response.guestRequest });
                    }
                    else {
                        this.setState({ guestRequestList: [] });
                        //   this.showMessage(response.message, "danger");
                    }
                }
                else {
                    this.setState({ guestRequestList: [] });
                    //  this.showMessage(response.message, "danger");
                }

            })
            .catch(err => {
                this.setState({ guestRequestList: [] });
                // this.showMessage('Guest Request List - Failed to fetch - ' + err, "danger");
            });
    };

    handleSaveGuestRequest(requestName) {
        // this.setState({ loading: true });
        let postObj = {
            OutletId: this.props.propertyId + this.props.outletCode,
            RequestType: 0,
            ServiceType: this.props.serviceType,
            TableNumber: this.props.serviceType === "FINE-DINE" ? this.props.tableNo : "0",
            OrderId: "",
            BillId: "",
            GuestMobileNo: this.props.mobileNo,
            RequestName: requestName,
            Remarks: "",
        }

        // console.log(postObj);

        fetch(Config.baseGuestUrl + 'SaveGuestRequest', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + this.props.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                if (response.errorCode === 0) {
                    // this.showMessage(response.message, "success");
                    // this.setState({ loading: false });
                    // added by dasprakash on 28-10-2023 jira-1677
                    //added by vijay correction hard coded message 
                    this.setState({ message: response.message,errorCode:response.errorCode }); 
                    setTimeout(() => {
                        this.inputElement.click();
                    }, Config.minMsgTimeout);
                    //this.getGuestRequestList();
                      //added by vijay correction hard coded message 
                    this.props.callBackRequest('', 1);
                }
                else {
                    //  console.log(response.message);
                    //  this.setState({ loading: false });
                    // added by dasprakash on 28-10-2023 jira-1677
                      //added by vijay correction hard coded message 
                    this.setState({ message: response.message,errorCode:response.errorCode });
                    setTimeout(() => {
                        this.inputElement.click();
                    }, Config.minMsgTimeout);
                    this.props.callBackRequest('Error request was not placed', 0);
                    // this.showMessage('Error request was not placed', "danger");                   
                }
            })
            .catch(err => {
                //   console.log(err);
                ///  this.setState({ loading: false });
                // added by dasprakash on 28-10-2023 jira-1677
                this.setState({ message: "Request falied" });
                setTimeout(() => {
                    this.inputElement.click();
                }, Config.minMsgTimeout);
                this.props.callBackRequest('Error request was not placed', 0);
                // this.showMessage('Error request was not placed', "danger");               
            });
    };


    render() {


        return (
            <>
                <div className="section-request">
                    <div className="menu-goup">
                        <button className="theme-button btn" style={{ display: "none" }} ref={input => this.inputElement = input} data-toggle="modal" data-target="#request-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU1</button>
                    </div>
                    {/* Show Modifier*/}
                    {/*data-backdrop is added by dasprakash on 27-10-2023 jira-1677  */}
                    <div className="modal fade" id="request-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content animate-bottom">
                                <div style={{ display: "flex", flexDirection: " column-reverse", alignItems: "center" }} className="modal-header">
                                    {/* // addded by dasprakash on 23-10-2023 jira-1677 */}
                                    {/* <h5 className="modal-title">
                                        Help
                                    </h5> */}
                                    {/* // added by dasprakash on 23-10-2023 jira-1677 */}
                                    <svg
                                        data-dismiss="modal" aria-label="Close" style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "10px" }} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM10.2235 29.7706C9.73538 29.2824 9.73538 28.491 10.2235 28.0028L18.2322 19.9941L10.2309 11.9928C9.74277 11.5047 9.74277 10.7132 10.2309 10.2251C10.7191 9.73691 11.5105 9.73691 11.9987 10.2251L20 18.2264L28.0013 10.2251C28.4895 9.73691 29.2809 9.73691 29.7691 10.2251C30.2572 10.7132 30.2572 11.5047 29.7691 11.9928L21.7678 19.9941L29.7765 28.0028C30.2646 28.491 30.2646 29.2824 29.7765 29.7706C29.2883 30.2588 28.4969 30.2588 28.0087 29.7706L20 21.7619L11.9913 29.7706C11.5031 30.2588 10.7117 30.2588 10.2235 29.7706Z" fill="black" />
                                    </svg>
                                    {this.state.message === '' ? "" :
                                        <span style={{
                                            color: this.state.errorCode === 0 ? "#18A437" : "#F00"
                                            , backgroundColor: this.state.errorCode === 0 ? "#DCF1E1" : "#F1E2E2", padding: "9px 17px 8.888px 18px", borderRadius: "5px", marginBottom: "10px"
                                        }}>{this.state.message}</span>
                                    }




                                    {/* // commented by dasprakash on 23-10-2023 jira-1677 */}
                                    {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button> */}
                                </div>
                                <div className="modal-body" id="div-request-body">
                                    <Tabs>
                                        <TabList>
                                            <Tab >Help
                                                {/* // commented by dasprakash on 23-10-2023 jira-1677 */}
                                                {/* <i className="fa fa-hand-paper" style={{ fontSize: "16px", color: "#d12f8b" }}></i> */}
                                            </Tab>
                                            <Tab>My Request<span>({this.state.guestRequestList.length})</span>
                                                {/* // commented by dasprakash on 23-10-2023 jira-1677 */}
                                                {/* <div className="refresh-icon" onClick={this.getGuestRequestList.bind(this)}> <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i></div> */}
                                            </Tab>

                                        </TabList>

                                        <TabPanel>
                                            <div style={{ margin: "10px 17px" }} className="help-list">
                                                <ul>
                                                    {
                                                        this.state.requestMasterList.map((req, idx) =>
                                                            <li style={{ backgroundColor: "#FAFAFA", borderRadius: "8px" }} key={"request-" + idx}>
                                                                <div className="content request">
                                                                    <div onClick={this.handleSaveGuestRequest.bind(this, req)} >
                                                                        <div className="help-heading"><span className="help-type">{req}</span>
                                                                        </div>
                                                                        {/* // commented by dasprakash on 23-10-2023 jira-1677 */}
                                                                        {/* <i className="fa fa-angle-right right-btn" ></i> */}
                                                                        {/* // addded by dasprakash on 23-10-2023 jira-1677 */}
                                                                        <svg className='right-btn' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path d="M9 6L15 12L9 18" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                                                                        </svg>
                                                                    </div>

                                                                </div>
                                                            </li>

                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            {this.state.requestMasterList.length === 0 &&
                                                <div>
                                                    <div className="no-data-img">
                                                        {/* changed img in public folder by dasprakash on 25-10-2023 jira-1677 */}
                                                        <img src="/img/no-image/no-data.png" alt="lucid-no-orders" /></div>
                                                </div>
                                            }
                                        </TabPanel>
                                        <TabPanel>
                                            <div style={{ margin: "10px 17px" }} className="help-list">
                                                <ul>
                                                    {
                                                        this.state.guestRequestList.map((req, idx) =>
                                                            <li style={{ backgroundColor: "#FAFAFA", borderRadius: "8px" }} key={"guest-request-" + idx}>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} className="content request">
                                                                    {/* <i className="fa fa-angle-right right-btn" ></i> */}
                                                                    <div >

                                                                        <div className="help-heading"><span className="help-type">{req.requestName}</span></div>
                                                                        <div className="help">
                                                                            {req.remarks}
                                                                        </div>
                                                                        <div className="request-time">
                                                                            {/* // commented by dasprakash on 23-10-2023 jira-1677 */}
                                                                            {/* <i className="fa fa-clock" aria-hidden="true"></i> */}

                                                                            {/* // addded by dasprakash on 23-10-2023 jira-1677 */}
                                                                            <svg style={{ marginRight: "4px", marginTop: "-2px" }} xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                                                                                <path d="M0 5.50977C0 4.51332 0.249112 3.59014 0.742451 2.74512C1.23579 1.90009 1.90497 1.23091 2.75 0.737566C3.59503 0.244227 4.51332 0 5.50488 0C6.24734 0 6.96048 0.146536 7.63943 0.434725C8.31838 0.722913 8.89964 1.11856 9.39298 1.60702C9.88632 2.09547 10.2771 2.68162 10.5653 3.36545C10.8535 4.04929 11 4.75755 11 5.50977C11 6.25222 10.8535 6.96536 10.5653 7.64432C10.2771 8.32327 9.88144 8.90941 9.39298 9.39787C8.90453 9.88632 8.31838 10.2771 7.63943 10.5653C6.96048 10.8535 6.25222 11 5.50488 11C4.75755 11 4.03952 10.8535 3.36057 10.5653C2.68162 10.2771 2.09547 9.88144 1.60213 9.39298C1.10879 8.90453 0.722913 8.31838 0.42984 7.64432C0.136767 6.97025 0 6.2571 0 5.50977ZM1.21137 5.50977C1.21137 6.66741 1.63144 7.67362 2.47647 8.52842C3.32149 9.37345 4.32771 9.79352 5.50488 9.79352C6.27664 9.79352 6.99467 9.60302 7.6492 9.21714C8.30373 8.83126 8.83126 8.3135 9.21714 7.65409C9.60302 6.99467 9.7984 6.28153 9.7984 5.50977C9.7984 4.73801 9.60302 4.01998 9.21714 3.36057C8.83126 2.70115 8.30861 2.17851 7.6492 1.79263C6.98979 1.40675 6.27664 1.21625 5.50488 1.21625C4.73313 1.21625 4.0151 1.40675 3.36057 1.79263C2.70604 2.17851 2.17851 2.70115 1.78774 3.36057C1.39698 4.01998 1.21137 4.73801 1.21137 5.50977ZM5.07993 5.50977V2.25666C5.07993 2.14432 5.11901 2.04663 5.19716 1.96847C5.27531 1.89032 5.373 1.85124 5.48535 1.85124C5.59769 1.85124 5.69538 1.89032 5.77353 1.96847C5.85169 2.04663 5.89076 2.14432 5.89076 2.25666V5.26066L7.64432 6.28153C7.74201 6.34014 7.80062 6.42318 7.82993 6.53064C7.85924 6.6381 7.84458 6.74067 7.78597 6.83348C7.70782 6.97025 7.59059 7.03863 7.43428 7.03863C7.35124 7.03863 7.28286 7.01909 7.22913 6.98002L5.36323 5.89076C5.2802 5.86634 5.21181 5.8175 5.15808 5.74911C5.10435 5.68073 5.07993 5.60258 5.07993 5.50977Z" fill="#6F7072" />
                                                                            </svg>

                                                                            {/* // commented by dasprakash on 23-10-2023 jira-1677 */}
                                                                            {/* Updated Time */}

                                                                            {/* // addded by dasprakash on 23-10-2023 jira-1677 */}
                                                                            Request Time {req.statusUpdatedTime}</div>
                                                                        {req.reason !== "" &&
                                                                            // addded by dasprakash on 23-10-2023 jira-1677 
                                                                            <div style={{ fontSize: "14px" }} className="help">
                                                                                <span className="request-time">Reason: </span>{req.reason}dasprakaash
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                    {req.status === "Open" && <div className="status-open">{req.status}</div>}
                                                                    {req.status === "Processing" && <div className="status-process">{req.status}</div>}
                                                                    {req.status === "Acknowledge" && <div className="status-closed">{req.status}</div>}
                                                                    {req.status === "Closed" && <div className="status-closed">{req.status}</div>}
                                                                    {req.status === "Cancelled" && <div className="status-cancelled">{req.status}</div>}


                                                                </div>
                                                            </li>

                                                        )
                                                    }
                                                </ul>
                                            </div>
                                            {this.state.guestRequestList.length === 0 &&
                                                <div>
                                                    <div className="no-data-img"><img src="/img/no-image/no-data.png" alt="lucid-no-orders" /></div>
                                                </div>
                                            }

                                        </TabPanel>
                                    </Tabs>
                                    {/* {this.handleGenerateModifierDom()} */}
                                    {/* <ul className="modifier-group-li">
                                        {this.props.modifierGroupIds.map(mid =>
                                            <div key={"modifier-group-id-" + mid}>
                                                <li key={mid}>
                                                    {this.props.modifierList.filter(mgrplst => mgrplst.modifierGroupId === mid).map(mgrp =>
                                                        <div key={"modifier-group-" + mgrp.modifierGroupId}>
                                                            <span className="group-name">{mgrp.minimumSelection > 0 && <span className="minimum-selection">*</span>}{mgrp.groupName}</span>

                                                            {mgrp.maximumSelection === 0 &&
                                                                <span> (<span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>)</span>
                                                            }
                                                            {mgrp.maximumSelection > 0 &&
                                                                <span> (
                                                                <span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>
                                                                /
                                                                <span id={"max-group-modifier-cnt-" + mgrp.modifierGroupId}>{mgrp.maximumSelection}</span>
                                                                )</span>
                                                            }

                                                            <ul className="modifier-li">
                                                                {mgrp.modifiers.map(mlst =>
                                                                    <li key={"modifier-li-" + mlst.modifierId}>
                                                                        {mgrp.maximumSelection === 2 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                                        {mgrp.maximumSelection === 1 && <Radio name={mgrp.modifierGroupId} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.radiostatus} onChange={(e) => this.handleRadioChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Radio>}
                                                                        {mgrp.maximumSelection === 0 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                                        {mlst.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                        {mlst.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                        {mlst.modifierName}<span className="rate-inr"> ₹{mlst.rate}</span>
                                                                    </li>

                                                                )
                                                                }
                                                            </ul>
                                                        </div>
                                                    )}
                                                </li>
                                            </div>
                                        )}
                                    </ul> */}
                                </div>
                                {/* <div className="modal-footer">
                                    <div className="cart" >
                                        <div className="c-left"> Item Total  ₹<span >110</span></div>
                                        <div className="c-right" data-dismiss="modal">Add Item</div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div >
            </>

        );
    }
}
