import React, { Component } from 'react';
import './settleprocesssuccess.scss';
import LoadingAnimation from '../../components/checkmarkanimation';
import { isMobile } from 'react-device-detect';
import Config from '../../data/SiteConfig';
let urlPattern = '';
export default class ProcessSuccess extends Component {
    constructor(props) {
        super(props);
        this.gotoShopping = this.gotoShopping.bind(this);
        this.gotoMyOrders = this.gotoMyOrders.bind(this);
        this.state = {
            cartValue: 0,
            loading: false,
            billSuccess: false
        }
    }
    componentWillMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (this.props.location.state !== undefined) {
            if (this.props.location.state.billSuccess === undefined || this.props.location.state.billSuccess === null || this.props.location.state.billSuccess === false) {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else {

                this.setState({
                    billSuccess: this.props.location.state.billSuccess
                });
            }
        }
        else {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/myorders' + urlPattern);
            }, 200);
        }

    }
    gotoShopping() {
        this.props.history.push('/items' + urlPattern);
    }
    gotoMyOrders() {
        this.props.history.push('/myorders' + urlPattern);
    }
    render() {
        return (
            <div>
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-settleprocesssuccess">
                            <div className="heading-page" ref="recommended">
                                {sessionStorage.getItem("user-propertyname")}
                                <span className="payment-txt">{sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")} </span>
                                {/* <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fas fa-arrow-left" aria-hidden="true"></i></div>
                                </div> */}
                            </div>
                            <div className="content-from">
                                <div className="message-details">
                                    <div className="content">
                                        <div><LoadingAnimation /></div>
                                        <div className="msg">Payment was completed successfully.</div>
                                        <div className="msg-order-id">Thank you!</div>
                                        {/* <div className="msg-order-id">Order Id : <span style={{fontSize: "16px", color: "#212529"}}>{this.state.orderId}</span></div> */}
                                        <div><button className="theme-button btn" onClick={this.gotoShopping}>Continue Shopping </button></div>
                                        <div className="btn-orders" onClick={this.gotoMyOrders}>Go to Orders</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}

