import React, { Component, Fragment } from 'react';
import './payment.scss';
//import LoadingAnimation from '../../components/loadinganimation/paymentloading';
import LoadingAnimation from '../../components/loadinganimation';
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
//import GooglePayButton from '@google-pay/button-react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Radio from '../../components/radionbutton/radiobutton';
//added by dasprakash on 15-11-2023 jira-1802
import Currency from '../../components/currency';


const podImg = '/img/payment/lucid-pod.png';
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
let olDetail = {};
let outletDetails = null;
let mainGuestMobileNo = '';
let urlPattern = '';
// const paymentMethods = [
//     {
//         type: 'CARD',
//         parameters: {
//             allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
//             allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
//         },
//         tokenizationSpecification: {
//             type: 'PAYMENT_GATEWAY',
//             parameters: {
//                 // 'gateway': 'stripe',
//                 // 'stripe:version': '2019-03-14',
//                 // 'stripe:publishableKey': '<YOUR_PUBLIC_STRIPE_KEY>'
//                 gateway: 'example',
//                 gatewayMerchantId: 'exampleGatewayMerchantId'
//             }
//         }
//     },
//     {
//         type: 'PAYPAL',
//         parameters: {
//             'purchase_context': {
//                 'purchase_units': [{
//                     'payee': {
//                         'merchant_id': 'BCR2DN6TV77ITYBK'
//                     }
//                 }]
//             }
//         },
//         tokenizationSpecification: {
//             type: 'DIRECT'
//         }
//     }
// ]
export default class Payment extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.gotoThirdParty = this.gotoThirdParty.bind(this);
        this.handlePrepaidCardBalance = this.handlePrepaidCardBalance.bind(this);
        this.gotoPrepaidCardVerification = this.gotoPrepaidCardVerification.bind(this);
        this.state = {

            cartValue: 1500,
            paymentList: [
                { vendorId: 3, vendorName: "Razorpay", imgUrl: "/img/payment/razorpay.png" },
                { vendorId: 2, vendorName: "Instamojo", imgUrl: "/img/payment/instamojo.png" },
                // { paymentId: 2, paymentName: "CCAvenue", imgUrl: "/img/payment/ccavenue.png" },
                // { paymentId: 3, paymentName: "Paytm", imgUrl: "/img/payment/paytm.png" },
                // { paymentId: 4, paymentName: "GPay", imgUrl: "/img/payment/gpay.png" },
                // { paymentId: 5, paymentName: "PhonePe", imgUrl: "/img/payment/phonepe.png" },
            ],
            onlineWalletVendorsList: [
                { vendorId: 1, vendorName: "LUCID Prepaid Card", imgUrl: "/img/payment/lucid-prepadicard.png" }
            ],
            loading: false,
            alertMessage: '',
            msgShow: false,
            totalItems: 0,
            totalNetAmount: 0,
            vendorKey: '',
            vendorTransactionId: '',
            vendorOrderId: '',
            paymentVendorLogo: '',
            prepaidCardBalance: 0,
            radiostatus: 0,
            selected: 'radio-0'
        }
        this.handlePayOnRadioChange = this.handlePayOnRadioChange.bind(this);
    }

    componentWillMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletDetails = sessionStorage.getItem('user-outletDetails');
        urlPattern = sessionStorage.getItem('url-pattern');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        // console.log(mobileNo);
        // console.log(token);
        // console.log(tableNo);
        // console.log(serviceType);
        // console.log(orgnationTd);
        // console.log(propertyId);
        // console.log(outletCode);

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }

        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDetails === undefined || outletDetails === "" || outletDetails === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            olDetail = JSON.parse(outletDetails);
            this.setState({ paymentVendorLogo: olDetail.outletImageUrl !== '' ? olDetail.outletImageUrl : "https://cdn.razorpay.com/logos/BUVwvgaqVByGp2_large.png" });
            if (olDetail.paymentVendors.length === 1 && olDetail.onlineWalletVendors.length === 0) // Single Vendor to direct
                this.gotoThirdParty(olDetail.paymentVendors[0].vendorId, olDetail.paymentVendors[0].vendorName);

        }

        /// Session Validation for Fine Dine
        if (serviceType === "FINE-DINE") {
            if (!sHelpers.sessionValidation()) {
                this.showMessage("Your session has expired, please scan once again", "danger");
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/logout' + urlPattern);
                }, Config.minMsgTimeout);
                return false;
            }
        }

        if (sessionStorage.getItem("cartList") === null) {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, Config.minMsgTimeout);
        }


        // Validate Vendor Order Id ()
        if (sessionStorage.getItem('user-vendorOrderId') === undefined || sessionStorage.getItem('user-vendorOrderId') === null || sessionStorage.getItem('user-vendorOrderId') === '') {
            this.setState({ loading: false });
            this.props.history.push('/cart' + urlPattern);
            return false;
        }

        if (this.props.location.state !== undefined) {

            if (this.props.location.state.totalNetAmount === undefined || this.props.location.state.totalNetAmount === null || this.props.location.state.totalNetAmount === 0) {
                this.setState({ loading: true });
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/items' + urlPattern);
                }, Config.minMsgTimeout);
            }
            else {

                this.setState({
                    totalItems: this.props.location.state.totalItems,
                    totalNetAmount: this.props.location.state.totalNetAmount
                })
            }
        }
        else {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, Config.minMsgTimeout);
        }
        window.scrollTo(0, 0);

        sessionStorage.removeItem('proceed-pod');
    }
    loadPaymentDataHandler = paymentData => {
        const paymentToken = paymentData.paymentMethodData.tokenizationData.token
        console.log(paymentToken);
    }
    submitClick = (e) => {
        e.preventDefault();

        // e.preventDefault();
        e.click();
        //this.inputElement.click();
    }

    goBack() {
        //this.props.history.goBack();
        this.props.history.push('/cart' + urlPattern);
    }
    gotoThirdParty(vendorId, vendorName) {

        // Validate Vendor Order Id ()
        if (sessionStorage.getItem('user-vendorOrderId') === undefined || sessionStorage.getItem('user-vendorOrderId') === null || sessionStorage.getItem('user-vendorOrderId') === '') {
            this.setState({ loading: false });
            this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
            setTimeout(() => {
                this.props.history.push('/cart' + urlPattern);
            }, Config.minMsgTimeout);

            return false;
        }

        // e.preventDefault();
        this.setState({ loading: true });

        setTimeout(() => {
            var postObj = {
                OutletId: propertyId + outletCode,
                PaymentVendorId: vendorId,
                Amount: this.state.totalNetAmount.toFixed(2),
                GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                MobileNoCountryCode: "91",
                // MobileNo: mainGuestMobileNo,
                MobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo,
                EmailId: "",
                OrderId: "",
                BillId: "",
                RedirectUrl: Config.redirectUrl
            }

            //  console.log(postObj);
            // API Call
            fetch(Config.basePaymentUrl + 'InitiateOnlinePayment', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    // console.log(response);
                    if (response.errorCode === 0) {
                        setTimeout(() => {
                            sessionStorage.setItem("paymentInitiatedId", response.response.paymentInitiatedId);
                            sessionStorage.setItem("vendorTransactionId", response.response.vendorTransactionId);
                            sessionStorage.setItem("vendorId", vendorId);
                            sessionStorage.setItem("vendorName", vendorName);
                            sessionStorage.setItem("paymentStatus", response.response.status);
                            if (vendorId === 2) {        // instamojo                         
                                window.location.href = response.response.vendorRedirectUrl;
                                sessionStorage.setItem('redirect-page', "paymentverification");
                            }
                            else if (vendorId === 3) { // Razor Pay
                                this.setState({
                                    vendorTransactionId: response.response.vendorTransactionId,
                                    vendorKey: response.response.vendorKey
                                })
                                setTimeout(() => {
                                    sessionStorage.setItem('redirect-page', "paymentverification");
                                    this.inputElement.click();
                                }, Config.minMsgTimeout);
                            }
                            else {
                                this.setState({ loading: true });
                                this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
                                setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/cart' + urlPattern); }, 1500);
                            }

                            //window.location.href ="https://www.instamojo.com/@gatewayofficeparks/c57b613d098242c4adf6893321a78b35";
                            // this.props.history.push('/paymentVerification?payment_status=Failed');
                        }, Config.minMsgTimeout);
                    }
                    else {
                        //  console.log(response.message);
                        this.setState({ loading: true });
                        this.showMessage('OOPS! Initial transaction failed [' + response.message + ']. Please try again', "danger");
                        setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/cart' + urlPattern); }, Config.minMsgTimeout);
                    }
                })
                .catch(err => {
                    //  console.log(err);
                    this.setState({ loading: true });
                    this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
                    setTimeout(() => { this.setState({ loading: false }); this.props.history.push('/cart' + urlPattern); }, Config.minMsgTimeout);
                });
        }, 2000);
    }

    //added by dasprakash on 16-11-2023 jira-1802 
    handleLucidCardPayOnRadioChange(vendorId, vendorName, accountId) {
        sessionStorage.setItem("vendorId", vendorId);
        sessionStorage.setItem("vendorName", vendorName);
        sessionStorage.setItem("prepaidCardAccountId", accountId);
    }

    handlePrepaidCardBalance(vendorId, vendorName, rowId) {
        //added by dasprakash on 16-11-2023 jira-1802 
        sessionStorage.setItem("vendorId", "");
        sessionStorage.setItem("vendorName", "");
        sessionStorage.setItem("prepaidCardAccountId", "");

        this.setState({ loading: true });
        //  this.handleMultiPrepaidCardAccountBalance(vendorId, []);

        var postObj = {
            OutletId: propertyId + outletCode,
            VendorId: vendorId,
            /// GuestMobileNo: mainGuestMobileNo
            GuestMobileNo: (mainGuestMobileNo === '' || mainGuestMobileNo === "0000000000") ? mobileNo : mainGuestMobileNo
        }
        //  console.log(postObj);
        // API Call
        fetch(Config.basePOSUrl + 'GetGuestOnlineWalletDetails', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        let activeCardList = response.response.walletDetails.filter(c => c.remarks === "Card is Valid");
                        if (activeCardList.length > 0) {
                            this.setState({ loading: false });
                            confirmAlert({
                                customUI: ({ onClose }) => {
                                    return (
                                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                            <svg onClick={onClose} style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "20px" }} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM10.2235 29.7706C9.73538 29.2824 9.73538 28.491 10.2235 28.0028L18.2322 19.9941L10.2309 11.9928C9.74277 11.5047 9.74277 10.7132 10.2309 10.2251C10.7191 9.73691 11.5105 9.73691 11.9987 10.2251L20 18.2264L28.0013 10.2251C28.4895 9.73691 29.2809 9.73691 29.7691 10.2251C30.2572 10.7132 30.2572 11.5047 29.7691 11.9928L21.7678 19.9941L29.7765 28.0028C30.2646 28.491 30.2646 29.2824 29.7765 29.7706C29.2883 30.2588 28.4969 30.2588 28.0087 29.7706L20 21.7619L11.9913 29.7706C11.5031 30.2588 10.7117 30.2588 10.2235 29.7706Z" fill="black" />
                                            </svg>
                                            <div className="prepaid-card-list">
                                                {/* //commented by dasprakash on 16-11-2023 jira-1802  */}
                                                {/* <h6>Select card to pay</h6> */}
                                                {/* //style added by dasprakash on 16-11-2023 jira-1802  */}
                                                <div style={{ fontSize: "16px", fontWeight: "500", color: "#000" }} className="itm-total-txt">
                                                    {/* //added by dasprakash on 16-11-2023 jira-1802  */}
                                                    <span>{vendorName}</span>
                                                    {/* //commented by dasprakash on 16-11-2023 jira-1802  */}
                                                    {/* {this.state.totalItems} items, To pay: ₹{this.state.totalNetAmount.toFixed(2)} */}
                                                    {/* //added by dasprakash on 16-11-2023 jira-1802  */}
                                                    Total:₹{this.state.totalNetAmount.toFixed(2)}
                                                </div>
                                                <ul id="card-li-list">
                                                    {
                                                        activeCardList.map((acc, idx) =>
                                                            <li key={"card-account" + idx}>
                                                                {acc.availableBalance > this.state.totalNetAmount &&
                                                                    <div className="content prepaid-card-account">
                                                                        <div style={{ width: "70%", display: "flex" }}>
                                                                            <img src={this.state.onlineWalletVendorsList[0].imgUrl} alt={this.state.onlineWalletVendorsList[0].vendorName} />
                                                                            <div style={{ marginLeft: "15px" }}>
                                                                                <span className="pay-wallet-bal">Card No:{acc.accountId}</span>
                                                                                {/* //commented by dasprakash on 16-11-2023 jira-1802                                                                            */}
                                                                                {/* <span className="pay-wallet-bal">Wallet Balance ₹{acc.availableBalance} </span> */}
                                                                                {/* //added by dasprakash on 16-11-2023 jira-1802  */}
                                                                                <span className="pay-wallet-bal">Balance:₹{acc.availableBalance} </span>
                                                                            </div>
                                                                        </div>

                                                                        <div style={{ marginRight: "10px" }}>
                                                                            {/* <span className="pay-btn" onClick={this.gotoPrepaidCardVerification.bind(this, vendorId, vendorName, acc.accountId, onClose)}>
                                                                                Pay
                                                                            </span> */}
                                                                            {/* //added by dasprakash on 16-11-2023 jira-1802  */}
                                                                            <Radio name={"lucid-PrepaidCard-mode"} id={"lucid-PrepaidCard-mode-chk-" + idx} onChange={(e) => this.handleLucidCardPayOnRadioChange(vendorId, vendorName, acc.accountId)} value="" ></Radio>
                                                                        </div>

                                                                    </div>
                                                                }
                                                                {/* //commented by dasprakash on 16-11-2023 jira-1802  */}
                                                                {/* {acc.availableBalance < this.state.totalNetAmount &&
                                                                    <div className="content prepaid-card-account">
                                                                        {acc.accountId}
                                                                        <span className="non-pay-btn">Insufficient  Wallet Balance ₹{acc.availableBalance} </span>
                                                                    </div>
                                                                } */}

                                                                {/* //added by dasprakash on 16-11-2023 jira-1802  */}
                                                                {acc.availableBalance < this.state.totalNetAmount &&
                                                                    <div className="content prepaid-card-account">
                                                                        <div style={{ width: "70%", display: "flex", opacity: "0.5" }}>
                                                                            <img src={this.state.onlineWalletVendorsList[0].imgUrl} alt={this.state.onlineWalletVendorsList[0].vendorName} />
                                                                            <div style={{ marginLeft: "15px" }}>
                                                                                <span className="pay-wallet-bal">Card No:{acc.accountId}</span>
                                                                                {/* //commented by dasprakash on 16-11-2023 jira-1802                                                                            */}
                                                                                {/* <span className="pay-wallet-bal">Wallet Balance ₹{acc.availableBalance} </span> */}
                                                                                {/* //added by dasprakash on 16-11-2023 jira-1802  */}
                                                                                <span className="pay-wallet-bal">Balance:₹{acc.availableBalance} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div >
                                                                            <span className="non-pay-btn">Insufficient Balance</span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </li>
                                                        )
                                                    }

                                                </ul>
                                                {/* //commented by dasprakash on 16-11-2023 jira-1802  */}
                                                {/* <button onClick={onClose}>Cancel</button> */}
                                                {/* //added by dasprakash on 16-11-2023 jira-1802  */}
                                                <div className='payondelivery-buttons-v2'>
                                                    <div className='payondelivery-buttons-v2-2' onClick={onClose}>
                                                        Cancel
                                                    </div>
                                                    <div className='payondelivery-buttons-v2-1' onClick={() => { this.gotoPrepaidCardVerification(onClose); onClose(); }}>
                                                        Proceed
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    );
                                }
                            });
                        }
                        // else if (activeCardList.length === 1) {
                        //     const payclick = document.getElementById("" + rowId + "");
                        //     payclick.innerHTML = '<span onClick="' + this.gotoPrepaidCardVerification.bind(this, vendorId, vendorName, activeCardList[0].accountId, null) + '" style="float: right;border: 1px solid #d12f8b;padding: 2px 8px 4px 8px;font-size: 14px;font-weight: 500;">Pay ₹' + activeCardList[0].availableBalance + '</span>'; //'<span style="float: right;border: 1px solid #d12f8b;padding: 2px 8px 4px 8px;font-size: 14px;font-weight: 500;">Pay ₹' + activeCardList[0].availableBalance + '</span>';
                        //     // payclick.onClick(this.gotoPrepaidCardVerification.bind(this, vendorId, vendorName, activeCardList[0].accountId, null));
                        //     //document.getElementById("" + rowId + "").innerHTML = this.SingleCardPayDom(vendorId, vendorName, activeCardList[0].accountId, activeCardList[0].availableBalance); // '<span onClick="' + this.gotoPrepaidCardVerification.bind(this, vendorId, vendorName, activeCardList[0].accountId, null) + '" style="float: right;border: 1px solid #d12f8b;padding: 2px 8px 4px 8px;font-size: 14px;font-weight: 500;">Pay ₹' + activeCardList[0].availableBalance + '</span>';
                        // }
                        else {
                            this.showMessage('OOPS! Card detail is not available', "danger");
                        }
                    }
                }
                else {
                    //console.log(response.message);
                    //this.showMessage('OOPS! Get Balance failed [' + response.message + ']. Please try again', "danger");
                    this.showMessage(response.message, "danger");
                }
                this.setState({ loading: false });
            })
            .catch(err => {
                //console.log(err);
                this.setState({ loading: false });
                this.showMessage('OOPS! Get Balance failed. Please try again', "danger");
            });
    }

    gotoPrepaidCardVerification(vendorId, vendorName, accountId, close) {

        if (close != null) close();
        this.setState({ loading: true });
        if (sessionStorage.getItem('user-vendorOrderId') === undefined || sessionStorage.getItem('user-vendorOrderId') === null || sessionStorage.getItem('user-vendorOrderId') === '') {
            this.setState({ loading: false });
            this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
            setTimeout(() => {
                this.props.history.push('/cart' + urlPattern);
            }, Config.minMsgTimeout);

            return false;
        }
        //commented by dasprakash on 16-11-2023 jira-1802 
        // sessionStorage.setItem("vendorId", vendorId);
        // sessionStorage.setItem("vendorName", vendorName);
        // sessionStorage.setItem("prepaidCardAccountId", accountId);

        setTimeout(() => {
            this.props.history.push('/prepaidcardverification' + urlPattern);
        }, 1500);

    }

    handlePayOnDelivery() {

        sessionStorage.setItem("payonDeliveryMode", '');
        this.setState({ loading: true });
        if (olDetail.payOnDeliveryModes.length > 0) {
            this.setState({ loading: false });
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <svg onClick={this.gotoClosePayOnDelivery.bind(this, onClose)} style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "20px" }} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM10.2235 29.7706C9.73538 29.2824 9.73538 28.491 10.2235 28.0028L18.2322 19.9941L10.2309 11.9928C9.74277 11.5047 9.74277 10.7132 10.2309 10.2251C10.7191 9.73691 11.5105 9.73691 11.9987 10.2251L20 18.2264L28.0013 10.2251C28.4895 9.73691 29.2809 9.73691 29.7691 10.2251C30.2572 10.7132 30.2572 11.5047 29.7691 11.9928L21.7678 19.9941L29.7765 28.0028C30.2646 28.491 30.2646 29.2824 29.7765 29.7706C29.2883 30.2588 28.4969 30.2588 28.0087 29.7706L20 21.7619L11.9913 29.7706C11.5031 30.2588 10.7117 30.2588 10.2235 29.7706Z" fill="black" />
                            </svg>
                            <div className="prepaid-card-list">
                                {/* //commented by dasprakash on 15-11-2023 jira-1802 */}
                                {/* <h6>Pay On Delivery Mode</h6> */}

                                {/* //added by dasprakash on 15-11-2023 jira-1802 */}
                                <div className="itm-total-txt">
                                    <span className='payondelivery-v2'>Pay On Delivery</span>
                                    {/* //added by dasprakash on 16-11-2023 jira-1802 */}
                                    <span style={{ fontSize: "16px", color: "#000", fontWeight: "500" }}>
                                        Total:₹{this.state.totalNetAmount.toFixed(2)}
                                    </span>
                                    {/* //commented by dasprakash on 16-11-2023 jira-1802 */}
                                    {/* {this.state.totalItems} items, To pay: ₹{this.state.totalNetAmount.toFixed(2)} */}
                                </div>
                                <ul id="card-li-list">
                                    {
                                        olDetail.payOnDeliveryModes.map((acc, idx) =>
                                            <li key={"card-account" + idx}>
                                                <div className="content prepaid-card-account">
                                                    <div style={{ float: "right", marginTop: "-1px", fontWeight: "500" }}>{acc}</div>
                                                    <Radio name={"pay-mode"} id={"pay-mode-chk-" + idx} onChange={(e) => this.handlePayOnRadioChange(e, acc)} value={acc} ></Radio>
                                                </div>
                                            </li>
                                        )
                                    }

                                </ul>
                                {/* //commented by dasprakash on 16-11-2023 jira-1802  */}
                                {/* <button onClick={this.gotoProceedPayOnDelivery.bind(this, onClose)}>Proceed</button>
                                <button onClick={this.gotoClosePayOnDelivery.bind(this, onClose)}>Cancel</button> */}
                                {/* //added by dasprakash on 16-11-2023 jira-1802  */}
                                <div className='payondelivery-buttons-v2'>
                                    <div className='payondelivery-buttons-v2-2' onClick={this.gotoClosePayOnDelivery.bind(this, onClose)}>
                                        Cancel
                                    </div>
                                    <div className='payondelivery-buttons-v2-1' onClick={this.gotoProceedPayOnDelivery.bind(this, onClose)}>
                                        Proceed
                                    </div>
                                </div>
                            </div>
                        </div>


                    );
                }
            });

            setTimeout(() => {
                document.getElementById('pay-mode-chk-' + 0).setAttribute('checked', true);
                let val = document.getElementById('pay-mode-chk-' + 0).value;
                sessionStorage.setItem("payonDeliveryMode", val);
            }, 500);
        }
    }

    handlePayOnRadioChange(e, value) {
        sessionStorage.setItem("payonDeliveryMode", value);
        // console.log(sessionStorage.getItem('payonDeliveryMode'));
    }

    gotoClosePayOnDelivery(close) {
        if (close != null) close();
        sessionStorage.setItem("payonDeliveryMode", '');
    }
    gotoProceedPayOnDelivery(close) {
        this.setState({ loading: true });
        if (sessionStorage.getItem('payonDeliveryMode') === undefined || sessionStorage.getItem('payonDeliveryMode') === null || sessionStorage.getItem('payonDeliveryMode') === '') {
            if (close != null) close();
            this.setState({ loading: false });
            this.showMessage('OOPS! Initial transaction failed. Please try again', "danger");
            setTimeout(() => {
                this.props.history.push('/cart' + urlPattern);
            }, 1500);

            return false;
        }

        if (close != null) close();
        sessionStorage.setItem('proceed-pod', true);
        setTimeout(() => {
            this.props.history.push('/payondeliveryverification' + urlPattern);
        }, 1500);
    }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

    render() {

        return (
            <div> {this.state.loading && <LoadingAnimation />}
                <div style={{ backgroundColor: "#F5F5F5" }} id="content" className="top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <div className="content-warp">
                        <div className="section-payment">
                            {/* style modified by vijay on 04-11-2023 */}
                            <div className="heading-page" ref="recommended" style={{ textAlign: "center", padding: "0px" }}>
                                {/* //added by dasprakash on 15-11-2023 jira-1802 */}
                                <span style={{ fontSize: "16px", fontWeight: "500", color: "#000" }}>
                                    Payments
                                </span>
                                {/* //commented by dasprakash on 15-11-2023 jira-1802 */}
                                {/* <span className="payment-txt">{this.state.totalItems} items, To pay: ₹{this.state.totalNetAmount.toFixed(2)} </span> */}
                                {/* //added by dasprakash on 15-11-2023 jira-1802 */}
                                <span style={{ fontSize: "16px", fontWeight: "500", color: "#000", justifyContent: "center" }} className="payment-txt">Total: <Currency />{this.state.totalNetAmount.toFixed(2)} </span>
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}>

                                        {/* //commented by dasprakash on 15-11-2023 jira-1802 */}
                                        {/* <i className="fa fa-angle-left" ></i> */}

                                        {/* //added by dasprakash on 15-11-2023 jira-1802 */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="22" viewBox="0 0 13 22" fill="none">
                                            <path d="M12 21L2 11L12 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                    </div>
                                </div>
                            </div>


                            <div className="content-from m-t-contact-form">
                                <div className="gateway-details">
                                    {
                                        this.state.paymentList.map((pay, i) => {
                                            return (
                                                <Fragment key={"pay-main-div-" + pay.vendorId}>
                                                    {
                                                        (olDetail.paymentVendors.length > 1 || olDetail.onlineWalletVendors.length > 0) &&
                                                        olDetail.paymentVendors.filter(x => x.vendorId === pay.vendorId).length > 0 &&
                                                        <div className="content" key={"pay-div-" + pay.vendorId} onClick={this.gotoThirdParty.bind(this, pay.vendorId, pay.vendorName)}>
                                                            <div>
                                                                <span className='payment-type-logo-v2' ><img src={pay.imgUrl} alt="" /></span>
                                                                <span style={{ fontSize: "15px" }} className="payment-name">{pay.vendorName}</span>
                                                            </div>
                                                            {/* //added by dasprakash on 15-11-2023 jira-1802 */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                                                                <path d="M1 1L9 9L1 17" stroke="#6F7072" strokeWidth="2" strokeLinecap="round" />
                                                            </svg>
                                                        </div>

                                                    }
                                                </Fragment>

                                            );
                                        })
                                    }
                                    {
                                        this.state.onlineWalletVendorsList.map((pay, i) => {
                                            return (
                                                <Fragment key={"pay-main-div-wallet-" + pay.vendorId}>
                                                    {
                                                        // olDetail.paymentVendors.length > 1 &&
                                                        olDetail.onlineWalletVendors.filter(x => x.vendorId === pay.vendorId).length > 0 &&
                                                        <div onClick={this.handlePrepaidCardBalance.bind(this, pay.vendorId, pay.vendorName, "pay-btn-balance-" + pay.vendorId)} className="content" key={"pay-div-card-" + pay.vendorId} >
                                                            <span>
                                                                <img style={{ height: "31px" }} src={pay.imgUrl} alt="" />
                                                                <span className="payment-name">{pay.vendorName}</span>
                                                                <span id={"pay-btn-balance-" + pay.vendorId}></span>
                                                            </span>
                                                            {/*/added by dasprakash on 15-11-2023 jira-1802 */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                                                                <path d="M1 1L9 9L1 17" stroke="#6F7072" strokeWidth="2" strokeLinecap="round" />
                                                            </svg>
                                                        </div>
                                                    }
                                                </Fragment>

                                            );
                                        })
                                    }
                                    {
                                        /* Pay on Delivery */

                                        (olDetail.enablePayOnDelivery && serviceType === Config.serviceType.DELIVERY &&
                                            <div onClick={this.handlePayOnDelivery.bind(this)} className="content" key={"pay-div-delivery-1"}>
                                                <div>
                                                    <span><img style={{ height: "30px" }} src={podImg} alt="lucid-pay-on- delivery" /></span>
                                                    <span className="payment-name">Pay On Delivery</span>
                                                </div>
                                                {/* //added by dasprakash on 15-11-2023 jira-1802 */}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
                                                    <path d="M1 1L9 9L1 17" stroke="#6F7072" strokeWidth="2" strokeLinecap="round" />
                                                </svg>
                                            </div>
                                        )

                                    }
                                    {
                                        (olDetail.paymentVendors.length === 1 && olDetail.onlineWalletVendors.length === 0) && <div className="content"><span>Please wait...</span></div>
                                        // <div className="content">
                                        //     <GooglePayButton
                                        //         environment="TEST" // after change production
                                        //         paymentRequest={{
                                        //             apiVersion: 2,
                                        //             apiVersionMinor: 0,
                                        //             allowedPaymentMethods: [
                                        //                 {
                                        //                     type: 'CARD',
                                        //                     parameters: {
                                        //                         allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                                        //                         allowedCardNetworks: ['MASTERCARD', 'VISA'],
                                        //                     },
                                        //                     tokenizationSpecification: {
                                        //                         type: 'PAYMENT_GATEWAY',
                                        //                         parameters: {
                                        //                             gateway: 'example',
                                        //                         },
                                        //                     },
                                        //                 },
                                        //             ],
                                        //             merchantInfo: {
                                        //                 // merchantId: '12345678901234567890',
                                        //                 // merchantName: 'Demo Merchant',
                                        //                 merchantId: 'BCR2DN6TV77ITYBK',
                                        //                 merchantName: 'LUCID IT Solutions',
                                        //             },
                                        //             transactionInfo: {
                                        //                 totalPriceStatus: 'FINAL',
                                        //                 totalPriceLabel: 'Total',
                                        //                 totalPrice: '1.00',
                                        //                 currencyCode: 'INR',
                                        //                 countryCode: 'IN',
                                        //             },
                                        //             shippingAddressRequired: false,
                                        //             callbackIntents: ["PAYMENT_AUTHORIZATION"],
                                        //         }}
                                        //         onLoadPaymentData={paymentRequest => {
                                        //             console.log('load payment data', paymentRequest);
                                        //         }}
                                        //         onPaymentAuthorized={paymentRequest => {
                                        //             console.log('load payment data', paymentRequest);
                                        //             // setTimeout(() => {
                                        //             //     this.setState({ loading: false });
                                        //             //     this.props.history.push('/gpaysuccess');
                                        //             // }, 2000);
                                        //             return { transaction: "success", }

                                        //         }}
                                        //         onCancel={paymentRequest => {
                                        //             console.log('load cancel data', paymentRequest);
                                        //         }}
                                        //         onError={paymentRequest => {
                                        //             console.log('load error data', paymentRequest);
                                        //         }}
                                        //         onReadyToPayChange={paymentRequest => {
                                        //             console.log('ready to data', paymentRequest);
                                        //         }}
                                        //         existingPaymentMethodRequired='false'
                                        //         buttonColor='white'
                                        //         buttonType='buy'
                                        //         buttonSizeMode='fill'
                                        //         style={{ color: "#ddd" }}
                                        //     />

                                        // </div>

                                    }
                                </div>
                            </div>
                            <div>
                                <form id="razorpay-submit" method="POST" action={Config.razorpayRedirectUrl} onSubmit={this.handleSubmit}>
                                    <input type="hidden" name="key_id" value={this.state.vendorKey} />
                                    <input type="hidden" name="order_id" value={this.state.vendorTransactionId} />
                                    <input type="hidden" name="name" value={sessionStorage.getItem("user-propertyname")} />
                                    <input type="hidden" name="description" value={sessionStorage.getItem("user-outletname")} />
                                    <input type="hidden" name="image" value={this.state.paymentVendorLogo} />
                                    <input type="hidden" name="prefill[name]" value="" />
                                    <input type="hidden" name="prefill[contact]" value={mobileNo} />
                                    <input type="hidden" name="prefill[email]" value="test@mail.com" />
                                    <input type="hidden" name="notes[shipping address]" value=" " />
                                    <input type="hidden" name="callback_url" value={Config.redirectUrl} />
                                    <input type="hidden" name="cancel_url" value={Config.redirectFailedUrl} />
                                    <button style={{ display: "none" }} ref={input => this.inputElement = input}>Submit</button>
                                    {/* <button style={{ display: "none" }} ref={(e) => this.submitClick.bind(e)}>Submit</button> */}

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}