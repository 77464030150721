import { Component } from "react";
import { withRouter } from "react-router-dom";
import Config from "../../data/SiteConfig";
import SecureStorage from "secure-web-storage";
let outletDetails = {};
let loggedDate = "";
let mobileNo = "";
let token = "";
let tableNo = "";
let serviceType = "";
let organizationId = "";
let propertyId = "";
let outletCode = "";
let guestName = "";
let appModeType = 0;
let statsId = 0;
let userSessionId = "";
let mainMobileNo = "";

var CryptoJS = require("crypto-js");
var SECRET_KEY = Config.secretkey;

class StaticHelpers extends Component {
	static enableGuestAppOrders = false;
	static enableDigitalMenu = false;

	// constructor() {
	//      super();
	// }

	static initiate() {
		outletDetails = JSON.parse(sessionStorage.getItem("user-outletDetails"));
		loggedDate = sessionStorage.getItem("user-loggedin-date");

		mobileNo = sessionStorage.getItem("user-mobileno");
		token = sessionStorage.getItem("user-token");
		tableNo = sessionStorage.getItem("user-tableno");
		serviceType = sessionStorage.getItem("user-servicetype");
		organizationId = sessionStorage.getItem("user-orgnationid");
		propertyId = sessionStorage.getItem("user-propertyid");
		outletCode = sessionStorage.getItem("user-outletcode");
		mainMobileNo = sessionStorage.getItem('main-guest-no');
		guestName =
			sessionStorage.getItem("guest-name") === null
				? ""
				: sessionStorage.getItem("guest-name");
		appModeType =
			outletDetails != null
				? outletDetails.enableGuestAppOrders === true
					? 1
					: 0
				: 0;
		statsId = sessionStorage.getItem("user-statsid");
		userSessionId = sessionStorage.getItem("user-session-id");
	}

	static StaticSwitchCtrl() {
		this.initiate();
		this.enableGuestAppOrders = outletDetails.enableGuestAppOrders;
		this.enableDigitalMenu = outletDetails.enableDigitalMenu;
	}

	static sessionValidation() {
		this.initiate();
		let cDate = new Date();
		let logCurrentDate = this.getFormattedDate(cDate);

		if (Date.parse(loggedDate) < Date.parse(logCurrentDate)) {
			return false;
		}
		return true;
	}

	static inputParamValidation() {
		this.initiate();

		if (token === undefined || token === "" || token === null) {
			return false;
		}
		if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
			return false;
		}
		if (tableNo === undefined || tableNo === "" || tableNo === null) {
			return false;
		}

		if (
			mainMobileNo === undefined ||
			mainMobileNo === "" ||
			mainMobileNo === null  
		) {
			return false;
		}
		if (
			serviceType === undefined ||
			serviceType === "" ||
			serviceType === null
		) {
			return false;
		}
		if (
			organizationId === undefined ||
			organizationId === "" ||
			organizationId === null
		) {
			return false;
		}
		if (propertyId === undefined || propertyId === "" || propertyId === null) {
			return false;
		}
		if (outletCode === undefined || outletCode === "" || outletCode === null) {
			return false;
		}
		return true;
	}

	static getFormattedDate(date) {
		var year = date.getFullYear();
		var month = (1 + date.getMonth()).toString();
		month = month.length > 1 ? month : "0" + month;
		var day = date.getDate().toString();
		day = day.length > 1 ? day : "0" + day;
		return year + "-" + month + "-" + day;
	}

	static roundOff(Type, Value, Nearest) {
		var _roundOffValue = Value;
		if (Type === Config.roundOffType.Higher) {
			// if (Nearest === 1)
			//     _roundOffValue = Math.ceil(Value / 1) * 1;
			// else if (Nearest === 5)
			//     _roundOffValue = Math.ceil(Value / 5) * 5;
			// else if (Nearest === 10)
			//     _roundOffValue = Math.ceil(Value / 10) * 10;
			// else if (Nearest === 50)
			//     _roundOffValue = Math.ceil(Value / 50) * 50;
			// else if (Nearest === 100)
			//     _roundOffValue = Math.ceil(Value / 100) * 100;
			_roundOffValue = Math.ceil(Value / Nearest) * Nearest;
		}
		// Insert the Code on 02-01-2021 - Jira Id -
		else if (Type === Config.roundOffType.Lower) {
			// if (Nearest === 1)
			//     _roundOffValue = Math.floor(Value);

			// else if (Nearest === 5)
			//     _roundOffValue = Math.floor(Value / 5) * 5;
			// else if (Nearest === 10)
			//     _roundOffValue = Math.floor(Value / 10) * 10;
			// else if (Nearest === 50)
			//     _roundOffValue = Math.floor(Value / 50) * 50;
			// else if (Nearest === 100)
			//     _roundOffValue = Math.floor(Value / 100) * 100;
			_roundOffValue = Math.floor(Value / Nearest) * Nearest;
		} else if (Type === Config.roundOffType.Nearest) {
			// if (Nearest === 1)
			//     _roundOffValue = Math.round(Value);
			// else if (Nearest === 5) {
			//     if (Value % 5 === 0)
			//         _roundOffValue = Value;
			//     else
			//         (Value % 5 < 2.50 ? _roundOffValue = Math.floor(Value / 5) * 5 : _roundOffValue = Math.ceil(Value / 5) * 5);
			// }
			// else if (Nearest === 10) {
			//     if (Value % 10 === 0)
			//         _roundOffValue = Value;
			//     else
			//         (Value % 10 < 5 ? _roundOffValue = Math.floor(Value / 10) * 10 : _roundOffValue = Math.ceil(Value / 10) * 10);
			// }
			// else if (Nearest === 50) {
			//     if (Value % 50 === 0)
			//         _roundOffValue = Value;
			//     else
			//         (Value % 50 < 25 ? _roundOffValue = Math.floor(Value / 50) * 50 : _roundOffValue = Math.ceil(Value / 50) * 50);
			// }
			// else if (Nearest === 100) {
			//     if (Value % 100 === 0)
			//         _roundOffValue = Value;
			//     else
			//         (Value % 100 < 50 ? _roundOffValue = Math.floor(Value / 100) * 100 : _roundOffValue = Math.ceil(Value / 100) * 100);
			// }
			if (Nearest === 1) _roundOffValue = Math.round(Value);
			else {
				if (Value % Nearest === 0) {
					_roundOffValue = Value;
				} else {
					Value % Nearest < Nearest / 2
						? (_roundOffValue = Math.floor(Value / Nearest) * Nearest)
						: (_roundOffValue = Math.ceil(Value / Nearest) * Nearest);
				}
			}
		}

		return _roundOffValue;
	} // End RoundOff

	updateClickDetail() {
		this.initiate();

		let notes = {
			guestname: guestName,
			modetype: appModeType,
		};
		let posturlObj = {
			organizationId: parseInt(organizationId),
			propertyId: propertyId,
			outletCode: outletCode,
			ServiceMode: serviceType,
			ContactNo: mobileNo,
			StatsId: parseInt(statsId),
			numofMenuAccess: 1,
			username: Config.linkUserName,
			password: Config.linkPassword,
			notes: JSON.stringify(notes),
		};
		// Update Contact Detail
		fetch(Config.baseAPIUrl + "ClickURLDetail", {
			method: "POST",
			headers: {
				"content-type": "application/json",
			},
			body: JSON.stringify(posturlObj),
		})
			.then((res) => res.json())
			.then((response) => {})
			.catch((err) => {
				console.log(err);
			});
	}

	static userSessionValidation(exSessionId) {
		this.initiate();
		if (userSessionId !== exSessionId) {
			this.removeCartSessionDetails();
			return false;
		}
		return true;
	}

	static removeCartSessionDetails() {
		sessionStorage.removeItem("cartList");
		sessionStorage.removeItem("taxCartList");
		sessionStorage.removeItem("modifierCartList");
		sessionStorage.removeItem("chargeCartList");
	}

	static secureStorage = new SecureStorage(sessionStorage, {
		hash: function hash(key) {
			key = CryptoJS.SHA256(key, SECRET_KEY);

			return key.toString();
		},
		encrypt: function encrypt(data) {
			data = CryptoJS.AES.encrypt(data, SECRET_KEY);

			data = data.toString();

			return data;
		},
		decrypt: function decrypt(data) {
			data = CryptoJS.AES.decrypt(data, SECRET_KEY);

			data = data.toString(CryptoJS.enc.Utf8);

			return data;
		},
	});

	static checkGuestTableStatus(mobileNo, tableNo) {
		let postObj = {
			OutletId: propertyId + outletCode,
			GuestMobileCountryCode: "91",
			GuestMobileNumber: mobileNo,
			TableNumber: tableNo,
		};

		fetch(Config.basePOSUrl + "GetGuestTableStatus", {
			method: "POST",
			headers: {
				Authorization: "Bearer " + token,
				"content-type": "application/json",
			},
			body: JSON.stringify(postObj),
		})
			.then((res) => res.json())
			.then((response) => {
				if (response.errorCode === 0) {
					if (!response.response.guestTableStatus.isCheckedIn) {
						this.showMessage(
							"Your selected table has been checked out.",
							"danger"
						);
						this.setState({
							errorMessage: "Your selected table has been checked out.",
						});
						// Added by vijay on 04-03-2024 -Due to second guest login time without bill and settle
						setTimeout(() => {
							this.props.history.push("/app-error", {
								errorMessage: this.state.errorMessage,
							});
						}, Config.averageMsgTimeout);
						return false;
					} else {
						// check in true
						if (
							tableNo !== response.response.guestTableStatus.currentTableNumber
						) {
							// added by dasprakash on 21-10-2023 jira-1644
							this.setState({
								errorMessage:
									"Your table has been transfer from " +
									tableNo +
									" to " +
									response.response.guestTableStatus.currentTableNumber,
							});
							//this.showMessage("Your table has been transfer from " + tableNo + " to " + response.response.guestTableStatus.currentTableNumber, "danger");
							sessionStorage.setItem(
								"user-tableno",
								response.response.guestTableStatus.currentTableNumber
							);
						}
						return false;
					}
				} else {
					this.setState({ errorMessage: response.message });
				}
			})
			.catch((err) => {
				this.setState({ errorMessage: err });
			});
	}
}

export default withRouter(StaticHelpers);
