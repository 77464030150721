import React, { Component } from 'react';
import './address.scss';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../components/loadinganimation';
import ErrorAlert from '../../components/alertmessages';
import Config from '../../data/SiteConfig';
import AddressEntry from '../../components/addressentry';
let guestLoggedIn = "false";
let guestAddressId = 0;
let mobileNo = '';
let token = '';
let outletDetailsStr = null;
let outletDetails = {};
let urlPattern = '';
const addressEntryRef = React.createRef();  //  Added by vijay 
//let guestDetailsStr = null;
//let guestDetails = {};
export default class MyAddressList extends Component {
    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this);
        this.callBackAddress = this.callBackAddress.bind(this);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
            eBillUrl: '',                          
            isPay: false,
            billAmount: 0,
            posBillId: '',
            guestAddressList: [],
            addressType: '',
          addressId: 0,
          addressLine1: '',  // Make sure these are initialized
          formMode:1,
            addressLine2: '',
          addressLine3: '',
          cityName: '',
         areaName: '',
         landmark: '',
         zipCode: '',
         addressEntryShow : false,
         selectedAddressTypeId: 0
        }
    }

    componentDidMount() {

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }

        urlPattern = sessionStorage.getItem('url-pattern');
       
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        guestAddressId = parseInt(sessionStorage.getItem('user-addressId') == null ? 0 : sessionStorage.getItem('user-addressId'));
        outletDetailsStr = sessionStorage.getItem('user-outletDetails');
        if (outletDetailsStr === undefined || outletDetailsStr === "" || outletDetailsStr === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            outletDetails = JSON.parse(outletDetailsStr);
        }
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        if (!sHelpers.inputParamValidation()) {
            this.props.history.push('/' + urlPattern);
        }
        // this.setState({ loading: true });

        window.scrollTo(0, 0);

        if (guestLoggedIn === "false") {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/cart' + urlPattern);
            }, 200);
            return false;
        }

        //guestDetailsStr = sessionStorage.getItem('user-GuestDetails');
        token = sessionStorage.getItem('user-token');

        // if (guestDetailsStr === undefined || guestDetailsStr === "" || guestDetailsStr === null) {
        //     guestDetails = null;
        // }
        // else {
        //     guestDetails = JSON.parse(guestDetailsStr);
        // }
        this.getGuestList();
    };

    goBack() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/cart' + urlPattern);
        }, 200);

    };

    gotoAddAddress() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/addaddress' + urlPattern, {
                mode: Config.formMode.Add, addressId: 0, addressLine1: '', addressLine2: '', addressLine3: '',
                addressType: 'Home', addressTypeId: 1, areaName: outletDetails.address.area, cityName: outletDetails.address.city, landmark: '', zipCode: outletDetails.address.zipCode
            });
        }, 200);
    };

    getGuestList() {
        mobileNo = sessionStorage.getItem('user-mobileno');

        this.setState({ loading: true });
        fetch(Config.baseCRMUrl + 'GetGuestAddressList?GuestMobileNo=' + mobileNo, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                this.setState({ loading: false });
                //  console.log(response);
                if (response.errorCode === 0) {
                    this.setState({ guestAddressList: response.response.addressList });
                }
                else {
                    this.setState({ guestAddressList: [] });
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMessage('Guest Address List - Failed to fetch - ' + err, "danger");
            });
    };

    handleSelectAddress(addressId, addressTypeId, addressType, addressLine1, addressLine2, addressLine3, areaName, cityName, zipCode, landmark) {
        this.setState({ loading: true });
        let IsAddressChange = false;
        // console.log(addressId, addressTypeId, addressType, addressLine1, addressLine2, addressLine3, areaName, cityName, zipCode, landmark);
        if (addressLine1.trim() === '') {
            if (addressLine2.trim() === '') {
                if (addressLine3.trim() === '') {
                    this.showMessage('unable to add  this address. Please edit the address', "danger");
                    IsAddressChange = true;
                }
            }
        }
        else if (zipCode.trim() === '') {
            this.showMessage('Invalid zipcode, Please edit the address', "danger");
            IsAddressChange = true;
        }
        else if (cityName.trim() === '') {
            this.showMessage('Invalid city name, Please edit the address', "danger");
            IsAddressChange = true;
        }
        else if (areaName.trim() === '') {
            this.showMessage('Invalid area name, Please edit the address', "danger");
            IsAddressChange = true;
        }

        if (IsAddressChange) {
            this.setState({ loading: false });
        }
        else {
            let selectedAddress = {
                addressId: addressId,
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                addressLine3: addressLine3,
                addressType: addressType,
                addressTypeId: addressTypeId,//response.response.guestDetails.addressTypeId,
                area: areaName,
                city: cityName,
                landmark: landmark,
                zipCode: zipCode
            }
            sessionStorage.setItem('user-CurrentAddress', JSON.stringify(selectedAddress));
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/cart' + urlPattern);
            }, Config.minMsgTimeout);
        }

    };

    // handleEditAddress(addressId, addressTypeId, addressType, addressLine1, addressLine2, addressLine3, areaName, cityName, zipCode, landmark) {
    //     // console.log(addressId, addressTypeId, addressType, addressLine1, addressLine2, addressLine3, areaName, cityName, zipCode, landmark);
    //     this.setState({ loading: true });
    //     setTimeout(() => {
    //         this.setState({ loading: false });
    //         this.props.history.push('/addaddress', {               
    //             mode: Config.formMode.Edit, addressId: addressId, addressLine1: addressLine1, addressLine2: addressLine2, addressLine3: addressLine3,
    //             addressType: addressType, addressTypeId: addressTypeId, areaName: areaName, cityName: cityName, landmark: landmark, zipCode: zipCode
    //         });
    //     }, 200);

    // };
   

      goToAddressEntryEdit = (addressId, addressTypeId, addressType, addressLine1, addressLine2, addressLine3, areaName, cityName, zipCode, landmark) => {
        
        this.setState({ addressEntryShow: true,mode: Config.formMode.Edit, addressId: addressId, addressLine1:addressLine1, addressLine2: addressLine2, addressLine3: addressLine3,
        addressType: addressType, addressTypeId: addressTypeId, areaName: areaName, cityName: cityName, landmark: landmark, zipCode: zipCode  });    
        setTimeout(() => {
          this.setState({ loading: false });
          if (addressEntryRef.current) {
            console.log(this.state);
            //addressEntryRef.current.resetState(); 
            addressEntryRef.current.handleEditAddress();
          }
        }, Config.minMsgTimeout);
      };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

// Added by vijay on 11-11-2023
callBackAddress() {   
    this.setState({ HelpRequestShow: false });  
    this.getGuestList();
    this.setState({message:''});
};

goToAddressEntry() {

    this.setState({ addressEntryShow: true,formMode: Config.formMode.Add, addressId: 0, addressLine1: '', addressLine2: '', addressLine3: '',
    addressType: 'Home', addressTypeId: 1, areaName: outletDetails.address.area, cityName: outletDetails.address.city, landmark: '', zipCode: outletDetails.address.zipCode  });

    setTimeout(() => {
        this.setState({ loading: false });
        if (addressEntryRef.current) {
            addressEntryRef.current.handleAddressEntry();
           if (this.addressInput) {
            this.addressInput.focus(); // Focus on input-address1
        }
        }
       
    }, Config.minMsgTimeout);

};
    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-address">
                            <div className="heading-page" ref="recommended">
                                <span style={{ fontSize: "14px", fontWeight: 600 }}>Select a Delivery Address</span>
                                <div className="back-btn">
                                    {/* <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left"></i></div> */}
                                    <svg onClick={this.goBack} style={{ marginTop: "15px" }} xmlns="http://www.w3.org/2000/svg" width="11" height="22" viewBox="0 0 13 22" fill="none">
                                        <path d="M12 21L2 11L12 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </div>
                            </div>
                            {this.state.guestAddressList.length < Config.GuestAddressCount && // Max 5
                            
                                // <div className="add-address" onClick={this.gotoAddAddress.bind(this)}>   commended by vijay on 11-11-2023
                                //added by vijay on 11-11-2023
                                    <div className="add-address" onClick={this.goToAddressEntry.bind(this)}> 
                                    <span className="plus"><i className="fas fa-plus"></i></span> Add New Address
                                </div>
                            }
                            <div className="delivery-heading">
                                {/* Delivering in */}
                                Your saved address
                            </div>
                            <div className="address-list">
                                <ul>
                                    {
                                        this.state.guestAddressList.map(a =>
                                            <li key={"add-" + a.addressId}>
                                                {a.addressId === guestAddressId && <div className="selected-address">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="19" viewBox="0 0 13 19" fill="none">
                                                  <path d="M6.5 8.82143C5.88432 8.82143 5.29385 8.57685 4.8585 8.1415C4.42315 7.70615 4.17857 7.11568 4.17857 6.5C4.17857 5.88432 4.42315 5.29385 4.8585 4.8585C5.29385 4.42315 5.88432 4.17857 6.5 4.17857C7.11568 4.17857 7.70615 4.42315 8.1415 4.8585C8.57685 5.29385 8.82143 5.88432 8.82143 6.5C8.82143 6.80485 8.76138 7.10672 8.64472 7.38837C8.52806 7.67002 8.35706 7.92593 8.1415 8.1415C7.92593 8.35706 7.67002 8.52806 7.38837 8.64472C7.10672 8.76138 6.80485 8.82143 6.5 8.82143ZM6.5 0C4.77609 0 3.12279 0.684819 1.90381 1.90381C0.684819 3.12279 0 4.77609 0 6.5C0 11.375 6.5 18.5714 6.5 18.5714C6.5 18.5714 13 11.375 13 6.5C13 4.77609 12.3152 3.12279 11.0962 1.90381C9.87721 0.684819 8.22391 0 6.5 0Z" fill="#18A437"/>
                                                   </svg>
                                                </div>}
                                                <div className="content delivery" >
                                                    <div onClick={this.handleSelectAddress.bind(this, a.addressId, a.addressTypeId, a.addressType, a.addressLine1, a.addressLine2, a.addressLine3, a.areaName, a.cityName, a.zipCode, a.landmark)}>
                                                        <div className="addr-heading"><span className="addr-type">{a.addressType}</span></div>
                                                       {/* //Commended by7 vijay on 10-11-2023 */}
                                                        {/* <i className="fa fa-angle-right right-btn" ></i> */}
                                                        {/* //Added by7 vijay on 10-11-2023 */}
                                                        <div className='right-btn-address'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
                                                        <path d="M2 2L10 10L2 18" stroke="#18A437" strokeWidth="2.5" stroke-linecap="round"/>
                                                         </svg>
                                                         </div>
                                                        <div className="address">
                                                            {a.addressLine1} {a.addressLine2} {a.addressLine3}
                                                            <br />
                                                            {a.areaName}
                                                            <br />
                                                            {a.cityName} - {a.zipCode}
                                                            <br />
                                                            {a.landmark.trim() !== '' && 'Landmark: ' + a.landmark}
                                                        </div>
                                                    </div>
                                                    {/* <button className="theme-button btn change-btn" onClick={this.handleEditAddress.bind(this, a.addressId, a.addressTypeId, a.addressType, a.addressLine1, a.addressLine2, a.addressLine3, a.areaName, a.cityName, a.zipCode, a.landmark)}>Edit</button> */}
                                                    <button className="theme-button btn change-btn" onClick={this.goToAddressEntryEdit.bind(this, a.addressId, a.addressTypeId, a.addressType, a.addressLine1, a.addressLine2, a.addressLine3, a.areaName, a.cityName, a.zipCode, a.landmark)}>Edit</button>

                                                </div>
                                            </li>
                                        )
                                    }
                                    {/* <li>
                                        <div className="selected-address"><i className="fa fa-check" aria-hidden="true"></i></div>  goToAddressEntryEdit
                                        <div className="content delivery">
                                            <div className="addr-heading"><span className="addr-type">Home</span></div>
                                            <div className="address">
                                                65/1, Venkateshwara Colony,3rd Main,5th Cross, New Thippasandra ,
                                                Bangalore, Karnataka-560075,India. jeikum jey kumar jeyakumarcidit.com,9060960221.
                                    </div>
                                            <button className="theme-button btn change-btn">Edit</button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="content delivery">
                                            <div className="addr-heading"><span className="addr-type">Work</span></div>

                                            <div className="address">
                                                65/1, Venkateshwara Colony,3rd Main,5th Cross, New Thippasandra ,
                                                Bangalore, Karnataka-560075,India. jeikum jey kumar jeyakumarcidit.com,9060960221.
                                    </div>
                                            <button className="theme-button btn change-btn">Edit</button>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="content delivery">
                                            <div className="addr-heading"><span className="addr-type">Other</span></div>
                                            <div className="address">
                                                65/1, Venkateshwara Colony,3rd Main,5th Cross, New Thippasandra ,
                                                Bangalore, Karnataka-560075,India. jeikum jey kumar jeyakumarcidit.com,9060960221.
                                    </div>
                                            <button className="theme-button btn change-btn">Edit</button>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                            {this.state.addressEntryShow && 
                            <AddressEntry
                                ref={addressEntryRef}
                                callBackAddress={this.callBackAddress}
                                mode={this.state.formMode}
                                addressId={this.state.addressId}
                                addressTypeId={this.state.addressTypeId}
                                addressLine1={this.state.addressLine1}
                                addressLine2={this.state.addressLine2}
                                addressLine3={this.state.addressLine3}
                                addressType={this.state.addressType}
                                areaName={this.state.areaName}
                                cityName={this.state.cityName}
                                landmark={this.state.landmark}
                                zipCode={this.state.zipCode}        
                            ></AddressEntry>
    }
                        </div>
                    </div>
                </div>
            </>
        )
    };

}