import React, { Component } from 'react';
import './changeacessmode.scss';
import Config from '../../data/SiteConfig';


let token = '';
let qrLevel = 5 // By Default Service type Mode
let serviceType = '';
let propertyId = '';
let outletCode = '';
//let outletDet = [];
//let isappOrders = true;
let guestLoggedIn = "false";
let outletName = '';
let urlPattern = '';


export default class Changeaccessmode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            //  loading: false,
            // alertMessage: '',
            //  msgShow: false,
            guestRequestList: [],
            requestMasterList: [],
            message: '',

            outletList: [],
            ServiceTypeList: [],
            showOutlets: false,
            showServiceTypes: false,
            fullscreen: true,
            show: false,
            accessModeShow: false,
            selectedOutletImage: "",
            propertyImage: "",
            clickingServicemode: 'false',
        }

    }


    componentDidMount() {
       
        const openModalButton = document.getElementById('openModalButton');
        if (openModalButton) {
            openModalButton.click();
        }
    }
    componentWillMount() {
     //   console.log("componentWillMount")

        this.setState({ accessModeShow: this.props.accessModeShow, clickingServicemode: this.props.clickingServicemode })
      //  console.log(this.props.clickingServicemode,"this.props.clickingServicemode")
        token = sessionStorage.getItem('user-token');
        qrLevel = sessionStorage.getItem('user-qr-level') == null ? 5 : sessionStorage.getItem('user-qr-level');
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletName = sessionStorage.getItem('user-outletname');
        outletCode = sessionStorage.getItem('user-outletcode');
        urlPattern = sessionStorage.getItem('url-pattern');
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
       
       
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }


       // console.log("console1")
        if (parseInt(qrLevel) === Config.qrCodeLevel.Property) {
          //  console.log("console1sdsds")
            if (this.props.clickingServicemode === "true") {
                this.getServiceTypeList(outletCode, outletName);

            } else {
              //  console.log("das")
                this.getOutletList();
            }

        }
        else if (parseInt(qrLevel) === Config.qrCodeLevel.Outlet) {
           
            this.getServiceTypeList(outletCode, outletName);
        }
        
    }
    getOutletList() {
        // GetOutlet
        fetch(Config.basePOSUrl + 'GetOutletList', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
               // console.log("getOutletList-");
              //  console.log(response);
                this.setState({ showOutlets: true });
                if (response.errorCode === 0) {
                    this.setState({ outletList: response.response.outletList, serviceTypeList: [], propertyImage: response.response.propertyImageUrl });
                }
                else {
                    //this.showMessage(response.message, "danger");
                    this.setState({ outletList: [], serviceTypeList: [] });
                }
            })
            .catch(err => {
                // //////console.log(err);
                //this.showMessage('An error occured - ' + err, "danger");
                this.setState({ outletList: [], serviceTypeList: [] });
            });
    }

    outletListDom() {
        // ////console.log(this.state.outletList)
        return (
            <>

                <div style={{ height: "70px", display: "flex", justifyContent: "center" }}>
                    <img src={this.state.propertyImage} alt={this.state.showOutlets ? "outlets" : "servicetype"} />
                </div>
                <div className="outlet-group ">
                    <h6 style={{ margin: "10px" }}>Outlets</h6>
                    {this.state.outletList.map(o =>
                        <div className='outlet-details-v2' key={o.outletCode}
                            onClick={() =>
                                this.showOutletServiceType(
                                    false,
                                    o.outletCode,
                                    o.outletName,
                                    o.imageUrl === ""
                                        ? "/img/no-image/no-outlet.png"
                                        : o.imageUrl
                                )
                            }>
                            <div className='outlet-details-v2-section1'>
                                {o.imageUrl === "" &&
                                    <img src="/img/no-image/no-outlet.png" alt="profile" />
                                }
                                {o.imageUrl !== "" &&
                                    <img src={o.imageUrl} alt="lucid-outlet-contact-less" />
                                }
                            </div>
                            <div className='outlet-details-v2-section2'>

                                <div className='foodtype-icon-v2'>
                                    {o.isVegOutlet &&

                                        <svg className='foodtype-icon-v2' xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                                            <rect x="1.43066" y="0.5" width="14" height="14" rx="2.5" fill="white" stroke="#289C3A" />
                                            <circle cx="8.43223" cy="7.49961" r="3.9" fill="#289C3A" />
                                        </svg>
                                    }
                                    {!o.isVegOutlet &&

                                        <svg className='foodtype-icon-v2' xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <rect x="0.5" y="0.5" width="14" height="14" rx="2.5" fill="white" stroke="#FF0000" />
                                            <path d="M6.63397 2.5C7.01887 1.83333 7.98113 1.83333 8.36603 2.5L12.2631 9.25C12.648 9.91667 12.1669 10.75 11.3971 10.75H3.60289C2.83309 10.75 2.35196 9.91667 2.73686 9.25L6.63397 2.5Z" fill="#FF0000" />
                                        </svg>
                                    }
                                </div>
                                <span>{o.outletName}</span>


                            </div>

                        </div>
                    )}

                </div>
            </>
        )
    }

    showOutletServiceType(outlet, outletCode, outletName, outletImage) {
        this.setState({ loading: true });
        const outletGroupElement = document.querySelector('.outlet-group');
        const serviceTypeElement = document.querySelector('.servicetype-v2');
        if (outletGroupElement) {
            outletGroupElement.scrollIntoView({ behavior: 'smooth' });
        }
        if (serviceTypeElement) {
            serviceTypeElement.scrollIntoView({ behavior: 'smooth' });
        }
        if (!outlet) {
            this.getServiceTypeList(outletCode, outletName);
            this.setState({ selectedOutletImage: outletImage });

        }
        else {
            setTimeout(() => {
                this.setState({ serviceTypeList: [] });
                this.setState({ showOutlets: true, showServiceTypes: false, loading: false });
            }, 800);
        }
    }

    getServiceTypeList(outleCode, outletName) {
        sessionStorage.setItem('user-outletcode', outleCode);
        sessionStorage.setItem('user-outletname', outletName);

        sessionStorage.removeItem('cartList');
        sessionStorage.removeItem('modifierCartList');
        sessionStorage.removeItem('taxCartList');
        sessionStorage.removeItem('chargeCartList');

        // GetService Type       
        fetch(Config.basePOSUrl + 'GetOutletServiceTypeList?OutletCode=' + outleCode + '', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                ////console.log("getServiceTypeList-");
                ////console.log(response);
                if (response.errorCode === 0) {
                    if (response.response.serviceModeList.length === 1) {
                        if (parseInt(qrLevel) === Config.qrCodeLevel.Outlet) {
                            sessionStorage.setItem('dashboard-hide', "true");
                            this.handleClickServiceType(response.response.serviceModeList[0].serviceType);
                        }
                        else {
                            setTimeout(() => {
                                this.setState({ serviceTypeList: response.response.serviceModeList });
                                this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                                sessionStorage.setItem('dashboard-hide', "false");
                            }, 800);
                        }
                    }
                    else if (response.response.serviceModeList.length > 1) {
                        setTimeout(() => {
                            this.setState({ serviceTypeList: response.response.serviceModeList });
                            this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                        }, 800);
                    }
                    else {
                        //this.showMessage('Service not available', "danger");
                        this.setState({ serviceTypeList: [], showOutlets: false, showServiceTypes: true, loading: false });
                    }
                }
                else {
                    //this.showMessage(response.message, "danger");
                    setTimeout(() => {
                        this.setState({ serviceTypeList: [] });
                        this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                    }, 800);
                }
            })
            .catch(err => {
                // //////console.log(err);
                //this.showMessage('An error occured - ' + err, "danger");
                setTimeout(() => {
                    this.setState({ serviceTypeList: [] });
                    this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                }, 800);
            });
    }

    serviceTypeListDom() {
        ////console.log("this.state.serviceTypeList", this.state.serviceTypeList)
        return (
            <>
                <div style={{ height: "70px", display: "flex", justifyContent: "center" }}>
                    <img src={this.state.selectedOutletImage} alt={this.state.showOutlets ? "outlets" : "servicetype"} />
                </div>
                <div className='servicetype-v2 '>
                    {this.state.serviceTypeList.map(s =>
                        <div className='servicetype-v2-services' key={s.serviceType} onClick={this.handleClickServiceType.bind(this, s.serviceType, s.serviceTypeName)}>
                            <span>
                                {s.serviceTypeName}
                            </span>
                        </div>
                    )}
                    {(parseInt(qrLevel) === Config.qrCodeLevel.Property && this.props.clickingServicemode === "false") &&
                        <div className="back-to-outlet" onClick={this.showOutletServiceType.bind(this, true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22" fill="none">
                                <path d="M12 1L2 11L12 21" stroke="black" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                            <span>
                                Change Outlet
                            </span>
                        </div>
                    }
                </div>
            </>
        )
    }

    handleClickServiceType(serviceType) {
        // ////console.log("serviceType", serviceType)

        sessionStorage.setItem('user-servicetype', serviceType);
        sessionStorage.removeItem('cartList');
        sessionStorage.removeItem('modifierCartList');
        sessionStorage.removeItem('taxCartList');
        sessionStorage.removeItem('chargeCartList');

        this.setState({ APIResponseMsg: "Loading...", loading: true, pageLoading: true });
        this.getoutletDetails();
    }

    getoutletDetails() {
        serviceType = sessionStorage.getItem('user-servicetype');
        outletCode = sessionStorage.getItem('user-outletcode');

        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        // // Get Outlet Details                    
        fetch(Config.basePOSUrl + 'GetOutletDetails' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                ////console.log("getoutletDetails----------------");
                ////console.log(response);
                if (response.errorCode === 0) {
                    this.setState({ outletDetails: response.response.outletDetails });
                    // if (response.response.outletDetails.homePageSliderImages !== null && response.response.outletDetails.homePageSliderImages.length > 0) {
                    //     this.setState({ sliderImages: response.response.outletDetails.homePageSliderImages });
                    // }
                    sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                    // outletDet = JSON.parse(sessionStorage.getItem("user-outletDetails"));
                    //  isappOrders = response.response.outletDetails.enableGuestAppOrders;
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, 500);
                    // Digital Menu
                    if (response.response.outletDetails.enableGuestAppOrders === false && response.response.outletDetails.enableDigitalMenu === true) {

                        if (response.response.outletDetails.guestDetailsMandatoryForDigitalMenu === false) {
                            sessionStorage.setItem('user-table-verified', true);
                            sessionStorage.setItem('user-digitalMenu', true);
                            //sessionStorage.setItem('user-mobileno', "0000000000");                           

                            setTimeout(() => {
                                // this.props.history.push('/emhome');
                                this.props.history.push('/home' + urlPattern);
                            }, 1000);
                            return false;
                        }
                        if (guestLoggedIn === "false") {
                            setTimeout(() => {
                                this.props.history.push('/login' + urlPattern);
                                this.setState({ loading: false });
                            }, 2500);
                            return false;
                        }
                    }

                    // guestLoginAtTheTimeOfPlaceOrder
                    if (guestLoggedIn === "false") {
                        if (!response.response.outletDetails.guestLoginAtTheTimeOfPlaceOrder) {
                            sessionStorage.setItem("login-page-session", 2); // Item Session      
                            setTimeout(() => {
                                ////console.log("guestLoggedIn")
                                this.props.history.push('/login' + urlPattern);
                                this.setState({ loading: false });
                            }, 2500);
                            return false;
                        }
                    }

                    //// Table Check                    
                    setTimeout(() => {
                        ////console.log("setTimeout");
                        sessionStorage.setItem('user-access-mode', 'true')
                        this.props.callbackAccessMode();
                        // this.props.callbackAccessModeHeader();
                        this.setState({ show: false });
                        const openModalButton = document.getElementById('openModalButton');
                        if (openModalButton) {
                            openModalButton.click();
                        }

                    }, 500);
                }
                else {

                    this.setState({ APIResponseMsg: "Failed to fetch", loading: false });
                }

            })
            .catch(err => {
                this.setState({ APIResponseMsg: "Outlet Details - Failed to fetch", loading: false })
                //  //////console.log('Outlet Details - Failed to fetch - ' + err);
                //this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
            });
    }

    render() {
       
        return (
            <>
                <div >
                    <div className="menu-goup">
                        <button
                            id="openModalButton"
                            className="theme-button btn"
                            style={{ display: "none" }}
                            // ref={input => (this.inputElement = input)}
                            data-toggle="modal"
                            data-target="#accessMode-modal"
                        >
                            <i className="fa fa-utensils sidebar-icon"></i> MENU
                        </button>
                    </div>

                    {this.state.accessModeShow && (
                        <div className="modal fade access-model" id="accessMode-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
                            <div style={{ margin: "0px auto", alignItems: "normal" }} className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content animate-bottom">
                                    {/* <div style={{ padding: "0px", justifyContent: "center", borderBottom: "0px" }} className="modal-header">
                                        <div style={{ height: "80px", display: "flex", justifyContent: "center" }}>
                                            <img src={this.state.propertyImage} alt={this.state.showOutlets ? "outlets" : "servicetype"} />
                                        </div>
                                    </div> */}
                                    <div style={{ padding: "0px" }} className="modal-body">
                                        {/* Content of the modal body */}
                                        {this.state.showOutlets && this.outletListDom()}
                                        {this.state.showServiceTypes && this.serviceTypeListDom()}
                                    </div>
                                    {/* Add other modal components as needed (e.g., modal-footer) */}
                                </div>
                            </div>
                        </div>
                    )}

                    {/* <Modal className="access-model" show={this.state.show} fullscreen={this.state.fullscreen} >
                        <Modal.Header >
                            <Modal.Title>Modal</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            {this.state.showOutlets && this.outletListDom()}
                            {this.state.showServiceTypes && this.serviceTypeListDom()}
                        </Modal.Body>
                </Modal> */}





                </div >
            </>

        );
    }
}
