import React, { Component } from 'react';
//import Footer from '../../components/footer';
import defaultimg from '../items/default.png'

// import Header from '../../components/Header';
import './items.scss';
import Cart from '../../components/cart';
import PopupMenus from '../../components/popumenus';
import Modifier from '../../components/modifier';
import uuid from 'react-uuid';
import ErrorAlert from '../../components/alertmessages';
import LoadingAnimation from '../../components/loadinganimation';
import { isMobile } from 'react-device-detect';
import enmConfig from '../../data/enumConfig';
import Config from '../../data/SiteConfig';
import Request from '../../components/request';
import GroupMembers from '../../components/popup/groupmembers';
import ImagePopup from '../../components/popup/image';
import { css } from 'glamor';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { config } from 'react-transition-group';
import Currency from '../../components/currency';

// import { lstat } from 'fs-extra';
// const homeslidersettings = {
//     autoplay: true,
//     dots: true,
//     infinite: true,
//     arrows: false,
//     speed: 300,
//     slidesToShow: 1,
//     adaptiveHeight: false

// };



//import Cookies from 'universal-cookie';
// let loadcomfn = null;
let guestLoggedIn = false;
const imageUrl = '/img/item/item-icon.png';
let totalItems = 0;
let totalAmount = 0;
let cartList = { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] };
let taxList = [];
//let modifierList = [];
let chargeList = [];
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
let verifiedTable = '';

let getClassification = [];
let getMenus = [];
let getItems = [];
let getGlobalItems = [];
let getTaxes = [];
let getModifiers = [];
let getCharges = [];
let recommendedList = [];
// let isUpdateMenu = false;
let isorderTracking = "false";

//let getCMSSettings = {};
//const BrowserHistory = require('react-router/lib/BrowserHistory').default;
// const itemslist = [
//     {
//         mnuId: 1, itemImageUrl: "/img/item/item-icon.png", mnuName: "Veg Manchurian", price: 127, foodType: 1,badge:1,shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },
//     {
//         mnuId: 2, itemImageUrl: "/img/item/chilli_paneer.jpg", mnuName: "Chilli Paneer", price: 127, foodType: 1,badge:0, shortName: "Chilli Paneer", description: "Chilli Paneer",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },
//     {
//         mnuId: 3, itemImageUrl: "/img/item/item-icon.png", mnuName: "Wok Tossed Chilli Chicken", price: 166, foodType: 2,badge:2, shortName: "Chilli Chicken", description: "Wok Tossed Chilli Chicken",
//         modifier: []
//     },
//     {
//         mnuId: 4, itemImageUrl: "/img/item/gobi_manchurian.jpg", mnuName: "Veg Manchurian", price: 127, foodType: 2,badge:0, shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },
//     {
//         mnuId: 5, itemImageUrl: "/img/item/gobi_manchurian.jpg", mnuName: "Veg Manchurian", price: 127, foodType: 1,badge:2, shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: []
//     },
//     {
//         mnuId: 6, itemImageUrl: "/img/item/gobi_manchurian.jpg", mnuName: "Veg Manchurian", price: 127, foodType: 1,badge:1, shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },
//     {
//         mnuId: 7, itemImageUrl: "/img/item/gobi_manchurian.jpg", mnuName: "Veg Manchurian", price: 127, foodType: 2,badge:1, shortName: "Veg Manchurian", description: "Veg Manchurian",
//         modifier: [{ modifierId: 1, modifierName: "Spicy", price: 10 }, { modifierId: 2, modifierName: "Tomato Jam", price: 15 },]
//     },


// ];
// const itemGroupList = [
//     { groupId: 1, groupName: "Compos" }, { groupId: 2, groupName: "Fries" }, { groupId: 3, groupName: "Shawarmas" }
// ];
const modifierRef = React.createRef();
const reqeustRef = React.createRef();
const imageRef = React.createRef();
const groupMemberRef = React.createRef();
//const [isVisible, setIsVisible] = useState(true);
let outletDetailsStr = null;
let outletDetails = {}
let groupPin = '';
let grouppinstyle = {
    marginBottom: "40px",
    marginTop: "162px"
};
let mainGuestMobileNo = '';
let userSessionId = '';
let urlPattern = '';
const SmartText = ({ text, length = 20 }) => {
    const [showLess] = React.useState(true);

    if (text.length < length) {
        return <p>{text}</p>;
    }

    return (
        <div>
            <div
                dangerouslySetInnerHTML={{
                    __html: showLess ? `${text.slice(0, length)}...` : text,
                }}
            ></div>
            <div
            // style={{ color: "rgb(28, 28, 28)", fontWeight: "400", cursor: "pointer" }}
            // onClick={() => setShowLess(!showLess)}
            >
                {showLess ? <p style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>More</p> : ""}
            </div>
        </div>
    );
};

//const [showTopBtn, setShowTopBtn] = useState(false);



export default class Itemsearch extends Component {

    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.goMyOrders = this.goMyOrders.bind(this);
        this.goCart = this.goCart.bind(this);
        this.callBackModifier = this.callBackModifier.bind(this);
        this.callBackRequest = this.callBackRequest.bind(this);
        this.itemTaxCalc = this.itemTaxCalc.bind(this);
        this.goBill = this.goBill.bind(this);
        this.goToOrderTracking = this.goToOrderTracking.bind(this);
        this.goToHelpRequest = this.goToHelpRequest.bind(this);
        this.goToGroupMemberList = this.goToGroupMemberList.bind(this);
        this.gotoLogin = this.gotoLogin.bind(this);

        this.state = {
            avlCart: [],
            submenu: [],
            newmenuitem: [],
            fliteredmenuitems: [],
            mgfliter: [],
            selectedItem: [],
            loading: false,
            getClassification: [],
            getMenus: [],
            getItems: [],
            getModifiers: [],
            getTaxes: [],
            getCharges: [],
            recommendedList: [],
            isRecommendedItemEnabled: false,
            recommendedItemCount: 0,
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            groupName: '',
            groupId: '',
            switch1: false,
            activeMenuGroup: -1,
            totalItems: 0,
            totalAmount: 0,
            cartList: { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] },
            modifierGroupIds: [],
            modifierItemName: '',
            modifierItemId: 0,
            modifierItemCQty: 0,
            modifierItemCRate: 0,
            modifierItemCId: "",
            modifierItemCGroupId: 0,
            modifierItemCTaxId: 0,
            modifierItemCChargeIds: [],
            modifierFoodType: '',
            modifierTotalItemAmount: 0,
            checkNoQty: new Map(),
            searchText: "",
            themeId: 0, // No Theme,
            orders: [],
            showModal: false,

            imgItemName: '',
            imgItemDesc: '',
            imgItemPrice: '',
            imgItemFType: '',
            itemImgUrl: '',
            item: '',
            id: '',
            hide: '',
            styleBackgroundImage: {
                background: `url(${imageUrl}) no-repeat center center fixed`,
                WebkitBackgroundSize: "cover",
                BackgroundSize: "cover",
                MozBackgroundSize: "cover",
                OBackgroundSize: "cover",
                // fontFamily: "Nunito, sans-serif ",
                border: "none",
                paddingBottom: "52px",
            },
            styleButton: {
                padding: "4px"
            },
            styleHeading: {
                backgroundColor: "#59324c",
                color: "#fff",
                height: "110px",
                fontSize: "18px",
                // fontFamily: "Nunito, sans-serif ",
                padding: "10px 14px 8px 40px",
                borderBottom: "1px solid #fff"
            },
            styleSubHeading: {
                color: "#f9bd09",
                fontSize: "12px",
                // fontFamily: "Nunito, sans-serif "
            },
            styleMenuGroup: {
                color: "#000",
                fontSize: "6.94vw",
                // fontFamily: "Nunito, sans-serif ",
                backgroundColor: "#fff",
                lineHeight: "2rem",
                fontWeight: "700"
            },
            styleMengroupAfter: css({
                ':after': [{ borderLeft: "16px solid #59324c", content: `''`, }]
            }),
            styleMengroupBefore: css({
                ':before': [{ borderBottom: "9px solid #59324c", content: `''`, }]
            }),
            styleSubMenuGroup: {
                color: "#000",
                fontSize: "4.94vw",
                // fontFamily: "Nunito, sans-serif ",
                backgroundColor: "#fff",
                lineHeight: "2rem",
                fontWeight: "700"
            },
            styleMenuItem: {
                color: "#262626",
                fontSize: "4.27vw",
                // fontFamily: "Nunito, sans-serif ",
                fontWeight: "600",
                lineHeight: "1.2",
            },

            styleMenuDesc: {
                color: "#444",
                fontSize: "2.67vw",
                // fontFamily: "Nunito, sans-serif ",
                marginTop: "4px",
               // letterSpacing: "1px",
                lineHeight: "1.2"
            },
            styleMenuPrice: {
                color: "#262626",
                fontSize: "3.2vw",
                // fontFamily: "Nunito, sans-serif ",
                fontWeight: "600",
                letterSpacing: "-1px"
            },
            stylePopupMenubBtn:
            {
                color: "#f9f2ea",
                // fontFamily: "Nunito, sans-serif ",
                backgroundColor: "#59324c",
            },
            stylePopupText:
            {
                color: "#59324c",
                // fontFamily: "Nunito, sans-serif ",
            },
            styleContent:
            {
                color: "#f9f2ea",
                fontSize: "13px",
                backgroundColor: "#fcf9ea",
            },
            styleRefresh:
            {
                color: "#fff",
            },
            stylegroupPin:
            {
                marginBottom: "40px",
                marginTop: "214px"
            },
            pagePreview: "",
            pageContent: "",
            groupPin: '',
            ordertrack: false,
            sliderImages: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Slide 1",
                    primaryImageUrl: "/img/slider/no-image.png",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],
            activeClassification: -1,
            selectedClassification: 0,
            selectedMenuGroupId: 0,
            recommendedTxt: "Recommended",
            pageTitle: '',
            groupMemberList: [],
            showOnlySelectedMenuGroupItems: false,
            showOnlyVeg: false
        }
        // level2 change
        this.searchInp = React.createRef();
        // 
    }

    componentWillMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }

        sessionStorage.removeItem("user-vendorOrderId");

        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        verifiedTable = sessionStorage.getItem('user-table-verified');
        outletDetailsStr = sessionStorage.getItem('user-outletDetails');
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        isorderTracking = sessionStorage.getItem('order-track-status') == null ? "false" : sessionStorage.getItem('order-track-status');
        //getCMSSettings = sessionStorage.getItem('pageCMSSettings') == null ? null : JSON.parse(sessionStorage.getItem('pageCMSSettings'));
        userSessionId = sessionStorage.getItem('user-session-id') == null ? "" : sessionStorage.getItem('user-session-id');
        urlPattern = sessionStorage.getItem('url-pattern');
        // //console.log(mobileNo);
        // //console.log(token);
        // //console.log(tableNo);
        // //console.log(servic0eType);
        // //console.log(orgnationTd);
        // //console.log(propertyId);
        // //console.log(outletCode);
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }

        if (!urlPattern) {
            // Handle error or redirect as needed
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (verifiedTable === undefined || verifiedTable === "" || verifiedTable === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDetailsStr === undefined || outletDetailsStr === "" || outletDetailsStr === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            outletDetails = JSON.parse(outletDetailsStr);
            enmConfig.checkOutTypewithPay = outletDetails.paymentVendors.length > 0 ? true : false;
        }


        if (isorderTracking === undefined || isorderTracking === "" || isorderTracking === null) {
            isorderTracking = "false";
        }


        if (isorderTracking === "true")
            this.setState({ ordertrack: true });
        else
            this.setState({ ordertrack: false });


        window.scrollTo(0, 0);
        document.body.classList.remove('modal-open');
        let modalPopup = document.querySelector('.modal-backdrop');
        if (modalPopup !== null) {
            modalPopup.classList.remove('modal-backdrop', 'fade', 'show');
        }

        getClassification = sessionStorage.getItem("getclf") === null ? [] : JSON.parse(sessionStorage.getItem("getclf"));
        getMenus = sessionStorage.getItem("getMenuGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getMenuGroups"));
        getItems = sessionStorage.getItem("getmenuItems") === null ? [] : JSON.parse(sessionStorage.getItem("getmenuItems"));
        getModifiers = sessionStorage.getItem("getModifierGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getModifierGroups"));
        getTaxes = sessionStorage.getItem("getTaxes") === null ? [] : JSON.parse(sessionStorage.getItem("getTaxes"));
        getCharges = sessionStorage.getItem("getCharges") === null ? [] : JSON.parse(sessionStorage.getItem("getCharges"));
        recommendedList = getItems.filter(itm => itm.isRecommended);
        getGlobalItems = getItems;
        this.setState({
            recommendedList: recommendedList,
            getMenus: getMenus,
            getItems: getItems,
            getClassification: getClassification
        });

        // if (getCMSSettings === null) {
        //     // CMS Settings
        //     this.getCMSDetails();
        // }
        // else {
        //     // //console.log(getCMSSettings);
        //     this.setState({ themeId: getCMSSettings.page.themeId });
        // }

        // Enable Modifier
        this.setState({ showModal: true });

    }

    componentDidMount() {

        // level2 change
        if (sessionStorage.getItem("cartList") != null) {
            cartList = JSON.parse(sessionStorage.getItem("cartList"));
            this.setState({ totalItems: cartList.totalItems, totalAmount: cartList.totalAmount });
        }
        // //console.log(this.state)
        // 

        //    level2 change
        this.searchInp.current.focus();
        // 
        getMenus.forEach(menu => {
            // //console.log(menu.description);
        });
        // //console.log(this.state)
        this.setState({ newmenuitem: this.state.getItems });

        window.scrollTo(0, 0);
        // sessionStorage.setItem("cart_details", JSON.stringify({ cart: [] }));

        //  //console.log(getModifiers);
        //     //console.log(sessionStorage.getItem("cartList"));


        // if (sessionStorage.getItem("cartList") != null) {
        //     cartList = JSON.parse(sessionStorage.getItem("cartList"));
        //     ////console.log(cartList);
        //     this.setState({ totalItems: cartList.totalItems, totalAmount: cartList.totalAmount });
        //     totalItems = cartList.totalItems;
        //     totalAmount = cartList.totalAmount;
        //     taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
        //     chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));
        //     // group by item
        //     Array.prototype.forEach.call(cartList.itemList, itm => {
        //         let totlaQty = document.getElementById("" + itm.id + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itm.id + "").innerHTML);
        //         document.getElementById("" + itm.id + "").innerHTML = (totlaQty + itm.qty);
        //         let sout = document.getElementById("" + itm.id + "").getAttribute('data-so');
        //         this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id, sout);
        //     });

        // }
        // else {
        //     cartList = { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] };
        //     this.setState({ cartList: { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] } });
        //     this.setState({ totalItems: 0, totalAmount: 0 });
        //     totalAmount = 0;
        //     totalItems = 0;
        //     taxList = [];
        //     chargeList = [];
        // }



        // //console.log(getTaxes);
        // //console.log(getCharges);


        if (this.props.location.state !== undefined) {
            var stickyContainer = document.getElementById("" + this.props.location.state.focusGroup + "");
            if (stickyContainer != null) {
                // //console.log(this.props.location.state.focusGroup);
                window.scrollTo({
                    top: stickyContainer.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }

        // check Guest Order list
        // //console.log(isorderTracking);
        if (outletDetails.enableOrderTracking) {
            if (isorderTracking === "false")
                this.getGuestProcessOrders();
            else
                this.setState({ ordertrack: true });
        }
        if (serviceType === "FINE-DINE") {
            if (guestLoggedIn === "true") {
                this.getGuestDashboardDetails();
                grouppinstyle = {
                    marginBottom: "40px",
                    marginTop: "256px"
                }
            }
            else {
                grouppinstyle = {
                    marginBottom: "40px",
                    marginTop: "214px"
                }
            }
            this.setState({ stylegroupPin: grouppinstyle });
        }
        else {
            if (guestLoggedIn === "true") {
                grouppinstyle = {
                    marginBottom: "40px",
                    marginTop: "214px"
                }
                this.setState({ stylegroupPin: grouppinstyle });
            }
        }

        // update outlet switch

        this.setState({ showOnlySelectedMenuGroupItems: outletDetails.showOnlySelectedMenuGroupItems });

        this.setState({ loading: false });
        //  this.ScrollHide();   
    }

    getCartDetails() {

        // fetch(Config.baseAPIUrl + 'PageDetails', {
        //     method: 'POST',
        //     headers: {
        //         'content-type': 'application/json'
        //     },
        //     body: JSON.stringify(postobj)
        // })
        //     .then(res => res.json())
        //     .then(response => {
        //         //console.log(response);

        //         if (response.isSuccessful) {
        //             // //console.log(response);
        //             sessionStorage.setItem("pageCMSSettings", JSON.stringify(response));
        //             this.setState({ themeId: response.page.themeId, pageTitle: response.page.pageTitle });
        //             this.themeStyle(response);
        //         }
        //         else {
        //             this.setState({ themeId: 1, pageTitle: '' }); // Default Theme Value
        //             // this.showMessage(response.message, "danger");
        //             this.setState({ sliderImages: [] });
        //         }



        //         this.setState({ loading: true });
        //         // Classification Filter
        //         if (getClassification.length > 0) {
        //             // Default -> First Classification Select
        //             this.setState({ activeClassification: 0 });
        //             this.handleClassificationClick(0, getClassification[0].classificationName, getClassification[0].classification);
        //         }

        //         this.setState({ loading: false });

        //     })
        //     .catch(err => {
        //         //console.log(err);
        //         //this.showMessage("CMS - Failed to fetch", "danger");
        //     });

        // Update Cart Infomation
        //console.log("cartList");

        if (sessionStorage.getItem("cartList") != null) {
            cartList = JSON.parse(sessionStorage.getItem("cartList"));
            //console.log(cartList);
            this.setState({ totalItems: cartList.totalItems, totalAmount: cartList.totalAmount });
            totalItems = cartList.totalItems;
            totalAmount = cartList.totalAmount;
            taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
            chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));
            // group by item
            // Array.prototype.forEach.call(cartList.itemList, itm => {
            //     if (document.getElementById("" + itm.id + "") != null) {
            //         let totlaQty = document.getElementById("" + itm.id + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itm.id + "").innerHTML);
            //         document.getElementById("" + itm.id + "").innerHTML = (totlaQty + itm.qty);
            //         let sout = document.getElementById("" + itm.id + "").getAttribute('data-so');
            //         this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id, sout);
            //     }
            // });
        }
        else {
            cartList = { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] };
            this.setState({ cartList: { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] } });
            this.setState({ totalItems: 0, totalAmount: 0 });
            totalAmount = 0;
            totalItems = 0;
            taxList = [];
            chargeList = [];
        }

        this.setState({ loading: false });

    }

    themeStyle(response) {
        // //console.log(response.brands);
        // Slider
        if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0)
            this.setState({ sliderImages: response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });
        else
            this.setState({ sliderImages: [] });

        if (response.banners.filter(i => i.bannerType === Config.bannerImageType.BackGroundImage) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.BackGroundImage).length > 0) {
            let img = JSON.stringify(response.banners.filter(i => i.bannerType === Config.bannerImageType.BackGroundImage)[0].primaryImageUrl);
            let fontFamily = response.brands.filter(b => b.brandCode === 'p-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'p-f-f')[0].brandValue : "Montserrat";
            let styleBGPattern = {
                background: `url(${img}) no-repeat center center fixed`,
                fontFamily: fontFamily,
                border: "none",
                paddingBottom: "114px",
            }
            this.setState({ styleBackgroundImage: styleBGPattern });
        }

        // Heading
        if (response.brands.length > 0) {
            let bgColor = response.brands.filter(b => b.brandCode === 't-h-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-b-c')[0].brandValue : "#59324c";
            let fontColor = response.brands.filter(b => b.brandCode === 't-h-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-f-c')[0].brandValue : "#fff";
            let fontSize = response.brands.filter(b => b.brandCode === 't-h-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-f-s')[0].brandValue : "#18px";
            let fontFamily = response.brands.filter(b => b.brandCode === 't-h-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-f-f')[0].brandValue : "Montserrat";
            ////console.log(response.brands.filter(b => b.brandCode === 't-h-b-c')[0].brandValue);
            let styleHeading = {
                backgroundColor: bgColor,
                color: fontColor,
                fontFamily: fontFamily,
                height: "110px",
                fontSize: fontSize,
                padding: "10px 14px 8px 40px",
                borderBottom: "1px solid #fff"
            }
            this.setState({ styleHeading: styleHeading });
            // Refresh button
            let styleRefresh = {
                color: fontColor,
            }
            this.setState({ styleRefresh: styleRefresh });

            // SubHeading                
            fontColor = response.brands.filter(b => b.brandCode === 't-s-h-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 't-s-h-f-c')[0].brandValue : "#f9bd09";
            fontSize = response.brands.filter(b => b.brandCode === 't-s-h-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 't-s-h-f-s')[0].brandValue : "#12px";
            fontFamily = response.brands.filter(b => b.brandCode === 't-s-h-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 't-s-h-f-f')[0].brandValue : "Montserrat";

            let styleSubHeading = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize
            }
            this.setState({ styleSubHeading: styleSubHeading });

            // Menu Group
            bgColor = response.brands.filter(b => b.brandCode === 'mg-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-b-c')[0].brandValue : "#59324c";
            fontColor = response.brands.filter(b => b.brandCode === 'mg-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-f-c')[0].brandValue : "#fff";
            fontSize = response.brands.filter(b => b.brandCode === 'mg-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-f-s')[0].brandValue : "#17px";
            fontFamily = response.brands.filter(b => b.brandCode === 'mg-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-f-f')[0].brandValue : "Montserrat";

            let styleMenuGroup = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                backgroundColor: bgColor,
            }
            let afterbg = css({
                ':after': [{ borderLeft: "16px solid" + bgColor + "", content: `''`, }]
            });
            let beforebg = css({
                ':before': [{ borderBottom: "9px solid " + bgColor + "", content: `''`, }]
            });

            this.setState({ styleMenuGroup: styleMenuGroup });
            this.setState({ styleMengroupAfter: afterbg });
            this.setState({ styleMengroupBefore: beforebg });


            // Sub Menu Group                
            fontColor = response.brands.filter(b => b.brandCode === 'smg-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'smg-f-c')[0].brandValue : "#000";
            fontSize = response.brands.filter(b => b.brandCode === 'smg-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'smg-f-s')[0].brandValue : "#15px";
            fontFamily = response.brands.filter(b => b.brandCode === 'smg-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'smg-f-f')[0].brandValue : "Montserrat";
            let _styleSubMenuGroup = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize
            }

            this.setState({ styleSubMenuGroup: _styleSubMenuGroup });

            // Menu Item            
            fontColor = response.brands.filter(b => b.brandCode === 'mi-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mi-f-c')[0].brandValue + '' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'mi-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mi-f-s')[0].brandValue : "#16px";
            fontFamily = response.brands.filter(b => b.brandCode === 'mi-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mi-f-f')[0].brandValue : "Montserrat";
            let fontWeight = response.brands.filter(b => b.brandCode === 'mi-f-w').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mi-f-w')[0].brandValue + '' : '500';
            let styleMenuItem = {
                color: fontColor,
                fontFamily: fontFamily,
                fontWeight: fontWeight,
                fontSize: fontSize
            }

            this.setState({ styleMenuItem: styleMenuItem });

            // Menu Desc            
            fontColor = response.brands.filter(b => b.brandCode === 'md-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'md-f-c')[0].brandValue + '' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'md-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'md-f-s')[0].brandValue : "#12px";
            fontFamily = response.brands.filter(b => b.brandCode === 'md-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'md-f-f')[0].brandValue : "Montserrat";
            fontWeight = response.brands.filter(b => b.brandCode === 'md-f-w').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'md-f-w')[0].brandValue + '' : '600';
            let styleMenuDesc = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                fontWeight: fontWeight,
                marginTop: "4px"
            }

            this.setState({ styleMenuDesc: styleMenuDesc });

            // Menu Price            
            fontColor = response.brands.filter(b => b.brandCode === 'mp-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mp-f-c')[0].brandValue + '' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'mp-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mp-f-s')[0].brandValue : "#16px";
            fontFamily = response.brands.filter(b => b.brandCode === 'mp-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mp-f-f')[0].brandValue : "inherit";
            fontWeight = response.brands.filter(b => b.brandCode === 'mp-f-w').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mp-f-w')[0].brandValue + '' : '400';

            let styleMenuPrice = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                fontWeight: fontWeight
            }

            this.setState({ styleMenuPrice: styleMenuPrice });

            // Menu popup
            fontColor = response.brands.filter(b => b.brandCode === 'mpu-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mpu-f-c')[0].brandValue + '' : '#f9f2ea';
            fontSize = response.brands.filter(b => b.brandCode === 'mpu-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-f-s')[0].brandValue : "#16px";
            bgColor = response.brands.filter(b => b.brandCode === 'mpu-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-f-f')[0].brandValue : "Montserrat";

            let stylePopupMenubBtn = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                backgroundColor: bgColor,
            }
            this.setState({ stylePopupMenubBtn: stylePopupMenubBtn });

            // Menu popup Text
            fontColor = response.brands.filter(b => b.brandCode === 'mpu-t-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mpu-t-f-c')[0].brandValue + '' : '#59324c';
            fontFamily = response.brands.filter(b => b.brandCode === 'mpu-t-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-t-f-f')[0].brandValue : "Montserrat";

            let stylePopupText = {
                color: fontColor,
                fontFamily: fontFamily
            }
            this.setState({ stylePopupText: stylePopupText });

            // Conte Style
            bgColor = response.brands.filter(b => b.brandCode === 'cont-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 'cont-b-c')[0].brandValue : "#fcf9ea";
            fontColor = response.brands.filter(b => b.brandCode === 'cont-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'cont-f-c')[0].brandValue : "#f9f2ea";
            fontSize = response.brands.filter(b => b.brandCode === 'cont-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'cont-f-s')[0].brandValue : "#13px";

            let styleContent = {
                backgroundColor: bgColor,
                color: fontColor,
                fontSize: fontSize
            }
            this.setState({ styleContent: styleContent });


        }

        // Content                   
        if (response.page != null) {
            ////console.log(response.page.preview);
            this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });
        }
    }

    qtyMode(itmAddClassName, itmEntryClassName, itemId, outOfStock) {
        //console.log("qtyMode");

        let itmAddcls = document.querySelector("." + itmAddClassName + "");
        let itmEntrycls = document.querySelector("." + itmEntryClassName + "");
        let itmDivId = document.getElementById("" + itemId + "");

        let itmqTy = 0;
        if (itmDivId !== null)
            itmqTy = document.getElementById("" + itemId + "").innerHTML === null ? 0 : JSON.parse(document.getElementById("" + itemId + "").innerHTML);

        // //console.log(itmqTy);
        // //console.log(itmAddClassName);
        // //console.log(itmEntryClassName);
        // //console.log(itmEntrycls);
        // //console.log(itmAddcls);
        // //console.log(itmDivId);
        // //console.log(itemId);

        if (itmqTy > 0) {
            if (itmEntrycls !== null) itmEntrycls.classList.remove('qty-hide');
            if (itmAddcls !== null) itmAddcls.classList.add('qty-hide');
        }
        else {
            if (itmEntrycls !== null) itmEntrycls.classList.add('qty-hide');
            if (itmAddcls !== null) itmAddcls.classList.remove('qty-hide');
        }

        // out of stock       
        if (outOfStock === "true") {
            if (itmEntrycls !== null) itmEntrycls.classList.add('qty-hide');
            if (itmAddcls !== null) itmAddcls.classList.add('qty-hide');
        }
    }

    goBack() {
        //this.props.history.goBack();
        this.props.history.push('/items' + urlPattern, { focusGroup: "focus-mnugrp-focus-mnugrp-recommended" });
    }

    goMyOrders() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/myorders' + urlPattern);
            }, Config.minMsgTimeout);
        }
        else {
            this.setState({ loading: true });
            sessionStorage.setItem("login-page-session", 8); // My ORders
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
    }

    gotoLogin() {
        this.setState({ loading: true });
        sessionStorage.setItem("login-page-session", 2); //Items
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/login' + urlPattern);
        }, Config.minMsgTimeout);
        return false;
    }

    filterMenus(menuList, recommendedList, clfFilterItems = null) {

        //let filterItems = clfFilterItems === null ? menuList : clfFilterItems;
        let filterItems = menuList;
        let clfitems = clfFilterItems;
        let recList = recommendedList;

        let filterGroups = [];


        //Menu Group Filter
        let _uniquesGroups = [];

        var _taxItmFound = {};
        for (var md = 0, lenc = clfitems.length; md <= lenc - 1; md++) {
            var stringified = JSON.stringify(clfitems[md].menuGroupId);
            if (_taxItmFound[stringified]) { continue; }
            _uniquesGroups.push({ groupId: clfitems[md].menuGroupId });
            _taxItmFound[stringified] = true;
        };
        // //console.log(_uniquesGroups);

        Array.prototype.forEach.call(getMenus, grp => {
            let grplst = [];
            let subgrplst = []
            let itemCount = clfitems.filter(itm => itm.menuGroupId === grp.menuGroupId).length;
            if (grp.subMenuGroup.length > 0) {
                Array.prototype.forEach.call(grp.subMenuGroup, sgp => {
                    grplst = _uniquesGroups.filter(x => x.groupId === sgp.menuGroupId);
                    if (grplst.length > 0) {
                        itemCount += clfitems.filter(itm => itm.menuGroupId === sgp.menuGroupId).length;
                        // level2 change
                        subgrplst.push({ classification: sgp.classification, description: sgp.description, displayOrder: sgp.displayOrder, hasSubMenuGroup: sgp.hasSubMenuGroup, hsnsac: sgp.hsnsac, isActive: sgp.isActive, menuGroupId: sgp.menuGroupId, menuGroupName: sgp.menuGroupName, subMenuGroup: sgp.subMenuGroup, noOfItems: itemCount, imageUrl: sgp.imageUrl });
                        // 
                    }
                    // third Level menus 
                    Array.prototype.forEach.call(sgp.subMenuGroup, ssgp => {
                        grplst = _uniquesGroups.filter(x => x.groupId === ssgp.menuGroupId);
                        if (grplst.length > 0) {
                            itemCount += clfitems.filter(itm => itm.menuGroupId === ssgp.menuGroupId).length;
                            subgrplst.push({ classification: ssgp.classification, description: ssgp.description, displayOrder: ssgp.displayOrder, hasSubMenuGroup: ssgp.hasSubMenuGroup, hsnsac: ssgp.hsnsac, isActive: ssgp.isActive, menuGroupId: ssgp.menuGroupId, menuGroupName: ssgp.menuGroupName, subMenuGroup: ssgp.subMenuGroup, noOfItems: itemCount });
                        }
                    });

                });
                // //console.log(subgrplst);
                if (subgrplst.length > 0) {
                    // level2 change
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: subgrplst, noOfItems: itemCount, imageUrl: grp.imageUrl });
                    // 
                }
            }
            else {
                grplst = _uniquesGroups.filter(x => x.groupId === grp.menuGroupId);
                if (grplst.length > 0)
                    // level2 change
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: [], noOfItems: itemCount, imageUrl: grp.imageUrl });
                // 
            }
        });
        // //console.log(filterGroups);



        // //console.log(recommendedList);


        // this.setState({ getItems: clfFilterItems === null ? filterItems : menuList });
         console.log(getItems);
        this.setState({ getItems: filterItems });
        this.setState({ recommendedList: recList });
        this.setState({ getMenus: filterGroups });
    }

    // handleSwitchChange = nr => () => {
    //     let switchNumber = `switch${nr}`;
    //     let isCheckVeg = !this.state[switchNumber];

    //     this.setState({ showOnlyVeg: isCheckVeg });

    //     if (isCheckVeg === true) {

    //         if (outletDetails.showOnlySelectedMenuGroupItems) {

    //             getGlobalItems = getItems.filter(obj => { return (obj.foodType === 1) && obj.isRecommended === false });

    //             if (this.state.searchText !== "")
    //                 getGlobalItems = getItems.filter(obj => { return (obj.foodType === 1) && obj.isRecommended === false && (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });

    //             let clfList = this.getFilterClassification(getGlobalItems);
    //             this.setState({ getClassification: clfList });
    //             if (clfList.length > 0) {
    //                 this.handleClassificationClick(0, clfList[0].classificationName, clfList[0].classification)
    //             }
    //             else this.filterMenus([], [], []);

    //         }
    //         else {

    //             let clffilterItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });
    //             let filterItems = clffilterItems.filter(x => x.foodType === 1);

    //             if (this.state.searchText !== "")
    //                 filterItems = filterItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });

    //             let recList = filterItems.filter(itm => itm.isRecommended);
    //             this.filterMenus(filterItems, recList, filterItems);
    //         }


    //     }
    //     else {

    //         if (outletDetails.showOnlySelectedMenuGroupItems) {
    //             getGlobalItems = getItems;

    //             if (this.state.searchText !== "")
    //                 getGlobalItems = getGlobalItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });


    //             let clfList = this.getFilterClassification(getGlobalItems);
    //             this.setState({ getClassification: clfList });

    //             if (clfList.length > 0) {
    //                 this.handleClassificationClick(0, clfList[0].classificationName, clfList[0].classification)
    //             }
    //             else this.filterMenus([], [], []);
    //         }

    //         else {

    //             let clffilterItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });
    //             if (this.state.searchText !== "")
    //                 clffilterItems = clffilterItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) >= 0) });

    //             let recList = clffilterItems.filter(itm => itm.isRecommended);
    //             this.filterMenus(clffilterItems, recList, clffilterItems);

    //         }
    //         // this.setState({ getItems: getItems });
    //         // this.setState({ recommendedList: recommendedList });
    //         // this.setState({ getMenus: getMenus });
    //     }

    //     window.scrollTo(0, 0);

    //     this.setState({
    //         [switchNumber]: !this.state[switchNumber]
    //     });

    //     //  { this.state[switchNumber] === true ? alert('true') : alert('false') }
    //     // //console.log(!this.state[switchNumber]);
    // };

    // Menu Search
    // filtercheck = (val) => {
    //     this.setState({ searchText: val });

    //     const menugroupfliter = this.state.getMenus.filter(item =>
    //         item.itemName.toLowerCase().includes(val.toLowerCase())
    //     );

    //     //console.log(menugroupfliter);
    // }

    handlechange = value => {

        //     const keys = Object.keys(sessionStorage);
        // // Retrieve values for each key
        // const data = keys.map(key => {
            
            // });
            this.setState({ searchText: value });
        if (value === "") {
            this.setState({ mgfliter: [] });
            this.setState({ fliteredmenuitems: [] });
            this.handleGenerateRowRecommendedListDom()
        }
        else {
                   

            const menugroupfilter = this.state.getMenus.filter(mgitem =>
                mgitem.menuGroupName.toLowerCase().includes(value.toLowerCase())
            );
            this.setState({ mgfliter: menugroupfilter });
            const menuitemfliter = this.state.newmenuitem.filter(mitem =>
                mitem.itemName.toLowerCase().includes(value.toLowerCase())
            );
            this.setState({ fliteredmenuitems: menuitemfliter });
            console.log(this.state.mgfliter)
            console.log(this.state.fliteredmenuitems)
            // this.getCartDetails();

        }
        // if (val === "") {

        //     if (outletDetails.showOnlySelectedMenuGroupItems) {

        //         getGlobalItems = getItems;

        //         if (this.state.showOnlyVeg)
        //             getGlobalItems = getItems.filter(obj => { return (obj.foodType === 1) });

        //         let clfList = this.getFilterClassification(getGlobalItems);
        //         ////console.log(clfList);
        //         this.setState({ getClassification: clfList });

        //         if (clfList.length > 0) {
        //             this.handleClassificationClick(0, clfList[0].classificationName, clfList[0].classification)
        //         }
        //         else this.filterMenus([], [], []);
        //     }
        //     else {

        //         let clfList = this.getFilterClassification(getItems);
        //         this.setState({ getClassification: clfList });

        //         getGlobalItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });

        //         if (this.state.showOnlyVeg)
        //             getGlobalItems = getGlobalItems.filter(obj => { return (obj.foodType === 1) });

        //         let recList = getGlobalItems.filter(itm => itm.isRecommended);

        //         this.filterMenus(getGlobalItems, recList, getGlobalItems);
        //     }
        // }
        // else {
        //     if (outletDetails.showOnlySelectedMenuGroupItems) {

        //         // //console.log(val);

        //         // let clffilterItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });

        //         getGlobalItems = getItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(val.toLowerCase()) >= 0) && obj.isRecommended === false });

        //         if (this.state.showOnlyVeg)
        //             getGlobalItems = getGlobalItems.filter(obj => { return (obj.foodType === 1) });

        //         //  //console.log(getGlobalItems);

        //         let clfList = this.getFilterClassification(getGlobalItems);
        //         this.setState({ getClassification: clfList });
        //         if (clfList.length > 0) {
        //             this.handleClassificationClick(0, clfList[0].classificationName, clfList[0].classification)
        //         }
        //         else this.filterMenus([], [], []);
        //     }
        //     else {
        //         // //console.log(val);

        //         let clfList = this.getFilterClassification(getItems);
        //         this.setState({ getClassification: clfList });

        //         let clffilterItems = getItems.filter(obj => { return (obj.classification === this.state.selectedClassification) });



        //         getGlobalItems = clffilterItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(val.toLowerCase()) >= 0) });

        //         if (this.state.showOnlyVeg)
        //             getGlobalItems = getGlobalItems.filter(obj => { return (obj.foodType === 1) });

        //         // //console.log(getGlobalItems);

        //         let recList = getGlobalItems.filter(itm => itm.isRecommended);
        //         //   //console.log(recList);
        //         this.filterMenus(getGlobalItems, recList, getGlobalItems);
        //     }

        // }
        // window.scrollTo(0, 0);
    };

    getFilterClassification(itemList) {

        //Classification  Filter
        let _uniquesCLF = [];

        var _clfFound = {};
        for (var md = 0, lenc = itemList.length; md <= lenc - 1; md++) {
            var stringified = JSON.stringify(itemList[md].classification);
            if (_clfFound[stringified]) { continue; }
            _uniquesCLF.push({ classification: itemList[md].classification });
            _clfFound[stringified] = true;
        };

        //console.log(_uniquesCLF);

        let clfList = [];
        Array.prototype.forEach.call(_uniquesCLF, clf => {
            let list = getClassification.filter(c => c.classification === clf.classification);
            if (list.length > 0) clfList.push({ classification: list[0].classification, classificationName: list[0].classificationName, displayOrder: list[0].displayOrder, imageUrl: list[0].imageUrl });
        });


        clfList = [...clfList].sort((a, b) => a.displayOrder - b.displayOrder);

        //console.log(clfList);

        return clfList;
    }

    goCart() {
        window.scrollTo(0, 0);
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/cart' + urlPattern, {
                getMenus: this.props.location.state.getMenus,
                getItems: this.props.location.state.getItems,
                getModifiers: this.props.location.state.getModifiers,
                getTaxes: this.props.location.state.getTaxes,
                getCharges: this.props.location.state.getCharges
            });
        }, Config.minMsgTimeout);
    }

    handleMenuGroupClick(index, mnuid) {
        this.setState({
            activeMenuGroup: index,
        });

        if (!outletDetails.showOnlySelectedMenuGroupItems) {
            //  window.location.hash = '#' + mnuid;           
            var stickyContainer = document.getElementById("" + mnuid + "");
            //  //console.log(stickyContainer.offsetTop + 170);
            // window.scrollTo(0, stickyContainer.offsetTop);
            window.scrollTo({
                top: stickyContainer.offsetTop - 185,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    handleAddQty(itmId, val, rate, itmName, itemId, groupId, foodType, modifierGroupIds, taxId, chargeId) {
        this.handleQtyIncDec(itmId, val, rate, itmName, itemId, groupId, foodType, modifierGroupIds, taxId, chargeId);
    }
    // getCardcount(itemId) {
    //     //console.log('starts');
    //     //console.log(this.state.avlCart);
    //     var PreviousCart = sessionStorage.getItem('cart_details');

    //     var CartOriginal = JSON.parse(PreviousCart);
    //     var count = 0;

    //     CartOriginal.cart.forEach(element => {
    //         //console.log(`element ${element.itemId}`);
    //         //console.log(`itemId ${itemId}`);
    //         if (element.itemId == itemId) {
    //             count++;
    //         }
    //     });
    //     //console.log(`count ${count}`);
    //     return count;
    // }
    handleQtyIncDec(itmId, val, rate, itmName, itemId, groupId, foodType, modifierGroupIds, taxId, chargeId) {
        // //console.log("itemId")
        // //console.log(itmId)
        // //console.log("val")
        // //console.log(val)
        // //console.log("rate")
        // //console.log(rate)
        // //console.log("itmName")
        // //console.log(itmName)
        // //console.log("itemId")
        // //console.log(itemId)
        // //console.log("groupId")
        // //console.log(groupId)
        // //console.log("foodType")
        // //console.log(foodType)
        // //console.log("modifierGroupIds")
        // //console.log(modifierGroupIds)
        // //console.log("taxId")
        // //console.log(taxId)
        // //console.log("chargeId")
        // //console.log(chargeId)
        this.getCartDetails();

        if (modifierGroupIds.length === 0) { // Find the modifier
            // //console.log("gon inside")
            let qty = document.getElementById("" + itmId + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itmId + "").innerHTML);
            //console.log(qty);
            if (qty === 0 && val === 0) {
                return false;
            }
            let _qtyVal = val === 1 ? qty += 1 : qty -= 1;
            if (qty >= 0) {
                // //console.log("_qtyVal")
                // //console.log(_qtyVal)
                this.setState({ qty: _qtyVal });
                // //console.log('this.state.qty')
                // //console.log(this.state.qty)
                // //console.log("gon")
                document.getElementById("" + itmId + "").innerHTML = _qtyVal;
                totalItems = val === 1 ? totalItems += 1 : totalItems -= 1;
                totalAmount = val === 1 ? totalAmount += rate : totalAmount -= rate;
            }

            this.qtyMode('item-add-' + itmId, 'item-entry-' + itmId, itmId);


            //let _amtVal = _qtyVal * rate;                            
            // totalAmount += _amtVal;                                      

            this.setState({ totalItems: totalItems });
            this.setState({ totalAmount: totalAmount });

            cartList.totalItems = totalItems;
            cartList.totalAmount = totalAmount;
            let uid = uuid();
            taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
            chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));

            let index = cartList.itemList.findIndex(x => x.id === "" + itmId + "");
            if (index < 0) {
                this.itemTaxCalc(taxId, rate, qty, itemId, uid);
                this.itemChargeCalc(chargeId, rate, qty, itemId, uid);
                cartList.itemList.push({ id: "" + itmId + "", itemId: itemId, groupId: groupId, foodType: foodType, qty: _qtyVal, rate: rate, itemName: itmName, isModifier: 0, uid: uid, taxId: taxId, chargeIds: chargeId });
            }

            else {
                if (_qtyVal > 0) {
                    // tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    });

                    cartList.itemList[index].qty = _qtyVal;
                    this.itemTaxCalc(taxId, rate, _qtyVal, itemId, cartList.itemList[index].uid);

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });
                    // //console.log(chargeList);
                    this.itemChargeCalc(chargeId, rate, _qtyVal, itemId, cartList.itemList[index].uid);
                }
                else {
                    //tax
                    let exTax = taxList.filter(x => x.uid === cartList.itemList[index].uid);
                    let txidx = -1;
                    Array.prototype.forEach.call(exTax, tx => {
                        txidx = taxList.findIndex(x => x.serialNo === tx.serialNo && x.uid === tx.uid);
                        taxList.splice(txidx, 1);
                    });

                    // charge
                    let exCharge = chargeList.filter(x => x.uid === cartList.itemList[index].uid);
                    let chgidx = -1;
                    Array.prototype.forEach.call(exCharge, chg => {
                        chgidx = chargeList.findIndex(x => x.uid === chg.uid);
                        chargeList.splice(chgidx, 1);
                    });


                    cartList.itemList.splice(index, 1);
                }
            }

            sessionStorage.setItem("cartList", JSON.stringify(cartList));
            sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
            sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));
            // //console.log(cartList);
            // //console.log(taxList);
            // //console.log(chargeList);
        }


        else {
            // //console.log('itemId' + itemId);
            // //console.log(sessionStorage.getItem('cart_details'));
            // Push the new item into the cart array
            // CartOriginal.cart.push({ itemId });
            // // Store the updated cart details back in local storage
            // sessionStorage.setItem("cart_details", JSON.stringify(CartOriginal));
            let qty = document.getElementById("" + itmId + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itmId + "").innerHTML);
            if (val === 1) {
                // //console.log('foodType', foodType);
                // //console.log('itmName', itmName);
                // //console.log('itemId', itemId);
                // //console.log('itmId', itmId);
                // //console.log('qty', qty);
                // //console.log('rate', rate);
                // //console.log('groupId', groupId);
                // //console.log('taxId', taxId);
                // //console.log('chargeId', chargeId);

                this.setState({
                    modifierFoodType: foodType,
                    modifierItemName: itmName,
                    modifierItemId: itemId,
                    modifierItemCId: itmId,
                    modifierItemCQty: qty,
                    modifierItemCRate: rate,
                    modifierItemCGroupId: groupId,
                    modifierItemCTaxId: taxId,
                    modifierItemCChargeIds: chargeId,
                    showModal: true
                });

                this.setState({ modifierGroupIds: modifierGroupIds })
                setTimeout(() => {
                    this.handleModifier();
                }, Config.minMsgTimeout);
            }
            else { // validating modifier minus process
                if (qty > 0) {
                    this.showMessage('This item has multiple customizations added. Proceed to cart to remove item.', "danger");
                    this.setState({ loading: true });
                    setTimeout(() => { this.props.history.push('/cart' + urlPattern); }, Config.minMsgTimeout);
                    return false;
                }
            }
            // this.handleModifier();
        }

    };

    itemTaxCalc(taxId, rate, qty, itemId, uid) {
        let cList = getTaxes.filter(x => x.taxId === taxId);
        let taxObj = [];
        let taxAmt = 0;
        let itemValue = rate * qty;
        let bInsertTax = false;
        if (cList.length > 0) {
            Array.prototype.forEach.call(cList[0].structure, tx => {
                bInsertTax = false;
                taxAmt = 0;
                //Applicable on = 2 Discount Value  && applicableon = 1 direct value
                if (tx.applicableOn === 3) { // Tax on Tax     
                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                    if (serialTax.length > 0) {
                        if (tx.taxType === 1) // (%)                                                
                            taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                        else if (tx.taxType === 2)  // Amount
                            taxAmt = tx.taxValue * qty;

                        bInsertTax = true;

                    }
                }
                else {
                    if (tx.taxType === 1) // (%)             
                        taxAmt = itemValue * tx.taxValue * 0.01;
                    else if (tx.taxType === 2) // Amount
                        taxAmt = tx.taxValue * qty; // 

                    bInsertTax = true;
                }

                if (bInsertTax === true) {
                    taxObj.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                    taxList.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                }
            });
        }
    }

    itemChargeCalc(chargeId, rate, qty, itemId, uid) {
        //  //console.log(chargeId);
        chargeId = chargeId === null ? [] : chargeId;
        if (chargeId.length > 0) {
            Array.prototype.forEach.call(chargeId, cId => {
                // filter based on Item wise & Item Qty wise => apply category
                let chargelist = getCharges.filter(x => x.chargeId === cId && x.applyTo === 1);
                let taxlist = [];
                let taxObj = [];
                let taxAmt = 0;

                let itemValue = rate * qty;
                //  //console.log(chargelist);
                let chargeAmt = 0;
                if (chargelist.length > 0) {
                    Array.prototype.forEach.call(chargelist, chg => {
                        let rnTaxAmt = 0;
                        if (chg.applyType === 2) { // slabe wise (%)   - only for slab base value from and value to (percentage =>itmvalue * per * 0.01) or amount=> direct slab amount)         
                            chg.chargeSlab = chg.chargeSlab === null ? [] : chg.chargeSlab;
                            let cslab = chg.chargeSlab.filter(x => x.ValueFrom > itemValue && x.ValueTo < itemValue)
                            if (cslab.length > 0) {
                                if (cslab.chargeValueType === 1)
                                    chargeAmt = itemValue * chg.chargeValue * 0.01;
                                else if (cslab.chargeValueType === 2)
                                    chargeAmt = cslab.ChargeValue;
                            }
                        }
                        else if (chg.chargeValueType === 2) // Amount only qty based 
                            chargeAmt = chg.chargeValue * qty; // 
                        //  //console.log(chargeAmt);
                        // Tax 
                        taxlist = getTaxes.filter(x => x.taxId === chg.taxId);
                        if (taxlist.length > 0) {
                            Array.prototype.forEach.call(taxlist[0].structure, tx => {
                                taxAmt = 0;
                                if (tx.applicableOn === 3) {
                                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                                    if (serialTax.length > 0) {
                                        if (tx.taxType === 1) // (%) 
                                            taxAmt = serialTax[0].taxAmount * tx.taxValue * 0.01;
                                        else if (tx.taxType === 2) // Amount
                                            taxAmt = tx.taxValue;
                                    }
                                }
                                else {

                                    if (tx.taxType === 1) // (%)             
                                        taxAmt = (chargeAmt * tx.taxValue * 0.01);
                                    else if (tx.taxType === 2) // Amount
                                        taxAmt = tx.taxValue; // 
                                }
                                rnTaxAmt += taxAmt;
                                taxObj.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, taxId: chg.taxId, taxGroupName: taxlist[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                            })
                            // //console.log(taxObj);
                        }
                        chargeList.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, chargeName: chg.chargeName, chargeType: chg.chargeType, isMerchantCharge: false, chargeValueType: chg.chargeValueType, chargeValue: chg.chargeValue, chargeAmount: Number((chargeAmt).toFixed(2)), taxes: taxObj, totalTaxAmount: rnTaxAmt });

                    });
                    // //console.log(chargeList);
                }
            });
        }
    }

    handleModifier(_this) {
        if (modifierRef.current) {
            modifierRef.current.handleModifier();
        }
    }

    callBackModifier() {

        if (sessionStorage.getItem("cartList") != null) {
            cartList = JSON.parse(sessionStorage.getItem("cartList"));

            Array.prototype.forEach.call(cartList.itemList, itm => {
                this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id);
            });
            //  //console.log(cartList);
            this.setState({ totalItems: cartList.totalItems, totalAmount: cartList.totalAmount });
            totalItems = cartList.totalItems;
            totalAmount = cartList.totalAmount;
        }

        this.setState({ showModal: true });
        //  //console.log(this.state.showModal);
    }

    callBackRequest(msg, type) {

        //   //console.log(msg + '-' + type);

        setTimeout(() => {
            if (type === 1)
                this.showMessage(msg, "success");
            else
                this.showMessage(msg, "danger");
        }, Config.minMsgTimeout);
    }

    callBackGroupMembers(msg, type) {

        setTimeout(() => {
            if (type === 1)
                this.showMessage(msg, "success");
            else
                this.showMessage(msg, "danger");
        }, Config.minMsgTimeout);
    }

    handleClearText() {
        // level2 change
        this.searchInp.current.focus();
        // 

        this.setState({ searchText: '' })
        this.setState({ mgfliter: [] });
        this.setState({ fliteredmenuitems: [] });
    }


      // Commended  by vijay on 08-01-2024 Jira id - 2075 -Testing points Unwanted API calls[GetGuestAppToken]
    // getUpdateMenus() {
    //     if (!isUpdateMenu) {
    //         isUpdateMenu = true;
    //         window.scrollTo(0, 0);
    //         this.setState({ loading: true });
    //         setTimeout(() => {
    //             let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
    //             sessionStorage.getItem('menuFetchDateTime') !== null ? urlParam += '&menuDateTime=' + sessionStorage.getItem('menuFetchDateTime') : urlParam += '';
    //             fetch(Config.basePOSUrl + 'Getguestappmenus' + urlParam, {
    //                 method: 'GET',
    //                 headers: {
    //                     Authorization: "Bearer " + token,
    //                     'content-type': 'application/json'
    //                 }
    //             })
    //                 .then(res => res.json())
    //                 .then(response => {
    //                     // //console.log(response);
    //                     if (response.errorCode === 0) {
    //                         // //console.log(JSON.stringify(response.response.menuGroups));          
    //                         if (response.response !== null) {
    //                             // Filter active Menu Items
    //                             let activeMenuGroups = [];
    //                             let activeMenuItems = [];
    //                             if (response.response.menuGroups.length > 0)
    //                                 activeMenuGroups = response.response.menuGroups.filter(x => x.isActive === true).sort(function (a, b) {	// asc										 
    //                                     return new Date(a.displayOrder) - new Date(b.displayOrder);
    //                                 });

    //                             if (response.response.menuItems.length > 0)
    //                                 activeMenuItems = response.response.menuItems.filter(x => x.isActive === true).sort(function (a, b) {	// asc										 
    //                                     return new Date(a.displayOrder) - new Date(b.displayOrder);
    //                                 });

    //                             sessionStorage.setItem("getMenuGroups", JSON.stringify(activeMenuGroups));
    //                             sessionStorage.setItem("getmenuItems", JSON.stringify(activeMenuItems));
    //                             sessionStorage.setItem("getModifierGroups", JSON.stringify(response.response.modifierGroups));
    //                             sessionStorage.setItem("getTaxes", JSON.stringify(response.response.taxes));
    //                             sessionStorage.setItem("getCharges", JSON.stringify(response.response.charges));
    //                             sessionStorage.setItem("menuFetchDateTime", response.response.menuDateTime);
    //                             getMenus = sessionStorage.getItem("getMenuGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getMenuGroups"));
    //                             getItems = sessionStorage.getItem("getmenuItems") === null ? [] : JSON.parse(sessionStorage.getItem("getmenuItems"));
    //                             getModifiers = sessionStorage.getItem("getModifierGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getModifierGroups"));
    //                             getTaxes = sessionStorage.getItem("getTaxes") === null ? [] : JSON.parse(sessionStorage.getItem("getTaxes"));
    //                             getCharges = sessionStorage.getItem("getCharges") === null ? [] : JSON.parse(sessionStorage.getItem("getCharges"));
    //                             recommendedList = getItems.filter(itm => itm.isRecommended);

    //                             this.setState({
    //                                 recommendedList: recommendedList,
    //                                 getMenus: getMenus,
    //                                 getItems: getItems,
    //                             });
    //                             this.setState({ searchText: "" });


    //                             // Update Item Qty Mode
    //                             if (sessionStorage.getItem("cartList") != null) {
    //                                 cartList = JSON.parse(sessionStorage.getItem("cartList"));
    //                                 // group by item
    //                                 Array.prototype.forEach.call(cartList.itemList, itm => {
    //                                     //let totlaQty = document.getElementById("" + itm.id + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itm.id + "").innerHTML);                               
    //                                     let sout = document.getElementById("" + itm.id + "").getAttribute('data-so');
    //                                     this.qtyMode('item-add-' + itm.id, 'item-entry-' + itm.id, itm.id, sout);
    //                                 });
    //                             }
    //                         }
    //                         if (getClassification.length > 0) {
    //                             // Default -> First Classification Select
    //                             this.setState({ activeClassification: 0 })
    //                             this.handleClassificationClick(0, getClassification[0].classificationName, getClassification[0].classification)
    //                         }
    //                     }
    //                     else {
    //                         this.setState({ APIResponseMsg: "Failed to fetch" })
    //                         this.showMessage(response.message, "danger");
    //                     }
    //                     this.setState({ loading: false });
    //                     isUpdateMenu = false;
    //                 })
    //                 .catch(err => {
    //                     this.setState({ APIResponseMsg: "Failed to fetch" });
    //                     this.setState({ loading: false });
    //                     isUpdateMenu = false;
    //                     ////console.log(err);
    //                     // this.showMessage("An error occured", "danger");
    //                 });
    //         }, 500);
    //     }
    // }

    goBill() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });
            setTimeout(() => {

                this.setState({ loading: false });
                this.props.history.push('/mybills' + urlPattern);
            }, Config.minMsgTimeout);
        }
        else {
            this.setState({ loading: true });
            sessionStorage.setItem("login-page-session", 7); // PayBill        
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }
    }

    handleGenerateRecommendedListDom() {
        const { scrollPosition } = this.props;
        let mnugrp = "mnu-grp-";

        return (
            <div>
                {this.state.getMenus.map(itmgrp =>
                    <div className="content-from" key={mnugrp + itmgrp.menuGroupId} id={"focus-mnugrp-" + itmgrp.menuGroupId} >
                        {/* <p>dsas</p> */}
                        {itmgrp.subMenuGroup.length > 0 &&
                            <div className="accordion" id="accordion2">
                                {itmgrp.subMenuGroup.map(sgrp =>

                                    <>
                                        {this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).length > 0 &&
                                            <div className="accordion-group" key={"acc-grp-" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId}>
                                                <div className="accordion-heading">
                                                    <div className='panel-heading'>
                                                        <h4 className="panel-title">
                                                            <a className="accordion-toggle expand collapsed" style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion2" href={"#subgroupcollapse" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId}>
                                                                <p style={{ marginBottom: "-4px" }}>
                                                                    {sgrp.menuGroupName.toLowerCase() + " (" + this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).length + ")"}
                                                                </p>
                                                            </a>
                                                        </h4>
                                                    </div>

                                                </div>

                                                <div id={"subgroupcollapse" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId} className="accordion-body collapse">
                                                    <div className="accordion-inner">
                                                        <div className="wrap-list-rc regular-item">
                                                            {
                                                                this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).map(itm =>
                                                                    <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + sgrp.menuGroupId + "-" + itm.itemId}>
                                                                        <div className="lrc-content">
                                                                            <div className="lrc-desc">
                                                                                <div className={"qty-detail item-entry-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                                                    <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                                    <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                                    <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                                </div>

                                                                                {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                    <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                                                </div>}

                                                                                {outletDetails.enableGuestAppOrders === false && <div className={"qty-detail-dm " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itmgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                </div>}

                                                                                {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itmgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                    <span className="soldout">SOLD OUT</span>
                                                                                </div>}
                                                                                {
                                                                                    (itm.modifierGroupIds !== null &&
                                                                                        itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "0px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? "110px" : (window.innerWidth >= 420 ? "44px" : "40px")), fontSize: ".78rem", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>
                                                                                }
                                                                                {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                                                    <div className={"itm-img " + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl)} >
                                                                                        {itm.outOfStock ? (
                                                                                            <LazyLoadImage
                                                                                                alt="item"
                                                                                                className="item-imgs"
                                                                                                effect="blur"
                                                                                                placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                                scrollPosition={scrollPosition}
                                                                                                src={itm.imageUrl}
                                                                                                delayTime={10000}
                                                                                                threshold={100}
                                                                                            />
                                                                                        ) : (
                                                                                            <LazyLoadImage
                                                                                                alt="item"
                                                                                                className="item-img"
                                                                                                effect="blur"
                                                                                                placeholderSrc="/img/no-image/itm-df-img.png"
                                                                                                scrollPosition={scrollPosition}
                                                                                                src={itm.imageUrl}
                                                                                                delayTime={10000}
                                                                                                threshold={100}
                                                                                            />
                                                                                        )}
                                                                                    </div>}
                                                                                    {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                                                {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                                                                {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                                 <div className='tag-soldout-txt'>
                                                                                 <span className="soldout-txt" style={{ marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px' }}>
                                                                                  SOLD OUT
                                                                               </span>
                                                                                  </div>
                                                                                  )}
                                                                                <div className="lrc-title" style={this.state.styleMenuItem}>

                                                                                    <app-item-type>
                                                                                        <div className="image-type">
                                                                                            {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                            {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                        </div>
                                                                                    </app-item-type>
                                                                                    <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-32px", marginLeft: "28px" }}>{itm.itemName}</span>
                                                                                </div>
                                                                                <div className="lrc-button">
                                                                                    <div className="lrcb-left">
                                                                                        <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                                            <Currency />
                                                                                            {itm.mrp}

                                                                                        </span>
                                                                                    </div>

                                                                                    <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl)} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                                                        <SmartText text={itm.description.toLowerCase()} length={(outletDetails.enableGuestAppOrders === false ? itm.imageUrl === null ? 80 : 30 : 30)} />
                                                                                    </div>
                                                                                    <div className="clear"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) // End Item

                                                            }
                                                        </div>
                                                        {/* Third Level Menugroup - Start */}
                                                        {sgrp.subMenuGroup.length > 0 &&
                                                            <>
                                                                {sgrp.subMenuGroup.map(ssgrp =>
                                                                    <div className="accordion-group" key={"acc-grp-" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId}>
                                                                        <div className="accordion-heading" style={this.state.styleMenuGroup}>

                                                                            <a className="accordion-toggle expand collapsed" style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion2" href={"#subgroupcollapse" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId}>
                                                                                <p style={{ marginBottom: "-4px" }}> {ssgrp.menuGroupName.toLowerCase() + " (" + this.state.getItems.filter(i => i.menuGroupId === ssgrp.menuGroupId).length + ")"}</p>
                                                                            </a>
                                                                        </div>
                                                                        <div id={"subgroupcollapse" + sgrp.menuGroupId + "-" + ssgrp.menuGroupId} className="accordion-body collapse">
                                                                            <div className="accordion-inner">
                                                                                <div className="wrap-list-rc regular-item">
                                                                                    {
                                                                                        this.state.getItems.filter(i => i.menuGroupId === ssgrp.menuGroupId).map(itm =>

                                                                                            <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + ssgrp.menuGroupId + "-" + itm.itemId}>
                                                                                                <div className="lrc-content">
                                                                                                    <div className="lrc-desc">
                                                                                                        <div className={"qty-detail item-entry-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                                                                            <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                                                            <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                                                            <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                                                        </div>

                                                                                                        {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                            <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, sgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                                                                        </div>}


                                                                                                        {outletDetails.enableGuestAppOrders === false && <div className={"qty-detail-dm " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itmgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                                        </div>}


                                                                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + sgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + sgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                                                                            <span className="soldout">SOLD OUT</span>
                                                                                                        </div>}
                                                                                                        {

                                                                                                            (itm.modifierGroupIds !== null &&
                                                                                                                itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={(itm.imageUrl !== null && itm.imageUrl !== "") ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "0px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? "162px" : "46px"), fontSize: ".78rem", width: "116px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>
                                                                                                        }
                                                                                                        {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                                                                            <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl)}>
                                                                                                                <LazyLoadImage
                                                                                                                    alt={"item"}
                                                                                                                    className="item-img"
                                                                                                                    effect="blur"
                                                                                                                    placeholderSrc={"/img/no-image/itm-df-img.png"}
                                                                                                                    scrollPosition={scrollPosition}
                                                                                                                    src={itm.imageUrl}
                                                                                                                    delayTime={10000}
                                                                                                                    threshold={100}
                                                                                                                />
                                                                                                            </div>}
                                                                                                            {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                                                                        {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                                                                                        {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                                                        <div className='tag-soldout-txt'>
                                                                                                        <span className="soldout-txt" style={{ marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px' }}>
                                                                                                        SOLD OUT
                                                                                                        </span>
                                                                                                          </div>
                                                                                                          )}
                                                                                                        <div className="lrc-title" style={this.state.styleMenuItem}>

                                                                                                            <app-item-type>
                                                                                                                <div className="image-type">
                                                                                                                    {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                                                                    {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                                                                                </div>
                                                                                                            </app-item-type>
                                                                                                            <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-32px", marginLeft: "28px" }}>{itm.itemName}</span>


                                                                                                        </div>


                                                                                                        <div className="lrc-button">
                                                                                                            <div className="lrcb-left">
                                                                                                                <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                                                                    <Currency />
                                                                                                                    {itm.mrp}
                                                                                                                </span>                                                                                                            </div>

                                                                                                            <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl)} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                                                                                <SmartText text={itm.description.toLowerCase()} length={(outletDetails.enableGuestAppOrders === false ? itm.imageUrl === null ? 80 : 30 : 30)} />
                                                                                                            </div>
                                                                                                            <div className="clear"></div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) // End Item

                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                                }
                                                            </>
                                                        }
                                                        {/* Third Level Menugroup - End */}
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                    </>

                                )
                                }
                            </div>


                        }
                        {(itmgrp.subMenuGroup.length === 0 && this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).length > 0) &&

                            <div className="wrap-list-rc regular-item">
                                {
                                    this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).map(itm =>
                                        <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + itmgrp.menuGroupId + "-" + itm.itemId}>
                                            <div className="lrc-content">
                                                <div className="lrc-desc">
                                                    <div className={"qty-detail item-entry-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                        <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                    </div>

                                                    {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                        <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                    </div>}


                                                    {outletDetails.enableGuestAppOrders === false && <div className={"qty-detail-dm " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itmgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                    </div>}

                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                        <span className="soldout">SOLD OUT</span>
                                                    </div>}

                                                    {(itm.modifierGroupIds !== null &&
                                                        itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={(itm.imageUrl !== null && itm.imageUrl !== "") === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "0px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? "110px" : (window.innerWidth >= 420 ? "44px" : "42px")), fontSize: ".78rem", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>}
                                                    {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                        <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl)}>


                                                            {itm.outOfStock ? (
                                                                <LazyLoadImage
                                                                    alt="item"
                                                                    className="item-imgs"
                                                                    effect="blur"
                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                    scrollPosition={scrollPosition}
                                                                    src={itm.imageUrl}
                                                                    delayTime={10000}
                                                                    threshold={100}
                                                                />
                                                            ) : (
                                                                <LazyLoadImage
                                                                    alt="item"
                                                                    className="item-img"
                                                                    effect="blur"
                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                    scrollPosition={scrollPosition}
                                                                    src={itm.imageUrl}
                                                                    delayTime={10000}
                                                                    threshold={100}
                                                                />
                                                            )}

                                                        </div>}
                                                        {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                    {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                <div className='tag-soldout-txt'>
                                                               <span className="soldout-txt" style={{ marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px' }}>
                                                                SOLD OUT
                                                               </span>
                                                                 </div>
                                                                 )}
                                                    <div className="lrc-title" style={this.state.styleMenuItem}>

                                                        <app-item-type>
                                                            <div className="image-type">
                                                                {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                            </div>
                                                        </app-item-type>
                                                        <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-32px", marginLeft: "28px" }}>{itm.itemName}</span>


                                                    </div>


                                                    <div className="lrc-button">
                                                        <div className="lrcb-left">
                                                            <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                <Currency />
                                                                {itm.mrp}
                                                            </span>
                                                        </div>

                                                        <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl)} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                            <SmartText text={itm.description.toLowerCase()} length={(outletDetails.enableGuestAppOrders === false ? itm.imageUrl === null ? 80 : 30 : 30)} />
                                                        </div>

                                                        <div className="clear"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) // End Item

                                }
                            </div>


                        }
                        {/* Menu Item with submeu group */}
                        {(itmgrp.subMenuGroup.length > 0 && this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).length > 0) &&

                            <div className="wrap-list-rc regular-item">
                                {
                                    this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).map(itm =>
                                        <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"itm-" + itmgrp.menuGroupId + "-" + itm.itemId}>
                                            <div className="lrc-content">
                                                <div className="lrc-desc">
                                                    <div className={"qty-detail item-entry-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")}>
                                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                        <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                    </div>

                                                    {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail item-add-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                        <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                    </div>}
                                                    {outletDetails.enableGuestAppOrders === false && <div className={"qty-detail-dm " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itmgrp.menuGroupId + "-itm-so-" + itm.itemId}>
                                                    </div>}

                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) && <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                        <span className="soldout">SOLD OUT</span>
                                                    </div>}
                                                    {(itm.modifierGroupIds !== null &&
                                                        // starters menuitems customizable margin top by windowsinnerwidth  change by dasprakash on 18-08-2023 jira-1320
                                                        itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true) && <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "0px", marginTop: ((itm.imageUrl !== null && itm.imageUrl !== "") ? "110px" : (window.innerWidth >= 420 ? "18px" : "42px")), fontSize: ".78rem", width: "130px", textAlign: "center", color: "#7e808c" }}><div className="modifier-notify">customisable</div></div>}
                                                    {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                                        <div className={"itm-img" + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl)}>

                                                            {itm.outOfStock ? (
                                                                <LazyLoadImage
                                                                    alt="item"
                                                                    className="item-imgs"
                                                                    effect="blur"
                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                    scrollPosition={scrollPosition}
                                                                    src={itm.imageUrl}
                                                                    delayTime={10000}
                                                                    threshold={100}
                                                                />
                                                            ) : (
                                                                <LazyLoadImage
                                                                    alt="item"
                                                                    className="item-img"
                                                                    effect="blur"
                                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                                    scrollPosition={scrollPosition}
                                                                    src={itm.imageUrl}
                                                                    delayTime={10000}
                                                                    threshold={100}
                                                                />
                                                            )}
                                                        </div>}
                                                        {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                                    {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                <div className='tag-soldout-txt'>
                                                               <span className="soldout-txt" style={{ marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px' }}>
                                                                SOLD OUT
                                                               </span>
                                                                 </div>
                                                                 )}
                                                    <div className="lrc-title" style={this.state.styleMenuItem}>

                                                        <app-item-type>
                                                            <div className="image-type">
                                                                {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                                {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                            </div>
                                                        </app-item-type>
                                                        <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-32px", marginLeft: "28px" }}>{itm.itemName}</span>
                                                    </div>


                                                    <div className="lrc-button">
                                                        <div className="lrcb-left">
                                                            <span className="lhc price" style={this.state.styleMenuPrice}>
                                                                <Currency />
                                                                {itm.mrp}
                                                            </span>
                                                        </div>

                                                        <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl)} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                                            <SmartText text={itm.description.toLowerCase()} length={(outletDetails.enableGuestAppOrders === false ? itm.imageUrl === null ? 80 : 30 : 30)} />
                                                        </div>
                                                        <div className="clear"></div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    ) // End Item

                                }
                            </div>


                        }
                        {/* Menu Item with submeu group - end */}
                        {!this.state.showOnlySelectedMenuGroupItems &&
                            <div className="clear clear-bottom"></div>}
                    </div>
                )}
            </div>
        );
    };

    handleVerticalGenerateRecommendedListDom() {
        return (
            <div className="content-from" id="focus-mnugrp-recommended">
                <div className="itm-heading" style={this.state.styleMenuGroup}>{this.state.recommendedTxt}</div>
                <div className="wrap-list-rc recommended-item" id="item-list" style={{marginTop:"-12px"}}>
                    {
                        this.state.recommendedList.map(itm =>
                            <div className="lrc-rec-1-item" key={"mnu-rec-" + itm.itemId}>
                                <div className="lrc-content">
                                    {itm.bestSellerItem === true && <span className="ribbon"><span className="txt">BEST SELLER</span></span>}
                                    <div className="lrc-img">
                                        <img src={itm.imageUrl === null ? imageUrl : itm.imageUrl} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, (itm.imageUrl === null ? imageUrl : itm.imageUrl))} loading="lazy" alt="item" />
                                    </div>
                                    <div className="lrc-desc">
                                        <div className="lrc-title" style={this.state.styleMenuItem}>
                                            {/* {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                            {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                            <span className="itm-title">{itm.itemName}</span> */}
                                            <app-item-type>
                                                <div className="image-type">
                                                    {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                    {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                </div>
                                            </app-item-type>
                                            <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-32px", marginLeft: "28px" }}>{itm.itemName}</span>

                                            {/* <div className={"row align-items-start item-name-row" + (itm.outOfStock ? ' tag-soldout-item' : '')} >
                                                <app-item-type>
                                                    <div className="image-type">
                                                        {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <div className='col-8 p-0'>
                                                    <div className='item-name'>{itm.itemName}</div>
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="lrc-text">
                                                                {itm.description.toLowerCase()}
                                                            </div> */}
                                        <div className="lrc-button">
                                            <div>
                                                <div className={"rec-qty-detail item-entry-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide"} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-entry-" + itm.itemId}>
                                                    <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                    <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                    <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>

                                                </div>

                                                {!itm.outOfStock && <div className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                    <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                </div>
                                                }
                                                {itm.outOfStock && <div className={"rec-qty-detail item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                                    <span className="soldout">SOLD OUT</span>
                                                </div>}

                                            </div>
                                            {itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 && <div className={itm.imageUrl === null ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "0px", fontSize: ".78rem", width: "78px", textAlign: "center", color: "#7e808c", top: "314px" }}><div className="modifier-notify">customisable</div></div>}
                                            <div className="lrcb-left">
                                                <span className="lhc price" style={this.state.styleMenuPrice}>
                                                    <Currency />
                                                    {itm.mrp}
                                                </span>
                                            </div>
                                            <div className="lrcb-right">


                                            </div>
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="clear clear-bottom"></div>
            </div>
        );
    };

    handleGenerateRowRecommendedListDom() {
        const { scrollPosition
        } = this.props;
        return (
            <div className="content-from" id="focus-mnugrp-recommended ">
                <div className="wrap-list-rc recommended-item" id="item-list" style={{marginTop:"-12px"}}>
                    {this.state.fliteredmenuitems.map(itm =>
                        <div className={"lrc-item" + ((itm.foodType !== 1 && itm.foodType !== 2) ? " no-food-type" : "")} key={"mnu-rec-" + itm.itemId} >
                            <div className="lrc-content">
                                <div className="lrc-desc">
                                    <div style={{ padding: '1px' }} className={"qty-detail item-entry-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-entry-" + itm.itemId}>
                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                        <span className="qty" data-so={itm.outOfStock} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                    </div>

                                    {(!itm.outOfStock && outletDetails.enableGuestAppOrders === true) &&
                                        // first rec page 
                                        <div className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>

                                            <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                        </div>
                                    }

                                    {/* level2 change */}

                                    {/* commented by dasprakash on 4-08-2023 jira-1302 */}
                                    {/* {outletDetails.enableGuestAppOrders === false &&
                                            <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                            </div>
                                        } */}

                                    {outletDetails.enableGuestAppOrders === false && (
                                        itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 ? (
                                            // If both conditions are true
                                            !(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && 
                                            <div style={{ padding: "5px" }} className={"rec-qty-detail item-add-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " img-button_v2" : "")} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                <span className="modifier-notify dm-customize-fnt-clr" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itm.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                    Customisable
                                                </span>
                                            </div>
                                        ) : (
                                             // If the first condition is true but the second one is false
                                             // Condition Added by vijay on 21-03-2024
                                            // <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                           (itm.outOfStock && itm.foodType !== 1 && itm.foodType !== 2) || (itm.outOfStock && (itm.foodType === 1 || itm.foodType === 2)) ? (                                       
                                           <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId} style={{ display: 'none' }}> </div>
                                           ) : (                                                    
                                          <div className={"qty-detail-dm" + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img_v2" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>  </div>
                                        )
                                        )
                                    )}

                                    {/*  */}

                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === true) &&
                                        <div className={"qty-detail " + ((itm.imageUrl !== null && itm.imageUrl !== "") ? " item-sold-out-img" : "item-sold-out") + " item-so-qty-mnu-rec-" + itm.menuGroupId + "-itm-" + itm.itemId + ""} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-so-" + itm.itemId}>
                                            <span className="soldout">SOLD OUT</span>
                                        </div>}
                                    {/* recomended item customizable margin top change by windowsinnerwidth  changed  by dasprakash on 18-08-2023 jira-1302 */}
                                   {itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true && <div className={itm.imageUrl !== null && itm.imageUrl !== "" ? 'desktop-modifier-no-img-search' : 'desktop-modifier'} style={{ float: "right", position: "absolute", right: "0px", marginTop: itm.imageUrl !== null && itm.imageUrl !== "" ? "102px" : (window.innerWidth >= 420 ? "18px" : "18px"), fontSize: ".78rem", width: "130px", textAlign: "center", color: "#7e808c" }}> 
                                  {/* {itm.modifierGroupIds !== null && itm.modifierGroupIds.length > 0 && outletDetails.enableGuestAppOrders === true && <div className={itm.imageUrl !== null && itm.imageUrl !== "" ? 'desktop-modifier-no-img' : 'desktop-modifier'} style={{ float: "right", position: "relative", right: "-120px", marginTop: itm.imageUrl !== null && itm.imageUrl !== ""  ? (window.innerWidth >= 420 ? "110px" : "18px") : (window.innerWidth >= 420 ? "110px" : "18px"), fontSize: ".78rem", width: "130px", textAlign: "center", color: "#7e808c"}}> */}
                                        <div style={{ fontSize: "13px", fontWeight: "400" }} className="modifier-notify">Customisable</div></div>}
                                    {(itm.imageUrl !== null && itm.imageUrl !== "") &&
                                        <div className={"itm-img " + (outletDetails.enableGuestAppOrders === false ? ' itm-img-dm' : '')} onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-rec-", "false")} >
                                            {/* <img className='item-img' src={itm.imageUrl} loading="lazy" alt="item" /> */}
                                            {/* added function to blure the out of stock img in recomended page by dasprakash on 18-08-2023 jira-1302 */}
                                            {itm.outOfStock ? (
                                                <LazyLoadImage
                                                    alt="item"
                                                    className="item-imgs"
                                                    effect="blur"
                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                    scrollPosition={scrollPosition}
                                                    src={itm.imageUrl}
                                                    delayTime={10000}
                                                    threshold={100}

                                                />
                                            ) : (
                                                <LazyLoadImage
                                                    alt="item"
                                                    className="item-img"
                                                    effect="blur"
                                                    placeholderSrc="/img/no-image/itm-df-img.png"
                                                    scrollPosition={scrollPosition}
                                                    src={itm.imageUrl}
                                                    delayTime={10000}
                                                    threshold={100}
                                                />
                                            )}


                                        </div>
                                    }
                                    {/* Condition Added by vijay on 20-03-2024 -Due to sold out itmes having alignment issues  */}
                                    {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <div className='tag-soldout-txt'><span className="soldout-txt">SOLD OUT</span></div>} */}
                                    {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && (
                                                                <div className='tag-soldout-txt'>
                                                               <span className="soldout-txt" style={{ marginTop: (itm.imageUrl !== null && itm.imageUrl !== "") ? '77px' : '-7px' }}>
                                                                SOLD OUT
                                                               </span>
                                                                 </div>
                                                                 )}
                                    <div className="lrc-title" style={this.state.styleMenuItem}>
                                        {/* <div className={"row align-items-start item-name-row" + (itm.outOfStock ? ' tag-soldout-item' : '')} >
                                                <app-item-type>
                                                    <div className="image-type">
                                                        {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <div className='col-8 p-0'>
                                                    <div className='item-name'>{itm.itemName}</div>
                                                </div>

                                            </div> */}


                                        {/* {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                            {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                            <span className="itm-title">{itm.itemName}</span>
                                           */}
                                        <app-item-type>
                                            <div className="image-type">
                                                {itm.foodType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                {itm.foodType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                            </div>
                                        </app-item-type>
                                        {/* rec page item name */}
                                        <span className={"itm-title " + ((itm.foodType !== 1 && itm.foodType !== 2) ? " itm-title-no-ft" : "")} style={{ display: "flex", marginTop: "-32px", marginLeft: "28px", color: "#9D9D9D" }}>
                                            {this.highlightMatchingText(itm.itemName, this.state.searchText)}
                                            {/* {itm.itemName} */}
                                        </span>

                                        {/* {(itm.outOfStock && outletDetails.enableGuestAppOrders === false) && <span className="soldout-txt">SOLD OUT</span>} */}
                                    </div>


                                    {/* recomended menu items price and des change position by dasprakash on 17-08-2023 jira-1302 */}
                                    <div className="lrc-button">
                                        <div className="lrcb-left">
                                            <span className="lhc price" style={this.state.styleMenuPrice}>
                                                <Currency />
                                                {itm.mrp}
                                            </span>

                                        </div>
                                        {/* <div className="lrcb-right">
                                            <button className="itm-add" type="button" data-toggle="modal" data-target="#modifier-modal" data-Qty><span>ADD</span></button>
                                            // </div> */}
                                        {/* handleSetPopupImage function for image popup by dasprakash on 22-08-2023 jira-1302 */}
                                        <div onClick={this.handleSetPopupImage.bind(this, itm.itemName, itm.description, itm.mrp, itm.foodType, itm.imageUrl, itm, "qty-mnu-rec-", "false")} className={"lrc-text item-desc" + (outletDetails.enableGuestAppOrders === false ? ' item-desc-dm' : '')} style={this.state.styleMenuDesc}>
                                            {/* {itm.description.toLowerCase()} */}
                                            {/* added by dasprakash on 22=09-2023 jira-1302 */}

                                                                   <p className={itm.description.trim() ? "description-true" : "description-false"} style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description.trim() ? "19px" : "-2px" }}>                       
                                                                        {/* <p style={{ marginLeft: "6px", whiteSpace: "pre", marginBottom: itm.description ? "" : "-2px" }}> */}
                                                                        { /* Media Query for Desktop Mode */ }
                                                                             <span className="desktop-description">
                                                                    {itm.description.trim().length > 55
                                                                    ? (
                                                                      <>
                                                                    {itm.description.slice(0, 55) + "..."}
                                                                       <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                    </>
                                                                     )
                                                                         : itm.description.trim()}
                                                                             </span>
                                                                           { /* Media Query for Mobile Mode */ }
                                                                             <span className="mobile-description" style={{marginLeft:"6px"}}>
                                                                        {itm.imageUrl ? // If imageUrl is true
                                                                   (itm.description.trim().length > 18
                                                                     ? (
                                                                <>  
                                                            {itm.description.slice(0, 18) + "..."}
                                                           <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                                 </>
                                                                 )
                                                          : itm.description.trim())
                                                               : // If imageUrl is false/null/empty
                                                 (itm.description.trim().length > 18
                                                ? (
                                                       <>
                                                {itm.description.slice(0, 18) + "..."}
                                                  <span style={{ color: "#000", fontWeight: "500", fontSize: "14px" }}>more</span>
                                                   </>
                                           )
                                               : itm.description.trim())
                                                      }
                                                                             </span>
                                                                   </p> 
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>
                <div className="clear clear-bottom"></div>
            </div>
        );
    };

    goToOrderTracking() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });
            setTimeout(() => {

                this.setState({ loading: false });
                this.props.history.push('/ordertrack' + urlPattern);
            }, Config.minMsgTimeout);
        }
        // else {
        //     this.setState({ loading: true });
        //     sessionStorage.setItem("login-page-session", 7); // PayBill        
        //     setTimeout(() => {
        //         this.setState({ loading: false });
        //         this.props.history.push('/login');
        //     }, 3000);
        //     return false;
        // }
    };

    goToHelpRequest() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });
            // setTimeout(() => {

            //     this.setState({ loading: false });
            //     this.props.history.push('/helprequest');
            // }, 1200);

            setTimeout(() => {
                this.setState({ loading: false });
                this.handleRequest();
            }, Config.minMsgTimeout);


        }
    };

    goToGroupMemberList() {
        if (guestLoggedIn === "true") {
            this.setState({ loading: true });

            setTimeout(() => {
                this.setState({ loading: false });
                this.handleGroupMemberList();
            }, Config.minMsgTimeout);
        }
    };

    getGuestProcessOrders() {
        ////console.log('order getGuestProcessOrders()...');
        // //console.log(mobileNo);
        mobileNo = sessionStorage.getItem('user-mobileno');
        this.setState({ loading: true });
        if (outletCode !== null && mobileNo !== null && mobileNo !== '0000000000') {
            fetch(Config.baseGuestUrl + 'GetGuestOrderList?OutletCode=' + outletCode + '&GuestMobileNo=' + mobileNo, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
            })
                .then(res => res.json())
                .then(response => {
                    this.setState({ loading: false });
                    if (response.errorCode === 0) {
                        // //console.log(response.response.orders);
                        this.setState({ orders: response.response.orders });
                        if (response.response.orders.length > 0) {
                            sessionStorage.setItem('order-track-status', "true");
                            this.setState({ ordertrack: true });
                        }
                    }
                    else {
                        this.setState({ orders: [] });
                        sessionStorage.setItem('order-track-status', "false");
                        this.setState({ ordertrack: false });
                        this.showMessage(response.message, "danger");
                    }
                })
                .catch(err => {
                    this.setState({ loading: false, orders: [] });
                    sessionStorage.setItem('order-track-status', "false");
                    this.setState({ ordertrack: false });
                    this.showMessage('Guest Order List - Failed to fetch - ' + err, "danger");
                });
        }
    }

    //https://lucid.atlassian.net/browse/L21-320
    handleRequest(_this) {
        if (reqeustRef.current) {
            reqeustRef.current.handleRequest();
        }
    }

    handleGroupMemberList(_this) {
        if (groupMemberRef.current) {
            groupMemberRef.current.handleGroupMemberList();
        }
    }
    // added by dasprakash on 23-08-2023 jira-1302
    handleSetPopupImage = (itmName, itmDesc, itmPrice, imgFType, imgUrl, item, id, hide) => {
        //console.log("itmName")
        //console.log(hide)
         // Added by vijay on 16-02-2024 Jira id-2075 - Testing Points
        const itemImgUrl = imgUrl !== null && imgUrl !== '' ? imgUrl : '';  
       
        this.setState({
            imgItemName: itmName,
            imgItemDesc: itmDesc,
            imgItemPrice: itmPrice,
            imgItemFType: imgFType,
            itemImgUrl: itemImgUrl,
            item: item,
            id: id,
            hide: hide
        });

        setTimeout(() => {
            this.handlePopupImage();
        }, 500);
    };

    handlePopupImage() {
        if (imageRef.current) {
            imageRef.current.handlePopupImage();
        }
    };

    getGuestDashboardDetails() {
        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType + '&TableNumber=' + tableNo + '&guestmobileNumber=' + mobileNo;
        ////console.log(urlParam);
        fetch(Config.basePOSUrl + 'GetGuestAppDashBoardDetails' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                // //console.log(response);
                if (response.errorCode === 0) {
                    if (mobileNo === mainGuestMobileNo) {
                        this.setState({ groupPin: response.response.groupDetails.groupCode });
                        sessionStorage.setItem('group-pin', response.response.groupDetails.groupCode);
                        groupPin = sessionStorage.getItem('group-pin') == null ? '' : sessionStorage.getItem('group-pin');
                        if (groupPin !== '') {
                            grouppinstyle = {
                                marginBottom: "40px",
                                marginTop: "256px"
                            }
                            this.setState({ stylegroupPin: grouppinstyle });
                        }
                    }
                    else {
                        groupPin = '';
                        grouppinstyle = {
                            marginBottom: "40px",
                            marginTop: "214px"
                        }
                        this.setState({ stylegroupPin: grouppinstyle });
                    }

                    this.setState({ groupMemberList: response.response.groupDetails.guestDetails });
                }
                else {
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                //////console.log(err);            
                this.showMessage('An error occured - ' + err, "danger");
            });
    };

    handleClassificationClick(index, clfName, clfid, Isfocus = false) {
        let grecList = [];

        if (outletDetails.showOnlySelectedMenuGroupItems) {
            getGlobalItems = getGlobalItems.length === 0 ? getItems : getGlobalItems;
            // //console.log(val);
            let filterItems = getGlobalItems.filter(obj => { return (obj.classification === clfid) });
            //  //console.log(filterItems);
            let recList = filterItems.filter(itm => itm.isRecommended);
            recList.length > 0 ? this.setState({ isRecommendedItemEnabled: true, recommendedItemCount: recList.length }) : this.setState({ isRecommendedItemEnabled: false, recommendedItemCount: 0 })
            grecList = recList;
            // //console.log(recList);
            if (recList.length > 0)
                this.filterMenus([], recList, filterItems);
            else {
                let mgList = this.getFilterMenGroupList(filterItems);
                this.handleMenugroupsClick(1, clfid, mgList[0].menuGroupId);
            }
        }
        else {
            // //console.log(val);
            let filterItems = getItems.filter(obj => { return (obj.classification === clfid) });
            //  //console.log(filterItems);
            let recList = filterItems.filter(itm => itm.isRecommended);
            recList.length > 0 ? this.setState({ isRecommendedItemEnabled: true, recommendedItemCount: recList.length }) : this.setState({ isRecommendedItemEnabled: false, recommendedItemCount: 0 })
            grecList = recList;
            // //console.log(recList);

            this.filterMenus(filterItems, recList, filterItems);

            this.setState({ searchText: "" });
        }

        this.setState({
            activeClassification: index, recommendedTxt: "Recommended " + clfName, loading: true,
            selectedClassification: clfid, activeMenuGroup: grecList.length ? -1 : 0
        });

        if (Isfocus === true) {
            var stickyContainer = document.getElementById("fist-item-row");
            window.scrollTo({
                top: stickyContainer.offsetTop - 200,
                left: 0,
                behavior: 'smooth'
            });
        }
        setTimeout(() => {
            this.setState({
                loading: false
            });
        }, Config.minMsgTimeout);
    };

    handleMenugroupsClick(index, clfid, mgid, Isfocus = false, isRecom = false, mng) {
        const getMenuGroups = JSON.parse(sessionStorage.getItem("getMenuGroups"));
        const getClassification = JSON.parse(sessionStorage.getItem("getclf"));
        // eslint-disable-next-line 
        const filteredMenus = getMenuGroups.filter(item => item.classification == mng.classification);
        // //console.log(fliterClassification);

        // Use findIndex to find the index of mng in the filtered array
        // eslint-disable-next-line 
        const selectedindex = filteredMenus.findIndex(item => item.menuGroupId == mng.menuGroupId); // Assuming mng has an 'id' property
        // eslint-disable-next-line 
        const classificationfind = getClassification.filter(item => item.classification == mng.classification); // Assuming mng has an 'id' property
        // eslint-disable-next-line 
        const classificationindex = getClassification.findIndex(item => item.classification == mng.classification); // Assuming mng has an 'id' property
        // //console.log(classificationfind);


        this.props.history.push('/items' + urlPattern, {
            selectedMenuGroup: mng,
            selectedMenugroupIndex: selectedindex,
            classificationindex: classificationindex,
            searchSelectedClassification: classificationfind,

        })
        // added by dasprakash on 25-08-2023 jira-1302
        // if (mng.subMenuGroup.length > 0) {
        //     this.setState({ submenu: mng.subMenuGroup }, () => {
        //         // //console.log(this.state.submenu); // This will log the updated value
        //         const subMenuItems = [];
        //         this.state.submenu.forEach((element) => {
        //             var filteredItems = this.state.newmenuitem.filter(item => item.menuGroupId === element.menuGroupId);
        //             filteredItems.forEach((items) => {
        //                 subMenuItems.push(items);

        //             });
        //         });
        //         this.setState(filtereddata => ({
        //             fliteredmenuitems: [...filtereddata.fliteredmenuitems, ...subMenuItems]
        //         }));
        //         // //console.log(subMenuItems);
        //     });
        // }
        // else {
        //     this.setState({ submenu: [] })
        // }
        // const menugroupitem = this.state.newmenuitem.filter(items => items.menuGroupId === mgid)
        // // //console.log(menugroupitem)
        // this.setState({ fliteredmenuitems: menugroupitem })


        // //console.log(mgid);
        // let mgFilterItems = [];
        // let clfFilterItems = [];
        // let recList = [];
        // // let mgList = [];
        // this.setState({
        //     activeMenuGroup: index, loading: true,
        //     selectedMenuGroupId: mgid
        // });


        // if (outletDetails.showOnlySelectedMenuGroupItems) {
        //     getGlobalItems = getGlobalItems.length === 0 ? getItems : getGlobalItems;


        //     clfFilterItems = getGlobalItems.filter(obj => { return (obj.classification === clfid) });

        //     recList = isRecom ? clfFilterItems.filter(itm => itm.isRecommended) : [];

        //     clfFilterItems.filter(itm => itm.isRecommended).length > 0 ? this.setState({ isRecommendedItemEnabled: true, recommendedItemCount: clfFilterItems.filter(itm => itm.isRecommended).length }) : this.setState({ isRecommendedItemEnabled: false, recommendedItemCount: 0 });



        //     let filterMGList = getMenus.filter(mg => mg.menuGroupId === mgid);


        //     mgFilterItems = this.getFilterItemList(clfFilterItems, filterMGList);

        //     this.filterMenus(mgFilterItems, recList, clfFilterItems);

        //     if (Isfocus === true) {
        //         var stickyContainer = document.getElementById("fist-item-row");
        //         window.scrollTo({
        //             top: stickyContainer.offsetTop - 200,
        //             left: 0,
        //             behavior: 'smooth'
        //         });
        //     }
        // }
        // else {
        //     if (Isfocus === true) {
        //         stickyContainer = document.getElementById("fist-item-row");
        //         window.scrollTo({
        //             top: stickyContainer.offsetTop - 200,
        //             left: 0,
        //             behavior: 'smooth'
        //         });
        //     }
        //     else {
        //         stickyContainer = document.getElementById("focus-mnugrp-" + mgid + "");
        //         window.scrollTo({
        //             top: stickyContainer.offsetTop - 185,
        //             left: 0,
        //             behavior: 'smooth'
        //         });
        //     }

        // }

        // setTimeout(() => {
        //     this.setState({
        //         loading: false
        //     });
        // }, 500);
    };

    getFilterMenGroupList(itemslist) {
        let filterGroups = [];

        //Menu Group Filter
        let _uniquesGroups = [];

        var _taxItmFound = {};
        for (var md = 0, lenc = itemslist.length; md <= lenc - 1; md++) {
            var stringified = JSON.stringify(itemslist[md].menuGroupId);
            if (_taxItmFound[stringified]) { continue; }
            _uniquesGroups.push({ groupId: itemslist[md].menuGroupId });
            _taxItmFound[stringified] = true;
        };
        //  //console.log(_uniquesGroups);

        Array.prototype.forEach.call(getMenus, grp => {
            let grplst = [];
            let subgrplst = []
            let itemCount = itemslist.filter(itm => itm.menuGroupId === grp.menuGroupId).length;
            if (grp.subMenuGroup.length > 0) {
                Array.prototype.forEach.call(grp.subMenuGroup, sgp => {
                    grplst = _uniquesGroups.filter(x => x.groupId === sgp.menuGroupId);
                    if (grplst.length > 0) {
                        itemCount += itemslist.filter(itm => itm.menuGroupId === sgp.menuGroupId).length;
                        subgrplst.push({ classification: sgp.classification, description: sgp.description, displayOrder: sgp.displayOrder, hasSubMenuGroup: sgp.hasSubMenuGroup, hsnsac: sgp.hsnsac, isActive: sgp.isActive, menuGroupId: sgp.menuGroupId, menuGroupName: sgp.menuGroupName, subMenuGroup: sgp.subMenuGroup, noOfItems: itemCount });

                    }
                    // third Level menus 
                    Array.prototype.forEach.call(sgp.subMenuGroup, ssgp => {
                        grplst = _uniquesGroups.filter(x => x.groupId === ssgp.menuGroupId);
                        if (grplst.length > 0) {
                            itemCount += itemslist.filter(itm => itm.menuGroupId === ssgp.menuGroupId).length;
                            subgrplst.push({ classification: ssgp.classification, description: ssgp.description, displayOrder: ssgp.displayOrder, hasSubMenuGroup: ssgp.hasSubMenuGroup, hsnsac: ssgp.hsnsac, isActive: ssgp.isActive, menuGroupId: ssgp.menuGroupId, menuGroupName: ssgp.menuGroupName, subMenuGroup: ssgp.subMenuGroup, noOfItems: itemCount });
                        }

                    });

                });
                // //console.log(subgrplst);
                if (subgrplst.length > 0) {
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: subgrplst, noOfItems: itemCount });
                }
            }
            else {
                grplst = _uniquesGroups.filter(x => x.groupId === grp.menuGroupId);
                if (grplst.length > 0)
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: [], noOfItems: itemCount });
            }
        });
        ////console.log(filterGroups);

        filterGroups = [...filterGroups].sort((a, b) => a.displayOrder - b.displayOrder);


        return filterGroups;

    };

    getFilterItemList(itemslist, mgList) {

        let filterItemList = [];
        let items = [];
        Array.prototype.forEach.call(mgList, grp => {

            items = itemslist.filter(itm => itm.menuGroupId === grp.menuGroupId);

            if (items.length > 0) filterItemList = this.getFilterItems(items, filterItemList);

            // 2nd lvele items
            if (grp.subMenuGroup.length > 0) {
                Array.prototype.forEach.call(grp.subMenuGroup, sgp => {
                    items = itemslist.filter(itm => itm.menuGroupId === sgp.menuGroupId);
                    if (items.length > 0) filterItemList = this.getFilterItems(items, filterItemList);

                    // third Level menus 
                    Array.prototype.forEach.call(sgp.subMenuGroup, ssgp => {
                        items = itemslist.filter(itm => itm.menuGroupId === ssgp.menuGroupId);
                        if (items.length > 0) filterItemList = this.getFilterItems(items, filterItemList);
                    })
                })
            }
        });
        // //console.log(filterItemList);
        return filterItemList;

    };

    getFilterItems(aList, oList) {
        let itemlist = [];
        Array.prototype.forEach.call(aList, i => {
            itemlist = {
                itemType: i.itemType, itemId: i.itemId, itemName: i.itemName,
                description: i.description, rate: i.rate, mrp: i.mrp, foodType: i.foodType, spiciness: i.spiciness,
                menuGroupId: i.menuGroupId, classification: i.classification, taxId: i.taxId, modifierGroupIds: i.modifierGroupIds,
                chargeIds: i.chargeIds, imageUrl: i.imageUrl, outOfStock: i.outOfStock, bestSellerItem: i.bestSellerItem, isRecommended: i.isRecommended,
                displayOrder: i.displayOrder, hsnsac: i.hsnsac, subItems: i.subItems, isActive: i.isActive
            }
            oList.push(itemlist);
        })
        return oList;
    };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            // this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };
    // added by dasprakash on 23-09-2023 jira-1302
    highlightMatchingText = (text) => {
        const { searchText } = this.state;

        if (!searchText) {
            return text;
        }

        const lowerSearchText = searchText.toLowerCase();

        let startIndex = text.toLowerCase().indexOf(lowerSearchText);

        if (startIndex === -1) {
            // If search text not found, return the original text
            return text;
        }

        const endIndex = startIndex + searchText.length;
        const beforeMatch = text.slice(0, startIndex);
        const matchedText = text.slice(startIndex, endIndex);
        const afterMatch = text.slice(endIndex);

        return (
            <>
                <span style={{ marginBottom: "1px", display: "block" }}>
                    {beforeMatch}
                    <span style={{ color: "black", display: "inline" }}>{matchedText}</span>
                    {afterMatch}
                </span>

            </>
        );
    };

    render() {
        // let mnugrp = "mnu-grp-";
        //let recmnu = "mnu-rec-";
        let showCart = cartList.itemList.length > 0 ? "block" : "none";
        // let itmindex = -1;     
        // const { scrollPosition } = this.props;
        return (
            <div>
                {this.state.loading && <LoadingAnimation />}
                <div id="content" className="items-top">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    {/* <Header /> */}

                    <div className="content-warp">
                        <div className="section-items" >
                            <div style={{ display: "flex", alignItems: "center", fontSize: "30px", marginLeft: "10px", marginTop: "6px", padding: "0px 3px" }} className="back-btn">
                                <div className="icon-back" onClick={this.goBack}>
                                    {/* level2 change */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 24" fill="none">
                                        <path d="M12 2L2 12L12 22" stroke="#222222" strokeWidth="2.5" strokeLinecap="round" />
                                    </svg>
                                    {/*  */}
                                </div>
                                {/* level2 change */}
                                <p style={{ color: "#454545", fontSize: "14px", fontWeight: "400", height: "1px", marginLeft: "20px" }} >
                                    Search your favourite dishes
                                </p>
                                {/*  */}
                            </div>

                            <div style={{ position: "static", padding: "10px 0px" }} className='item-filter'>
                                <div style={{ width: "97%", padding: "0px 10px " }} className="search-wrapper">
                                    {/* level2 change */}
                                    <input
                                        style={{ fontSize: "14px" }}
                                        id="search"
                                        autoComplete="Off"
                                        ref={this.searchInp}
                                        placeholder="Enter dish name"
                                        value={this.state.searchText}
                                        onChange={(e) => this.handlechange(e.target.value)}
                                    />
                                    {/*  */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                        <rect x="1" y="1" width="33" height="33" fill="#728EB8" fillOpacity="0.25" />
                                        <rect x="0.75" y="0.75" width="33.5" height="33.5" stroke="#666666" strokeOpacity="0.03" strokeWidth="0.5" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M22.6288 21.4783C22.6089 21.4586 22.6058 21.4271 22.6228 21.4048C23.6111 20.1101 24.0757 18.4899 23.9225 16.8671C23.7678 15.2277 22.9944 13.7089 21.7595 12.6195C20.5247 11.5302 18.9213 10.9522 17.2754 11.0031C15.6295 11.054 14.0649 11.7301 12.8998 12.8937C11.7336 14.0581 11.0554 15.6233 11.0032 17.2705C10.9511 18.9176 11.529 20.5226 12.6191 21.7584C13.7092 22.9943 15.2296 23.768 16.8703 23.9218C18.4944 24.074 20.1153 23.6077 21.4097 22.6171C21.4324 22.5997 21.4646 22.602 21.4844 22.6227L24.9176 26.0567C24.9928 26.1319 25.0821 26.1916 25.1803 26.2323C25.2786 26.273 25.3839 26.2939 25.4902 26.2939C25.5966 26.2939 25.7019 26.273 25.8001 26.2323C25.8983 26.1916 25.9876 26.1319 26.0628 26.0567C26.138 25.9815 26.1977 25.8923 26.2384 25.794C26.279 25.6958 26.3 25.5905 26.3 25.4841C26.3 25.3778 26.279 25.2725 26.2384 25.1742C26.1977 25.076 26.138 24.9867 26.0628 24.9115L22.6288 21.4783ZM20.9122 14.0389C21.3692 14.4885 21.7326 15.024 21.9815 15.6148C22.2304 16.2055 22.3599 16.8397 22.3625 17.4807C22.3651 18.1217 22.2408 18.7569 21.9967 19.3496C21.7526 19.9424 21.3935 20.4809 20.9403 20.9342C20.487 21.3875 19.9485 21.7465 19.3557 21.9906C18.763 22.2347 18.1278 22.359 17.4868 22.3564C16.8458 22.3538 16.2116 22.2243 15.6209 21.9754C15.0301 21.7265 14.4945 21.3631 14.045 20.9061C13.1465 19.9929 12.6454 18.7617 12.6506 17.4807C12.6558 16.1997 13.167 14.9726 14.0728 14.0667C14.9787 13.1609 16.2057 12.6497 17.4868 12.6445C18.7678 12.6393 19.999 13.1405 20.9122 14.0389Z" fill="#454545" />
                                    </svg>

                                    {/* level2 change */}
                                    {this.state.searchText !== "" ?
                                        <button style={{ marginRight: "23px", marginTop: "-10px" }} className="ssubmit" type="button" name="search_submit" onClick={this.handleClearText.bind(this)}><i className="fas fa-times"></i></button>
                                        : ""
                                    }
                                    {/*  */}

                                </div>
                            </div>
                            {/* level2 change  */}
                            {this.state.mgfliter.length >= 1 && (
                                <div id='menu-group-v2' style={{ position: "static", boxShadow: "0px 0px 0px 0px", border: "1px solid rgba(217, 217, 217, 0.50)" }} className="filter-sticky">
                                    {/*  */}{this.state.mgfliter.length >= 1 ?
                                        <p style={{ color: "#7D7D7D", fontSize: "16px", fontWeight: "500", height: "8px", padding: "0px 5px", marginLeft: "8px" }}>Menu Group</p>
                                        : ""}
                                    {this.state.themeId !== 2 &&
                                        <div style={{ borderTop: "0", marginLeft: "10px", height: "auto" }} className='mng-list'>

                                            {/* menu group lists */}
                                            {/* <Slider {...mngpSettings} > */}
                                            {
                                                this.state.mgfliter.map((mng, idx) =>
                                                    <div key={"mng-main-" + mng.menuGroupId}>
                                                        <div style={{ marginRight: "10px", width: "110px" }} className={idx === this.state.activeMenuGroup ? "mng-name" : "mng-name"} key={"mng-" + mng.menuGroupId} id={"mng-" + mng.menuGroupId} onClick={this.handleMenugroupsClick.bind(this, idx, this.state.selectedClassification, mng.menuGroupId, false, false, mng)}>
                                                            {/*  added by dasprakash 16-08-2023 jira-1302 */}
                                                            {mng.imageUrl === '' ?
                                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "auto" }}>
                                                                    <img style={{ borderRadius: "11px", marginBottom: "5px" }} width="80" height="80" alt={mng.menuGroupName} src={defaultimg} />
                                                                    <p style={{ marginBottom: "-11px", }}> {mng.menuGroupName}</p>
                                                                </div>
                                                                :
                                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "auto" }}>
                                                                    <img style={{ borderRadius: "11px", marginBottom: "5px" }} width="80" height="80" alt={mng.menuGroupName} src={mng.imageUrl} />

                                                                    <p style={{ marginBottom: "-11px", color: "#6F7072" }}>
                                                                        {this.highlightMatchingText(mng.menuGroupName)}
                                                                    </p>

                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            {/* </Slider> */}
                                        </div>
                                    }
                                </div>
                            )}



                            <div id='control'>

                                {this.state.fliteredmenuitems.length >= 1 ? <p style={{ color: "#7D7D7D", fontSize: "16px", fontWeight: "500", height: "8px", marginLeft: "10px", padding: "0px 5px" }}>Items</p>
                                    : ""}
                                <div id="fist-item-row"></div>
                                {/* {this.state.searchText == "" ? (this.handleGenerateRowRecommendedListDom()) : ""} */}
                                {/* {this.state.recommendedList.length > 0 && */}
                                <>
                                    {this.handleGenerateRowRecommendedListDom()}
                                </>
                                {/* } */}



                            </div>
                            {/* end */}



                            {this.state.getMenus.length > 0 && <div className="clear clear-bottom"></div>}
                            {this.state.getMenus.length === 0 &&
                                <div className="no-menu-img"><img src="/img/no-image/no-menus.png" alt="lucid-no-menus" /></div>
                            }
                            <Modifier
                                ref={modifierRef}
                                callBackModifier={this.callBackModifier}
                                modifierItemCFoodType={this.state.modifierFoodType}
                                modifierItemCItemName={this.state.modifierItemName}
                                modifierItemCItemId={this.state.modifierItemId}
                                modifierGroupIds={this.state.modifierGroupIds}
                                modifierList={getModifiers}
                                modifierTotalItemAmount={this.state.totalAmount}
                                modifierTotalItems={this.state.totalItems}
                                modifierItemCQty={this.state.modifierItemCQty}
                                modifierItemCQtyId={this.state.modifierItemCId}
                                modifierItemCRate={this.state.modifierItemCRate}
                                modifierItemCGroupId={this.state.modifierItemCGroupId}
                                modifierItemCTaxId={this.state.modifierItemCTaxId}
                                modifierItemCChargeIds={this.state.modifierItemCChargeIds}
                                getTaxes={getTaxes}
                                getCharges={getCharges}
                                showModal={this.state.showModal}
                            ></Modifier>
                            <Request
                                ref={reqeustRef}
                                callBackRequest={this.callBackRequest}
                                token={sessionStorage.getItem('user-token')}
                                mobileNo={sessionStorage.getItem('user-mobileno')}
                                propertyId={sessionStorage.getItem('user-propertyid')}
                                outletCode={sessionStorage.getItem('user-outletcode')}
                                tableNo={sessionStorage.getItem('user-tableno')}
                                serviceType={sessionStorage.getItem('user-servicetype')}

                            ></Request>
                            <GroupMembers
                                ref={groupMemberRef}
                                callBackGroupMembers={this.callBackGroupMembers}
                                token={sessionStorage.getItem('user-token')}
                                mobileNo={sessionStorage.getItem('user-mobileno')}
                                propertyId={sessionStorage.getItem('user-propertyid')}
                                outletCode={sessionStorage.getItem('user-outletcode')}
                                tableNo={sessionStorage.getItem('user-tableno')}
                                serviceType={sessionStorage.getItem('user-servicetype')}
                            ></GroupMembers>
                            {/* image popup */}


                            <ImagePopup
                                ref={imageRef}
                                imgItemName={this.state.imgItemName}
                                imgItemDesc={this.state.imgItemDesc}
                                imgItemPrice={this.state.imgItemPrice}
                                imgItemFType={this.state.imgItemFType}
                                imgUrl={this.state.itemImgUrl}
                                item={this.state.item}
                                id={this.state.id}
                                hide={this.state.hide}
                                handleQtyIncDec={this.handleQtyIncDec}
                            >
                            </ImagePopup>

                            {/* <Footer></Footer> */}
                            {(this.state.themeId === 2 && this.state.searchText.trim() === '') && <PopupMenus recommendedList={this.state.recommendedList} getMenus={this.state.getMenus} ></PopupMenus>}

                            {/* <div className="menu-goup">
                                <button className="theme-button btn" data-toggle="modal" data-target="#filter-menu-group-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU</button>
                            </div> */}
                            {this.state.totalItems > 0 &&
                                <Cart
                                    totalItems={this.state.totalItems}
                                    totalAmount={this.state.totalAmount}
                                    getMenus={getMenus}
                                    getItems={getItems}
                                    getModifiers={getModifiers}
                                    getTaxes={getTaxes}
                                    getCharges={getCharges}
                                    active={showCart}
                                    checkOutTypewithPay={enmConfig.checkOutTypewithPay}
                                    userSessionId = {userSessionId}
                                ></Cart>
                            }
                            {/* <div className="cart-div" onClick={this.goCart}>
                                <div className="c-left"> <span>3</span> Items | ₹<span> 525</span></div>
                                <div className="c-right">View Cart <i className="fa fa-shopping-bag"></i></div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
