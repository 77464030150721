import React, { Component } from "react";
import "react-tabs/style/react-tabs.css";

export default class AlertMessagePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
		};
	}

	openModal = () => {
		this.setState({ showModal: true });
	};

	closeModal = () => {
		this.setState({ showModal: false });
	};

	handlePopupAlertMessage() {
		this.inputElement.click();
	}

	handlePopupContentMessage = () => {
        this.inputElement.click();
    };

	render() {
		return (
			<>
				<div className="section-image">
					<div className="menu-goup">
						<button
							className="theme-button btn"
							style={{ display: "none" }}
							ref={(input) => (this.inputElement = input)}
							data-toggle="modal"
							data-target="#alert-message-modal"
						>
							<i className="fa fa-utensils sidebar-icon"></i> MENU1
						</button>
					</div>
					<div
						className={`modal fade ${this.state.showModal ? "show" : ""}`}
						style={{ display: this.state.showModal ? "block" : "none" }}
						id="alert-message-modal"
						role="dialog"
						aria-labelledby="exampleModalLabel"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-dialog-centered" role="document">
							<div
								className="modal-content animate-bottom"
								style={{ borderRadius: "11px", backgroundColor: "#f1f0f5" }}
							>
								<div className="modal-header modal-header_v2 p-2">
									{/* <button type="button" className="close" onClick={this.closeModal} aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button> */}
								</div>
								<div
									className="modal-body"
									id="div-request-body"
									style={{ marginTop: "-40px"}}
								>
									<div className="single-item-wrapper">
										<div className="single-item">
											<div
												className="message-container"
												style={{
													textAlign: "center",
													marginTop: "20px",
													fontSize: "14px",
													fontWeight: "600",
													color: "#000",
												}}
											>
												<svg 
                                                    
													height="48"
													viewBox="0 0 48 48"
													width="48"
													xmlns="http://www.w3.org/2000/svg"
                                                    style={{ padding: "5px" }}
												>
                                                   
													<path d="M0 0h48v48h-48z" fill="none" />
													<path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z" />
												</svg>
												<p>{this.props.message}</p>
											</div>
										</div>

										<div
											className="popup-footer_v2"
											style={{ justifyContent: "center" }}
										>
											{/* class name popup-footer_v2-price added by dasprakash on 21-08-2023 jira-1302 */}
											<div className="text-right popup-footer_v2-price"></div>
											<div
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
												}}
											>
												<div
													data-dismiss="modal"
													aria-label="Close"
													style={{
														textAlign: "center",
														backgroundColor: "#FFF",
														boxShadow: "1px 1px 5px 1px rgba(0, 0, 0, 0.25)",
														borderRadius: "7px",
														marginTop:
															this.props.item?.modifierGroupIds?.length > 0
																? "10px"
																: "14px",
														width: "108px",
														cursor: "pointer",
													}}
													className="add"
													onClick={this.closeModal}
												>
													OK
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
