import React, { Component } from 'react';
//import './request.scss';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//import Config from '../../data/SiteConfig';
import Currency from '../../components/currency';
export default class ImagePopup extends Component {
    constructor(props) {
        super(props)
        this.handlePopupImage = this.handlePopupImage.bind(this);
        // this.handleSetPopupImage = this.handleSetPopupImage.bind(this);
        this.state = {
            enableGuestAppOrders: true,
        }
    }

    handlePopupImage() {
        //this.getGuestRequestList();        
        this.inputElement.click();
        // console.log("this.props");
        // console.log(this.props);
        // console.log('function')
        // console.log(this.props.hide);
        const outletDetailsJSON = sessionStorage.getItem('user-outletDetails');
        const outletDetails = JSON.parse(outletDetailsJSON);
        // console.log("outletDetails");
        // console.log(outletDetails.enableGuestAppOrders);
        this.setState({ enableGuestAppOrders: outletDetails.enableGuestAppOrders })

    }
    handleClick = () => {
        const { id, menuGroupsId, item } = this.props;
        const menuGroupId = menuGroupsId || item.menuGroupId; // Use menuGroupsId if it exists, otherwise fallback to item.menuGroupId
        const uniqueIdentifier = `${id}${menuGroupId}-itm-${item.itemId}`;

        // console.log("uniqueIdentifier");
        // console.log(uniqueIdentifier);

        this.props.handleQtyIncDec(
            uniqueIdentifier,
            1,
            item.rate,
            item.itemName,
            item.itemId,
            item.menuGroupId,
            item.foodType,
            item.modifierGroupIds,
            item.taxId,
            item.chargeIds
        );
    };





    render() {

        return (
            <>
                <div className="section-image">
                    <div className="menu-goup">
                        <button className="theme-button btn" style={{ display: "none" }} ref={input => this.inputElement = input} data-toggle="modal" data-target="#image-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU1</button>
                    </div>
                    <div className="modal fade" id="image-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div style={{ height: "90vh" }} className="modal-dialog modal-dialog-centered" role="document">
                            {/* style by dasprakash on 21-08-2023 jira-1302 */}
                            {/* <button style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "9px" }} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ padding: "4px" }} aria-hidden="true">&times;</span>
                            </button> */}
                            <div style={{ borderRadius: "11px", backgroundColor: "#f1f0f5" }} className="modal-content animate-bottom">
                                <div className="modal-header modal-header_v2 p-2">
                                    {/* <h6 className="modal-title" style={{ textTransform: "capitalize" }}>
                                        {this.props.imgItemName}
                                    </h6> */}

                                    {/* commented by dasprakash on 21-08-2023 jira-1302 */}

                                </div>
                                <div className="modal-body" id="div-request-body" style={{ marginTop: "-12px", marginBottom: "-12px" }}>
                                    <div className='single-item-wrapper'>
                                        <div className='single-item'>
                                            <div className='row'>
                                                {/* class name single-item-row-img_v2 added by dasprakash on 21-08-2023 jira-1302 */}
                                                {this.props.imgUrl !== "" && <img className='img-fluid item-img single-item-row-img_v2 ' src={this.props.imgUrl} loading="lazy" alt={this.props.imgItemName + '-lucid-pos'} style={{ objectFit: "cover", width: "100%" }} />}
                                            </div>
                                        </div>
                                        <div className=' single-item mt-3'>
                                            <div className="row mb-2 mt-2 align-items-start">
                                                <app-item-type>
                                                    {/* class name  type-img_v2 added by dasprakash on 21-08-2023 jira-1302 */}

                                                    <div className="single-image-type type-img_v2">
                                                        {this.props.imgItemFType === 1 && <img className="food-type-img" src={"/img/f-t-veg.png"} alt="item" />}
                                                        {this.props.imgItemFType === 2 && <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="item" />}
                                                    </div>
                                                </app-item-type>
                                                <div className='col-8 p-0'>
                                                    {/* class name items-start-itemname_v2 added by dasprakash on 21-08-2023 jira-1302 */}
                                                    <div className='item-name items-start-itemname_v2 '>{this.props.imgItemName}</div>
                                                </div>
                                            </div>
                                            {this.props.imgItemDesc !== "" &&
                                                <div className="row ">
                                                    <div className="col">
                                                        {/* class name popup-des_v2 added by dasprakash on 21-08-2023 jira-1302 */}

                                                        <p className="item-description popup-des_v2">
                                                            {this.props.imgItemDesc}
                                                        </p>
                                                    </div>
                                                </div>
                                            }

                                            {/* class name popup-footer_v2 added by dasprakash on 21-08-2023 jira-1302 */}
                                            <div className='popup-footer_v2'>
                                                {/* class name popup-footer_v2-price added by dasprakash on 21-08-2023 jira-1302 */}
                                                <div className='text-right popup-footer_v2-price'>
                                                    <p className='item-price '>
                                                        <Currency />
                                                        <span>{this.props.imgItemPrice}</span>
                                                    </p>
                                                </div>
                                                {this.props.hide === "false" || this.state.enableGuestAppOrders === false ?
                                                    ""
                                                    :
                                                    // <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    //     {this.props.item?.outOfStock ?
                                                    //         <div style={{ textAlign: "center", backgroundColor: "#FFF", boxShadow: "1px 1px 5px 1px rgba(0, 0, 0, 0.25)", borderRadius: "7px", marginTop: "10px", padding: "1px 5px", width: "108px", height: "30px" }} className="soldout" >
                                                    //             Sold Out
                                                    //         </div>
                                                    //         :
                                                    //         // <div onClick={this.handleClick} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                    //         //     <span style={{ textAlign: "center", backgroundColor: "#FFF", boxShadow: "2px 4px 30px 1px rgba(0, 0, 0, 0.25)", borderRadius: "7px", marginTop: "10px" }} aria-hidden="true">ADD</span>
                                                    //         // </div>
                                                    //         <div data-dismiss="modal" aria-label="Close" style={{ textAlign: "center", backgroundColor: "#FFF", boxShadow: "1px 1px 5px 1px rgba(0, 0, 0, 0.25)", borderRadius: "7px", marginTop: "10px", width: "108px" }} className="add" onClick={this.handleClick}>
                                                    //             ADD
                                                    //         </div>
                                                    //     }
                                                    //     <div>
                                                    //         {this.props.item?.modifierGroupIds?.length > 0 ? (
                                                    //             <div style={{ fontSize: "15px", fontWeight: "400", color: "#7F7F7F" }}>Customisable</div>
                                                    //         ) : (
                                                    //             <div>&nbsp;</div> // Empty space
                                                    //         )}
                                                    //     </div>
                                                    // </div>
                                                    <div
														style={{
															display: "flex",
															flexDirection: "column",
															alignItems: "center",
														}}
													>
														{this.props.item?.outOfStock ? (
															<div
																style={{
																	textAlign: "center",
																	backgroundColor: "#FFF",
																	boxShadow:
																		"1px 1px 5px 1px rgba(0, 0, 0, 0.25)",
																	borderRadius: "7px",
																	marginTop: this.props.item?.modifierGroupIds?.length > 0 ? "10px" : "3px",
																	padding: "1px 5px",
																	width: "108px",
																	height: "30px",
																}}
																className="soldout"
															>
																SOLD OUT
															</div>
														) : (
															// <div onClick={this.handleClick} type="button" className="close" data-dismiss="modal" aria-label="Close">
															//     <span style={{ textAlign: "center", backgroundColor: "#FFF", boxShadow: "2px 4px 30px 1px rgba(0, 0, 0, 0.25)", borderRadius: "7px", marginTop: "10px" }} aria-hidden="true">ADD</span>
															// </div>
															<div
																data-dismiss="modal"
																aria-label="Close"
																style={{
																	textAlign: "center",
																	backgroundColor: "#FFF",
																	boxShadow:
																		"1px 1px 5px 1px rgba(0, 0, 0, 0.25)",
																	borderRadius: "7px",
																	marginTop: this.props.item?.modifierGroupIds?.length > 0 ? "10px" : "3px",
																	width: "108px",
																}}
																className="add"
																onClick={this.handleClick}
															>
																ADD
															</div>
														)}
														<div>
                                       {this.props.item?.modifierGroupIds?.length > 0 && (
                                  <div
      style={{
        fontSize: "15px",
        fontWeight: "400",
        color: "#7F7F7F",
      }}
    >
      Customisable
    </div>
  )}
  {this.props.item?.modifierGroupIds?.length === 0 && this.props.addButtonVisible && (
    <div>&nbsp;</div>
  )}
                                                   </div>

													</div>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    {/* commented by dasprakash on 21-08-2023 */}
                                    {/* <div></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>

        );
    }

}
