import React, { Component } from 'react';
//import Footer from '../../components/footer';
import './items.scss';
import PopupMenus from '../../../components/emenu/popumenus';
import Modifier from '../../../components/emenu/modifier';
//import uuid from 'react-uuid';
//import ErrorAlert from '../../components/alertmessages';
import LoadingAnimation from '../../../components/loadinganimation';
import { isMobile } from 'react-device-detect';
//import Cookies from 'universal-cookie';
import Config from '../../../data/SiteConfig';
import { css } from 'glamor'

const imageUrl = '../assets/theme1/img/theme/bg-pattern/bg4.jpg';

let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationId = '';
let propertyId = '';
let outletCode = '';
//let verifiedTable = '';
let digitalMenu = '';
let getMenus = [];
let getItems = [];
let getTaxes = [];
let getModifiers = [];
let getCharges = [];
let recommendedList = [];
let isUpdateMenu = false;
const modifierRef = React.createRef();
export default class emItems extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.filterMenus = this.filterMenus.bind(this);
        this.state = {
            loading: false,
            getMenus: [],
            getItems: [],
            getModifiers: [],
            getTaxes: [],
            getCharges: [],
            recommendedList: [],
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            groupName: '',
            groupId: '',
            switch1: false,
            activeMenuGroup: -1,
            totalItems: 0,
            totalAmount: 0,
            cartList: { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] },
            modifierGroupIds: [],
            modifierItemName: '',
            modifierItemId: 0,
            modifierItemCQty: 0,
            modifierItemCRate: 0,
            modifierItemCId: "",
            modifierItemCGroupId: 0,
            modifierItemCTaxId: 0,
            modifierItemCChargeIds: [],
            modifierFoodType: '',
            modifierTotalItemAmount: 0,
            checkNoQty: new Map(),
            // Style
            styleBackgroundImage: {
                background: `url(${imageUrl}) no-repeat center center fixed`,
                WebkitBackgroundSize: "cover",
                BackgroundSize: "cover",
                MozBackgroundSize: "cover",
                OBackgroundSize: "cover",
                fontFamily: "Raleway, sans-serif",
                border: "none",
                paddingBottom: "52px",
            },
            styleHeading: {
                backgroundColor: "#59324c",
                color: "#fff",
                height: "110px",
                fontSize: "18px",
                fontFamily: "Raleway, sans-serif",
                padding: "10px 14px 8px 40px",
                borderBottom: "1px solid #fff"
            },
            styleSubHeading: {
                color: "#f9bd09",
                fontSize: "12px",
                fontFamily: "Raleway, sans-serif"
            },
            styleMenuGroup: {
                color: "#fff",
                fontSize: "17px",
                fontFamily: "Raleway, sans-serif",
                backgroundColor: "#59324c",
                width: "250px"
            },
            styleMengroupAfter: css({
                ':after': [{ borderLeft: "16px solid #59324c", content: `''`, }]
            }),
            styleMengroupBefore: css({
                ':before': [{ borderBottom: "9px solid #59324c", content: `''`, }]
            }),

            styleSubMenuGroup: {
                color: "#59324c",
                fontSize: "15px",
                fontFamily: "Raleway, sans-serif"
            },

            styleMenuItem: {
                color: "#59324c",
                fontSize: "16px",
                fontFamily: "Raleway, sans-serif"
            },

            styleMenuDesc: {
                color: "#59324c",
                fontSize: "12px",
                fontFamily: "Raleway, sans-serif"
            },
            styleMenuPrice: {
                color: "#59324c",
                fontSize: "16px",
                fontFamily: "Raleway, sans-serif"
            },
            stylePopupMenubBtn:
            {
                color: "#f9f2ea",
                fontFamily: "Raleway, sans-serif",
                backgroundColor: "#59324c",
            },
            stylePopupText:
            {
                color: "#59324c",
                fontFamily: "Raleway, sans-serif"
            },
            styleContent:
            {
                color: "#f9f2ea",
                fontSize: "13px",
                backgroundColor: "#fcf9ea",
            },
            styleRefresh:
            {
                color: "#fff",
            },
            pageContent: "",
            pagePreview: "",
            searchText: ""

        }
    }

    componentWillMount() {

        if (!isMobile) {
            this.props.history.push('/deviceerror');
            return false;
        }

        sessionStorage.removeItem("user-vendorOrderId");

        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationId = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        digitalMenu = sessionStorage.getItem('user-digitalMenu');
        // console.log(mobileNo);
        // console.log(token);
        // console.log(tableNo);
        // console.log(serviceType);
        // console.log(orgnationId);
        // console.log(propertyId);
        // console.log(outletCode);
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror');
            return false;
        }

        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/');
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/');
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/');
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/');
            return false;
        }
        if (orgnationId === undefined || orgnationId === "" || orgnationId === null) {
            this.props.history.push('/');
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/');
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/');
            return false;
        }

        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/');
            return false;
        }

        if (digitalMenu === undefined || digitalMenu === "" || digitalMenu === null || digitalMenu === false || digitalMenu === "false") {
            this.props.history.push('/');
            return false;
        }


        window.scrollTo(0, 0);
        document.body.classList.remove('modal-open');
        let modalPopup = document.querySelector('.modal-backdrop');
        if (modalPopup !== null) {
            modalPopup.classList.remove('modal-backdrop', 'fade', 'show');
        }


        getMenus = sessionStorage.getItem("getMenuGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getMenuGroups"));
        getItems = sessionStorage.getItem("getmenuItems") === null ? [] : JSON.parse(sessionStorage.getItem("getmenuItems"));
        getModifiers = sessionStorage.getItem("getModifierGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getModifierGroups"));
        getTaxes = sessionStorage.getItem("getTaxes") === null ? [] : JSON.parse(sessionStorage.getItem("getTaxes"));
        getCharges = sessionStorage.getItem("getCharges") === null ? [] : JSON.parse(sessionStorage.getItem("getCharges"));
        recommendedList = getItems.filter(itm => itm.isRecommended);

        this.setState({
            recommendedList: recommendedList,
            getMenus: getMenus,
            getItems: getItems,
        })

        // CMS Settings
        this.getCMSDetails();
    }

    getCMSDetails() {

        let postobj = {
            organizationId: parseInt(orgnationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.menu, // Default Menu
            PageType: Config.pageType.menu, // Menu           
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        }
        // console.log(postobj);

        // (async () => {
        //     console.log(await publicIp.v4());
        //     //=> '46.5.21.123'

        //     console.log(await publicIp.v6());
        //     //=> 'fe80::200:f8ff:fe21:67cf'
        // })();      

        fetch(Config.baseAPIUrl + 'PageDetails', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.isSuccessful) {

                    sessionStorage.setItem("pageCMSSettings", JSON.stringify(response));
                    this.themeStyle(response);
                }
                else {
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                //console.log(err);
                this.showMessage("CMS - Failed to fetch", "danger");
            });
    }

    themeStyle(response) {
        //console.log(response.banners);
        if (response.banners.filter(i => i.bannerType === Config.bannerImageType.BackGroundImage) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.BackGroundImage).length > 0) {
            let img = JSON.stringify(response.banners.filter(i => i.bannerType === Config.bannerImageType.BackGroundImage)[0].primaryImageUrl);
            let fontFamily = response.brands.filter(b => b.brandCode === 'p-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'p-f-f')[0].brandValue : "Raleway, sans-serif";
            let styleBGPattern = {
                background: `url(${img}) no-repeat center center fixed`,
                fontFamily: fontFamily,
                border: "none",
                paddingBottom: "114px",
            }
            this.setState({ styleBackgroundImage: styleBGPattern });
        }

        // Heading
        if (response.brands.length > 0) {
            let bgColor = response.brands.filter(b => b.brandCode === 't-h-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-b-c')[0].brandValue : "#59324c";
            let fontColor = response.brands.filter(b => b.brandCode === 't-h-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-f-c')[0].brandValue : "#fff";
            let fontSize = response.brands.filter(b => b.brandCode === 't-h-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-f-s')[0].brandValue : "18px";
            let fontFamily = response.brands.filter(b => b.brandCode === 't-h-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 't-h-f-f')[0].brandValue : "Raleway, sans-serif";
            //console.log(response.brands.filter(b => b.brandCode === 't-h-b-c')[0].brandValue);
            let styleHeading = {
                backgroundColor: bgColor,
                color: fontColor,
                fontFamily: fontFamily,
                height: "110px",
                fontSize: fontSize,
                padding: "10px 14px 8px 40px",
                borderBottom: "1px solid #fff"
            }
            this.setState({ styleHeading: styleHeading });
            // Refresh button
            let styleRefresh = {
                color: fontColor,
            }
            this.setState({ styleRefresh: styleRefresh });

            // SubHeading                
            fontColor = response.brands.filter(b => b.brandCode === 't-s-h-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 't-s-h-f-c')[0].brandValue : "#f9bd09";
            fontSize = response.brands.filter(b => b.brandCode === 't-s-h-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 't-s-h-f-s')[0].brandValue : "12px";
            fontFamily = response.brands.filter(b => b.brandCode === 't-s-h-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 't-s-h-f-f')[0].brandValue : "Raleway, sans-serif";

            let styleSubHeading = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize
            }
            this.setState({ styleSubHeading: styleSubHeading });

            // Menu Group
            bgColor = response.brands.filter(b => b.brandCode === 'mg-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-b-c')[0].brandValue : "#59324c";
            fontColor = response.brands.filter(b => b.brandCode === 'mg-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-f-c')[0].brandValue : "#fff";
            fontSize = response.brands.filter(b => b.brandCode === 'mg-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-f-s')[0].brandValue : "17px";
            fontFamily = response.brands.filter(b => b.brandCode === 'mg-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-f-f')[0].brandValue : "Raleway, sans-serif";
            let mgwidth = response.brands.filter(b => b.brandCode === 'mg-w').length > 0 ? response.brands.filter(b => b.brandCode === 'mg-w')[0].brandValue : "250px";
            console.log(mgwidth);
            let styleMenuGroup = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                backgroundColor: bgColor,
                width: mgwidth
            }
            let afterbg = css({
                ':after': [{ borderLeft: "16px solid" + bgColor + "", content: `''`, }]
            });
            let beforebg = css({
                ':before': [{ borderBottom: "9px solid " + bgColor + "", content: `''`, }]
            });

            this.setState({ styleMenuGroup: styleMenuGroup });
            this.setState({ styleMengroupAfter: afterbg });
            this.setState({ styleMengroupBefore: beforebg });


            // Sub Menu Group            
            fontColor = response.brands.filter(b => b.brandCode === 'smg-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'smg-f-c')[0].brandValue + ' !important' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'smg-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'smg-f-s')[0].brandValue : "15px";
            fontFamily = response.brands.filter(b => b.brandCode === 'smg-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'smg-f-f')[0].brandValue : "Raleway, sans-serif";
            let styleSubMenuGroup = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize
            }

            this.setState({ styleSubMenuGroup: styleSubMenuGroup });

            // Menu Item            
            fontColor = response.brands.filter(b => b.brandCode === 'mi-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mi-f-c')[0].brandValue + '' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'mi-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mi-f-s')[0].brandValue : "16px";
            fontFamily = response.brands.filter(b => b.brandCode === 'mi-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mi-f-f')[0].brandValue : "Raleway, sans-serif";

            let styleMenuItem = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize
            }

            this.setState({ styleMenuItem: styleMenuItem });

            // Menu Desc            
            fontColor = response.brands.filter(b => b.brandCode === 'md-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'md-f-c')[0].brandValue + '' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'md-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'md-f-s')[0].brandValue : "12px";
            fontFamily = response.brands.filter(b => b.brandCode === 'md-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'md-f-f')[0].brandValue : "Raleway, sans-serif";

            let styleMenuDesc = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize
            }

            this.setState({ styleMenuDesc: styleMenuDesc });

            // Menu Price            
            fontColor = response.brands.filter(b => b.brandCode === 'mp-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mp-f-c')[0].brandValue + '' : '#59324c';
            fontSize = response.brands.filter(b => b.brandCode === 'mp-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mp-f-s')[0].brandValue : "16px";
            fontFamily = response.brands.filter(b => b.brandCode === 'mp-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mp-f-f')[0].brandValue : "Raleway, sans-serif";

            let styleMenuPrice = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize
            }

            this.setState({ styleMenuPrice: styleMenuPrice });

            // Menu popup
            fontColor = response.brands.filter(b => b.brandCode === 'mpu-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mpu-f-c')[0].brandValue + '' : '#f9f2ea';
            fontSize = response.brands.filter(b => b.brandCode === 'mpu-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-f-s')[0].brandValue : "16px";
            bgColor = response.brands.filter(b => b.brandCode === 'mpu-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-b-c')[0].brandValue : "Raleway, sans-serif";
            fontFamily = response.brands.filter(b => b.brandCode === 'mpu-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-f-f')[0].brandValue : "Raleway, sans-serif";

            let stylePopupMenubBtn = {
                color: fontColor,
                fontFamily: fontFamily,
                fontSize: fontSize,
                backgroundColor: bgColor,
            }
            this.setState({ stylePopupMenubBtn: stylePopupMenubBtn });

            // Menu popup Text
            fontColor = response.brands.filter(b => b.brandCode === 'mpu-t-f-c').length > 0 ? '' + response.brands.filter(b => b.brandCode === 'mpu-t-f-c')[0].brandValue + '' : '#59324c';
            fontFamily = response.brands.filter(b => b.brandCode === 'mpu-t-f-f').length > 0 ? response.brands.filter(b => b.brandCode === 'mpu-t-f-f')[0].brandValue : "Raleway, sans-serif";

            let stylePopupText = {
                color: fontColor,
                fontFamily: fontFamily
            }
            this.setState({ stylePopupText: stylePopupText });

            // Conte Style
            bgColor = response.brands.filter(b => b.brandCode === 'cont-b-c').length > 0 ? response.brands.filter(b => b.brandCode === 'cont-b-c')[0].brandValue : "#fcf9ea";
            fontColor = response.brands.filter(b => b.brandCode === 'cont-f-c').length > 0 ? response.brands.filter(b => b.brandCode === 'cont-f-c')[0].brandValue : "#f9f2ea";
            fontSize = response.brands.filter(b => b.brandCode === 'cont-f-s').length > 0 ? response.brands.filter(b => b.brandCode === 'cont-f-s')[0].brandValue : "13px";

            let styleContent = {
                backgroundColor: bgColor,
                color: fontColor,
                fontSize: fontSize
            }
            this.setState({ styleContent: styleContent });


            // Content                   
            if (response.page != null) {
                //      console.log(response.page.preview);
                this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });
            }

        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        if (this.props.location.state !== undefined) {
            var stickyContainer = document.getElementById("" + this.props.location.state.focusGroup + "");
            if (stickyContainer != null) {
                // console.log(this.props.location.state.focusGroup);
                window.scrollTo({
                    top: stickyContainer.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        }

    }


    goBack() {
        //this.props.history.goBack();
        this.props.history.push('/emhome');
    };

    filterMenus(menuList, recommendedList) {
        let filterItems = menuList;
        let recList = recommendedList;

        let filterGroups = [];


        //Menu Group Filter
        let _uniquesGroups = [];

        var _taxItmFound = {};
        for (var md = 0, lenc = filterItems.length; md <= lenc - 1; md++) {
            var stringified = JSON.stringify(filterItems[md].menuGroupId);
            if (_taxItmFound[stringified]) { continue; }
            _uniquesGroups.push({ groupId: filterItems[md].menuGroupId });
            _taxItmFound[stringified] = true;
        };
        //   console.log(_uniquesGroups);

        Array.prototype.forEach.call(getMenus, grp => {
            let grplst = [];
            let subgrplst = []
            if (grp.subMenuGroup.length > 0) {
                Array.prototype.forEach.call(grp.subMenuGroup, sgp => {
                    grplst = _uniquesGroups.filter(x => x.groupId === sgp.menuGroupId);
                    if (grplst.length > 0)
                        subgrplst.push({ classification: sgp.classification, description: sgp.description, displayOrder: sgp.displayOrder, hasSubMenuGroup: sgp.hasSubMenuGroup, hsnsac: sgp.hsnsac, isActive: sgp.isActive, menuGroupId: sgp.menuGroupId, menuGroupName: sgp.menuGroupName, subMenuGroup: sgp.subMenuGroup });
                });

                if (subgrplst.length > 0)
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: subgrplst });
            }
            else {
                grplst = _uniquesGroups.filter(x => x.groupId === grp.menuGroupId);

                if (grplst.length > 0)
                    filterGroups.push({ classification: grp.classification, description: grp.description, displayOrder: grp.displayOrder, hasSubMenuGroup: grp.hasSubMenuGroup, hsnsac: grp.hsnsac, isActive: grp.isActive, menuGroupId: grp.menuGroupId, menuGroupName: grp.menuGroupName, subMenuGroup: [] });
            }
        });
        // console.log(filterGroups)


        this.setState({ getItems: filterItems });
        this.setState({ recommendedList: recList });
        this.setState({ getMenus: filterGroups });

        // Apply Theme Styles
        setTimeout(() => {
            if (sessionStorage.getItem("pageCMSSettings") !== null) {
                let cmsSetting = JSON.parse(sessionStorage.getItem("pageCMSSettings"));
                this.themeStyle(cmsSetting);
            }
        }, 5000);

    }

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        let isCheckVeg = !this.state[switchNumber];

        if (isCheckVeg === true) {
            //this.setState({loading: true});
            let filterItems = this.state.getItems.filter(x => x.foodType === 1);
            let recList = filterItems.filter(itm => itm.isRecommended);
            this.filterMenus(filterItems, recList);
        }
        else {
            this.setState({ getItems: getItems });
            this.setState({ recommendedList: recommendedList });
            this.setState({ getMenus: getMenus });
        }

        window.scrollTo(0, 0);

        this.setState({
            [switchNumber]: !this.state[switchNumber]
        });

        //  { this.state[switchNumber] === true ? alert('true') : alert('false') }
        // console.log(!this.state[switchNumber]);
    };

    // Menu Search
    handlechange = event => {
        const val = event.target.value;

        this.setState({ searchText: val });

        if (val === "") {
            this.setState({ getItems: getItems });
            this.setState({ recommendedList: recommendedList });
            this.setState({ getMenus: getMenus });
        }
        else {

            // console.log(val);
            let filterItems = getItems.filter(obj => { return (obj.itemName.toLowerCase().indexOf(val.toLowerCase()) >= 0) });
            //  console.log(filterItems);
            let recList = filterItems.filter(itm => itm.isRecommended);
            this.filterMenus(filterItems, recList);
        }
        window.scrollTo(0, 0);

    };

    handleMenuGroupClick(index, mnuid) {
        this.setState({
            activeMenuGroup: index,
        });
        // console.log("clicked");


        //  window.location.hash = '#' + mnuid;   
        // console.log(mnuid) ;

        var stickyContainer = document.getElementById("" + mnuid + "");
        //  console.log(stickyContainer.offsetTop);
        // window.scrollTo(0, stickyContainer.offsetTop);
        window.scrollTo({
            top: stickyContainer.offsetTop,
            left: 0,
            behavior: 'smooth'
        });
        //  window.scrollTo(0, this.myRef.current.offsetTop)           
    };

    handleModifier(_this) {
        if (modifierRef.current) {
            modifierRef.current.handleModifier();
        }
    };

    handleClearText() {
        if (this.state.searchText !== "") {
            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ searchText: "" });
                this.setState({ getItems: getItems });
                this.setState({ recommendedList: recommendedList });
                this.setState({ getMenus: getMenus });
                this.searchInp.focus();
                this.setState({ loading: false });
                window.scrollTo(0, 0);
            }, 900);
        }
    }

    getUpdateMenus() {
        if (!isUpdateMenu) {
            isUpdateMenu = true;
            window.scrollTo(0, 0);
            this.setState({ loading: true });
            setTimeout(() => {
                let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
                sessionStorage.getItem('menuFetchDateTime') !== null ? urlParam += '&menuDateTime=' + sessionStorage.getItem('menuFetchDateTime') : urlParam += '';
                fetch(Config.basePOSUrl + 'Getguestappmenus' + urlParam, {
                    method: 'GET',
                    headers: {
                        Authorization: "Bearer " + token,
                        'content-type': 'application/json'
                    }
                })
                    .then(res => res.json())
                    .then(response => {
                        console.log(response);
                        if (response.errorCode === 0) {
                            if (response.response !== null) {
                                // console.log(JSON.stringify(response.response.menuGroups));               
                                // Filter active Menu Items
                                let activeMenuGroups = [];
                                let activeMenuItems = [];
                                if (response.response.menuGroups.length > 0)
                                    activeMenuGroups = response.response.menuGroups.filter(x => x.isActive === true);

                                if (response.response.menuItems.length > 0)
                                    activeMenuItems = response.response.menuItems.filter(x => x.isActive === true);

                                sessionStorage.setItem("getMenuGroups", JSON.stringify(activeMenuGroups));
                                sessionStorage.setItem("getmenuItems", JSON.stringify(activeMenuItems));
                                sessionStorage.setItem("getModifierGroups", JSON.stringify(response.response.modifierGroups));
                                sessionStorage.setItem("getTaxes", JSON.stringify(response.response.taxes));
                                sessionStorage.setItem("getCharges", JSON.stringify(response.response.charges));
                                sessionStorage.setItem("menuFetchDateTime", response.response.menuDateTime);
                                getMenus = sessionStorage.getItem("getMenuGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getMenuGroups"));
                                getItems = sessionStorage.getItem("getmenuItems") === null ? [] : JSON.parse(sessionStorage.getItem("getmenuItems"));
                                getModifiers = sessionStorage.getItem("getModifierGroups") === null ? [] : JSON.parse(sessionStorage.getItem("getModifierGroups"));
                                getTaxes = sessionStorage.getItem("getTaxes") === null ? [] : JSON.parse(sessionStorage.getItem("getTaxes"));
                                getCharges = sessionStorage.getItem("getCharges") === null ? [] : JSON.parse(sessionStorage.getItem("getCharges"));
                                recommendedList = getItems.filter(itm => itm.isRecommended);

                                this.setState({
                                    recommendedList: recommendedList,
                                    getMenus: getMenus,
                                    getItems: getItems,
                                });
                                this.setState({ searchText: "" });
                            }
                        }
                        else {
                            this.setState({ APIResponseMsg: "Failed to fetch" })
                            this.showMessage(response.message, "danger");
                        }
                        this.setState({ loading: false });
                        isUpdateMenu = false;
                    })
                    .catch(err => {
                        this.setState({ APIResponseMsg: "Failed to fetch" });
                        this.setState({ loading: false });
                        isUpdateMenu = false;
                        //console.log(err);
                        // this.showMessage("An error occured", "danger");
                    });
            }, 500);
        }

    };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, 2500);
    };

    render() {
        let mnugrp = "mnu-grp-";
        return (
            <div>
                {this.state.loading && <LoadingAnimation />}
                <div id="content" className="top">
                    {/* {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />} */}
                    {/* <Header /> */}
                    <div className="content-warp">
                        <div className="dmenu-section-items" style={this.state.styleBackgroundImage}>
                            <div className="heading-page" style={this.state.styleHeading} ref="recommended">
                                <div className="back-btn" >
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left" ></i></div>
                                </div>
                                <div className="refresh-icon" style={this.state.styleRefresh} onClick={this.getUpdateMenus.bind(this)}> <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i></div>
                                {sessionStorage.getItem("user-propertyname")}
                                <span className="city-txt" style={this.state.styleSubHeading} > {sessionStorage.getItem("user-outletname")}</span>
                                <div>
                                    <div className='custom-control custom-switch f-r'>
                                        <input
                                            type='checkbox'
                                            className='custom-control-input'
                                            id='customSwitches'
                                            checked={this.state.switch1}
                                            onChange={this.handleSwitchChange(1)}
                                            readOnly
                                        />
                                        <label className='custom-control-label' htmlFor='customSwitches'>
                                            Veg Only
                                        </label>
                                    </div>
                                    <div className="search-wrapper ">
                                        <input id="search" autoComplete="Off" ref={(input) => { this.searchInp = input; }} placeholder="Start typing to search" value={this.state.searchText} onChange={(e) => this.handlechange(e)} />
                                        <button className="ssubmit" type="button" name="search_submit" onClick={this.handleClearText.bind(this)}><i className="fas fa-times"></i></button>
                                    </div>

                                </div>
                            </div>
                            {
                                this.state.getMenus.map(itmgrp =>
                                    <div className="content-from" key={mnugrp + itmgrp.menuGroupId} id={"focus-mnugrp-" + itmgrp.menuGroupId}>
                                        <div className="itm-heading">
                                            <div className="ribbon-heading">
                                                <span className="ribbon3" {...css(this.state.styleMengroupAfter, this.state.styleMengroupBefore)} style={this.state.styleMenuGroup}>{itmgrp.menuGroupName}</span>
                                            </div>
                                        </div>
                                        {itmgrp.subMenuGroup.length > 0 &&
                                            <div className="accordion" id="accordion2">
                                                {
                                                    itmgrp.subMenuGroup.map(sgrp =>
                                                        <div className="accordion-group" key={"acc-grp-" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId}>
                                                            <div className="accordion-heading">
                                                                <a className="accordion-toggle" style={this.state.styleSubMenuGroup} data-toggle="collapse" data-parent="#accordion2" href={"#subgroupcollapse" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId}>
                                                                    {sgrp.menuGroupName.toUpperCase()}
                                                                    <div className="itm-cnt">{this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).length + " items..."} </div>
                                                                </a>
                                                            </div>
                                                            <div id={"subgroupcollapse" + itmgrp.menuGroupId + "-" + sgrp.menuGroupId} className="accordion-body collapse">
                                                                <div className="accordion-inner">
                                                                    <div className="wrap-list-rc">
                                                                        {/* {itemList = getItems.filter(fitm => { return(fitm.menuGroupId === itmgrp.menuGroupId);})} */}
                                                                        {
                                                                            this.state.getItems.filter(i => i.menuGroupId === sgrp.menuGroupId).map(itm =>
                                                                                <div className="lrc-item" key={"itm-" + sgrp.menuGroupId + "-" + itm.itemId}>
                                                                                    <div className="lrc-content">
                                                                                        <div className="lrc-desc">
                                                                                            {/* <div className={"qty-detail item-entry-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId + " qty-hide"}>
                                                                                                <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                                                <span className="qty" id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                                                <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                                            </div>
                                                                                            <div className={"qty-detail item-add-qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId} id={"div-qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                                                <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                                                            </div>
                                                                                            {itm.modifierGroupIds.length > 0 && <div style={{ float: "right", position: "absolute", right: "8px", marginTop: "36px", fontSize: "10px" }}><span className="modifier-notify">customizable</span></div>} */}
                                                                                            <div className="lrc-title em-itm-txt" style={this.state.styleMenuItem}>
                                                                                                <div className="lhc price em-itm-price-txt" style={this.state.styleMenuPrice}>₹{itm.mrp.toFixed(2)}</div>
                                                                                                <div className="itm-title em-itm-title">  {itm.foodType === 1 && <img className="food-type-img em-itm-food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                                                                                    {itm.foodType === 2 && <img className="food-type-img em-itm-food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />}
                                                                                                    {itm.itemName.toLowerCase()}{itm.outOfStock && <span className="soldout">SOLD OUT</span>}</div>

                                                                                            </div>

                                                                                            <div className="lrc-text em-itm-desc" style={this.state.styleMenuDesc}>
                                                                                                {itm.description.toLowerCase()}
                                                                                            </div>
                                                                                            {/* <div className="lrc-button">
                                                                                                <div className="lrcb-left">
                                                                                                    <span className="lhc price">₹{itm.mrp}</span>
                                                                                                </div>                                                                                               
                                                                                                <div className="clear"></div>
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) // End Item

                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                }
                                            </div>
                                        }
                                        {itmgrp.subMenuGroup.length === 0 &&
                                            <div className="wrap-list-rc">
                                                {/* {itemList = getItems.filter(fitm => { return(fitm.menuGroupId === itmgrp.menuGroupId);})} */}
                                                {
                                                    this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).map(itm =>
                                                        <div className="lrc-item" key={"itm-" + itmgrp.menuGroupId + "-" + itm.itemId}>
                                                            <div className="lrc-content">
                                                                <div className="lrc-desc">
                                                                    {/* <div className={"qty-detail item-entry-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide"}>
                                                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                        <span className="qty" id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                    </div>
                                                                    <div className={"qty-detail item-add-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                        <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                                    </div>
                                                                    {itm.modifierGroupIds.length > 0 && <div style={{ float: "right", position: "absolute", right: "8px", marginTop: "36px", fontSize: "10px" }}><span className="modifier-notify">customizable</span></div>} */}

                                                                    <div className="lrc-title em-itm-txt" style={this.state.styleMenuItem}>
                                                                        <div className="lhc price em-itm-price-txt" style={this.state.styleMenuPrice}>₹{itm.mrp.toFixed(2)}</div>
                                                                        <div className="itm-title em-itm-title">  {itm.foodType === 1 && <img className="food-type-img em-itm-food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                                                            {itm.foodType === 2 && <img className="food-type-img em-itm-food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />}
                                                                            {itm.itemName.toLowerCase()}{itm.outOfStock && <span className="soldout">SOLD OUT</span>}</div>

                                                                    </div>

                                                                    <div className="lrc-text em-itm-desc" style={this.state.styleMenuDesc}>
                                                                        {itm.description.toLowerCase()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) // End Item

                                                }
                                            </div>
                                        }
                                        {itmgrp.subMenuGroup.length > 0 &&
                                            <div className="wrap-list-rc">
                                                {/* {itemList = getItems.filter(fitm => { return(fitm.menuGroupId === itmgrp.menuGroupId);})} */}
                                                {
                                                    this.state.getItems.filter(i => i.menuGroupId === itmgrp.menuGroupId).map(itm =>
                                                        <div className="lrc-item" key={"itm-" + itmgrp.menuGroupId + "-" + itm.itemId}>
                                                            <div className="lrc-content">
                                                                <div className="lrc-desc">
                                                                    {/* <div className={"qty-detail item-entry-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId + " qty-hide"}>
                                                                        <span className="minus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 0, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-minus" aria-hidden="true"></i></span>
                                                                        <span className="qty" id={"qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId}>0</span>
                                                                        <span className="plus" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)}><i className="fa fa-plus" aria-hidden="true"></i></span>
                                                                    </div>
                                                                    <div className={"qty-detail item-add-qty-mnu-" + itm.menuGroupId + "-itm-" + itm.itemId} id={"div-qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>
                                                                        <span className="add" onClick={this.handleQtyIncDec.bind(this, "qty-mnu-" + itmgrp.menuGroupId + "-itm-" + itm.itemId, 1, itm.rate, itm.itemName, itm.itemId, itmgrp.menuGroupId, itm.foodType, itm.modifierGroupIds, itm.taxId, itm.chargeIds)} id={"qty-mnu-rec-" + itm.menuGroupId + "-itm-add-" + itm.itemId}>ADD</span>
                                                                    </div>
                                                                    {itm.modifierGroupIds.length > 0 && <div style={{ float: "right", position: "absolute", right: "8px", marginTop: "36px", fontSize: "10px" }}><span className="modifier-notify">customizable</span></div>} */}

                                                                    <div className="lrc-title em-itm-txt" style={this.state.styleMenuItem}>
                                                                        <div className="lhc price em-itm-price-txt" style={this.state.styleMenuPrice}>₹{itm.mrp.toFixed(2)}</div>
                                                                        <div className="itm-title em-itm-title">  {itm.foodType === 1 && <img className="food-type-img em-itm-food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                                                            {itm.foodType === 2 && <img className="food-type-img em-itm-food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />}
                                                                            {itm.itemName.toLowerCase()}{itm.outOfStock && <span className="soldout">SOLD OUT</span>}</div>

                                                                    </div>

                                                                    <div className="lrc-text em-itm-desc" style={this.state.styleMenuDesc}>
                                                                        {itm.description.toLowerCase()}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) // End Item

                                                }
                                            </div>
                                        }

                                    </div>
                                ) // End Group
                            }
                            {this.state.getMenus.length === 0 &&
                                < div className="no-menus-img"><img src="../assets/theme1/img/no-image/no-menus.png" alt="lucid-no-menus" /></div>
                            }
                            {/* Seach Item*/}

                            <Modifier
                                ref={modifierRef}
                                callBackModifier={this.callBackModifier}
                                modifierItemCFoodType={this.state.modifierFoodType}
                                modifierItemCItemName={this.state.modifierItemName}
                                modifierItemCItemId={this.state.modifierItemId}
                                modifierGroupIds={this.state.modifierGroupIds}
                                modifierList={getModifiers}
                                modifierTotalItemAmount={this.state.totalAmount}
                                modifierTotalItems={this.state.totalItems}
                                modifierItemCQty={this.state.modifierItemCQty}
                                modifierItemCQtyId={this.state.modifierItemCId}
                                modifierItemCRate={this.state.modifierItemCRate}
                                modifierItemCGroupId={this.state.modifierItemCGroupId}
                                modifierItemCTaxId={this.state.modifierItemCTaxId}
                                modifierItemCChargeIds={this.state.modifierItemCChargeIds}
                                getTaxes={getTaxes}
                                getCharges={getCharges}
                            ></Modifier>
                            {/* <Footer></Footer> */}

                            <PopupMenus recommendedList={this.state.recommendedList} getMenus={this.state.getMenus} stylePopupMenubBtn={this.state.stylePopupMenubBtn} stylePopupText={this.state.stylePopupText} ></PopupMenus>
                            {
                                this.state.pagePreview !== "" &&
                                <div className="item-content" style={this.state.styleContent} dangerouslySetInnerHTML={{ __html: this.state.pagePreview }}></div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
