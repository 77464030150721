import React, { Component } from 'react';
import './jointable.scss';
import ErrorAlert from '../../components/alertmessages';
import LoadingAnimation from '../../components/loadinganimation/login';
import { isMobile } from 'react-device-detect';
import Config from '../../data/SiteConfig';
let propertyId = '';
let outletCode = '';
let serviceType = '';
let organizationId = '';
let tableNo = '';
let mainMaskMobileNo = '';
let mainMobileNo = '';
let pageSession = '';
let urlPattern = '';
export default class JoinTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
        }
    }

    componentDidMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
       
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        window.scrollTo(0, 0);

        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        serviceType = sessionStorage.getItem('user-servicetype');
        organizationId = sessionStorage.getItem('user-orgnationid');
        tableNo = sessionStorage.getItem('user-tableno');
        mainMobileNo = sessionStorage.getItem('main-guest-no');
        mainMaskMobileNo = sessionStorage.getItem('main-guest-mask-no');
        pageSession = sessionStorage.getItem('login-page-session') == null ? 1 : parseInt(sessionStorage.getItem('login-page-session'));

        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else if (organizationId === undefined || organizationId === "" || organizationId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        // else if (mainMobileNo === undefined || mainMobileNo === "" || mainMobileNo === null) {
        //     this.props.history.push('/');
        //     return false;
        // }
        // else if (mainMaskMobileNo === undefined || mainMaskMobileNo === "" || mainMaskMobileNo === null) {
        //     this.props.history.push('/');
        //     return false;
        // }

        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);

    }

    handleJoinTable(_yesno) {

        if (_yesno === 1) {
            window.scrollTo(0, 0);
            if (mainMobileNo === undefined || mainMobileNo === "" || mainMobileNo === null) {
                this.showMessage('Invalid main guest mobile number', "danger");
                return false;
            }


            sessionStorage.setItem('group-session', true);

            this.setState({ loading: true });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, 2000);
        }
        else {

            if (mainMobileNo === undefined || mainMobileNo === "" || mainMobileNo === null) {
                this.props.history.push('/login' + urlPattern);
                return false;
            }

            sessionStorage.setItem('group-session', false);
            this.setState({ loading: true });
            // Redirect to Page
            if (pageSession === Config.pageSession.Cart) {
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/cart' + urlPattern);
                }, 2000);
                return false;
            }
            else if (pageSession === Config.pageSession.PayBill) {
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/mybills' + urlPattern);
                }, 2000);
                return false;
            }
            else if (pageSession === Config.pageSession.MyOrders) {
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/myorders' + urlPattern);
                }, 2000);
                return false;
            }
            else {
                setTimeout(() => {
                    this.setState({ loading: false });
                    this.props.history.push('/home' + urlPattern);
                }, 2000);
                return false;
            }
            // this.props.history.push('/');
        }

    }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, 2000);
    };

    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-jointable">
                            <div className="heading-page" ref="recommended">
                                <span>Join Table</span>
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left" ></i></div>
                                </div>
                            </div>
                            <div className="content-from m-t-contact-form">
                                <div className='table-alert'>
                                    <div>
                                        <img className="img-center table-img" src="/img/table-logo.png" alt="lucid-contactless-app-user-table-service" />
                                        <h6>Welcome to {sessionStorage.getItem("user-propertyname")}</h6>
                                        <p className="table-no">Table No: {tableNo}</p>
                                        <p className="notify-msg">Table is occupied. Do you want to join the table?</p>
                                    </div>
                                    <button onClick={this.handleJoinTable.bind(this, 0)}>No</button>
                                    <button onClick={this.handleJoinTable.bind(this, 1)}>Yes</button>
                                    <div className="people">You can get group pin from people below</div>
                                    <div className="people-list">
                                        <i className="fas fa-mobile-alt"></i>  {mainMaskMobileNo}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}