import React, { Component } from 'react';
//import { render } from 'react-dom';
import './modifier.scss';
import Checkbox from '../checkbox/modifiercheckbox';
import Radio from '../radionbutton/modifierradiobutton';
import uuid from 'react-uuid';
//import Cart from '../../components/cart';
let modifiercartItemList = [];
let itemModifierRate = 0;
let cartList = {};
let taxList = [];
let chargeList = [];
let itemGroupId = 0;
//let itemTaxId = 0;
let itemId = 0;
let itemName = "";
let itemRate = 0;
let itemQtyId = "";
//let itemQty = 0;
let itemTotalAmout = 0;
let itemTotalItems = 0;
//let modifierDom = '';


export default class Modifier extends Component {
    constructor(props) {
        super(props)
        this.handleModifier = this.handleModifier.bind(this);
        this.state = {
            modifierList: [],
            modifierGroupIds: [],
            checkId: -1,
            checkboxstatus: false,
            checkedItems: new Map(),
            showActionFilterList: false,
            totalItems: 0,
            totalAmount: 0,
            modifierItemCQty: 0,
            modifierItemCRate: 0,
            modifierItemCQtyId: "",
            modifierItemCItemName: "",
            modifierItemCItemId: 0,
            modifierItemCFoodType: 0,
            modifierItemCGroupId: 0,
            modifierItemCtaxId: 0,
            modifiterItemModifierRate: 0,
            radiostatus: null

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleRadioChange = this.handleRadioChange.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
    }

    componentWillMount() {
       
        this.setState({
            modifierItemCGroupId: this.props.modifierItemCGroupId,
            modifierItemCItemId: this.props.modifierItemCItemId,
            modifierItemCItemName: this.props.modifierItemCItemName,
            modifierItemCQtyId: this.props.modifierItemCQtyId,
            modifierItemCQty: this.props.modifierItemCQty,
            modifierItemCRate: this.props.modifierItemCRate,
            modifierItemCFoodType: this.props.modifierItemCFoodType,
            modifiterItemModifierRate: this.props.modifierItemCRate
        })
    }

    componentDidMount() {
        if (sessionStorage.getItem("cartModifierList") != null) {
            modifiercartItemList = JSON.parse(sessionStorage.getItem("cartModifierList"));
        }
        else {
            modifiercartItemList = [];
            // this.setState({ totalAmount: this.props.modifierItemCRate });
        }
    }

    handleModifier() {
        itemModifierRate = this.props.modifierItemCRate;
        this.setState({ modifiterItemModifierRate: this.props.modifierItemCRate });
        // console.log(this.state.checkedItems);
        this.setState({ radiostatus: null });
        this.setState({ checkedItems: new Map() });
        //document.getElementById('div-modifier-body').innerHTML = '';
        this.handleGenerateModifierDom();
        // console.log(modifierDom);
        //document.getElementById('div-modifier-body').innerHTML = modifierDom === null ? '' : modifierDom;
        modifiercartItemList = [];
        this.inputElement.click();
        // alert();
        //this.props.callBackModifier();
    }

    handleGenerateModifierDom() {
        return (
            <ul className="modifier-group-li">
                {this.props.modifierGroupIds.map(mid =>
                    <div key={"modifier-group-id-" + mid}>
                        <li key={mid}>
                            {this.props.modifierList.filter(mgrplst => mgrplst.modifierGroupId === mid).map(mgrp =>
                                <div key={"modifier-group-" + mgrp.modifierGroupId}>
                                    <span className="group-name">{mgrp.minimumSelection > 0 && <span className="minimum-selection">*</span>}{mgrp.groupName}</span>

                                    {mgrp.maximumSelection === 0 &&
                                        <span> (<span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>)</span>
                                    }
                                    {mgrp.maximumSelection > 0 &&
                                        <span> (
                                                                <span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>
                                                                /
                                                                <span id={"max-group-modifier-cnt-" + mgrp.modifierGroupId}>{mgrp.maximumSelection}</span>
                                                                )</span>
                                    }

                                    <ul className="modifier-li">
                                        {mgrp.modifiers.map(mlst =>
                                            <li key={"modifier-li-" + mlst.modifierId}>
                                                {mgrp.maximumSelection === 2 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                {mgrp.maximumSelection === 1 && <Radio name={mgrp.modifierGroupId} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.radiostatus} onChange={(e) => this.handleRadioChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Radio>}
                                                {mgrp.maximumSelection === 0 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                {/* {mgrp.maximumSelection === 2 && <input type="checkbox" checked={mlst.modifierId === this.state.checkId} id={'modifier-chk-' + mgrp.modifierGroupId-mlst.modifierId} ref={'ref-chk-' + mgrp.modifierGroupId-mlst.modifierId} onChange={this.handleModifierDetail.bind(this,mlst.modifierId,mgrp.modifierGroupId,mlst.modifierName,mlst.rate, "min-group-modifier-cnt-" + mgrp.modifierGroupId,"max-group-modifier-cnt-" + mgrp.modifierGroupId,mgrp.minimumSelection,mgrp.maximumSelection)} />}
                                                                    {mgrp.maximumSelection === 1 && <input type="radio" onChange={this.handleModifierDetail.bind(this,mlst.modifierId,mlst.modifierName,mlst.rate,"min-group-modifier-cnt-" + mgrp.modifierGroupId,"max-group-modifier-cnt-" + mgrp.modifierGroupId,mgrp.minimumSelection,mgrp.maximumSelection)} />}
                                                                    {mgrp.maximumSelection === 0 && <input type="checkbox" onChange={this.handleModifierDetail.bind(this,mlst.modifierId,mlst.modifierName,mlst.rate,"min-group-modifier-cnt-" + mgrp.modifierGroupId,"max-group-modifier-cnt-" + mgrp.modifierGroupId,mgrp.minimumSelection,mgrp.maximumSelection)} />} */}
                                                {mlst.foodType === 1 && <img className="food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                                {mlst.foodType === 2 && <img className="food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />}
                                                {mlst.modifierName}<span className="rate-inr"> ₹{mlst.rate}</span>
                                            </li>

                                        )
                                        }
                                    </ul>
                                </div>
                            )}
                        </li>
                    </div>
                )}
            </ul>
        );
        // }

        //document.getElementById('div-modifier-body').innerHTML = mdofierDom;
    };

    handleAddItem() {

        // console.log(itemQtyId);
        // console.log(modifiercartItemList);   
        let exmodifierList = sessionStorage.getItem("modifierCartList") === null ? [] : JSON.parse(sessionStorage.getItem("modifierCartList"));
        cartList = sessionStorage.getItem("cartList") != null ? JSON.parse(sessionStorage.getItem("cartList")) : cartList = { mobileNo: 0, totalItems: 0, totalAmount: 0, itemList: [] };
        taxList = sessionStorage.getItem("taxCartList") === null ? [] : JSON.parse(sessionStorage.getItem("taxCartList"));
        chargeList = sessionStorage.getItem("chargeCartList") === null ? [] : JSON.parse(sessionStorage.getItem("chargeCartList"));
        // merge Item
        // let exleng = [];
        // let leng = 0;        
        // Array.prototype.forEach.call(modifiercartItemList, md => {
        //     leng = exmodifierList.filter(x => x.modifierGroupId === md.modifierGroupId && x.modifierId === md.modifierId && x.itemId === md.itemId).length;
        //     console.log("group:" + md.modifierGroupId + ", mid : " + md.modifierId + ", Iid : " + md.itemId);
        //     console.log(leng);
        //     if (leng > 0) {
        //         exleng.push({ modifierId: md.modifierId, itemId: md.itemId, modifierGroupId: md.modifierGroupId, modifierName: md.modifierName, rate: md.rate, foodType: md.foodType, ctrl: md.ctrl });
        //     }
        // });
        // console.log("EX MDO");
        // console.log(exleng);

        let uid = uuid();


        // if (modifiercartItemList.length !== exleng.length) {            
        //     Array.prototype.forEach.call(modifiercartItemList, md => {
        //         exmodifierList.push({ modifierId: md.modifierId, itemId: md.itemId, modifierGroupId: md.modifierGroupId, modifierName: md.modifierName, rate: md.rate, foodType: md.foodType, ctrl: md.ctrl,uid: uid })
        //     });
        //     cartList.itemList.push({ id: "" + itemQtyId + "", itemId: itemId, groupId: itemGroupId, foodType: this.props.modifierItemCFoodType, qty: 1, rate: itemRate, itemName: itemName, isModifier: 1,uid: uid  });
        //     itemQty+=1;
        // }
        // else {
        //     itemQty +=1;
        //     let index = cartList.itemList.findIndex(x => x.id === "" + itemQtyId + "");
        //     if (index < 0) {
        //         cartList.itemList.push({ id: "" + itemQtyId + "", itemId: itemId, groupId: itemGroupId, foodType: this.props.modifierItemCFoodType, qty: itemQty, rate: itemRate, itemName: itemName, isModifier: 1 });
        //     }
        //     else {
        //         cartList.itemList[index].qty = (cartList.itemList[index].qty + 1);
        //     }            
        // }

        console.log(modifiercartItemList);

        // not merge the item
        Array.prototype.forEach.call(modifiercartItemList, md => {
            let txList = this.modifierItemTaxCalc(md.taxId, md.rate, 1, itemId, uid);
            let txAmount = txList.reduce((prev, next) => prev + next.taxAmount, 0);
            let chgList = this.modifierItemChargeCalc(md.chargeIds, md.rate, 1, md.itemId, uid);
            let chgAmount = chgList.reduce((prev, next) => prev + next.taxAmount, 0);
            exmodifierList.push({ itemId: md.itemId, modifierGroupId: md.modifierGroupId, modifierGroupName: md.modifierGroupName, modifierId: md.modifierId, modifierName: md.modifierName, rate: md.rate, foodType: md.foodType, taxes: txList, totalTaxAmount: txAmount, charges: chgList, totalChargeAmount: chgAmount, ctrl: md.ctrl, uid: uid, taxId: md.taxId, chargeIds: md.chargeIds })
        });


        this.itemTaxCalc(this.props.modifierItemCTaxId, itemRate, 1, itemId, uid);
        this.itemChargeCalc(this.props.modifierItemCChargeIds, itemRate, 1, itemId, uid);
        cartList.itemList.push({ id: "" + itemQtyId + "", itemId: itemId, groupId: itemGroupId, foodType: this.props.modifierItemCFoodType, qty: 1, rate: itemRate, itemName: itemName, isModifier: 1, uid: uid, taxId: this.props.modifierItemCTaxId, chargeIds: this.props.modifierItemCChargeIds });
        //itemQty+=1;

        // re calculate cart value     
        let cartItemValue = 0;
        let cartItemQty = 0;
        let modifierCartValue = 0;
        Array.prototype.forEach.call(cartList.itemList, itm => {
            // console.log(itm.qty);
            cartItemValue += (itm.rate * itm.qty);
            cartItemQty += itm.qty;
            //  console.log(cartItemValue);
            let mdcartItemList = exmodifierList.filter(x => x.uid === itm.uid);
            //  console.log(mdcartItemList);
            Array.prototype.forEach.call(mdcartItemList, md => {
                modifierCartValue += md.rate * itm.qty;
            });
        });


        let totlaQty = document.getElementById("" + itemQtyId + "").innerHTML === "" ? 0 : JSON.parse(document.getElementById("" + itemQtyId + "").innerHTML);
        document.getElementById("" + itemQtyId + "").innerHTML = (totlaQty + 1);

        itemTotalItems = cartItemQty;
        itemTotalAmout = (cartItemValue + modifierCartValue);
        this.setState({ totalAmount: itemTotalAmout, totalItems: itemTotalItems });

        // add cart list


        cartList.totalItems = itemTotalItems;
        cartList.totalAmount = itemTotalAmout


        sessionStorage.setItem("modifierCartList", JSON.stringify(exmodifierList));
        sessionStorage.setItem("cartList", JSON.stringify(cartList));
        sessionStorage.setItem("taxCartList", JSON.stringify(taxList));
        sessionStorage.setItem("chargeCartList", JSON.stringify(chargeList));

        // console.log(JSON.parse(sessionStorage.getItem("cartList")));
        //  console.log(JSON.parse(sessionStorage.getItem("modifierCartList")));
        //  console.log(JSON.parse(sessionStorage.getItem("taxCartList")));

        // sessionStorage.setItem("cartModifierList", JSON.stringify(modifiercartList));
        // console.log(JSON.stringify(modifiercartList));



        this.setState({ checkedItems: new Map() });
        modifiercartItemList = [];
        this.props.callBackModifier();
    };

    itemTaxCalc(taxId, rate, qty, itemId, uid) {
        // console.log(this.props.getTaxes);
        let cList = this.props.getTaxes.filter(x => x.taxId === taxId);
        //  console.log(cList);
        let taxObj = [];
        let taxAmt = 0;
        let itemValue = rate * qty;
        if (cList.length > 0) {
            //  console.log(cList[0].structure);
            Array.prototype.forEach.call(cList[0].structure, tx => {
                if (tx.taxType === 1) // %            
                    taxAmt = itemValue * tx.taxValue * 0.01;
                else if (tx.taxType === 2) // Amount
                    taxAmt = tx.taxValue * qty; // 
                else if (tx.taxType === 3) // Tax on Tax
                {
                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                    if (serialTax.taxType === 1) // %
                        taxAmt = serialTax.taxAmount * tx.taxValue * 0.01;
                    else if (tx.taxType === 2) // Amount
                        taxAmt = tx.taxValue * qty;
                }
                taxObj.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                taxList.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
            });
            // console.log("jk");
            // console.log(taxList);
        }
    };

    itemChargeCalc(chargeId, rate, qty, itemId, uid) {
        //console.log(chargeId);
        if (chargeId.length > 0) {
            Array.prototype.forEach.call(chargeId, cId => {
                // filter based on Item wise & Item Qty wise => apply category
                let chargelist = this.props.getCharges.filter(x => x.chargeId === cId && x.applyTo === 1 && x.applyType === 1);
                let taxlist = [];
                let taxObj = [];
                let taxAmt = 0;
                let itemValue = rate * qty;
                // console.log(chargelist);
                let chargeAmt = 0;
                if (chargelist.length > 0) {
                    Array.prototype.forEach.call(chargelist, chg => {
                        let rnTaxAmt = 0;
                        if (chg.applyType === 2) { // slabe wise (%)   - only for slab base value from and value to (percentage =>itmvalue * per * 0.01) or amount=> direct slab amount)         
                            let cslab = chg.chargeSlab.filter(x => x.ValueFrom > itemValue && x.ValueTo < itemValue)
                            if (cslab.length > 0) {
                                if (cslab.chargeValueType === 1)
                                    chargeAmt = itemValue * chg.chargeValue * 0.01;
                                else if (cslab.chargeValueType === 2)
                                    chargeAmt = cslab.ChargeValue;
                            }
                        }
                        else if (chg.chargeValueType === 2) // Amount only qty based 
                            chargeAmt = chg.chargeValue * qty; // 
                        //  console.log(chargeAmt);
                        // Tax 
                        taxlist = this.props.getTaxes.filter(x => x.taxId === chg.taxId);
                        if (taxlist.length > 0) {
                            Array.prototype.forEach.call(taxlist[0].structure, tx => {
                                if (tx.taxType === 1) // (%)             
                                    taxAmt = (chargeAmt * tx.taxValue * 0.01);
                                else if (tx.taxType === 2) // Amount
                                    taxAmt = tx.taxValue; // 
                                else if (tx.taxType === 3) // Tax on Tax
                                {
                                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                                    if (serialTax.taxType === 1) // (%) 
                                        taxAmt = serialTax.taxAmount * tx.taxValue * 0.01;
                                    else if (tx.taxType === 2) // Amount
                                        taxAmt = tx.taxValue;
                                }
                                rnTaxAmt += taxAmt;
                                taxObj.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, taxId: chg.taxId, taxGroupName: taxlist[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                            })
                            //  console.log(taxObj);
                        }
                        chargeList.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, chargeName: chg.chargeName, chargeType: chg.chargeType, isMerchantCharge: false, chargeValueType: chg.chargeValueType, chargeValue: chg.chargeValue, chargeAmount: Number((chargeAmt).toFixed(2)), taxes: taxObj, totalTaxAmount: rnTaxAmt });

                    });
                    // console.log(chargeList);
                }
            });
        }
    };
    //
    modifierItemTaxCalc(taxId, rate, qty, itemId, uid) {
        // console.log(this.props.getTaxes);
        let cList = this.props.getTaxes.filter(x => x.taxId === taxId);
        // console.log(cList);
        let taxObj = [];

        let taxAmt = 0;
        let itemValue = rate * qty;
        if (cList.length > 0) {
            //   console.log(cList[0].structure);
            Array.prototype.forEach.call(cList[0].structure, tx => {
                if (tx.taxType === 1) // %            
                    taxAmt = itemValue * tx.taxValue * 0.01;
                else if (tx.taxType === 2) // Amount
                    taxAmt = tx.taxValue * qty; // 
                else if (tx.taxType === 3) // Tax on Tax
                {
                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                    if (serialTax.taxType === 1) // %
                        taxAmt = serialTax.taxAmount * tx.taxValue * 0.01;
                    else if (tx.taxType === 2) // Amount
                        taxAmt = tx.taxValue * qty;
                }
                taxObj.push({ itemId: itemId, uid: uid, taxId: taxId, taxGroupName: cList[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
            });
            //  console.log("modifier Tax");
            //  console.log(taxObj);
            return taxObj;
        }
    };

    modifierItemChargeCalc(chargeId, rate, qty, itemId, uid) {
        //  console.log(chargeId);
        let modifierChargeList = [];
        if (chargeId.length > 0) {
            Array.prototype.forEach.call(chargeId, cId => {
                // filter based on Item wise & Item Qty wise => apply category
                let chargelist = this.props.getCharges.filter(x => x.chargeId === cId && x.applyTo === 1 && x.applyType === 1);
                let taxlist = [];
                let taxObj = [];
                let taxAmt = 0;
                let itemValue = rate * qty;
                //    console.log(chargelist);
                let chargeAmt = 0;
                if (chargelist.length > 0) {
                    Array.prototype.forEach.call(chargelist, chg => {
                        let rnTaxAmt = 0;
                        if (chg.applyType === 2) { // slabe wise (%)   - only for slab base value from and value to (percentage =>itmvalue * per * 0.01) or amount=> direct slab amount)         
                            let cslab = chg.chargeSlab.filter(x => x.ValueFrom > itemValue && x.ValueTo < itemValue)
                            if (cslab.length > 0) {
                                if (cslab.chargeValueType === 1)
                                    chargeAmt = itemValue * chg.chargeValue * 0.01;
                                else if (cslab.chargeValueType === 2)
                                    chargeAmt = cslab.ChargeValue;
                            }
                        }
                        else if (chg.chargeValueType === 2) // Amount only qty based 
                            chargeAmt = chg.chargeValue * qty; // 
                        // console.log(chargeAmt);
                        // Tax 
                        taxlist = this.props.getTaxes.filter(x => x.taxId === chg.taxId);
                        if (taxlist.length > 0) {
                            Array.prototype.forEach.call(taxlist[0].structure, tx => {
                                if (tx.taxType === 1) // (%)             
                                    taxAmt = (chargeAmt * tx.taxValue * 0.01);
                                else if (tx.taxType === 2) // Amount
                                    taxAmt = tx.taxValue; // 
                                else if (tx.taxType === 3) // Tax on Tax
                                {
                                    let serialTax = taxObj.filter(x => x.serialNo === tx.taxOnSerialNo);
                                    if (serialTax.taxType === 1) // (%) 
                                        taxAmt = serialTax.taxAmount * tx.taxValue * 0.01;
                                    else if (tx.taxType === 2) // Amount
                                        taxAmt = tx.taxValue;
                                }
                                rnTaxAmt += taxAmt;
                                taxObj.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, taxId: chg.taxId, taxGroupName: taxlist[0].taxName, serialNo: tx.serialNo, taxCode: tx.taxCode, taxName: tx.taxName, applicableOn: tx.applicableOn, taxType: tx.taxType, taxValue: tx.taxValue, taxAmount: Number((taxAmt).toFixed(2)) });
                            })
                            // console.log(taxObj);
                        }
                        modifierChargeList.push({ itemId: itemId, uid: uid, chargeId: chg.chargeId, chargeName: chg.chargeName, chargeType: chg.chargeType, isMerchantCharge: false, chargeValueType: chg.chargeValueType, chargeValue: chg.chargeValue, chargeAmount: Number((chargeAmt).toFixed(2)), taxes: taxObj, totalTaxAmount: rnTaxAmt });

                    });
                    //  console.log(modifierChargeList);
                }
            });
        }
        return modifierChargeList;
    };

    handleRadioChange(e, modifierId, modifierName, modifierGroupId, groupName, itemId, rate, minQty, maxQty, foodType, taxId, chargeIds) {
        const item = e.target.name;
        const isChecked = e.target.checked;
        //  console.log(rate);
        //  console.log(itemModifierRate);

        let modifierlst = modifiercartItemList.filter(x => x.modifierGroupId === modifierGroupId && x.itemId === itemId);
        //  console.log(modifierlst);
        itemModifierRate = this.state.modifiterItemModifierRate === 0 ? this.props.modifierItemCRate : this.state.modifiterItemModifierRate;

        if (modifierlst.length === 0) {
            itemModifierRate += rate;
            modifiercartItemList.push({ itemId: itemId, modifierGroupId: modifierGroupId, modifierGroupName: groupName, modifierId: modifierId, modifierName: modifierName, rate: rate, foodType: foodType, taxId: taxId, chargeIds: chargeIds, ctrl: 2 });
            this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
        }
        else if (modifierlst.length === 1) {
            let modifierindex = modifiercartItemList.findIndex(x => x.modifierGroupId === modifierGroupId && x.itemId === itemId);
            itemModifierRate -= modifiercartItemList[modifierindex].rate;
            modifiercartItemList.splice(modifierindex, 1);
            itemModifierRate += rate;
            modifiercartItemList.push({ itemId: itemId, modifierGroupId: modifierGroupId, modifierGroupName: groupName, modifierId: modifierId, modifierName: modifierName, rate: rate, foodType: foodType, taxId: taxId, chargeIds: chargeIds, ctrl: 2 });
            this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
        }
        this.setState({ modifiterItemModifierRate: itemModifierRate });
        document.getElementById('min-group-modifier-cnt-' + modifierGroupId).innerHTML = modifiercartItemList.filter(x => x.modifierGroupId === modifierGroupId && x.itemId === itemId && x.ctrl === 2).length;
        // console.log(modifiercartItemList);
    };

    handleChange(e, modifierId, modifierName, modifierGroupId, groupName, itemId, rate, minQty, maxQty, foodType, taxId, chargeIds) {
        const item = e.target.name;
        //console.log(e.target.name);
        const isChecked = e.target.checked;
        // const radionid = e.target.id;
        // console.log(isChecked);
        // console.log(radionid);
        //  console.log(groupName);
        let modifierlst = modifiercartItemList.filter(x => x.modifierGroupId === modifierGroupId && x.itemId === itemId);
        //  console.log(modifierlst.length + "max : " + maxQty);
        itemModifierRate = this.state.modifiterItemModifierRate === 0 ? this.props.modifierItemCRate : this.state.modifiterItemModifierRate;
        if (isChecked) {
            if (maxQty > 0) {
                if (modifierlst.length + 1 > maxQty) {
                    alert("You have already selected max addons for this category");
                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, !isChecked) }));
                }

                else {
                    itemModifierRate += rate;
                    modifiercartItemList.push({ itemId: itemId, modifierGroupId: modifierGroupId, modifierGroupName: groupName, modifierId: modifierId, modifierName: modifierName, rate: rate, foodType: foodType, taxId: taxId, chargeIds: chargeIds, ctrl: 1 });
                    this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
                }
            }
            else {
                itemModifierRate += rate;
                modifiercartItemList.push({ itemId: itemId, modifierGroupId: modifierGroupId, modifierGroupName: groupName, modifierId: modifierId, modifierName: modifierName, rate: rate, foodType: foodType, taxId: taxId, chargeIds: chargeIds, ctrl: 1 });
                this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
            }
        }
        else // Unchecked
        {
            let modifierindex = modifiercartItemList.findIndex(x => x.modifierGroupId === modifierGroupId && x.itemId === itemId && x.modifierId === modifierId);
            itemModifierRate -= rate;
            modifiercartItemList.splice(modifierindex, 1);
            this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
        }
        this.setState({ modifiterItemModifierRate: itemModifierRate });
        document.getElementById('min-group-modifier-cnt-' + modifierGroupId).innerHTML = modifiercartItemList.filter(x => x.modifierGroupId === modifierGroupId && x.itemId === itemId && x.ctrl === 1).length;
    };

    render() {
        // this.setState({modifierItemCRate: this.props.modifierItemCRate});
        //   itemModifierRate = itemModifierRate === 0 ? this.props.modifierItemCRate : itemModifierRate;
        itemModifierRate = this.state.modifiterItemModifierRate === 0 ? this.props.modifierItemCRate : this.state.modifiterItemModifierRate;
        // itemModifierRate = this.props.modifierItemCRate;
        itemGroupId = this.state.modifierItemCGroupId === 0 ? this.props.modifierItemCGroupId : this.state.modifierItemCGroupId;
        //itemTaxId = this.state.modifierItemCTaxId === 0 ? this.props.modifierItemCTaxId : this.state.modifierItemCTaxId;
        itemId = this.state.modifierItemCItemId === 0 ? this.props.modifierItemCItemId : this.state.modifierItemCItemId;
        itemName = this.state.modifierItemCItemName === "" ? this.props.modifierItemCItemName : this.state.modifierItemCItemName;
        itemRate = this.state.modifierItemCRate === 0 ? this.props.modifierItemCRate : this.state.modifierItemCRate;
        itemQtyId = this.state.modifierItemCQtyId === "" ? this.props.modifierItemCQtyId : this.state.modifierItemCId;
        //  itemQty = this.state.modifierItemCQty === 0 ? this.props.modifierItemCQty : this.state.modifierItemCQty;
        itemTotalAmout = this.state.totalAmount === 0 ? this.props.modifierTotalItemAmount : this.state.totalAmount;
        itemTotalItems = this.state.totalItems === 0 ? this.props.modifierTotalItems : this.state.totalItems;

        return (
            <>
                <div className="section-modifier">
                    <div className="menu-goup">
                        <button className="theme-button btn" style={{ display: "none" }} ref={input => this.inputElement = input} data-toggle="modal" data-target="#modifier-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU1</button>
                    </div>
                    {/* Show Modifier*/}
                    <div className="modal fade" id="modifier-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content animate-bottom">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        {this.props.modifierItemCFoodType === 1 && <img className="food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                        {this.props.modifierItemCFoodType === 2 && <img className="food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />}
                                        {this.props.modifierItemCItemName}</h5>

                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body" id="div-modifier-body">
                                    {this.handleGenerateModifierDom()}
                                    {/* <ul className="modifier-group-li">
                                        {this.props.modifierGroupIds.map(mid =>
                                            <div key={"modifier-group-id-" + mid}>
                                                <li key={mid}>
                                                    {this.props.modifierList.filter(mgrplst => mgrplst.modifierGroupId === mid).map(mgrp =>
                                                        <div key={"modifier-group-" + mgrp.modifierGroupId}>
                                                            <span className="group-name">{mgrp.minimumSelection > 0 && <span className="minimum-selection">*</span>}{mgrp.groupName}</span>

                                                            {mgrp.maximumSelection === 0 &&
                                                                <span> (<span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>)</span>
                                                            }
                                                            {mgrp.maximumSelection > 0 &&
                                                                <span> (
                                                                <span id={"min-group-modifier-cnt-" + mgrp.modifierGroupId}>0</span>
                                                                /
                                                                <span id={"max-group-modifier-cnt-" + mgrp.modifierGroupId}>{mgrp.maximumSelection}</span>
                                                                )</span>
                                                            }

                                                            <ul className="modifier-li">
                                                                {mgrp.modifiers.map(mlst =>
                                                                    <li key={"modifier-li-" + mlst.modifierId}>
                                                                        {mgrp.maximumSelection === 2 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                                        {mgrp.maximumSelection === 1 && <Radio name={mgrp.modifierGroupId} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.radiostatus} onChange={(e) => this.handleRadioChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Radio>}
                                                                        {mgrp.maximumSelection === 0 && <Checkbox name={mlst.modifierName} id={"modifier-chk-grp-" + mgrp.modifierGroupId + "-" + mlst.modifierId} checked={this.state.checkedItems.get(mlst.modifierName)} onChange={(e) => this.handleChange(e, mlst.modifierId, mlst.modifierName, mgrp.modifierGroupId, mgrp.groupName, this.props.modifierItemCItemId, mlst.rate, mgrp.minimumSelection, mgrp.maximumSelection, mlst.foodType, mlst.taxId, mlst.chargeIds)}></Checkbox>}
                                                                        {mlst.foodType === 1 && <img className="food-type-img" src={"../assets/theme1/img/f-t-veg.png"} alt="item" />}
                                                                        {mlst.foodType === 2 && <img className="food-type-img" src={"../assets/theme1/img/f-t-nonveg.png"} alt="item" />}
                                                                        {mlst.modifierName}<span className="rate-inr"> ₹{mlst.rate}</span>
                                                                    </li>

                                                                )
                                                                }
                                                            </ul>
                                                        </div>
                                                    )}
                                                </li>
                                            </div>
                                        )}
                                    </ul> */}
                                </div>
                                <div className="modal-footer">
                                    <div className="cart" >
                                        <div className="c-left"> Item Total  ₹<span >{itemModifierRate}</span></div>
                                        <div className="c-right" data-dismiss="modal" onClick={this.handleAddItem.bind()}>Add Item</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </>
        );
    }
}