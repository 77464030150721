import React, { Component } from 'react';
import './help.scss';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../components/loadinganimation';
import ErrorAlert from '../../components/alertmessages';
import Config from '../../data/SiteConfig';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
let guestLoggedIn = "false";
//let guestAddressId = 0;
let mobileNo = '';
let token = '';
let propertyId = '';
let outletCode = '';
let serviceType = '';
let tableNo = '';
let urlPattern = '';
export default class HelpRequest extends Component {
    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
            guestRequestList: [],
            requestMasterList: []
        }
    }

    componentDidMount() {

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            // Handle error or redirect as needed
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }

        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        if (!sHelpers.inputParamValidation()) {
            this.props.history.push('/' + urlPattern);
        }
        // this.setState({ loading: true });

        window.scrollTo(0, 0);

        if (guestLoggedIn === "false") {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, 200);
            return false;
        }

        token = sessionStorage.getItem('user-token');
        mobileNo = sessionStorage.getItem('user-mobileno');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');

        // this.timer = setInterval(() => this.getGuestOrderList(), 60000)// 1 miniutes
        this.getRequestMasterList();
        this.getGuestRequestList();
    }

    getRequestMasterList() {
        this.setState({ loading: true });
        let param = 'Module=POS&OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        fetch(Config.baseGuestUrl + 'GetGuestRequestMasterData?' + param, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                this.setState({ loading: false });
                console.log(response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        this.setState({ requestMasterList: response.response.requestNames });
                    }
                    else {
                        this.setState({ requestMasterList: [] });
                        this.showMessage(response.message, "danger");
                    }
                }
                else {
                    this.setState({ requestMasterList: [] });
                    this.showMessage(response.message, "danger");
                }

            })
            .catch(err => {
                this.setState({ loading: false, requestMasterList: [] });
                this.showMessage('Request Master List - Failed to fetch - ' + err, "danger");
            });
    };

    getGuestRequestList() {
        this.setState({ loading: true });
        let param = 'OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType + '&GuestMobileNo=' + mobileNo;
        fetch(Config.baseGuestUrl + 'GetGuestRequest?' + param, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                this.setState({ loading: false });
                console.log(response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        this.setState({ guestRequestList: response.response.guestRequest });
                    }
                    else {
                        this.setState({ guestRequestList: [] });
                        this.showMessage(response.message, "danger");
                    }
                }
                else {
                    this.setState({ guestRequestList: [] });
                    this.showMessage(response.message, "danger");
                }

            })
            .catch(err => {
                this.setState({ loading: false, guestRequestList: [] });
                this.showMessage('Guest Request List - Failed to fetch - ' + err, "danger");
            });
    };

    handleRemarks = (requestName, uid) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='special-insc'>
                        <h5>Remarks</h5>
                        <span className="itm-name">{requestName}</span>
                        <textarea id={"request-remarks" + uid} rows="2" cols="30" maxLength="100" placeholder="write here.." autoFocus></textarea>
                        <button onClick={onClose} className="btn-left">Cancel</button>
                        <button
                            onClick={() => {
                                this.handleSaveGuestRequest(uid, requestName);
                                onClose();
                            }}
                            className="btn-right">
                            Done
                        </button>
                    </div>
                );
            }
        });
    };

    handleSaveGuestRequest(uid, requestName) {
        this.setState({ loading: true });
        let remarksVal = document.getElementById('request-remarks' + uid).value;
        let postObj = {
            OutletId: propertyId + outletCode,
            RequestType: 0,
            ServiceType: serviceType,
            TableNumber: serviceType === "FINE-DINE" ? tableNo : "0",
            OrderId: "",
            BillId: "",
            GuestMobileNo: mobileNo,
            RequestName: requestName,
            Remarks: remarksVal,
        }

        // console.log(postObj);

        fetch(Config.baseGuestUrl + 'SaveGuestRequest', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                console.log(response);
                if (response.errorCode === 0) {
                    this.showMessage(response.message, "success");
                    this.setState({ loading: false });
                    this.getGuestRequestList();
                }
                else {
                    //  console.log(response.message);
                    this.setState({ loading: false });
                    this.showMessage('Error request was not placed', "danger");
                    sessionStorage.removeItem("user-vendorOrderId");
                }
            })
            .catch(err => {
                //   console.log(err);
                this.setState({ loading: false });
                this.showMessage('Error request was not placed', "danger");
                sessionStorage.removeItem("user-vendorOrderId");
            });
    }


    goBack() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/items' + urlPattern);
        }, 200);

    };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };


    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-helprequest">
                            <div className="heading-page" ref="recommended">
                                <span style={{ fontSize: "14px", fontWeight: 600 }}>Help & Support</span>
                                {/* <span className="order-track-time-pay">12:48 PM | 10 items, ₹{238}</span> */}
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left"></i></div>
                                </div>

                            </div>
                            <div>
                                <Tabs>
                                    <TabList>
                                        <Tab>Call for Help  <i className="fa fa-hand-paper" style={{ fontSize: "16px", color: "#d12f8b" }}></i></Tab>
                                        <Tab>My Request <span>({this.state.guestRequestList.length})</span>
                                            <div className="refresh-icon" onClick={this.getGuestRequestList.bind(this)}> <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i></div>
                                        </Tab>
                                    </TabList>
                                    <TabPanel>
                                        <div className="help-list">
                                            <ul>
                                                {
                                                    this.state.requestMasterList.map((req, idx) =>
                                                        <li key={"request-" + idx}>
                                                            <div className="content request">
                                                                <div onClick={this.handleRemarks.bind(this, req, idx)}>
                                                                    <div className="help-heading"><span className="help-type">{req}</span></div>
                                                                    <i className="fa fa-angle-right right-btn" ></i>
                                                                </div>

                                                            </div>
                                                        </li>

                                                    )
                                                }
                                            </ul>
                                        </div>
                                        {this.state.requestMasterList.length === 0 &&
                                            <div>
                                                <div className="no-menus-img"><img src="img/no-image/no-data.png" alt="lucid-no-orders" /></div>
                                            </div>
                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="help-list">
                                            <ul>
                                                {
                                                    this.state.guestRequestList.map((req, idx) =>
                                                        <li key={"guest-request-" + idx}>
                                                            <div className="content request">
                                                                <div className="help-heading"><span className="help-type">{req.requestName}</span></div>
                                                                {/* <i className="fa fa-angle-right right-btn" ></i> */}
                                                                <div className="help">
                                                                    {req.remarks}
                                                                </div>
                                                                {req.status === "Open" && <div className="status-open">{req.status}</div>}
                                                                {req.status === "Processing" && <div className="status-process">{req.status}</div>}
                                                                {req.status === "Acknowledge" && <div className="status-process">{req.status}</div>}
                                                                {req.status === "Closed" && <div className="status-closed">{req.status}</div>}
                                                                {req.status === "Cancelled" && <div className="status-cancelled">{req.status}</div>}
                                                                <div className="request-time"><i className="fa fa-clock" aria-hidden="true"></i> Updated Time {req.statusUpdatedTime}</div>
                                                            </div>
                                                        </li>

                                                    )
                                                }
                                            </ul>
                                        </div>
                                        {this.state.guestRequestList.length === 0 &&
                                            <div>
                                                <div className="no-menus-img"><img src="/img/no-image/no-data.png" alt="lucid-no-orders" /></div>
                                            </div>
                                        }

                                    </TabPanel>
                                </Tabs>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    };

};