import React, { Component } from 'react';
import './login.scss';
import ErrorAlert from '../../components/alertmessages';
import LoadingAnimation from '../../components/loadinganimation/login';
import Config from '../../data/SiteConfig';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
//import Cookies from 'universal-cookie';
import Slider from "react-slick";
import Header from '../../components/Header';
const homeslidersettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: false
};

let tableNo = '';
let serviceType = '';
let orgnationId = '';
let propertyId = '';
let outletCode = '';
let mobileNo = '';
let token = '';
let urlPattern = '';
let outletDetails = '';

export default class TableVerification extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            pin: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            redirectCart: false,
            sliderImages: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Slide 1",
                    primaryImageUrl: "/img/slider/login-verification-df-lucid.jpg",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],
            loggedIn: false,
        }
    }
    componentWillMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }

        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationId = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletDetails = sessionStorage.getItem('user-outletDetails');
        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        urlPattern = sessionStorage.getItem('url-pattern');

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
       
       
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationId === undefined || orgnationId === "" || orgnationId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.setState({ loading: true });
            this.showMessage('Invalid Mobile Number', "danger");
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/login' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }

        if (this.props.location.state !== undefined) {
            if (this.props.location.state.redirectCart === undefined || this.props.location.state.redirectCart === null || this.props.location.state.redirectCart === '') {
                this.setState({ redirectCart: false });
                sessionStorage.removeItem('user-table-verified');
            }
            else {
                this.setState({ redirectCart: this.props.location.state.redirectCart });
            }
        }
        this.getCMSDetails(); //Get CMS Page
    }

    handlechange = event => {
        const value = event.target.value;
        this.setState({ pin: value });
        //  console.log(value);
    };

    handleClick = (e) => {
        e.preventDefault();
        //   console.log(this.state.pin);

        this.setState({ loading: true });

        if (this.state.pin === "") {
            this.showMessage("Please enter the valid PIN No.", "info")
            return false;
        }

        let postObj = {
            OutletId: propertyId + outletCode,
            GuestName: " ",
            MobileNo: mobileNo,
            PIN: this.state.pin,
            TableNumber: tableNo
        }

        fetch(Config.basePOSUrl + 'VerifyPin', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    sessionStorage.setItem('user-table-verified', true);
                    setTimeout(() => {
                        this.setState({ loading: false });
                        if (this.state.redirectCart)
                            this.props.history.push('/cart' + urlPattern);
                        else {
                            // set Logged in Date                                                                    
                            let loggedIn = sHelpers.getFormattedDate(new Date());
                            sessionStorage.setItem('user-loggedin-date', loggedIn);

                            this.props.history.push('/home' + urlPattern, { guestAppLandingPage: outletDetails.guestAppLandingPage });
                        }
                    }, Config.minMsgTimeout);
                }
                else {
                    sessionStorage.removeItem('user-table-verified');
                    //  console.log(response.message);
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                sessionStorage.removeItem('user-table-verified');
                // console.log(err);
                this.showMessage("An error occured", "danger");
            });
    };
    getCMSDetails() {
        let postobj = {
            organizationId: parseInt(orgnationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.login, // Default Login
            PageType: Config.pageType.login, // Login           
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        }

        fetch(Config.baseAPIUrl + 'PageDetails', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                if (response.isSuccessful) {

                    // Slider                   
                    if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0)
                        this.setState({ sliderImages: response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });

                    // if (response.page != null) {
                    //     if (response.page.preview !== '')
                    //         this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });

                    // }
                }

            })
            .catch(err => {
                console.log(err);
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }
    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };
    render() {
        const { loading } = this.state;

        return (
            <div>
                {loading &&
                    <LoadingAnimation />
                }
                <div id="login-content">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    <Header loggedIn={this.state.loggedIn} />
                    <div className="content-wrap">
                        {/* <div className="img-hero">
                            <div>
                                <img src="../assets/theme1/img/otp-bg.png" alt="slider" />
                            </div>
                        </div> */}
                        <div style={{ marginTop: "50px", padding: "8px" }}>
                            <Slider {...homeslidersettings} className="img-hero">
                                {this.state.sliderImages.map(img =>
                                    <div key={"slider-img-" + img.sort}>
                                        <img src={img.primaryImageUrl} alt="lucid-slider-contact-less-order" />
                                    </div>
                                )}
                            </Slider>
                        </div>

                        <div className="login-section-home login-panel">
                            <div className="heading-section">
                                <div><img className="img-center table-img" src="/img/table-logo.png" alt="lucid-contactless-app-user-table-service" /></div>
                                <div className="sa-title popcat">Confirm Table</div>
                                <div className="login-text">Table No: {tableNo}</div>
                                <div className="clear"></div>
                                <form className="form" action="##" method="post" id="setting">
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <input type="password" className="form-control login-input" onChange={(e) => this.handlechange(e)} ref={(input) => { this.nameInput = input; }} name="input-table-pin" id="input-table-pin" placeholder="Enter PIN" maxLength="6" autoFocus autoComplete="off" />
                                        </div>
                                    </div>
                                    <div className="submit-btn">
                                        <div className="col-12">
                                            <button className="theme-button  table-verify-btn" onClick={(e) => this.handleClick(e)} id="input-btn-send-otp" disabled={loading}>Verify</button>
                                        </div>
                                    </div>
                                </form>
                                <div className="clear"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

