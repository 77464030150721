import React, { Component } from 'react';
import './qrcodeerror.scss';

export default class DeviceError extends Component {

    componentWillMount() {
        sessionStorage.removeItem('user-orgnationid');
        sessionStorage.removeItem('user-propertyid');
        sessionStorage.removeItem('user-propertyname');
        sessionStorage.removeItem('user-outletcode');
        sessionStorage.removeItem('user-tableno');
        sessionStorage.removeItem('user-servicetype');
        sessionStorage.removeItem('user-organizationname');
        sessionStorage.removeItem('user-outletname');
        sessionStorage.removeItem('user-mobileno');
        sessionStorage.removeItem('user-token');


        sessionStorage.removeItem('getMenuGroups');
        sessionStorage.removeItem('getModifierGroups');
        sessionStorage.removeItem('getTaxes');
        sessionStorage.removeItem('getmenuItems');
        sessionStorage.removeItem('getCharges');


        sessionStorage.removeItem('cartList');
        sessionStorage.removeItem('modifierCartList');
        sessionStorage.removeItem('taxCartList');
        sessionStorage.removeItem('chargeCartList');


        sessionStorage.removeItem("paymentInitiatedId");
        sessionStorage.removeItem("vendorTransactionId");
        sessionStorage.removeItem("vendorId");
        sessionStorage.removeItem("vendorName");
        sessionStorage.removeItem("paymentStatus");
        sessionStorage.removeItem("order-instruction");
        sessionStorage.removeItem('otpReferenceId');
        sessionStorage.removeItem("user-vendorOrderId");
        sessionStorage.removeItem("user-table-verified");
        sessionStorage.removeItem("user-outletDetails");
        sessionStorage.removeItem('payAmount');
        sessionStorage.removeItem('pageCMSSettings');
        sessionStorage.removeItem('user-digitalMenu');
        sessionStorage.removeItem("user-loggedin-date");
        sessionStorage.removeItem("ebillUrl");
        sessionStorage.removeItem("isPay");
        sessionStorage.removeItem("posBillId");
        sessionStorage.removeItem('otp-exp-duration');
        sessionStorage.removeItem('user-statsid');
        sessionStorage.removeItem('main-guest-mask-no');
        sessionStorage.removeItem('main-guest-no');
        sessionStorage.removeItem('group-session');
        sessionStorage.removeItem('group-pin');
        sessionStorage.removeItem('guest-name');
        sessionStorage.removeItem('user-loggedin');
        sessionStorage.removeItem("login-page-session"); // Cart Session
        sessionStorage.removeItem("on-click-mode"); // Cart Session
        sessionStorage.removeItem('main-guest-name');
        sessionStorage.removeItem('user-qr-level');
        sessionStorage.removeItem('url-remarks');
        sessionStorage.removeItem('menuFetchDateTime');
        sessionStorage.removeItem('user-GuestDetails');
        sessionStorage.removeItem('user-addressId');
        sessionStorage.removeItem('user-CurrentAddress');
        sessionStorage.removeItem('prepaidCardAccountId');
        sessionStorage.removeItem('mobile-app');
        sessionStorage.removeItem('app-CountryCode');
        sessionStorage.removeItem('app-CountryCode');
        sessionStorage.removeItem('order-track-status');

        sessionStorage.removeItem('pageCMSSettings');
        sessionStorage.removeItem('menuFetchDateTime');
        sessionStorage.removeItem('group-pin');
        sessionStorage.removeItem('dashboard-hide');
        sessionStorage.removeItem('dashboard-hide');
        sessionStorage.removeItem('entered-mobile-no');
        sessionStorage.removeItem('entered-name');       
        sessionStorage.removeItem('user-access-mode');
        sessionStorage.removeItem('otp');
        sessionStorage.removeItem('verified-otp');
        sessionStorage.removeItem('selectedClassification');
        sessionStorage.removeItem('dashboard-hide');
        sessionStorage.removeItem('order-charge-Tax-Amount');
        sessionStorage.removeItem('getclf');
        sessionStorage.removeItem('order-charge-Amount');
        sessionStorage.removeItem('url-pattern');
        sessionStorage.removeItem('url-key');
        sessionStorage.removeItem('dt-number');
        sessionStorage.removeItem('uid');
        sessionStorage.removeItem('url-version');
        sessionStorage.removeItem('app-ver');
        sessionStorage.removeItem('app-ContactNo');
        sessionStorage.removeItem('order-charge-List');     
    }
    render() {
        return (
            <div>
                {/* {loading && <LoadingAnimation />}             */}
                <div id="login-content qrcode-err">
                    <div className="content-wrap qrcode_bg">
                        {/* <img src={LoadingImg} className="svg" alt="" />  */}
                        {/* <img src="../assets/theme1/img/error/qrcode-error.png" className="qrcode-img-center" alt="lucid-contactless-food-ordering" /> */}

                        <div className="qrcode-txt-center">
                            <img src="/img/error/exclamation-red.png" style={{ width: "50px" }} alt="lucid-device-error" />
                            <div className="qrcode-oops-txt">Device not supported </div>
                            {/* <div className="qrcode--txt-not-found">404 - Page Not Found.</div> */}
                            <div className="qrcode-msg-txt">Sorry, Web App doesn’t have a desktop version.
                                <br /> Please use a mobile device for the best experience.</div>
                            {/* <div className="qrcode-msg-txt">The page you are looking for might have been removed <br/>
                        had its name changed or is temporarily unavailable.
                        </div> */}
                        </div>
                    </div>
                    {/* <img src={LoadingImg} className="svg screen-img-center loading_img" alt="" /> */}
                </div>
            </div>
        )
    }
}