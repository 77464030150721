import React, { Component } from 'react';
import Slider from "react-slick";
import Header from '../../../components/emenu/header';
import LoadingAnimation from '../../../components/emenu/loadinganimation';
//import loadjs from 'loadjs';
import './home.scss';
import Config from '../../../data/SiteConfig';
import { isMobile } from 'react-device-detect';
const homeslidersettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: false
};


// const groups = [
//     { grpId: 1, grpName: 'Biriyani', imgName: '../assets/theme1/img/biriyani-food-7.png', routePath: "/home" },
//     { grpId: 2, grpName: 'Soups', imgName: '../assets/theme1/img/soups.jpg', routePath: "/home" },
//     { grpId: 3, grpName: 'Burger', imgName: '../assets/theme1/img/fast-food-3.png', routePath: "/home" },
//     { grpId: 4, grpName: 'Hot Dog', imgName: '../assets/theme1/img/fast-food-4.png', routePath: "/home" },
//     { grpId: 5, grpName: 'Fry Chick', imgName: '../assets/theme1/img/fast-food-5.png', routePath: "/home" },
//     { grpId: 6, grpName: 'Shawarma', imgName: '../assets/theme1/img/fast-food-6.png', routePath: "/home" }
// ];
//const publicIp = require('public-ip');
let loadcomfn = null;
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let organizationId = '';//
let propertyId = '';
let outletCode = '';
let digitalMenu = '';
let statsId = '';
export default class emHome extends Component {

    constructor(props) {
        super(props);
        this.goToItems = this.goToItems.bind(this);
        this.getDetails = this.getDetails.bind(this);
        this.state = {
            loading: false,
            getMenus: [],
            getItems: [],
            getModifiers: [],
            getTaxes: [],
            getCharges: [],
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            APIResponseMsg: '',
            outletDetails: null,
            sliderImages: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Slide 1",
                    primaryImageUrl: "../assets/theme1/img/slider/no-image.png",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],
            bannerImageUrl: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Banner",
                    primaryImageUrl: "../assets/theme1/img/slider/no-image.png",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],
            headerLogo: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Logo",
                    primaryImageUrl: "../assets/theme1/img/no-image/no-image-logo.png",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],

            pageContent: "",
            pagePreview: ""


        }

    }


    componentWillMount() {

        if (!isMobile) {
            this.props.history.push('/deviceerror');
            return false;
        }


        //$('.modal-backdrop').remove();
        //let cookie = new Cookies();
        // let token = cookie.get("token");
        // let mobileNo = cookie.get("user-mobileNo");
        // let tableNo = cookie.get("user-tableNo");
        // let serviceType = cookie.get("user-serviceType");


        sessionStorage.removeItem('getMenuGroups');
        sessionStorage.removeItem('getModifierGroups');
        sessionStorage.removeItem('getTaxes');
        sessionStorage.removeItem('getmenuItems');
        sessionStorage.removeItem('getCharges');
        sessionStorage.removeItem("user-vendorOrderId");
        sessionStorage.removeItem("pageCMSSettings");
        sessionStorage.removeItem("cms-logo");



        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        organizationId = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        digitalMenu = sessionStorage.getItem('user-digitalMenu');
        statsId = sessionStorage.getItem('user-statsid');

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror');
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/');
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/');
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/');
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/');
            return false;
        }
        if (organizationId === undefined || organizationId === "" || organizationId === null) {
            this.props.history.push('/');
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/');
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/');
            return false;
        }

        if (digitalMenu === undefined || digitalMenu === "" || digitalMenu === null || digitalMenu === false || digitalMenu === "false") {
            this.props.history.push('/');
            return false;
        }
        if (statsId === undefined || statsId === "" || statsId === null) {
            statsId = "0"
        }

        document.body.classList.remove('modal-open');
        let modalPopup = document.querySelector('.modal-backdrop');
        if (modalPopup !== null) {
            modalPopup.classList.remove('modal-backdrop', 'fade', 'show');
        }
        this.getCMSDetails();
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "../assets/theme1/js/theme.js"; // it is not calling mentioned for to avoid null value
        script.async = true;
        script.onload = () => this.jk();
        document.body.appendChild(script);
        // this.getCMSPageList();
        // window.location.reload(true);
    }

    jk() {
        loadcomfn = window.initiate;
        loadcomfn();
    }

    getCMSPageList() {

        // console.log(postobj);

        // (async () => {
        //     console.log(await publicIp.v4());
        //     //=> '46.5.21.123'

        //     console.log(await publicIp.v6());
        //     //=> 'fe80::200:f8ff:fe21:67cf'
        // })();      
        let urlParam = "PropertyId=" + propertyId + "&OutletCode=" + outletCode + "&ServiceMode=" + outletCode + "&RequestIp=''";
        this.setState({ APIResponseMsg: "Loading.." });
        fetch(Config.baseAPIUrl + 'AdminPageList?' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                // console.log(response);
                if (response.isSuccessful) {
                    // Slider                 

                    // sessionStorage.setItem("cms-logo", JSON.stringify(this.state.headerLogo));
                    // sessionStorage.setItem("pageCMSSettings", JSON.stringify(response));

                }
                else {
                    if (response.page == null) {
                        console.log('null');
                    }
                    this.setState({ APIResponseMsg: response.message });
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({ APIResponseMsg: "Content - Failed to fetch" });
            });
    }

    getCMSDetails() {
        let postobj = {
            organizationId: parseInt(organizationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.home, // Default Home
            PageType: Config.pageType.home, // Home
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        }
        // console.log(postobj);

        // (async () => {
        //     console.log(await publicIp.v4());
        //     //=> '46.5.21.123'

        //     console.log(await publicIp.v6());
        //     //=> 'fe80::200:f8ff:fe21:67cf'
        // })();      
        this.setState({ APIResponseMsg: "Loading.." });
        fetch(Config.baseAPIUrl + 'PageDetails', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => res.json())
            .then(response => {
                //console.log(response);
                if (response.isSuccessful) {

                    // Slider
                    if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0) {
                        this.setState({ sliderImages: response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });
                    }

                    // Normal Banner
                    if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Normal) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Normal).length > 0) {
                        this.setState({ bannerImageUrl: response.banners.filter(i => i.bannerType === Config.bannerImageType.Normal) });
                    }
                    //Logo
                    if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Logo) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Logo).length > 0) {
                        this.setState({ headerLogo: response.banners.filter(i => i.bannerType === Config.bannerImageType.Logo) });
                    }

                    // Content                   
                    if (response.page != null) {
                        //  let pageContent = {
                        //     content : response.page.content,
                        //     preview : response.page.preview
                        //  }
                        this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });
                        // console.log(response.page.preview);
                    }

                    sessionStorage.setItem("cms-logo", JSON.stringify(this.state.headerLogo));
                    sessionStorage.setItem("pageCMSSettings", JSON.stringify(response));

                    this.getDetails();
                }
                else {
                    if (response.page == null) {
                        console.log('null');
                    }
                    this.setState({ APIResponseMsg: response.message });
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({ APIResponseMsg: "Content - Failed to fetch" });
            });
    }

    getDetails() {
        this.setState({ APIResponseMsg: "Loading..." });
        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        fetch(Config.basePOSUrl + 'Getguestappmenus' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                    //  console.log(JSON.stringify(response));
                    // Filter active Menu Items
                    let activeMenuGroups = [];
                    let activeMenuItems = [];
                    if (response.response.menuGroups.length > 0)
                        activeMenuGroups = response.response.menuGroups.filter(x => x.isActive === true);

                    if (response.response.menuItems.length > 0)
                        activeMenuItems = response.response.menuItems.filter(x => x.isActive === true);

                    sessionStorage.setItem("getMenuGroups", JSON.stringify(activeMenuGroups));
                    sessionStorage.setItem("getmenuItems", JSON.stringify(activeMenuItems));
                    sessionStorage.setItem("getModifierGroups", JSON.stringify(response.response.modifierGroups));
                    sessionStorage.setItem("getTaxes", JSON.stringify(response.response.taxes));
                    sessionStorage.setItem("getCharges", JSON.stringify(response.response.charges));
                    sessionStorage.setItem("menuFetchDateTime", response.response.menuDateTime);
                    this.setState({ getMenus: activeMenuGroups });
                    this.setState({ getItems: activeMenuItems });
                    this.setState({ getModifiers: response.response.modifierGroups });
                    this.setState({ getTaxes: response.response.taxes });
                    this.setState({ getCharges: response.response.charges });
                    this.setState({ APIResponseMsg: "" });
                    // // Get Outlet Details                    
                    fetch(Config.basePOSUrl + 'GetOutletDetails' + urlParam, {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer " + token,
                            'content-type': 'application/json'
                        }
                    })
                        .then(res => res.json())
                        .then(response => {
                            //  console.log(response);
                            if (response.errorCode === 0) {
                                this.setState({ outletDetails: response.response.outletDetails });
                                sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                                this.setState({ APIResponseMsg: "" });
                                setTimeout(() => {
                                    window.scrollTo(0, 0);
                                }, 1000);
                            }
                            else {
                                // console.log(response);
                                this.setState({ APIResponseMsg: "Failed to fetch" });
                            }
                        })
                        .catch(err => {
                            this.setState({ APIResponseMsg: "Outlet Details - Failed to fetch" })
                            //  console.log('Outlet Details - Failed to fetch - ' + err);
                            this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
                        });

                }
                else {
                    // console.log(response);
                    this.setState({ APIResponseMsg: "Failed to fetch" })
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                this.setState({ APIResponseMsg: "Failed to fetch" })
                //console.log(err);
                // this.showMessage("An error occured", "danger");
            });
    }

    goToItems(grpId) {
        this.setState({ loading: true });

        let posturlObj = {
            organizationId: parseInt(organizationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ContactNo: mobileNo,
            StatsId: parseInt(statsId),
            numofMenuAccess: 1,
            username: Config.linkUserName,
            password: Config.linkPassword
        }
        // Update Contact Detail
        fetch(Config.baseAPIUrl + 'ClickURLDetail', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(posturlObj)
        })
            .then(res => res.json())
            .then(response => {
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.props.history.push('/emitems', { focusGroup: "focus-mnugrp-" + grpId });
                }, 1500);
            })
            .catch(err => {
                console.log(err);
                //this.showMessage("CMS - Failed to fetch", "danger");
            });

    }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, 2000);
    };



    //  createMarkup(htmlTxt) {
    //     return {__html: htmlTxt };
    //   }

    //    domContent(htmlTxt) {
    //     return <div dangerouslySetInnerHTML={this.createMarkup(htmlTxt)} />;
    //   }


    // componentWillMount() {
    //     loadjs('../../ assets/js/jquery341.min.js', function () {
    //         loadjs('../../assets/js/theme.js');
    //     });
    // }

    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                <div id="content">
                    {/* {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />} */}
                    <Header
                        headerLogo={this.state.headerLogo}
                    />
                    <div className="bottom-clear"></div>
                    <div className="content-warp" >

                        <Slider {...homeslidersettings} className="img-hero">
                            {this.state.sliderImages.map(img =>
                                <div key={"slider-img-" + img.sortOrder}>
                                    <img src={img.primaryImageUrl} alt={img.altName} />
                                </div>
                            )}
                            {/* <div>
                                <img src="../assets/theme1/img/slider/slide2.jpg" alt="slider" />
                            </div>
                            <div>
                                <img src="../assets/theme1/img/slider/slide3.jpg" alt="slider" />
                            </div>
                            <div>
                                <img src="../assets/theme1/img/slider/slide4.jpg" alt="slider" />
                            </div> */}
                        </Slider>

                        <div className="section-home you-might-like">
                            <div className="heading-section custom-heading-top">
                                <div className="sa-title popcat">Welcome to {sessionStorage.getItem("user-outletname")}</div>
                                <div className="section-subscribe custom-section-subscribe">
                                    <div className="subcontainer">
                                        <div className="subrow">
                                            <div className="subcol">
                                                {/* <div className="section-title">Popular Categories</div> */}
                                                {/* <p className="textsub">Casual Dining - Andhra, Biryani, North Indian, Chinese</p> */}
                                                {/* <p className="textsub home-param-txt">Service Type : <span className="home-param-value-txt">{sessionStorage.getItem("user-servicetype")}</span></p>
                                                {sessionStorage.getItem("user-servicetype") === "FINE-DINE" && <p className="textsub home-param-txt">Table No: <span className="home-param-value-txt">{sessionStorage.getItem("user-tableno")}</span></p>} */}

                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-subscribe">
                                        {/* <img src="../assets/theme1/img/food4.jpg" alt="banner" /> */}
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            {/* <Slider {...ymlcarousel} className="yml-carousel">
                                <div className="yml-box">
                                    <div className="yml-img">
                                        <img src="../assets/theme1/img/food9.jpg" alt="you might like" />
                                    </div>
                                    <div className="yml-food-text">Chicken Fried Steak</div>
                                </div>
                                <div className="yml-box">
                                    <div className="yml-img">
                                        <img src="../assets/theme1/img/food10.jpg" alt="you might like" />
                                    </div>
                                    <div className="yml-food-text">Grilled Fish Tacos</div>
                                </div>
                                <div className="yml-box">
                                    <div className="yml-img">
                                        <img src="../assets/theme1/img/food11.jpg" alt="you might like" />
                                    </div>
                                    <div className="yml-food-text">Steak with Garlic</div>
                                </div>
                                <div className="yml-box">
                                    <div className="yml-img">
                                        <img src="../assets/theme1/img/food12.jpg" alt="you might like" />
                                    </div>
                                    <div className="yml-food-text">Harvest Salad</div>
                                </div>
                                <div className="yml-box">
                                    <div className="yml-img">
                                        <img src="../assets/theme1/img/food13.jpg" alt="you might like" />
                                    </div>
                                    <div className="yml-food-text">Cheesy Pizza</div>
                                </div>
                                <div className="yml-box">
                                    <div className="yml-img">
                                        <img src="../assets/theme1/img/food14.jpg" alt="you might like" />
                                    </div>
                                    <div className="yml-food-text">Teriyaki Chicken</div>
                                </div>
                            </Slider> */}
                        </div>


                        <div className="home-menu box-menu">
                            <ul className="list-unstyled">
                                {/* <span style={{textAlign:"center",color: "#6c757d87",fontSize:"12px"}}><h1>Loading...</h1></span> */}
                                {this.state.getMenus.length === 0 &&
                                    <span style={{ textAlign: "center", color: "#6c757d87", fontSize: "12px" }}>
                                        <h1>
                                            <div>{this.state.APIResponseMsg}</div>
                                            <div>
                                                {/* {this.state.APIResponseMsg === "Failed to fetch" ? <div onClick={this.getDetails} style={{ marginLeft: "18px", fontSize: "20px", width: "50%", float: "right", marginTop: "-22px" }} ><i className="fas fa-sync-alt"></i></div> : <div style={{ marginLeft: "18px", fontSize: "20px", width: "50%", float: "right", marginTop: "-22px" }}><i className="fas fa-sync-alt fa-spin"></i></div>} */}
                                            </div>
                                        </h1>
                                    </span>
                                }
                                {
                                    this.state.outletDetails !== null &&
                                    <div className="order-now" onClick={() => this.goToItems("focus-mnugrp-recommended")}><i className="fa fa-utensils sidebar-icon" style={{ color: "#ffffff" }}></i> Go To Menu</div>
                                }
                                {
                                    this.state.pagePreview !== "" &&
                                    <div className="home-content" dangerouslySetInnerHTML={{ __html: this.state.pagePreview }}></div>
                                }

                                {/* {
                                    this.state.getMenus.map(grp =>
                                        <li key={grp.menuGroupId} onClick={() => this.goToItems(grp.menuGroupId, grp.menuGroupName)}>
                                            <NavLink to="/items" className="dish-list-link">
                                                <div className="dish-list">
                                                    <div className="dish-list-tbl-cell">
                                                        <img src="../assets/theme1/img/item/menu-icon.png" className="img-fluid" alt="dish-menu" />
                                                        <div className="dish-list-text">
                                                            <h4>{grp.menuGroupName}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </li>
                                    )} */}

                            </ul>
                        </div>
                        {/* <div className="section-home home-banner">
                            <img src={this.state.bannerImageUrl[0].primaryImageUrl} alt="" />
                        </div> */}
                        {/* <div class="section-home s-category">
                            <div class="heading-section">
                                <div class="sa-title popcat">Popular Categories</div>
                                <div class="clear"></div>
                            </div>
                            <div class="home-category-list">
                                <div class="home-box-category">
                                    <div class="home-text-category">
                                        <div class="bc-text">Quick and Easy</div>
                                    </div>
                                    <div class="home-image-category">
                                        <img src="../assets/theme1/img/food1.jpg" alt=""/>
                                    </div>
                                </div>
                                <div class="home-box-category">
                                    <div class="home-text-category">
                                        <div class="bc-text">Dinner Ideas</div>
                                    </div>
                                    <div class="home-image-category">
                                        <img src="../assets/theme1/img/food2.jpg" alt=""/>
                                    </div>
                                </div>
                                <div class="home-box-category">
                                    <div class="home-text-category">
                                        <div class="bc-text">Healthy</div>
                                    </div>
                                    <div class="home-image-category">
                                        <img src="../assets/theme1/img/food3.jpg" alt=""/>
                                    </div>
                                </div>
                                <div class="home-box-category">
                                    <div class="home-text-category">
                                        <div class="bc-text">Vegetarian</div>
                                    </div>
                                    <div class="home-image-category">
                                        <img src="../assets/theme1/img/food4.jpg"  alt=""/>
                                    </div>
                                </div>
                                <div class="home-box-category">
                                    <div class="home-text-category">
                                        <div class="bc-text">Cakes and Baking</div>
                                    </div>
                                    <div class="home-image-category">
                                        <img src="../assets/theme1/img/food5.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="home-box-category">
                                    <div class="home-text-category">
                                        <div class="bc-text">World Cuisine</div>
                                    </div>
                                    <div class="home-image-category">
                                        <img src="../assets/theme1/img/food6.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="home-box-category">
                                    <div class="home-text-category">
                                        <div class="bc-text">Meal Type</div>
                                    </div>
                                    <div class="home-image-category">
                                        <img src="../assets/theme1/img/food7.jpg" alt="" />
                                    </div>
                                </div>
                                <div class="home-box-category">
                                    <div class="home-text-category">
                                        <div class="bc-text">Chicken Recipes</div>
                                    </div>
                                    <div class="home-image-category">
                                        <img src="../assets/theme1/img/food8.jpg"  alt=""/>
                                    </div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div> */}
                    </div>
                </div>

            </>
        )
    }
}