import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
// added by dasprakash on 27-10-2023 jira-1677
import Modal from 'react-bootstrap/Modal';
// added by dasprakash on 27-10-2023 jira-1677
import Config from '../../data/SiteConfig';
// added by dasprakash on 27-10-2023 jira-1677
import Request from '../request';
import { withRouter } from 'react-router-dom';
import Changeaccessmode from '../Changeaccessmode'


const menuItems = [
  // commented by dasprakash on 23-08-2023 jira-1302
  // { menuId: 1, menuName: 'Home', iconsName: 'fas fa-home', routePath: "/home" },
  // { menuId: 2, menuName: 'My Orders', iconsName: 'fa fa-shopping-cart sidebar-icon', routePath: "/myorders" },
  // { menuId: 2, menuName: 'My Bills', iconsName: 'fa fa-money', routePath: "/mybills" },
  // { menuId: 3, menuName: 'Contact Us', iconsName: 'fa fa-phone sidebar-icon', routePath: "/home" },
  // commented by dasprakash on 23-08-2023 jira-1302
  // { menuId: 4, menuName: 'Logout', iconsName: 'fa fa-sign-out-alt', routePath: "/logout" }
  { menuId: 1, menuName: 'Home', iconsName: '', routePath: "/home" },
  { menuId: 2, menuName: 'My Orders', iconsName: '', routePath: "/myorders" },
  // commented by dasprakash on 25-10-2023 jira-1677
  // { menuId: 3, menuName: 'Order Tracking', iconsName: '', routePath: "/ordertrack" },
  // added by dasprakash on 25-10-2023 jira-1677
  { menuId: 3, menuName: 'History', iconsName: '', routePath: "/history" },
  { menuId: 4, menuName: 'Group Members', iconsName: '' },
  // { menuId: 4, menuName: 'Pay Bill', iconsName: '', routePath: "/mybills" },

  // added by dasprakash on 11-11-2023 jira-1744
  { menuId: 5, menuName: 'Change Outlet', iconsName: '' },
  { menuId: 6, menuName: 'Change Service Mode', iconsName: '' },

  // commented by dasprakash on 27-10-2023 jira-1677
  // { menuId: 7, menuName: 'Help', iconsName: '',routePath: "/helprequest" },
  // added by dasprakash on 27-10-2023 jira-1677
  { menuId: 7, menuName: 'Help', iconsName: '' },
  { menuId: 8, menuName: 'Logout', iconsName: '', routePath: "/logout" }
];
// added by dasprakash on 26-10-2023 jira-1677
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let propertyId = '';
let outletCode = '';
let callbackAccessModeHome = '';
let qrLevel = 5 // By Default Service type Mode
let digtalMode = "false";
let urlPattern = '';
// added by dasprakash on 27-10-2023 jira-1677
const reqeustRef = React.createRef();



//const config = require('../../data/SiteConfig');

class Header extends Component {
  constructor(props) {
    super(props);
    // added by dasprakash on 27-10-2023 jira-1677
    this.callBackRequest = this.callBackRequest.bind(this);
    this.goToHelpRequest = this.goToHelpRequest.bind(this);

    this.accessMode = this.accessMode.bind(this);

    this.callbackAccessModeHeader = this.callbackAccessModeHeader.bind(this);


    this.state = {
      active: "Receipts",

      // added by dasprakash on 26-10-2023 jira-1677
      show: false,
      groupMemberList: [],
      HelpRequestShow: false,
      accessModeShow: false,
      outletLevel: false,
      serviceLevel: false,

      clickingServicemode: "false",



    }

  }
  // added by dasprakash on 26-10-2023 jira-1677
  componentDidMount() {

    
    //console.log("props",this.props)
    mobileNo = sessionStorage.getItem('user-mobileno');
    token = sessionStorage.getItem('user-token');
    tableNo = sessionStorage.getItem('user-tableno');
    serviceType = sessionStorage.getItem('user-servicetype');
    propertyId = sessionStorage.getItem('user-propertyid');
    outletCode = sessionStorage.getItem('user-outletcode');
    qrLevel = sessionStorage.getItem('user-qr-level') == null ? 5 : sessionStorage.getItem('user-qr-level');
    mobileNo = sessionStorage.getItem('user-mobileno');
    digtalMode = sessionStorage.getItem('user-digitalMenu');
    this.setState({ active: this.props.activPageName });
    urlPattern = sessionStorage.getItem('url-pattern');
    const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
    if (!sessionDt) {
      this.props.history.push('/sessionerror' + urlPattern);
      return false;
  }
  if (!urlPattern) {
      this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
      return false;
  }
    if (parseInt(qrLevel) === Config.qrCodeLevel.Property) {
      //console.log("calling if")
      this.setState({ outletLevel: true, serviceLevel: true });

    }
    else if (parseInt(qrLevel) === Config.qrCodeLevel.Outlet) {
      //console.log("calling  else if ")
      this.setState({ outletLevel: false, serviceLevel: true });

    } else {
      //console.log("running else")
    }
  }

  // _handleClick(name) {
  //   this.setState({ active: name });
  //   //Added by vijay on 23-11-2023 -overlay-Blur  
  //   const overlay = document.querySelector('.overlay');
  //   overlay.classList.remove('active');
  //   document.body.style.overflow = 'auto';
  // }
  _handleClick = (name, urlPattern) => {
    const overlay = document.querySelector('.overlay');
    overlay.classList.remove('active');
    document.body.style.overflow = 'auto';
  //  this.setState({ loading: true });
    switch (name) {      
      case "login":
        // Handle login case
        this.setState({ active: name });
        setTimeout(() => {
          this.setState({ loading: false });
          this.props.history.push('/login' + urlPattern);
        }, 100);
        break;
      case "home":
        // Handle home case
        this.setState({ active: name });
        setTimeout(() => {
          this.setState({ loading: false });
          this.props.history.push('/home' + urlPattern);
        }, 100);
        break;
      default:
        const matchedMenuItem = menuItems.find(mnuItem => mnuItem.menuName === name);
        if (matchedMenuItem) {
          this.setState({ active: name });
          setTimeout(() => {
            this.props.history.push(matchedMenuItem.routePath + urlPattern);
            //  this.setState({ loading: true });
          }, 100);
        } else {
          // Handle other cases
        }
      break;
       
    }
  };
  // added by dasprakash on 26-10-2023 jira-1677
  handleClose = () => this.setState({ show: false });
  // added by dasprakash on 26-10-2023 jira-1677
  handleShow = () => {
    this.setState({ show: true });
    this.getGuestDashboardDetails()

  }

  // added by dasprakash on 26-10-2023 jira-1677
  getGuestDashboardDetails() {
    let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType + '&TableNumber=' + tableNo + '&guestmobileNumber=' + mobileNo;
    fetch(Config.basePOSUrl + 'GetGuestAppDashBoardDetails' + urlParam, {
      method: 'GET',
      headers: {
        Authorization: "Bearer " + token,
        'content-type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(response => {
        if (response.errorCode === 0) {
          this.setState({ groupMemberList: response.response.groupDetails.guestDetails });
        }
        else {
          this.showMessage(response.message, "danger");
        }
      })
      .catch(err => {
        this.showMessage('An error occured - ' + err, "danger");
      });
  }
  // added by dasprakash on 27-10-2023 jira-1677
  goToHelpRequest() {
    // this.setState({ loading: true });
    this.setState({ HelpRequestShow: true });
    setTimeout(() => {
      // this.setState({ loading: false });
      this.handleRequest();

    }, 500);
  };
  // added by dasprakash on 27-10-2023 jira-1677
  handleRequest(_this) {
    if (reqeustRef.current) {
      reqeustRef.current.handleRequest();

    }
  }
  // added by dasprakash on 27-10-2023 jira-1677
  callBackRequest(msg, type) {
    // //console.log("from header");
    // //console.log(msg + '-' + type);

    setTimeout(() => {
      if (type === 1) {
        // this.showMessage(msg, "success");
        this.setState({ HelpRequestShow: false });
      }

      else {
        // this.showMessage(msg, "danger");
        this.setState({ HelpRequestShow: false });
      }
    }, 1500);
  }
  accessMode() {
    // //console.log(this.props.callbackAccessModeHome)
    callbackAccessModeHome = this.props.callbackAccessModeHome
    this.setState({ accessModeShow: true })
  }
  callbackAccessModeHeader() {
    //console.log("callbackAccessModeHeader");
    callbackAccessModeHome()
    setTimeout(() => {
      this.setState({ accessModeShow: false, clickingServicemode: "false" });
    }, 500);

    // //console.log(this.props.callbackAccessModeHome)
  }


  render() {
    const activeStyle = { backgroundColor: '#18A43726', borderLeft: "14px solid #18A437C7" };

    return (
      <div>
        {/* // added by dasprakash on 26-10-2023 jira-1677 */}
        <Modal
          className="group-members-v2"
          style={{ borderRadius: "11px", top: "192px", maxHeight: "50vh" }}
          show={this.state.show}
          onHide={this.handleClose}>
          <Modal.Header style={{ backgroundColor: "#18A437", color: "#FFF", position: "sticky", top: "0px", zIndex: "3" }} >
            <Modal.Title style={{ fontSize: "16px" }}>Group Members({this.state.groupMemberList.length})</Modal.Title>
            <svg onClick={this.handleClose} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18 6L6 18" stroke="#EFF1F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M6 6L18 18" stroke="#EFF1F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: "#FFF", padding: "0px" }}>
            {
              this.state.groupMemberList.length > 0 &&
              <div >
                {
                  this.state.groupMemberList.map(m =>
                    <span key={m.guestMobileNumber}>

                      {m.guestName === '' &&
                        <div style={{ display: "flex", alignItems: "center", padding: "7.5px 15px" }}>
                          <svg style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 3.33333C4.16667 2.44928 4.51786 1.60143 5.14298 0.976311C5.7681 0.351189 6.61594 0 7.5 0C8.38406 0 9.2319 0.351189 9.85702 0.976311C10.4821 1.60143 10.8333 2.44928 10.8333 3.33333C10.8333 4.21739 10.4821 5.06523 9.85702 5.69036C9.2319 6.31548 8.38406 6.66667 7.5 6.66667C6.61594 6.66667 5.7681 6.31548 5.14298 5.69036C4.51786 5.06523 4.16667 4.21739 4.16667 3.33333ZM4.16667 8.33333C3.0616 8.33333 2.00179 8.77232 1.22039 9.55372C0.438987 10.3351 0 11.3949 0 12.5C0 13.163 0.263392 13.7989 0.732233 14.2678C1.20107 14.7366 1.83696 15 2.5 15H12.5C13.163 15 13.7989 14.7366 14.2678 14.2678C14.7366 13.7989 15 13.163 15 12.5C15 11.3949 14.561 10.3351 13.7796 9.55372C12.9982 8.77232 11.9384 8.33333 10.8333 8.33333H4.16667Z" fill="#18A437" />
                          </svg>
                          {/* <i className="fas fa-mobile-alt"></i> */}
                          <span style={{ fontSize: "14px", fontWeight: "500", color: "#000" }}>{m.guestMobileNumber}{m.isMainGuest &&
                            <span style={{ color: "#18A437" }}> (Main)</span>}{(m.guestMobileNumber === mobileNo && !m.isMainGuest) &&
                              <span style={{ color: "#0060ff" }}> (You)</span>
                            }
                          </span>
                        </div>
                      }

                      {m.guestName !== '' &&
                        <div style={{ display: "flex", alignItems: "center", padding: "7.5px 15px" }}>
                          <svg style={{ marginRight: "10px" }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 3.33333C4.16667 2.44928 4.51786 1.60143 5.14298 0.976311C5.7681 0.351189 6.61594 0 7.5 0C8.38406 0 9.2319 0.351189 9.85702 0.976311C10.4821 1.60143 10.8333 2.44928 10.8333 3.33333C10.8333 4.21739 10.4821 5.06523 9.85702 5.69036C9.2319 6.31548 8.38406 6.66667 7.5 6.66667C6.61594 6.66667 5.7681 6.31548 5.14298 5.69036C4.51786 5.06523 4.16667 4.21739 4.16667 3.33333ZM4.16667 8.33333C3.0616 8.33333 2.00179 8.77232 1.22039 9.55372C0.438987 10.3351 0 11.3949 0 12.5C0 13.163 0.263392 13.7989 0.732233 14.2678C1.20107 14.7366 1.83696 15 2.5 15H12.5C13.163 15 13.7989 14.7366 14.2678 14.2678C14.7366 13.7989 15 13.163 15 12.5C15 11.3949 14.561 10.3351 13.7796 9.55372C12.9982 8.77232 11.9384 8.33333 10.8333 8.33333H4.16667Z" fill="#18A437" />
                          </svg>
                          {/* <i className="fas fa-user-alt"></i> */}
                          <span style={{ fontSize: "14px", fontWeight: "500", color: "#000" }}>{m.guestName}{m.isMainGuest &&
                            <span style={{ color: "#18A437" }}> (Main)</span>}{(m.guestMobileNumber === mobileNo && !m.isMainGuest) &&
                              <span style={{ color: "#0060ff" }}> (You)</span>
                            }
                          </span>
                        </div>
                      }
                    </span>
                  )
                }

              </div>
            }
          </Modal.Body>

        </Modal>
        {/* // added by dasprakash on 27-10-2023 jira-1677 */}
        {this.state.HelpRequestShow &&
          <Request
            ref={reqeustRef}
            callBackRequest={this.callBackRequest}
            token={sessionStorage.getItem('user-token')}
            mobileNo={sessionStorage.getItem('user-mobileno')}
            propertyId={sessionStorage.getItem('user-propertyid')}
            outletCode={sessionStorage.getItem('user-outletcode')}
            tableNo={sessionStorage.getItem('user-tableno')}
            serviceType={sessionStorage.getItem('user-servicetype')}
          ></Request>
        }
        {/* // added by dasprakash on 11-11-2023 jira-1744 */}

        {this.state.accessModeShow &&
          <Changeaccessmode
            accessModeShow={this.state.accessModeShow}
            callbackAccessMode={this.callbackAccessModeHeader}
            clickingServicemode={this.state.clickingServicemode}

          />
        }

        {/* style added by dasprakash on 23-08-2023 jira-1302 */}
        {/* <nav style={{ background: "#F1F6FE", borderRadius: "13px" }} id="sidebarleft" className="sidenav"> */}
        {/* level2 change */}
        {/* //Commended by vijay on 30-10-2023 */}
        {/* <nav style={{ background: "#F1F6FE", borderRadius: "0px 13px 13px 0px" }} id="sidebarleft" className="sidenav"> */}
        {/* //Added by vijay on 30-10-2023 */}
        <nav style={{ background: "#F1F6FE", borderRadius: "0px 13px 13px 0px", border: "0.5px solid #4287FA", borderBottom: "2px solid #4287FA" }} id="sidebarleft" className="sidenav">



          {/* commented by dasprakash on 05-09-08-2023 jira-1302 */}
          {/* <div id="dismiss">
            <i style={{ color: "#000" }} className="fas fa-times"></i>
          </div> */}
          {/*  */}

          {/* commented by dasprakash on 23-08-2023 jira-1302 */}
          {/* <div className="sidebar-header">
            <img className="sidebar-logo" src={sidemenulogo} alt="lucid-contactless-food-order" />
          </div> */}
          {this.props.loggedIn === "true" &&
            <div style={{ display: "contents" }} className="sdprofile">
              <div className="sdp-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="77" height="81" viewBox="0 0 77 81" fill="none"><circle cx="38.5" cy="30.166" r="12.5" stroke="#33363F" strokeWidth="2" strokeLinecap="round" /><circle cx="38.5" cy="38.5" r="37.5" stroke="#33363F" strokeWidth="2" /><path d="M62.7691 66.8426C63.1924 66.6101 63.392 66.1072 63.2182 65.6566C61.6344 61.5498 58.5072 57.931 54.2559 55.3051C49.7358 52.5133 44.1975 51 38.5 51C32.8025 51 27.2642 52.5133 22.7441 55.3051C18.4928 57.931 15.3656 61.5498 13.7818 65.6566C13.608 66.1072 13.8076 66.6101 14.2309 66.8425L19.2498 69.598C31.2392 76.1804 45.7608 76.1804 57.7502 69.598L62.7691 66.8426Z" fill="#33363F" /></svg>
              </div>

              <div style={{ marginTop: "80px", marginLeft: "26px", marginBottom: "10px" }} className="sdp-right">
                <p style={{ color: "#000", fontWeight: "800", height: "4px" }}>{sessionStorage.getItem("guest-name")}</p>
                <div style={{ color: "#7F7F7F", }} className="sd-name">{sessionStorage.getItem("user-mobileno")}</div>
                {/* <div className="sd-status">LU-001</div> */}
              </div>
              <p style={{ borderBottom: "2px solid #D9D9D9" }}></p>
            </div>

          }
          {(this.props.loggedIn === "true" && this.props.clmenu === true) &&
            <ul className="list-unstyled components">
              {menuItems.map(mnuItem =>
                // <li style={{ fontWeight: "700" }} key={mnuItem.menuId} className="mnuclk side-menu-li">
                // level2 change

                <li style={{ fontWeight: "700", borderBottom: "0px" }} key={mnuItem.menuId} className="mnuclk side-menu-li">
                  {/* // condition added by dasprakash on 25-10-2023 jira-1677 */}
                  {mnuItem.menuId === 4 || mnuItem.menuId === 7 ?
                    <div>
                      {mnuItem.menuId === 4 ?
                        (serviceType === "FINE-DINE" && digtalMode === "false") && (
                          <li style={{ color: "#212529", padding: "5px 20px", fontSize: "20px", fontWeight: "500", borderLeft: "14px solid #F1F6FE" }}
                            onClick={this.handleShow}>
                            {mnuItem.menuName }
                          </li>
                        )
                        :
                        (serviceType === "FINE-DINE") && (
                          <li style={{ color: "#212529", padding: "5px 20px", fontSize: "20px", fontWeight: "500", borderLeft: "14px solid #F1F6FE" }}
                            onClick={this.goToHelpRequest}
                          >
                            {mnuItem.menuName}
                          </li>
                        )
                      }

                    </div>
                    :
                    <div>
                      {/* for change outlet and service mode */}
                      {mnuItem.menuId === 5 || mnuItem.menuId === 6 ?
                        <div>
                          {mnuItem.menuId === 5 ?
                            <div>
                              {(this.state.outletLevel && digtalMode === "false") &&
                                <li style={{ color: "#212529", padding: "5px 20px", fontSize: "20px", fontWeight: "500", borderLeft: "14px solid #F1F6FE" }}
                                  onClick={this.accessMode}>
                                  {mnuItem.menuName}
                                </li>
                              }
                            </div>

                            :
                            <div>
                              {(this.state.serviceLevel && digtalMode === "false") &&
                                <li style={{ color: "#212529", padding: "5px 20px", fontSize: "20px", fontWeight: "500", borderLeft: "14px solid #F1F6FE" }}
                                  onClick={() => {
                                    this.accessMode();
                                    this.setState({ clickingServicemode: "true" });

                                  }}>
                                  {mnuItem.menuName}
                                </li>
                              }
                            </div>
                          }
                        </div>
                        :
                        <div>
                          {/* for change outlet and service mode */}
                          {mnuItem.menuId === 2 || mnuItem.menuId === 3 ?
                            <div>
                              {(mnuItem.menuId === 2 && digtalMode === "false") ?
                                <div>
                                  {(digtalMode === "false") &&
                                    <NavLink
                                      style={this.state.active === mnuItem.menuName ? activeStyle : {}}
                                      onClick={this._handleClick.bind(this, mnuItem.menuName ,urlPattern)}
                                      to="#"
                                    >
                                      <i className={mnuItem.iconsName}></i>  {mnuItem.menuName}
                                    </NavLink>
                                  }
                                </div>
                                :
                                <div>
                                  {(mnuItem.menuId === 3 && digtalMode === "false") ?
                                    <NavLink
                                      style={this.state.active === mnuItem.menuName ? activeStyle : {}}
                                      onClick={this._handleClick.bind(this, mnuItem.menuName ,urlPattern)}
                                      to="#"
                                    >
                                      <i className={mnuItem.iconsName}></i>  {mnuItem.menuName}
                                    </NavLink>
                                    :
                                    <div></div>
                                  }
                                </div>
                              }
                            </div>
                            :
                            <NavLink
                              style={this.state.active === mnuItem.menuName ? activeStyle : {}}
                              onClick={this._handleClick.bind(this, mnuItem.menuName ,urlPattern)}
                              to="#"
                            >
                              <i className={mnuItem.iconsName}></i>  {mnuItem.menuName}
                            </NavLink>

                          }
                        </div>
                        // <NavLink
                        //   style={this.state.active === mnuItem.menuName ? activeStyle : {}}
                        //   onClick={this._handleClick.bind(this, mnuItem.menuName)}
                        //   to={mnuItem.routePath}
                        // >
                        //   <i className={mnuItem.iconsName}></i>  {mnuItem.menuName}
                        // </NavLink>

                      }
                    </div>

                  }
                </li>

              )}
            </ul>
          }

          {(this.props.loggedIn === "false" && this.props.clmenu === true) &&
            <ul className="list-unstyled components">
              <div style={{ padding: "5px 13px", display: "flex", alignItems: "center", marginBottom: "65px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="77" height="81" viewBox="0 0 77 81" fill="none"><circle cx="38.5" cy="30.166" r="12.5" stroke="#33363F" strokeWidth="2" strokeLinecap="round" /><circle cx="38.5" cy="38.5" r="37.5" stroke="#33363F" strokeWidth="2" /><path d="M62.7691 66.8426C63.1924 66.6101 63.392 66.1072 63.2182 65.6566C61.6344 61.5498 58.5072 57.931 54.2559 55.3051C49.7358 52.5133 44.1975 51 38.5 51C32.8025 51 27.2642 52.5133 22.7441 55.3051C18.4928 57.931 15.3656 61.5498 13.7818 65.6566C13.608 66.1072 13.8076 66.6101 14.2309 66.8425L19.2498 69.598C31.2392 76.1804 45.7608 76.1804 57.7502 69.598L62.7691 66.8426Z" fill="#33363F" /></svg>
                <p className='login_v2' style={{ fontSize: "20px", textDecoration: "underline", marginLeft: "10px", fontWeight: "500" }}>
                  <NavLink
                    style={{ textDecoration: "underline" }}
                    onClick={this._handleClick.bind(this, "login" , urlPattern)}
                    to="#"
                  >
                    {/* <i className={mnuItem.iconsName}></i> */}
                    Login
                  </NavLink>
                </p>
              </div>
              <p style={{ borderBottom: "2px solid #D9D9D9" }}></p>

              <li key={1} className="mnuclk side-menu-li">
                <NavLink
                  style={this.state.active === "Home" ? activeStyle : {}}
                  onClick={this._handleClick.bind(this, "home", urlPattern)}
                  to="#"
                >
                  {/* <i className={mnuItem.iconsName}></i> */}
                  Home
                </NavLink>
                {/* // Added by vijay on 25-11-2023  service mode condition */}
                {serviceType !== "FINE-DINE" && (
                  <div>
                    <p style={{ color: "#212529", padding: "5px 20px", fontSize: "20px", fontWeight: "500", borderLeft: "14px solid #F1F6FE", marginBottom: "0px" }}
                      onClick={this.accessMode}>
                      Change Outlet
                    </p>
                    <p
                      style={{ color: "#212529", padding: "5px 20px", fontSize: "20px", fontWeight: "500", borderLeft: "14px solid #F1F6FE" }}
                      onClick={() => {
                        this.accessMode();
                        this.setState({ clickingServicemode: "true" });
                      }}
                    >
                      Change Service Mode
                    </p>
                  </div>
                )}
              </li>


              {/* commented by dasprakash on 05-09-2023 jira-1302 */}
              {/* <li style={{ fontSize: "18px",fontWeight:"500" }} key={10} className="mnuclk side-menu-li">
                <NavLink
                  style={this.state.active === "Login" ? activeStyle : {}}
                  onClick={this._handleClick.bind(this, "Login")}
                  to="/login"
                >
                  Login
                </NavLink>
              </li> */}

            </ul>
          }
        </nav>
        <nav style={{ width: "10%", top: "4px", left: "4px" }} className="navbar navbar-expand-lg navbar-light bg-header">
          <div className="container-fluid">
            {this.props.loggedIn && this.props.mobileApp === true ? (
              /* style added by dasprakash on 23-08-2023 jira-1302 */
              <button type="button" id="sidebarleftbutton" className="btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                  <g filter="url(#filter0_d_1672_2095)">
                    <rect x="2" y="2" width="40" height="40" rx="8" fill="#6F7072" fillOpacity="0.07" /> {/* Corrected fill-opacity to fillOpacity */}
                    <path d="M13.4277 18V20H31.7134V18H13.4277ZM13.4277 24V26H31.7134V24H13.4277Z" fill="black" />
                  </g>
                  <defs>
                    <filter id="filter0_d_1672_2095" x="0" y="0" width="46" height="46" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"> {/* Corrected color-interpolation-filters to colorInterpolationFilters */}
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="1" dy="1" />
                      <feGaussianBlur stdDeviation="1.5" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1672_2095" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1672_2095" result="shape" />
                    </filter>
                  </defs>
                </svg>
              </button>

            ) : null}

            {/* commented by dasprakash on 22-08-2023 jira-1302 */}
            {/* <div onClick={//console.log(this.props)} className="logo home-logo" style={{ fontSize: "15px", paddingTop: "6px" }}>{this.props.pageTitle !== '' ? this.props.pageTitle : "Welcome to " + (sessionStorage.getItem("user-outletname") !== "NONE" ? sessionStorage.getItem("user-outletname") : sessionStorage.getItem("user-propertyname"))}</div> */}

            {/* <div style={{ whiteSpace: "pre-wrap" }}>{this.state.pageTitle !== '' ? this.state.pageTitle : "Welcome to " + sessionStorage.getItem("user-outletname")}</div> */}

            {/* <button type="button" id="sidebarrightbutton" className="btn">
      <i className="fa fa-user"></i>
    </button> */}
          </div>
        </nav>




      </div >
    );
  }
}

export default withRouter(Header);