import React, { Component } from 'react';
import './addressentry.scss';
 import Config from '../../data/SiteConfig';
let token = '';
let mobileNo = '';
let outletCode = '';
let  urlPattern = '';
export default class AddressEntry extends Component {
    constructor(props) {
        super(props)
        this.handleAddressEntry = this.handleAddressEntry.bind(this);
        this.getAddressTypeList = this.getAddressTypeList.bind(this);
        this.state = {    
            loading: false,      
            guestRequestList: [],
            requestMasterList: [],
            message: '',
            errorCode:0 ,   
            alertMessage: '',
            msgShow: false,
            eBillUrl: '',
            isPay: false,
            billAmount: 0,
            posBillId: '',
            addressTypeList: [],
            fields: {},
            addressTypeId: 0,
            addressType: '',
            addressId: 0,
        }
    }

    componentDidMount() {       
        
        token = sessionStorage.getItem('user-token');
        outletCode = sessionStorage.getItem('user-outletcode');
        mobileNo = sessionStorage.getItem('user-mobileno');
        window.scrollTo(0, 0);      
        // Fill Datea
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }

        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
    };

    getAddressTypeList() {
        this.setState({ loading: true });
        fetch(Config.baseCRMUrl + 'GetAddressTypeList', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                this.setState({ loading: false });
                if (response.errorCode === 0) {
                 this.setState({ addressTypeList: response.response.addressTypes });
                    setTimeout(() => {
                        this.inputElement.click();
                       
                    }, Config.minMsgTimeout);
                   // document.getElementById('input-address1').focus();   
                }
                else {
                    this.setState({ loading: false });
                    //this.showMessage(response.message, "danger");
                   // this.setState({ addressTypeList: [] });
                   setTimeout(() => {
                    this.inputElement.click();
                }, Config.minMsgTimeout);
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMessage(err, "danger");
            });
    };

    handleSaveAddress() {

        this.setState({ loading: true });

        if (!this.handleValidation()) {
            this.setState({ loading: false });
            return false;
        }

        let postObj = {
            GuestMobileNo: mobileNo,
            OutletCode: outletCode,
            AddressId: this.state.addressId,
            AddressTypeId: this.state.addressTypeId,
            AddressLine1: this.state.fields["address1"],
            AddressLine2: this.state.fields["address2"],
            AddressLine3: this.state.fields["address3"],
            AreaCode: 0,
            AreaName: this.state.fields["areaname"],
            CityCode: 0,
            CityName: this.state.fields["city"],
            ZipCode: this.state.fields["zipcode"],
            Landmark: this.state.fields["landmark"]
        }
        
        fetch(Config.baseCRMUrl + 'SaveGuestAddress', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)
        })
            .then(res => res.json())
            .then(response => {
                if (response.errorCode === 0) {
                   // this.showMessage(response.message, "success");  state.message
                   this.setState({ message: response.message,errorCode:response.errorCode }); 
                    this.setState({ loading: true });
                    // setTimeout(() => {
                    //     this.props.history.push('/chooseaddress');
                    // }, 2500);
                    setTimeout(() => {
                        this.setState({ message: '' });
                        this.inputElement.click();
                              
                    }, Config.minMsgTimeout);   
                    this.props.callBackAddress();  
                }
                else {
                    this.setState({ loading: false });
                    // this.showMessage(response.message, "danger");
                    setTimeout(() => {
                        this.setState({ message: '' });
                        this.inputElement.click();
                        
                    }, Config.minMsgTimeout);
                    this.props.callBackAddress();   
                }
            })
            .catch(err => {
                this.setState({ loading: false });
                this.showMessage(err, "danger");
            });
    };

    goBack() {
        this.props.history.push('/chooseaddress' + urlPattern);
    };

    handleChange(field, e) {

        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    };

    handleValidation() {
        let fields = this.state.fields;
        let formIsValid = true;

        //Address 1
        if (!fields["address1"]) {
            formIsValid = false;
           // this.showMessage("House / Flat / Block No.  cannot be empty", "danger");     // /Commended by vijay on 20-11-2023Commended by vijay on 20-11-2023
           // Added by vijay on 20-11-2023
           this.setState({ message: "House / Flat / Block No. cannot be empty", errorCode: "danger" });         
            document.getElementById('input-address1').focus();
            setTimeout(() => {
                this.setState({ message: '', errorCode: 0 });
            }, Config.minMsgTimeout); 
            return formIsValid;
        }
        //Area Name
        if (!fields["areaname"]) {
            formIsValid = false;
           // this.showMessage("Area Name  cannot be empty", "danger");  // /Commended by vijay on 20-11-2023
           // Added by vijay on 20-11-2023
           this.setState({ message: "Area Name cannot be empty", errorCode: "danger" });
            document.getElementById('input-areaname').focus();
            setTimeout(() => {
                this.setState({ message: '', errorCode: 0 });
            }, Config.minMsgTimeout); 
            return formIsValid;
        }

        //City
        if (!fields["city"]) {
            formIsValid = false;
           // this.showMessage("City cannot be empty", "danger");  // /Commended by vijay on 20-11-2023
           // Added by vijay on 20-11-2023
           this.setState({ message: "City cannot be empty", errorCode: "danger" });
            document.getElementById('input-city').focus();
            setTimeout(() => {
                this.setState({ message: '', errorCode: 0 });
            }, Config.minMsgTimeout); 
            return formIsValid;
        }

        // Zipcode         
        if (!fields["zipcode"]) {
            formIsValid = false;
           // this.showMessage("Zipcode  cannot be empty", "danger");   // /Commended by vijay on 20-11-2023
            // Added by vijay on 20-11-2023
           this.setState({ message: "Zipcode cannot be empty", errorCode: "danger" });
            document.getElementById('input-zipcode').focus();
            setTimeout(() => {
                this.setState({ message: '', errorCode: 0 });
            }, Config.minMsgTimeout); 
            return formIsValid;
        }

        // Type
        if (this.state.addressTypeId === 0) {
            formIsValid = false;
            // /Commended by vijay on 20-11-2023
           // this.showMessage("Please choose the save as", "danger");
            // Added by vijay on 20-11-2023
           this.setState({ message: "Please choose the save as", errorCode: "danger" });
           setTimeout(() => {
            this.setState({ message: '', errorCode: 0 });
        }, Config.minMsgTimeout); 
            return formIsValid;
        }

        return formIsValid;

    };

    handleChangeAddressType(id, name) {
       // //console.log(id);
        this.setState({ addressTypeId: id });
        this.setState({ addressType: name });
    };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

     handleAddressEntry() {       
        //this.setState({ message: '' });
        let fields = this.state.fields;
        //console.log(this.props);
        fields["Config.formMode"] = this.props.mode;
        fields[0] = this.props.addressId;
        fields["Home"] = this.props.addressType
        fields["address1"] = this.props.addressLine1;       
        fields["address2"] = this.props.addressLine2;
        fields["address3"] = this.props.addressLine3;
        fields["areaname"] = this.props.areaName;
        fields["city"] = this.props.cityName;
        fields["landmark"] = this.props.landmark;
        fields["zipcode"] = this.props.zipCode;
        this.setState({ addressTypeId: this.props.addressTypeId, addressType: this.props.addressType, addressId: this.props.addressId, fields });

      
        this.getAddressTypeList();
       

    }

     handleEditAddress = () => { 
         
        let fields = this.state.fields;
      //  //console.log(this.props);
        fields["Config.formMode"] = this.props.mode;
        fields[0] = this.props.addressId;
        fields["Home"] = this.props.addressType
        fields["address1"] = this.props.addressLine1;       
        fields["address2"] = this.props.addressLine2;
        fields["address3"] = this.props.addressLine3;
        fields["areaname"] = this.props.areaName;
        fields["city"] = this.props.cityName;
        fields["landmark"] = this.props.landmark;
        fields["zipcode"] = this.props.zipCode;
        this.setState({ addressTypeId: this.props.addressTypeId, addressType: this.props.addressType, addressId: this.props.addressId, fields });

        this.getAddressTypeList();

      };
    render() {
       
        return (
            <>
                <div className="section-address-entry"> 
                    <div className="menu-goup">
                        <button className="theme-button btn" style={{ display: "none" }} ref={input => this.inputElement = input} data-toggle="modal" data-target="#address-entry-modal"><i className="fa fa-utensils sidebar-icon"></i> MENU1</button>
                    </div>
                    <div className="modal fade" id="address-entry-modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content animate-bottom">
                                <div style={{ display: "flex", flexDirection: " column-reverse", alignItems: "center",borderBottom:"0px" }} className="modal-header">                                   
                                    <svg
                                        data-dismiss="modal" aria-label="Close" style={{ marginLeft: "auto", marginRight: "auto", marginBottom: "10px" }} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM10.2235 29.7706C9.73538 29.2824 9.73538 28.491 10.2235 28.0028L18.2322 19.9941L10.2309 11.9928C9.74277 11.5047 9.74277 10.7132 10.2309 10.2251C10.7191 9.73691 11.5105 9.73691 11.9987 10.2251L20 18.2264L28.0013 10.2251C28.4895 9.73691 29.2809 9.73691 29.7691 10.2251C30.2572 10.7132 30.2572 11.5047 29.7691 11.9928L21.7678 19.9941L29.7765 28.0028C30.2646 28.491 30.2646 29.2824 29.7765 29.7706C29.2883 30.2588 28.4969 30.2588 28.0087 29.7706L20 21.7619L11.9913 29.7706C11.5031 30.2588 10.7117 30.2588 10.2235 29.7706Z" fill="black" />
                                    </svg> 
                                    {/* // added by vijay on 20-11-2023 */}
                                    {this.state.message === '' ? "" :
                                        <span style={{
                                            color: this.state.errorCode === 0 ? "#18A437" : "#F00"
                                            , backgroundColor: this.state.errorCode === 0 ? "#DCF1E1" : "#F1E2E2", padding: "9px 17px 8.888px 18px", borderRadius: "5px", marginBottom: "10px"
                                        }}>{this.state.message}</span>
                                    }                                  
                                </div>                            
                                <div className="modal-body" id="div-address-entry-body" >
                                <div className="address-entry" style={{ background: "#FFF", borderRadius: "11px 11px 11px 11px" }}>
                                <div className="stt-form">
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="type" style={{fontSize:"16px", color:"#000000"}}>Save Address As <span style={{ color: "#FF0000" }}>*</span></label>
                                            <div>
                                                <ul>
                                                    {this.state.addressTypeList.map(t =>
                                                        <li key={"a-t-btn-" + t.addressTypeId}>
                                                            {(t.addressTypeId === this.state.addressTypeId) && <button className="theme-button btn address-type-btn selected-btn" onClick={this.handleChangeAddressType.bind(this, t.addressTypeId, t.addressType)}>{t.addressType}</button>}
                                                            {(t.addressTypeId !== this.state.addressTypeId) && <button className="theme-button btn address-type-btn" onClick={this.handleChangeAddressType.bind(this, t.addressTypeId, t.addressType)}>{t.addressType}</button>}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="address1">House / Flat / Block No. <span style={{ color: "#f00" }}>*</span></label>
                                            <input type="text" className="form-control" name="input-address1" id="input-address1" maxLength="100" onChange={this.handleChange.bind(this, "address1")}  value={this.state.fields["address1"]} ref={(input) => { this.addressInput = input; }} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="address2">Street / Building Name</label>
                                            <input type="text" className="form-control" name="input-address2" id="input-address2" maxLength="100" onChange={this.handleChange.bind(this, "address2")} value={this.state.fields["address2"]} />
                                        </div>
                                    </div>
                                    {/* Commended by vijay on 10-11-2023 */}
                                    {/* <div className="form-group">
                                        <div className="col-xs-12">
                                            <input type="text" className="form-control" name="input-address3" id="input-address3" maxLength="100" onChange={this.handleChange.bind(this, "address3")} value={this.state.fields["address3"]} />
                                        </div>
                                    </div> */}
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="areaname">Area Name <span style={{ color: "#f00" }}>*</span></label>
                                            <input type="text" className="form-control" name="input-areaname" id="input-areaname" maxLength="100" onChange={this.handleChange.bind(this, "areaname")} value={this.state.fields["areaname"]} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="city">City <span style={{ color: "#f00" }}>*</span></label>
                                            <input type="text" className="form-control" name="input-city" id="input-city" maxLength="100" onChange={this.handleChange.bind(this, "city")} value={this.state.fields["city"]} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="Zipcode">Zipcode <span style={{ color: "#f00" }}>*</span></label>
                                            <input type="text" className="form-control" name="input-zipcode" id="input-zipcode" maxLength="10" onChange={this.handleChange.bind(this, "zipcode")} value={this.state.fields["zipcode"]} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-xs-12">
                                            <label htmlFor="landmark">Landmark</label>
                                            <input type="text" className="form-control" name="input-landmark" id="input-landmark" maxLength="100" onChange={this.handleChange.bind(this, "landmark")} value={this.state.fields["landmark"]} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="save-btn" onClick={this.handleSaveAddress.bind(this)} style={{position:"sticky"}}>
                                Save Address
                            </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>

                </div >
            </>

        );
    }
    
}
