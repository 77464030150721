import React, { Component } from 'react';
import './ordersuccess.scss';
import LoadingAnimation from '../../components/checkmarkanimation';
import { isMobile } from 'react-device-detect';
import Config from '../../data/SiteConfig';
let urlPattern = '';
export default class GPaySuccess extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.gotoShopping = this.gotoShopping.bind(this);
        this.gotoMyOrders = this.gotoMyOrders.bind(this);
        this.state = {
            cartValue: 1500,
            loading: false,
            orderId: '',
            orderNo: '',
        }
    }
    componentWillMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        this.setState({
            orderId: "LUCID-Demo No",
            orderNo: "LUCID-Demo No"
        });
        sessionStorage.removeItem('cartList');
        sessionStorage.removeItem('modifierCartList');
        sessionStorage.removeItem('taxCartList');
        sessionStorage.removeItem('chargeCartList');

        window.scrollTo(0, 0);

    }
    componentDidMount() {
        // this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/items' + urlPattern);
        }, Config.minMsgTimeout);
    }
    goBack() {
        this.props.history.push('/home' + urlPattern);
    }
    gotoShopping() {
        this.props.history.push('/items' + urlPattern);
    }
    gotoMyOrders() {
        this.props.history.push('/myorders' + urlPattern);
    }
    render() {
        return (
            <div>
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-ordersuccess">
                            <div className="heading-page" ref="recommended">
                                {sessionStorage.getItem("user-propertyname")}
                                <span className="payment-txt">{sessionStorage.getItem("user-outletname")} - {sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")} </span>
                                {/* <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fas fa-arrow-left" aria-hidden="true"></i></div>
                                </div> */}
                            </div>
                            <div className="content-from">
                                <div className="message-details">
                                    <div className="content">
                                        <div><LoadingAnimation /></div>
                                        <div className="msg">Your order has been  <br /> placed successfully.</div>
                                        <div className="msg-order-id">Order No : <span style={{ fontSize: "16px", color: "#212529" }}>{this.state.orderNo}</span></div>
                                        {/* <div className="msg-order-id">Order Id : <span style={{fontSize: "16px", color: "#212529"}}>{this.state.orderId}</span></div> */}
                                        {/* <div><button className="theme-button btn" onClick={this.gotoShopping}>Continue Ordering </button></div> */}
                                        {/* <div className="btn-orders" onClick={this.gotoMyOrders}>Go to My Orders</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

}

