import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import sidemenulogo from './home-logo.png';

const menuItems = [
  { menuId: 1, menuName: 'Home', iconsName: 'fas fa-home', routePath: "/emHome" },
  // { menuId: 1, menuName: 'Menu', iconsName: 'fa fa-utensils sidebar-icon', routePath: "/emitems" },
  // { menuId: 2, menuName: 'About Us', iconsName: 'fas fa-exclamation-circle', routePath: "/emHome" },
  // { menuId: 3, menuName: 'Contact Us', iconsName: 'fa fa-phone sidebar-icon', routePath: "/emHome" },
  // { menuId: 4, menuName: 'Gallery', iconsName: 'fa fa-images sidebar-icon', routePath: "/emHome" },
  // { menuId: 2, menuName: 'My Bills', iconsName: 'fa fa-money', routePath: "/mybills" },
  // { menuId: 3, menuName: 'Contact Us', iconsName: 'fa fa-phone sidebar-icon', routePath: "/home" },
  { menuId: 5, menuName: 'Logout', iconsName: 'fa fa-sign-out-alt', routePath: "/logout" }
];

const imglogo = {
  width: "133px",
    height: "56px"
    // border: "1px solid #ddd"
}

//const config = require('../../data/SiteConfig');

class emHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "Receipts",      
      outletDetails: {},
      headerLogo: [
        {
          altName: "",
          sortOrder: 0,
          bannerType: 0,
          height: 0,
          imageName: "",
          imagePathName: "",
          primaryImageType: "",
          secondaryImageType: "",
          bannerName: "Logo",
          primaryImageUrl: "../assets/theme1/img/no-image/no-image-logo.png",
          secondaryImageUrl: "",
          status: 0,
          width: 0
        }
      ],

    }

  }
  componentDidMount()
  {
    
    this.setState({outletDetails: sessionStorage.getItem('user-outletDetails') != null ? JSON.parse( sessionStorage.getItem('user-outletDetails')) : {}});
    

  }

  _handleClick(name) {
    this.setState({ active: name });
  }
  render() {
    const activeStyle = { color: '#007bff' };
    return (
      <div>
        <nav id="sidebarleft" className="sidenav">
          <div id="dismiss">
            <i className="fas fa-time"></i>
          </div>
          <div className="sidebar-header">
            <img className="sidebar-logo" src={sidemenulogo} alt="lucid-contactless-food-order" />
          </div>
          {/* <div className="sdprofile">
            <div className="sdp-left">
              <img src="../assets/theme1/img/user-img.png" alt="profile" />
            </div>
            <div className="sdp-right">
              <div className="sd-name">{sessionStorage.getItem("user-mobileno")}</div>
               <div className="sd-status">LU-001</div> 
            </div>
          </div> */}
          <ul className="list-unstyled components">
            {
              menuItems.map(mnuItem =>
                <li key={mnuItem.menuId} className="mnuclk side-menu-li">
                  <NavLink
                    style={this.state.active === mnuItem.menuName ? activeStyle : {}}
                    onClick={this._handleClick.bind(this, mnuItem.menuName)}
                    to={mnuItem.routePath}
                  >
                    <i className={mnuItem.iconsName}></i> {mnuItem.menuName}
                  </NavLink>

                </li>
              )}            
          </ul>
        </nav>
        <nav className="navbar navbar-expand-lg navbar-light bg-header">
          <div className="container-fluid">
            <button type="button" id="sidebarleftbutton" className="btn">
              <i className="fa fa-bars"></i>
            </button>
            <div style={{margin: "0 auto"}}> <img style={imglogo} src={this.props.headerLogo[0].primaryImageUrl} alt="" /></div>
            {/* <div className="logo home-logo" style={{fontSize:"18px"}}> Welcome to {sessionStorage.getItem("user-outletname")}</div> */}
            {/* <button type="button" id="sidebarrightbutton" className="btn">
              <i className="fa fa-user"></i>
            </button> */}
          </div>
        </nav>

      </div>
    );
  }
}
export default emHeader