import React from 'react'
import './animate.scss';
export default function failed() {
    return (
        <div style={{textAlign: "center"}}>
            {/* //Modified by vijay on 26-10-2023- jira id -1067 */}
            <svg id="failureAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="33" height="33" stroke="#fff" color="#ec091f" viewBox="0 0 70 70">
                <circle id="failureAnimationCircle" cx="35" cy="35" r="33" stroke="fff" stroke-width="2" stroke-linecap="round" fill="#ec091f" />  
                <polyline className="failureAnimationCheckLine" stroke="fff" stroke-width="2" points="25,25 45,45" fill="transparent" />
                <polyline className="failureAnimationCheckLine" stroke="fff" stroke-width="2" points="45,25 25,45" fill="transparent" />
            </svg>
        </div>
    )
}