import React, { Component, Fragment } from 'react';
import './myorders.scss';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
import LoadingAnimation from '../../components/loadinganimation';
import ErrorAlert from '../../components/alertmessages';
import Config from '../../data/SiteConfig';
let guestLoggedIn = "false";
//let guestAddressId = 0;
let mobileNo = '';
let token = '';
let urlPattern = '';
//let outletDetailsStr = null;
//let outletDetails = {};
//let guestDetailsStr = null;
//let guestDetails = {};
export default class OrderTracking extends Component {
    constructor(props) {
        super(props)
        this.goBack = this.goBack.bind(this);
        this.handleOrderPrev = this.handleOrderPrev.bind(this);
        this.handleOrderNext = this.handleOrderNext.bind(this);
        this.state = {
            loading: false,
            alertMessage: '',
            msgShow: false,
            eBillUrl: '',
            isPay: false,
            billAmount: 0,
            posBillId: '',
            guestOrderList: [],
            selectedOrder: {
                orderDateTime: "",
                orderId: "",
                orderNo: "",
                orderStatus: "",
                orderStatusUpdatedTime: "",
                serviceType: "",
                tableNo: "",
                kotNo: ""
            },
            orders: [],
            selectedIndex: 1,
            orderItemDetails: [],
            collapse: true,
            orderIndex: 1
        }
    }

    componentDidMount() {
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        //outletDetailsStr = sessionStorage.getItem('user-outletDetails');
        // if (outletDetailsStr === undefined || outletDetailsStr === "" || outletDetailsStr === null) {
        //     this.props.history.push('/');
        //     return false;
        // }
        // else {
        //     outletDetails = JSON.parse(outletDetailsStr);
        // }
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        urlPattern = sessionStorage.getItem('url-pattern');
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        
        if (!sHelpers.inputParamValidation()) {
            this.props.history.push('/' + urlPattern);
        }
        // this.setState({ loading: true });

        window.scrollTo(0, 0);

        if (guestLoggedIn === "false") {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, Config.minMsgTimeout);
            return false;
        }

        //guestDetailsStr = sessionStorage.getItem('user-GuestDetails');
        token = sessionStorage.getItem('user-token');

        // if (guestDetailsStr === undefined || guestDetailsStr === "" || guestDetailsStr === null) {
        //     guestDetails = null;
        // }
        // else {
        //     guestDetails = JSON.parse(guestDetailsStr);
        // }
        //  this.timer = setInterval(() => this.getGuestOrderList(), 60000)// 1 miniutes
        this.getGuestOrderList();
    };

    // State transitions
    handleOrderNext() {
        //this.setState({ loading: true });
        // setTimeout(() => {
        this.setState({ collapse: true });
        var index = this.state.orders.findIndex(
            entry => entry.orderId === this.state.selectedOrder.orderId
        );
        if (this.state.orders.length > this.state.selectedIndex) {
            if ((this.state.orders.length - 1) === this.state.selectedIndex) {
                this.setState({ selectedOrder: this.state.orders[this.state.selectedIndex], selectedIndex: this.state.selectedIndex, orderIndex: this.state.orders.length });
                this.getGuestOrderItem(this.state.orders[this.state.selectedIndex].orderId);
            }
            else {
                this.setState({ selectedOrder: this.state.orders[index + 1], selectedIndex: this.state.selectedIndex + 1, orderIndex: this.state.orderIndex + 1 });
                this.getGuestOrderItem(this.state.orders[index + 1].orderId);
            }
        }
        // console.log(this.state.selectedIndex + '- ' + this.state.orders.length);
        // this.setState({ loading: false });
        // }, 500);


    }

    handleOrderPrev() {
        this.setState({ collapse: true });
        var index = this.state.orders.findIndex(
            entry => entry.orderId === this.state.selectedOrder.orderId
        );
        if (this.state.selectedIndex > -1) {
            if (this.state.selectedIndex === 1) {
                this.setState({ selectedOrder: this.state.orders[0], selectedIndex: 1, orderIndex: 1 });
                this.getGuestOrderItem(this.state.orders[0].orderId);
            }
            else {
                this.setState({ selectedOrder: this.state.orders[index - 1], selectedIndex: this.state.selectedIndex - 1, orderIndex: this.state.orderIndex - 1 });
                this.getGuestOrderItem(this.state.orders[index - 1].orderId);
            }
        }
        // console.log(this.state.selectedIndex);

    }

    goBack() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/items' + urlPattern);
        }, Config.minMsgTimeout);

    };

    getGuestOrderList() {

        //  console.log('order tracking...');

        mobileNo = sessionStorage.getItem('user-mobileno');
        let outletCode = sessionStorage.getItem('user-outletcode');
        this.setState({ loading: true });
        fetch(Config.baseGuestUrl + 'GetGuestOrderList?OutletCode=' + outletCode + '&GuestMobileNo=' + mobileNo, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                this.setState({ loading: false });
                //console.log(response);
                // console.log(this.state.selectedOrder);
                if (response.errorCode === 0) {
                    this.setState({ orders: response.response.orders, selectedOrder: response.response.orders.length > 0 ? response.response.orders[0] : this.state.selectedOrder, orderIndex: 1, selectedIndex: 1 });
                    //console.log(response.response.orders.length);                   
                    if (response.response.orders.length > 0) { this.setState({ collapse: true }); this.getGuestOrderItem(response.response.orders[0].orderId); };
                }
                else {
                    this.setState({ orders: [], selectedOrder: this.state.selectedOrder, orderIndex: 1, selectedIndex: 1 });
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                this.setState({ loading: false, orders: [], selectedOrder: this.state.selectedOrder, orderIndex: 1, selectedIndex: 1 });
                this.showMessage('Guest Order List - Failed to fetch - ' + err, "danger");
            });
    };

    getGuestOrderItem(OrderId) {
        //  console.log(this.state.collapse);
        //  this.setState({ collapse: this.state.collapse ? false : true });

        if (this.state.collapse) {
            //console.log('click');
            let outletCode = sessionStorage.getItem('user-outletcode');
            fetch(Config.baseGuestUrl + 'getguestorderitemdetails?OutletCode=' + outletCode + '&OrderId=' + OrderId, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
            })
                .then(res => res.json())
                .then(response => {
                    // this.setState({ loading: false });
                    // console.log(response);
                    if (response.errorCode === 0) {
                        this.setState({ orderItemDetails: response.response.orderDetails.items });
                    }
                    else {
                        this.setState({ orderItemDetails: [] });
                        this.showMessage(response.message, "danger");
                    }
                })
                .catch(err => {
                    this.setState({ loading: false, orderItemDetails: [] });
                    this.showMessage('Guest Order Item - Failed to fetch - ' + err, "danger");
                });
        }
    };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };
    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                <div id="content" className="top">
                    <div className="content-warp">
                        <div className="section-myorders">
                            <div className="heading-page" ref="recommended">
                                <span style={{ fontSize: "14px", fontWeight: 600 }}>Order Tracking</span>
                                {/* <span className="order-track-time-pay">12:48 PM | 10 items, ₹{238}</span> */}
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left"></i></div>
                                </div>
                                <div className="refresh-icon" onClick={this.getGuestOrderList.bind(this)}> <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i></div>

                            </div>
                            {this.state.orders.length > 0 &&
                                <div>

                                    {this.state.selectedOrder.orderStatus === "Placed" &&
                                        <div className="status-heading order-track-placed">
                                            {this.state.selectedOrder.orderStatus}
                                            <div style={{ fontSize: "12px" }}>at {this.state.selectedOrder.orderStatusUpdatedTime}</div>
                                        </div>}
                                    {(this.state.selectedOrder.orderStatus === "Accepted" || this.state.selectedOrder.orderStatus === "Preparing" || this.state.selectedOrder.orderStatus === "Prepared") &&
                                        <div className="status-heading order-track-accepted">
                                            {this.state.selectedOrder.orderStatus}
                                            <div style={{ fontSize: "12px" }}>at {this.state.selectedOrder.orderStatusUpdatedTime}</div>
                                        </div>}

                                    {this.state.selectedOrder.orderStatus === "FoodReady" &&
                                        <div className="status-heading order-track-foodready">
                                            {this.state.selectedOrder.orderStatus}
                                            <div style={{ fontSize: "12px" }}>at {this.state.selectedOrder.orderStatusUpdatedTime}</div>
                                        </div>}
                                    {this.state.selectedOrder.orderStatus === "Dispatched" &&
                                        <div className="status-heading order-track-dispatched">
                                            {this.state.selectedOrder.orderStatus}
                                            <div style={{ fontSize: "12px" }}>at {this.state.selectedOrder.orderStatusUpdatedTime}</div>
                                        </div>}
                                    {this.state.selectedOrder.orderStatus === "Delivered" &&
                                        <div className="status-heading order-track-delivered">
                                            {this.state.selectedOrder.orderStatus}
                                            <div style={{ fontSize: "12px" }}>at {this.state.selectedOrder.orderStatusUpdatedTime}</div>
                                        </div>}
                                    {this.state.selectedOrder.orderStatus === "Completed" &&
                                        <div className="status-heading order-track-completed">
                                            {this.state.selectedOrder.orderStatus}
                                            <div style={{ fontSize: "12px" }}>at {this.state.selectedOrder.orderStatusUpdatedTime}</div>
                                        </div>}
                                    {this.state.selectedOrder.orderStatus === "Rejected" &&
                                        <div className="status-heading order-track-rejected">
                                            {this.state.selectedOrder.orderStatus}
                                            <div style={{ fontSize: "12px" }}>at {this.state.selectedOrder.orderStatusUpdatedTime}</div>
                                        </div>}
                                    {this.state.selectedOrder.orderStatus === "Cancelled" &&
                                        <div className="status-heading order-track-cancelled">
                                            {this.state.selectedOrder.orderStatus}
                                            <div style={{ fontSize: "12px" }}>at {this.state.selectedOrder.orderStatusUpdatedTime}</div>
                                        </div>}
                                    {this.state.selectedOrder.orderStatus === "Forward" &&
                                        <div className="status-heading order-track-forward">
                                            {this.state.selectedOrder.orderStatus}
                                            <div style={{ fontSize: "12px" }}>at {this.state.selectedOrder.orderStatusUpdatedTime}</div>
                                        </div>}


                                    {this.state.selectedOrder.kotNo !== "" && <div className="order-track-order-no">#{this.state.selectedOrder.kotNo}</div>}
                                    {this.state.selectedOrder.kotNo === "" && <div className="order-track-order-no">-</div>}
                                    {this.state.selectedOrder.orderStatus === "Placed" && <div style={{ fontSize: "12px", textAlign: "center", fontWeight: "500", padding: "6px", color: "#000" }}>Order Received - waiting for to accept</div>}
                                    <div className="order-track-order-date-time">{this.state.selectedOrder.orderDateTime}</div>
                                    <div style={{ color: "#6c757d", textAlign: "center", fontWeight: "600" }}>Ref-Order No:- {this.state.selectedOrder.orderNo}</div>
                                    {/* <div className="order-track-order-date-time">DELIVERY</div>
                            <div className="order-track-order-date-time">Table No :- T14</div> */}
                                    {/* <div className="row-clear"></div> */}
                                    <div className="order-track-order-service"><span style={{ color: "#6c757d", textAlign: "center", fontWeight: "600" }}>Service Type :-</span> {this.state.selectedOrder.serviceType}</div>
                                    {this.state.selectedOrder.serviceType === "Table Service" && <div className="order-track-order-date-time">Table No :- {this.state.selectedOrder.tableNo}</div>}
                                    {/* <div className="item-view-heading">Item Details <span style={{ float: "right", paddingRight: "12px" }}><i className="fa fa-angle-right"></i></span></div> */}
                                    <div className="accordion" id="accordion2">
                                        <div className="accordion-heading" onClick={this.getGuestOrderItem.bind(this, this.state.selectedOrder.orderId)}>
                                            <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href={"#collapseOne-1"} >
                                                <span className="view-item-detail">View Item Details</span>
                                                <i className="fa fa-angle-right left-arrow" ></i>
                                            </a>
                                        </div>
                                        <div id={"collapseOne-1"} className="accordion-body collapse show" >
                                            {/* <div id={"collapseOne-1"} className={this.state.collapse ? "accordion-body collapse" : "accordion-body collapse show"} > */}
                                            <div className="accordion-inner">

                                                <table className="table">
                                                    <thead className="thead-txt">
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Qty</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="tbody-txt">
                                                        {
                                                            this.state.orderItemDetails.map((oitm, i) => {
                                                                return (
                                                                    <Fragment key={"orders-frg-tbl-itm-tr-" + (i + 1)}>
                                                                        <tr key={"orders-tbl-itm-tr-" + (i + 1)}>
                                                                            <td>{oitm.itemName.toLowerCase()}</td>
                                                                            <td>x{oitm.quantity}</td>
                                                                        </tr>
                                                                        {
                                                                            oitm.modifiers.map(gmd =>
                                                                                <tr>
                                                                                    <td className="modifier-item-txt">{gmd.toLowerCase()}</td>
                                                                                    <td className="modifier-item-row">x{oitm.quantity}</td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    </Fragment>
                                                                );
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>



                                    {/* <button className="theme-button btn order-track-view-item" onClick={this.goMyOrders}>View Details  <i className="fa fa-angle-right" ></i></button> */}
                                    {/* <div className="progress-track">
                                    <ul id="progressbar">
                                        <li className="step0 active " id="placed">Placed</li>
                                        <li className="step0 active text-center" id="accept">Accept</li>
                                        <li className="step0 active text-right" id="perparing">Perparing</li>
                                        <li className="step0 text-right" id="foodready">Food Ready</li>
                                        <li className="step0 text-right" id="dispatch">Dispatch</li>
                                        <li className="step0 text-right" id="dispatch">Dispatch</li>
                                        <li className="step0 text-right" id="dispatch">Dispatch</li>
                                        <li className="step0 text-right" id="dispatch">Dispatch</li>
                                    </ul>
                                </div> */}
                                    <div className="order-track-count-move">(<span style={{ color: "#d12f8b" }}>{this.state.orderIndex}</span> / <span style={{ color: "#078bd1" }}>{this.state.orders.length}</span>)</div>
                                    <div className="order-track-next-btn">
                                        <div className="order-track-next-btn-previous" onClick={this.handleOrderPrev}><i className="fa fa-angle-left"></i> Previous</div>
                                        <div className="order-track-next-btn-next" onClick={this.handleOrderNext}>Next <i className="fa fa-angle-right"></i></div>
                                    </div>

                                </div>
                            }
                            {this.state.orders.length === 0 &&
                                <div>
                                    <div className="no-menus-img"><img src="/img/no-image/no-orders.png" alt="lucid-no-orders" /></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    };

}