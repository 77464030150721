import React, { Component } from 'react';
import './login.scss';
import LoadingAnimation from '../../components/loadinganimation/login';
import Cookies from 'universal-cookie';
import ErrorAlert from '../../components/alertmessages';
import Config from '../../data/SiteConfig';
import { isMobile } from 'react-device-detect';
import sHelpers from '../../modules/helpers/statichelpers';
import Button from 'react-bootstrap/Button';
//import { config } from 'react-transition-group';

// commented by dasprakash on 16-10-2023 jira-1644

// const homeslidersettings = {
//     autoplay: true,
//     dots: true,
//     infinite: true,
//     arrows: false,
//     speed: 300,
//     slidesToShow: 1,
//     adaptiveHeight: false
// };
//import { createBrowserHistory } from 'history';
//import jumpTo from '../../modules/navigation';
//const history = createBrowserHistory();

// Image Part
//const location = history.location;

let tableNo = '';
let serviceType = '';
let orgnationId = '';
let propertyId = '';
let outletCode = '';
let token = '';
let exmainGuestMobileNo = '';
let outletDetails = null;
let olDetail = {};
let validateOTP = false;
let validatePIN = true;
let pageSession = '';
let statsId = '';
//let errorMessage='';
// eslint-disable-next-line
let groupSession = false;
let urlPattern = '';
class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // added by dasprakash on 16-10-2023 jira-1644
            outLetImage: '',
            errorMessage: '',

            loading: false,
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            guestName: '',
            sliderImages: [
                {
                    altName: "",
                    sortOrder: 0,
                    bannerType: 0,
                    height: 0,
                    imageName: "",
                    imagePathName: "",
                    primaryImageType: "",
                    secondaryImageType: "",
                    bannerName: "Slide 1",
                    primaryImageUrl: "/img/slider/login-df-lucid.jpg",
                    secondaryImageUrl: "",
                    status: 0,
                    width: 0
                }
            ],
            loggedIn: false,
            tableCheckedIn: false,

        };

    };

    componentWillMount() {
        // this.disableBackButton()
        // window.history.replaceState(null, null, window.location.href);

        // sessionStorage.removeItem("cartList");
        // sessionStorage.removeItem("taxCartList");
        // sessionStorage.removeItem("modifierCartList");
        // sessionStorage.removeItem("chargeCartList");
        // sessionStorage.removeItem("paymentInitiatedId");
        // sessionStorage.removeItem("paymentStatus");
        // sessionStorage.removeItem("vendorId");
        // sessionStorage.removeItem("vendorName");
        // sessionStorage.removeItem("order-instruction");

        // sessionStorage.removeItem("getCharges");
        // sessionStorage.removeItem("getTaxes");
        // sessionStorage.removeItem("getMenuGroups");
        // sessionStorage.removeItem("getModifierGroups");
        // sessionStorage.removeItem("getmenuItems");
        // sessionStorage.removeItem('otpReferenceId');
        // // sessionStorage.removeItem('user-outletDetails');
        // sessionStorage.removeItem('user-table-verified');
        // sessionStorage.removeItem("ebillUrl");
        // sessionStorage.removeItem("isPay");
        // sessionStorage.removeItem("posBillId");
        // added by dasprakash on 16-10-2023 jira-1644
        sessionStorage.removeItem("verified-otp");


        orgnationId = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        token = sessionStorage.getItem('user-token');
        statsId = sessionStorage.getItem('user-statsid');
        exmainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        outletDetails = sessionStorage.getItem('user-outletDetails');

        const parsedOutletDetails = JSON.parse(outletDetails);
        // added by dasprakash on 16-10-2023 jira-1644
        this.setState({ outLetImage: parsedOutletDetails.outletImageUrl })

        // Get PageSession
        pageSession = sessionStorage.getItem('login-page-session') == null ? 1 : parseInt(sessionStorage.getItem('login-page-session'));
        groupSession = sessionStorage.getItem('group-session') == null ? false : sessionStorage.getItem('group-session');

        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
                ////////console.log('mobile');
            }
        }

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        urlPattern = sessionStorage.getItem('url-pattern');
       
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationId === undefined || orgnationId === "" || orgnationId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletDetails === undefined || outletDetails === "" || outletDetails === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            olDetail = JSON.parse(outletDetails);
        }

        if (statsId === undefined || statsId === "" || statsId === null) {
            statsId = "0"
        }

        if (olDetail.enableGuestAppOrders === true) this.handleValidateTableStatus();

        this.getCMSDetails();
    };

    //added by dasprakash on 20-10-2023 jira-1645
    componentDidMount() {
        // Check if form data exists in local storage
        const storedMobileNo = sessionStorage.getItem('entered-mobile-no');
        const storedName = sessionStorage.getItem('entered-name');

        if (storedMobileNo) {
            this.setState({ mobileNo: storedMobileNo });
        }

        if (storedName) {
            document.getElementById('input-guest-name').value = storedName;
        }
    };

    disableBackButton = () => {
        // Disable the back button by replacing the current state
        window.history.replaceState(null, null, window.location.href);
    };

    // Added by vijay on 04-11-2023
    handleGuestNameChange = (event) => {
        this.setState({ guestName: event.target.value });
    };

    // Added by vijay on 04-11-2023
    handleClearText = () => {
        this.setState({ guestName: '' })
    };

    handleValidateTableStatus() {
        //  //////console.log(groupSession.toString());
        if (serviceType === "FINE-DINE") { // Table Check In               
            // let uParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
            let postObjGuest = {
                OutletId: propertyId + outletCode,
                TableNumber: tableNo
            };

            fetch(Config.basePOSUrl + 'GetGuestTableStatus', {
                method: 'POST',
                headers: {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObjGuest)
            })
                .then(res => res.json())
                .then(response => {
                    // console.log("GetGuestTableStatus Login Screen:", response);
                    //  console.log(response);
                    if (response.errorCode === 0) {
                        //Addded by vijay on 30-12-2023 Jira id -2075
                        this.setState({ tableCheckedIn: response.response.guestTableStatus.isCheckedIn });
                        if (response.response.guestTableStatus.isCheckedIn) {
                            sessionStorage.setItem('main-guest-mask-no', response.response.guestTableStatus.maskMobileNo);
                            //  console.log('main-guest-mask-no:', sessionStorage.getItem('main-guest-mask-no'));
                            sessionStorage.setItem('main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                            //  console.log('main-guest-no:', sessionStorage.getItem('main-guest-no'));
                            sessionStorage.setItem('main-guest-name', response.response.guestTableStatus.mainGuestName);
                            // console.log('main-guest-name:', sessionStorage.getItem('main-guest-name'));
                            sessionStorage.setItem('ex-main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                            //  console.log('ex-main-guest-no:', sessionStorage.getItem('ex-main-guest-no'));

                            if (olDetail.enableGroupOrdering) {
                                // group pin remove commented by dasprakash on 27-09-2023 jira-1493

                                // if (groupSession.toString() === "false") {
                                //     setTimeout(() => {
                                //         this.setState({ loading: false });
                                //         this.props.history.push('/jointable');
                                //     }, 500);
                                // }
                                // 
                                // group pin remove added by dasprakash on 27-09-2023 jira-1493
                                sessionStorage.setItem('group-session', true);
                                groupSession = true;
                                // 
                            }
                        } // End Check In  

                        //  Else condition Added by vijay on 30-01-2024 Jira id-2075 -Point No-3
                        else {
                            sessionStorage.setItem('main-guest-mask-no', response.response.guestTableStatus.maskMobileNo);
                            sessionStorage.setItem('main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                            sessionStorage.setItem('main-guest-name', response.response.guestTableStatus.mainGuestName);
                            sessionStorage.setItem('ex-main-guest-no', response.response.guestTableStatus.mainGuestMobileNo);
                        }
                    }
                })
                .catch(err => {
                    sessionStorage.removeItem("user-token");
                    sessionStorage.removeItem("user-mobileno");
                    //added by dasprakash on 20-10-2023 jira-1645
                    this.setState({ errorMessage: 'Table Status - Failed to fetch - ' + err })
                    this.setState({ loading: false });
                    // this.showMessage('Table Status - Failed to fetch - ' + err, "danger");
                    //////console.log('Table Status - Failed to fetch - ' + err);
                });

        } // End Service Type - Fine Dine
    };

    handlechange = event => {

        const val = event.target.value;
        // const max = 10
        //const maxLength = max.toString().length - 1

        //const newVal = val < max ? val : parseInt(val.toString().substring(0, maxLength))
        let mNo = val.substr(0, 10);
        ////////console.log(mNo);
        this.setState({ mobileNo: mNo });

        // //////console.log(mNo);
    };

    handleUpdateContactDetail() {
        let notes = {
            guestname: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
            modetype: olDetail != null ? olDetail.enableGuestAppOrders === true ? 1 : 0 : 0
        }

        let posturlObj = {
            organizationId: parseInt(orgnationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ContactNo: this.state.mobileNo,
            StatsId: parseInt(statsId),
            numofMenuAccess: 1,
            username: Config.linkUserName,
            password: Config.linkPassword,
            notes: JSON.stringify(notes)
        }
        // Update Contact Detail
        fetch(Config.baseAPIUrl + 'ClickURLDetail', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(posturlObj)
        })
            .then(res => res.json())
            .then(response => {
                //////console.log("responseddddddddddddd");
                //console.log( "Login Page API" ,response);
                // group pin added by dasprakash on 1-10-2023 jira-1490
                if (olDetail.validateGuestMobileNoWithOTP === true || olDetail.checkInWithTablePin === true) {
                    if (olDetail.enableDigitalMenu === true && olDetail.enableGuestAppOrders === false)
                        this.redirectToHome(true);   //End 
                    else
                        this.handleOnlyGroup();
                } else {
                    // Condition  Added by vijay on 10-02-2024 -Jira id -730 -  In Digital Menu, Not able to scan another guest if Guest Details Mandatory Switch is ON                     
                    if (olDetail.enableDigitalMenu === true && olDetail.enableGuestAppOrders === false)
                        this.redirectToHome(true);   //End 
                    else {
                        this.redirectToHome();
                    }
                }
                // this.redirectToHome();

            })
            .catch(err => {
                //////console.log(err);
                this.redirectToHome();
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    };

    handleOnlyGroup() {
        // console.log('goup');
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/loginverification' + urlPattern,
                { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: validatePIN });
        }, Config.minMsgTimeout);
    };

    handleClick = (e) => {
      //  e.preventDefault();
        // group pin added by daspraksh on 28-09-2023 jira-1409
        const Details = JSON.parse(outletDetails);
        // //////console.log(olDetail.validateGuestMobileNoWithOTP); // Remove the quotes around the property name
        //////console.log(olDetail.enableGroupOrdering); // Remove the quotes around the property name
        // 
        this.setState({ loading: true, errorMessage: '' });

        if (this.state.mobileNo === "" || this.state.mobileNo < 10) {
            //added by dasprakash on 20-10-2023 jira-1645
            this.setState({ errorMessage: "Please enter the valid mobile no." });
            this.setState({ loading: false });
            // this.showMessage("Please enter the valid mobile no.", "info");
            return false;
        }
        // group pin added by daspraksh on 28-09-2023 jira-1409
        if (Details.guestNameMandatory === true) {
            if (document.getElementById('input-guest-name').value === "") {
                //added by dasprakash on 20-10-2023 jira-1645
                this.setState({ errorMessage: "Please enter the name." })
                this.setState({ loading: false });
                // this.showMessage("Please enter the name.", "info");
                return false;
            }
        }
        // 
        //added by dasprakash on 20-10-2023 jira-1645
        sessionStorage.setItem('entered-mobile-no', this.state.mobileNo);
        sessionStorage.setItem('entered-name', document.getElementById('input-guest-name').value);
        // 

        sessionStorage.setItem("guest-name", document.getElementById('input-guest-name').value === null ? '' : document.getElementById('input-guest-name').value);
        exmainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');
        //////console.log("olDetail.enableGroupOrdering");
        //////console.log(exmainGuestMobileNo);
        // Group Order Switch is off 
        if (!olDetail.enableGroupOrdering) {
            if (serviceType === "FINE-DINE") { // Table Check In   
                //Addded by vijay on 30-12-2023 Jira id -2075         
                if (this.state.tableCheckedIn) {
                    // console.log(this.state.tableCheckedIn);
                    if (exmainGuestMobileNo !== '') {
                        if (exmainGuestMobileNo !== '0000000000') { // first time entry
                            if (exmainGuestMobileNo !== this.state.mobileNo) {
                                //added by dasprakash on 20-10-2023 jira-1645
                                this.setState({ loading: false });
                                this.setState({ errorMessage: "Table is already checked in, Please scan some other table" });
                                this.showMessage("Table is already checked in, Please scan some other table", "danger");
                                this.props.history.push('/app-error' + urlPattern, { errorMessage: "Table is already checked in, Please scan some other table" });
                                // setTimeout(() => {
                                //     this.setState({ loading: true });
                                //     // Redirect to Page
                                //     if (pageSession === Config.pageSession.Cart) {
                                //         setTimeout(() => {
                                //             this.setState({ loading: false });
                                //             //  this.props.history.push('/cart');
                                //             this.props.history.push('/app-error', { errorMessage: this.state.errorMessage });
                                //             //  console.log("QrError page :" , this.state.errorMessage);
                                //         }, Config.averageMsgTimeout);
                                //         return false;
                                //     }
                                //     else if (pageSession === Config.pageSession.PayBill) {
                                //         setTimeout(() => {
                                //             this.setState({ loading: false });
                                //             this.props.history.push('/mybills');
                                //         }, Config.minMsgTimeout);
                                //         return false;
                                //     }
                                //     else if (pageSession === Config.pageSession.MyOrders) {
                                //         setTimeout(() => {
                                //             this.setState({ loading: false });
                                //             this.props.history.push('/myorders');
                                //         }, Config.minMsgTimeout);
                                //         return false;
                                //     }
                                //     else if (pageSession === Config.pageSession.ChooseAddress) {
                                //         setTimeout(() => {
                                //             this.setState({ loading: false });
                                //             this.props.history.push('/cart');
                                //             //  this.props.history.push('/qrcoderror');
                                //         }, Config.minMsgTimeout);
                                //         return false;
                                //     }
                                //     else {
                                //         setTimeout(() => {
                                //             this.setState({ loading: false });
                                //             this.props.history.push('/home');
                                //         }, Config.minMsgTimeout);
                                //         return false;
                                //     }

                                // }, Config.averageMsgTimeout);
                                return false;
                            }


                        }
                    }
                }
            }
        }

        if (!olDetail.validateGuestMobileNoWithOTP && !olDetail.checkInWithTablePin) {
            if (exmainGuestMobileNo !== '') {
                if (exmainGuestMobileNo === this.state.mobileNo) {
                    //////console.log("dfsdfssdfd")
                    this.handleUpdateContactDetail();
                    // this.redirectToHome();
                    return false;
                }
                else {
                    validateOTP = false;
                    validatePIN = false

                    // group pin commented by dasprakash on 28-09-2023 jira-1490
                    // if (groupSession.toString() === "true") this.handleOnlyGroup();
                    // else this.handleUpdateContactDetail();

                    // group pin added by dasprakash on 28-09-2023 jira-1490
                    this.handleUpdateContactDetail()


                    //this.showMessage("Table is already checked in, Please scan some other table", "danger");
                    // this.setState({ loading: true });
                    // setTimeout(() => {
                    //     this.setState({ loading: false });
                    //     this.props.history.push('/');
                    // }, 3000);
                    return false;
                }
            }
            else {
                //console.log("else for")
                this.handleUpdateContactDetail();
                // this.redirectToHome();
                return false;
            }
        }
        else if (olDetail.validateGuestMobileNoWithOTP && olDetail.checkInWithTablePin) {
            // Added by vijay on 02-01-2023 Jira id -2075
            if (exmainGuestMobileNo !== '') {
                if (exmainGuestMobileNo === this.state.mobileNo) {
                    //////console.log("dfsdfssdfd")
                    this.handleRedirectToWithoutVerification();
                    // this.redirectToHome();
                    return false;
                }
            }

            validateOTP = true;
            validatePIN = true
        }
        else if (olDetail.validateGuestMobileNoWithOTP && !olDetail.checkInWithTablePin) {
            // Added by vijay on 02-01-2023 Jira id -2075
            if (exmainGuestMobileNo !== '') {
                if (exmainGuestMobileNo === this.state.mobileNo) {
                    //////console.log("dfsdfssdfd")
                    this.handleRedirectToWithoutVerification();
                    // this.redirectToHome();
                    return false;
                }
            }

            validateOTP = true;
            validatePIN = false
        }
        else if (!olDetail.validateGuestMobileNoWithOTP && olDetail.checkInWithTablePin) {
            // Added by vijay on 02-01-2023 Jira id -2075
            if (exmainGuestMobileNo !== '') {
                if (exmainGuestMobileNo === this.state.mobileNo) {
                    //////console.log("dfsdfssdfd")
                    this.handleRedirectToWithoutVerification();
                    // this.redirectToHome();
                    return false;
                }
                else {
                    if (olDetail.enableDigitalMenu === true && olDetail.enableGuestAppOrders === false) {
                        this.handleUpdateContactDetail();
                        return false;
                    }
                }
            }
            else {
                if (olDetail.enableDigitalMenu === true && olDetail.enableGuestAppOrders === false) {
                    this.handleUpdateContactDetail();
                    return false;
                }
            }

            validateOTP = false;
            validatePIN = true;
        }
        else {
            // Added by vijay on 02-01-2023 Jira id -2075
            if (exmainGuestMobileNo !== '') {
                if (exmainGuestMobileNo === this.state.mobileNo) {
                    //////console.log("dfsdfssdfd")
                    this.handleUpdateContactDetail();
                    // this.redirectToHome();
                    return false;
                }
            }
            validateOTP = false;
            validatePIN = true

            // Added by vijay on 28-02-2024 -Jira id-730-In Digital Menu, Not able to scan another guest -OTP -PIN - both -false mode- No Loginverification       
            if (olDetail.enableDigitalMenu === true && olDetail.enableGuestAppOrders === false) {
                if (olDetail.validateGuestMobileNoWithOTP === false && olDetail.checkInWithTablePin === true) {
                    //this.handleUpdateContactDetail();
                    this.redirectToHome(true);
                    return false;
                }
            }
        }
        //console.log("validateOTP")
        //console.log(validateOTP)
        if (validateOTP) {
            let postObj = {
                MobileNoCountryCode: "91",
                MobileNo: this.state.mobileNo,
                GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                PropertyId: propertyId,
                OutletId: propertyId + outletCode
            }

            //console.log(postObj);
            fetch(Config.baseGuestUrl + 'GuestLogin', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                .then(res => res.json())
                .then(response => {
                    //console.log("response2");
                    //console.log(response);
                    let cookie = new Cookies();
                    if (response.errorCode === 0) {
                        //this.showMessage(response.message, "danger");
                        sessionStorage.setItem('otpReferenceId', response.response.otpReferenceId);
                        sessionStorage.setItem('otp-exp-duration', response.response.otpExpiryDuration);
                        cookie.set('user', response);
                        cookie.set('gmno', this.state.mobileNo);
                        if (this.state.mobileNo !== exmainGuestMobileNo) {
                            setTimeout(() => {
                                this.setState({ loading: false });
                                this.props.history.push('/loginverification' + urlPattern,
                                    // Commended by vijay on 28-02-2024 -Jira id-730-In Digital Menu, Not able to scan another guest           
                                    // { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: validatePIN });
                                    // Added by vijay on 28-02-2024 -Jira id-730-In Digital Menu, Not able to scan another guest           
                                    { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: olDetail.enableDigitalMenu && !olDetail.enableGuestAppOrders ? false : validatePIN });
                            }, Config.minMsgTimeout);
                        }
                        else {

                            this.handleUpdateContactDetail();
                        }
                    }
                    else if (response.errorCode === 4) // Redirect to Verification - Already Sent
                    {
                        if (this.state.mobileNo !== exmainGuestMobileNo) {
                            //added by dasprakash on 20-10-2023 jira-1645
                            this.setState({ loading: false });
                            this.setState({ errorMessage: response.message })
                            // this.showMessage(response.message, "danger");
                            sessionStorage.setItem('otpReferenceId', response.response.otpReferenceId);
                            sessionStorage.setItem('otp-exp-duration', response.response.otpExpiryDuration);
                            cookie.set('user', response);
                            cookie.set('gmno', this.state.mobileNo);

                            setTimeout(() => {
                                this.setState({ loading: false });
                                //////console.log("err");                             
                                this.props.history.push('/loginverification' + urlPattern,
                                    // Commended by vijay on 28-02-2024 -Jira id-730-In Digital Menu, Not able to scan another guest           
                                    // { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: validatePIN });
                                    // Added by vijay on 28-02-2024 -Jira id-730-In Digital Menu, Not able to scan another guest           
                                    { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: olDetail.enableDigitalMenu && !olDetail.enableGuestAppOrders ? false : validatePIN });
                            }, Config.minMsgTimeout);
                        }
                        else {
                            this.handleUpdateContactDetail();
                        }
                    }
                    else {
                        cookie.remove('user');
                        cookie.remove('gmno');
                        cookie.remove('otpReferenceId');
                        sessionStorage.removeItem('otp-exp-duration');
                        sessionStorage.removeItem('otpReferenceId');
                        //added by dasprakash on 20-10-2023 jira-1645
                        this.setState({ loading: false });
                        this.setState({ errorMessage: response.message })
                        // this.showMessage(response.message, "danger");
                    }
                })
                .catch(err => {
                    //  //////console.log(err);
                    //added by dasprakash on 20-10-2023 jira-1645
                    this.setState({ loading: false });
                    this.setState({ errorMessage: "An error occured" })
                    // this.showMessage("An error occured", "danger");
                });
        }
        else {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/loginverification' + urlPattern,
                    { mobileNo: this.state.mobileNo, validateOTP: validateOTP, validatePIN: validatePIN });
            }, Config.minMsgTimeout);

        }
    }

    // Added by vijay on 02-01-2023 Jira id -2075
    handleRedirectToWithoutVerification() {
        let notes = {
            guestname: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
            modetype: olDetail != null ? olDetail.enableGuestAppOrders === true ? 1 : 0 : 0
        }

        let posturlObj = {
            organizationId: parseInt(orgnationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ContactNo: this.state.mobileNo,
            StatsId: parseInt(statsId),
            numofMenuAccess: 1,
            username: Config.linkUserName,
            password: Config.linkPassword,
            notes: JSON.stringify(notes)
        }
        // Update Contact Detail
        fetch(Config.baseAPIUrl + 'ClickURLDetail', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(posturlObj)
        })
            .then(res => res.json())
            .then(response => {

                //    console.log("Login Page API", response);

                this.redirectToHome(true);


            })
            .catch(err => {
                //////console.log(err);
                this.redirectToHome(true);
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }

    // handleClearText() {
    //     // level2 change
    //     this.searchInp.current.focus();
    //     // 

    //     this.setState({ searchText: '' })
    //     this.setState({ mgfliter: [] });
    //     this.setState({ fliteredmenuitems: [] });
    // }

    redirectToHome(exGuestLoggedIn = false) {
        if (serviceType === "FINE-DINE") { // Table Check In


            //sessionStorage.setItem('group-pin', '');

            // digital Menu
            // added by dasprakash on 07-10-2023 jira-1490
            if (olDetail.enableGuestAppOrders === false && olDetail.enableDigitalMenu === true) {
                
                    sessionStorage.setItem('user-table-verified', true);
                    sessionStorage.setItem('user-digitalMenu', true);
                    // sessionStorage.setItem('user-mobileno', this.state.mobileNo);
    
                    sessionStorage.setItem('user-loggedin', true);
    
    
                    setTimeout(() => {
                        // this.props.history.push('/emhome');
                        this.props.history.push('/home' + urlPattern , { guestAppLandingPage: olDetail.guestAppLandingPage });
                        return false;   // Due to setimeout is not working vijay 
                    }, Config.averageMsgTimeout);
                      // return false;
                             
              
            }

            if (!exGuestLoggedIn) {
                let postObj = {
                    OutletId: propertyId + outletCode,
                    GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
                    MobileNo: this.state.mobileNo,
                    TableNumber: tableNo,
                    GroupCode: ''
                }

                // //////console.log(postObj);

                fetch(Config.basePOSUrl + 'GuestCheckIn', {
                    method: 'POST',
                    headers: {
                        Authorization: "Bearer " + token,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(postObj)
                })
                    .then(res => res.json())
                    .then(response => {

                        if (response.errorCode === 0) {
                            sessionStorage.setItem('user-table-verified', true);
                            sessionStorage.removeItem('otpReferenceId');
                            sessionStorage.setItem('user-mobileno', this.state.mobileNo);
                            // group pin commented by dasprakash on 05-10-2023 jira-1490
                            // sessionStorage.setItem('main-guest-no', this.state.mobileNo);
                            sessionStorage.setItem('user-loggedin', true);
                            // Temperary hard code for table pin switch value as false
                            olDetail.checkInWithTablePin = false;

                            // digital Menu
                            // commented by dasprakash on 07-10-2023 jira-1490
                            // if (olDetail.enableGuestAppOrders === false && olDetail.enableDigitalMenu === true) {
                            //     sessionStorage.setItem('user-table-verified', true);
                            //     sessionStorage.setItem('user-digitalMenu', true);
                            //     setTimeout(() => {
                            //         // this.props.history.push('/emhome');
                            //         this.props.history.push('/home');
                            //     }, 1000);
                            //     return false;
                            // }

                            // set Logged in Date                                                                    
                            let loggedIn = sHelpers.getFormattedDate(new Date());
                            sessionStorage.setItem('user-loggedin-date', loggedIn);


                            this.getGuestDetails();

                            // // Redirect to Page
                            // if (pageSession === Config.pageSession.Cart) {
                            //     setTimeout(() => {
                            //         this.setState({ loading: false });
                            //         this.props.history.push('/cart', { onClick: true });
                            //     }, 2000);
                            //     return false;
                            // }
                            // else if (pageSession === Config.pageSession.PayBill) {
                            //     setTimeout(() => {
                            //         this.setState({ loading: false });
                            //         this.props.history.push('/mybills');
                            //     }, 2000);
                            //     return false;
                            // }
                            // else if (pageSession === Config.pageSession.MyOrders) {
                            //     setTimeout(() => {
                            //         this.setState({ loading: false });
                            //         this.props.history.push('/myorders');
                            //     }, 2000);
                            //     return false;
                            // }
                            // else if (pageSession === Config.pageSession.ChooseAddress) {
                            //     setTimeout(() => {
                            //         this.setState({ loading: false });
                            //         this.props.history.push('/cart');
                            //     }, 2000);
                            //     return false;
                            // }
                            // else {
                            //     setTimeout(() => {
                            //         this.setState({ loading: false });
                            //         this.props.history.push('/home');
                            //     }, 2000);
                            //     return false;
                            // }
                        }
                        else {
                            //added by dasprakash on 20-10-2023 jira-1645
                            this.setState({ loading: false });
                            this.setState({ errorMessage: response.message })
                            // this.showMessage(response.message, "danger");
                            this.setState({ loading: true });
                            sessionStorage.removeItem("login-page-session"); // Cart Session
                            sessionStorage.removeItem("on-click-mode"); // Cart Session
                            if (pageSession === Config.pageSession.Cart) {
                                setTimeout(() => {
                                    this.setState({ loading: false });
                                    this.props.history.push('/cart' + urlPattern);
                                }, Config.minMsgTimeout);
                                return false;
                            }
                            else if (pageSession === Config.pageSession.PayBill) {
                                setTimeout(() => {
                                    this.setState({ loading: false });
                                    this.props.history.push('/items' + urlPattern);
                                }, Config.minMsgTimeout);
                                return false;
                            }
                            else if (pageSession === Config.pageSession.MyOrders) {
                                setTimeout(() => {
                                    this.setState({ loading: false });
                                    this.props.history.push('/myorders' + urlPattern);
                                }, Config.minMsgTimeout);
                                return false;
                            }
                            else if (pageSession === Config.pageSession.ChooseAddress) {
                                setTimeout(() => {
                                    this.setState({ loading: false });
                                    this.props.history.push('/cart' + urlPattern);
                                }, Config.minMsgTimeout);
                                return false;
                            }
                            else {
                                setTimeout(() => {
                                    this.setState({ loading: false });
                                    
                                    this.props.history.push('/home' + urlPattern ,{ guestAppLandingPage: olDetail.guestAppLandingPage });
                                }, Config.minMsgTimeout);
                                return false;
                            }
                        }
                    })
                    .catch(err => {
                        // sessionStorage.removeItem("user-token");
                        //sessionStorage.removeItem("user-mobileno");
                        //////console.log("catch")
                        //added by dasprakash on 20-10-2023 jira-1645
                        this.setState({ loading: false });
                        this.setState({ errorMessage: 'Guest Check IN  - Failed to fetch - ' + err })
                        // this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
                    });
            }
            else // if already ex main guest logged in
            {
                sessionStorage.setItem('user-table-verified', true);
                sessionStorage.removeItem('otpReferenceId');
                sessionStorage.setItem('user-mobileno', this.state.mobileNo);
                sessionStorage.setItem('user-loggedin', true);
                // Temperary hard code for table pin switch value as false
                olDetail.checkInWithTablePin = false;

                // set Logged in Date                                                                    
                let loggedIn = sHelpers.getFormattedDate(new Date());
                sessionStorage.setItem('user-loggedin-date', loggedIn);

                this.getGuestDetails();


            }

        } // End Service Type - Fine Dine

        //  Condition Added by vijay on 02-01-2023 Jira id -2075 
        else {
            sessionStorage.setItem('user-table-verified', true);
            sessionStorage.removeItem('otpReferenceId');
            sessionStorage.setItem('user-loggedin', true);

            sessionStorage.setItem('user-mobileno', this.state.mobileNo);
            sessionStorage.setItem('main-guest-no', this.state.mobileNo);

            // Temperary hard code for table pin switch value as false
            olDetail.checkInWithTablePin = false;

            // digital Menu
            if (olDetail.enableGuestAppOrders === false && olDetail.enableDigitalMenu === true) {
                sessionStorage.setItem('user-table-verified', true);
                sessionStorage.setItem('user-digitalMenu', true);
                setTimeout(() => {
                    //this.props.history.push('/emhome');
                    this.props.history.push('/home' + urlPattern , { guestAppLandingPage: olDetail.guestAppLandingPage });
                }, 1000);
                return false;
            }

            this.getGuestDetails();

            // // Redirect to Page
            // if (pageSession === Config.pageSession.Cart) {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/cart', { onClick: true });
            //     }, 2000);
            //     return false;
            // }
            // else if (pageSession === Config.pageSession.PayBill) {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/mybills');
            //     }, 2000);
            //     return false;
            // }
            // else if (pageSession === Config.pageSession.MyOrders) {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/myorders');
            //     }, 2000);
            //     return false;
            // }
            // else if (pageSession === Config.pageSession.ChooseAddress) {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/cart');
            //     }, 2000);
            //     return false;
            // }
            // else {
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/home');
            //     }, 2000);
            //     return false;
            // }
        }
    }

    getGuestDetails() {
        let mobileNumber = sessionStorage.getItem('user-mobileno');
        fetch(Config.baseGuestUrl + 'GetGuestDetails?GuestMobileNo=' + mobileNumber, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                //////console.log("responsedas");
                //////console.log(response);
                if (response.errorCode === 0) {
                    sessionStorage.setItem('user-GuestDetails', JSON.stringify(response.response.guestDetails));
                    let selectedAddress = {
                        addressId: response.response.guestDetails.guestAddress.addressId,
                        addressLine1: response.response.guestDetails.guestAddress.addressLine1,
                        addressLine2: response.response.guestDetails.guestAddress.addressLine2,
                        addressLine3: response.response.guestDetails.guestAddress.addressLine3,
                        addressType: response.response.guestDetails.guestAddress.addressType,
                        addressTypeId: 1,//response.response.guestDetails.addressTypeId,
                        area: response.response.guestDetails.guestAddress.area,
                        city: response.response.guestDetails.guestAddress.city,
                        landmark: response.response.guestDetails.guestAddress.landmark,
                        zipCode: response.response.guestDetails.guestAddress.zipCode
                    }
                    sessionStorage.setItem('user-CurrentAddress', JSON.stringify(selectedAddress));
                }
                else {
                    //added by dasprakash on 20-10-2023 jira-1645
                    this.setState({ loading: false });
                    this.setState({ errorMessage: response.message })
                    // this.showMessage(response.message, "danger");
                    sessionStorage.removeItem('user-GuestDetails');
                }

                this.setState({ loading: true });
                // Redirect to page               
                if (pageSession === Config.pageSession.Cart) {
                    setTimeout(() => {
                        //////console.log("cart redi");
                        this.setState({ loading: false });
                        this.props.history.push('/cart' + urlPattern, { onClick: true });
                    }, 2000);
                    return false;
                }
                else if (pageSession === Config.pageSession.PayBill) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/mybills' + urlPattern);
                    }, 2000);
                    return false;
                }
                else if (pageSession === Config.pageSession.MyOrders) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/myorders' + urlPattern);
                    }, 2000);
                    return false;
                }
                else if (pageSession === Config.pageSession.ChooseAddress) {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/cart' + urlPattern);
                    }, 2000);
                    return false;
                }
                else {
                    setTimeout(() => {
                        this.setState({ loading: false });
                        this.props.history.push('/home' + urlPattern , { guestAppLandingPage: olDetail.guestAppLandingPage });
                    }, 2000);
                    return false;
                }
            })
            .catch(err => {
                //added by dasprakash on 20-10-2023 jira-1645
                this.setState({ loading: false });
                this.setState({ errorMessage: 'Guest Details  - Failed to fetch - ' + err })
                // this.showMessage('Guest Details  - Failed to fetch - ' + err, "danger");
            });
    }

    
    getCMSDetails() {

        let postobj = {
            organizationId: parseInt(orgnationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ApplicationCode: Config.applicationCode,
            PageId: Config.pageIds.login, // Default Login
            PageType: Config.pageType.login, // Login           
            RequestIp: "",
            username: Config.cmsUserName,
            password: Config.cmsPassword
        }

        fetch(Config.baseAPIUrl + 'PageDetails', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(postobj)
        })
            .then(res => res.json())
            .then(response => {
                // //////console.log(response);
                if (response.isSuccessful) {

                    // Slider                   
                    if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0)
                        this.setState({ sliderImages: response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });

                    // if (response.page != null) {
                    //     if (response.page.preview !== '')
                    //         this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });

                    // }
                }

            })
            .catch(err => {
                //////console.log(err);
                //this.showMessage("CMS - Failed to fetch", "danger");
            });
    }

   
        handleLoginClick = (e) => {
            e.preventDefault();

            const olDetail = JSON.parse(outletDetails);
               
            // if (olDetail.enableGuestAppOrders === false && olDetail.enableDigitalMenu === true) {

               this.setState({ loading: true, errorMessage: '' });


        if (this.state.mobileNo === "" ) {
            //added by dasprakash on 20-10-2023 jira-1645
            this.setState({ errorMessage: "Please enter the valid mobile no." });
            this.setState({ loading: false });
            // this.showMessage("Please enter the valid mobile no.", "info");
            return false;
        }
        // group pin added by daspraksh on 28-09-2023 jira-1409
        if (olDetail.guestNameMandatory === true) {
            if (document.getElementById('input-guest-name').value === "") {
                //added by dasprakash on 20-10-2023 jira-1645
                this.setState({ errorMessage: "Please enter the name." })
                this.setState({ loading: false });
                // this.showMessage("Please enter the name.", "info");
                return false;
            }
        }

       
        
        this.setState({ loading: true });     

        
        fetch(Config.baseGuestUrl + 'GetGuestDetails?GuestMobileNo=' + this.state.mobileNo, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(response => {
                //////console.log("responsedas");
                //////console.log(response);
                if (response.errorCode === 0) {
                    this.handleClick(); 
                }
                else {
                    this.setState({ loading: false });              
                  this.errorMessage(response.message) ;     
                }                         
            })
            .catch(err => {              
                this.setState({ loading: false });
                this.setState({ errorMessage: 'Guest Details  - Failed to fetch - ' + err })
                // this.showMessage('Guest Details  - Failed to fetch - ' + err, "danger");
            });
      
       
    // }
    // else
    // {
    //     this.handleClick(); 
    // }
       }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };

    errorMessage(msg){
        this.setState({ errorMessage:msg }) ;       
        setTimeout(() => {                      
           this.setState({ errorMessage: '' });
        }, 1500); 
    }
    render() {
        const { loading } = this.state;


        return (
            <div >
                {loading && <LoadingAnimation />}
                <div style={{ minHeight: "100vh" }} id="login-content">
                    {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                    {/* commented by dasprakash on 16-10-2023 jira-1644 */}
                    {/* <Header loggedIn={this.state.loggedIn} /> */}
                    <div style={{ minHeight: "100vh" }} className="content-wrap">
                        {/* <div className="img-hero">
                            <div>
                                <img src="../assets/theme1/img/login-bg.png" alt="slider" />
                            </div>
                        </div> */}

                        {/* commented by dasprakash on 16-10-2023 jira-1644 */}
                        {/* <div style={{ marginTop: "50px", padding: "8px" }}>
                            <Slider {...homeslidersettings} className="img-hero">
                                {this.state.sliderImages.map(img =>
                                    <div key={"slider-img-" + img.sort}>
                                        <img src={img.primaryImageUrl} alt="lucid-slider-contact-less-order" />
                                    </div>
                                )}
                            </Slider>
                        </div> */}
                        {/*  */}
                        {/* added by dasprakash on 16-10-2023 jira-1644 */}
                        <div className="logo-v2">
                            <img style={{ width: "220px", marginLeft: "12px", marginBottom: "30px" }} src={this.state.outLetImage} alt="outLet-logo" />
                        </div>



                        <div className="login-section-home login-panel login-section-v2">
                            <div className="heading-section">
                                {/* commented by dasprakash on 16-10-2023 jira-1644 */}
                                {/* <div><img className="img-center" src="../assets/theme1/img/user-img.png" alt="lucid-contactless-app-user-login" /></div> */}

                                <div style={{ marginBottom: "35px" }} className="sa-title popcat">Login</div>
                                <div style={{ marginBottom: "35px" }} className="login-text">Enter your valid mobile number to continue</div>
                                <div className="clear"></div>
                                <form className="form" action="##" method="post" id="setting">
                                    <div className="form-group">
                                        <div className="col-xs-12 input_v2" style={{ marginBottom: "35px" }}>
                                            <svg style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17" fill="none">
                                                <path d="M4.97055 7.13244C4.97055 7.13255 4.97069 7.13261 4.97077 7.13253L6.33204 5.88534C6.70431 5.5436 6.96596 5.09841 7.08342 4.6069C7.20087 4.1154 7.16877 3.60001 6.99123 3.12688L6.41063 1.57651C6.19372 0.997711 5.7643 0.523386 5.20985 0.250168C4.65541 -0.023051 4.01765 -0.0746065 3.42652 0.106004C1.25119 0.771534 -0.420876 2.79348 0.0938006 5.19446C0.43227 6.77398 1.08005 8.75663 2.30716 10.866C3.53681 12.9805 4.9414 14.5423 6.14569 15.6401C7.96354 17.2944 10.5623 16.8812 12.2343 15.3219C12.6825 14.904 12.9545 14.3309 12.9948 13.7194C13.0351 13.1079 12.8406 12.5041 12.4511 12.031L11.3863 10.738C11.0651 10.347 10.6343 10.061 10.1492 9.91689C9.66421 9.77275 9.14714 9.77705 8.66456 9.92923C7.58602 10.2688 6.3676 9.99261 5.77642 9.02873C5.7569 8.9969 5.73759 8.96494 5.71848 8.93284C5.39631 8.36565 5.1452 7.76095 4.97081 7.1324C4.97077 7.13225 4.97055 7.13228 4.97055 7.13244Z" fill="#18A437" />
                                            </svg>
                                            {/* //commented by dasprakash on 20-10-2023 jira-1645 */}
                                            {/* <input type="number" style={{ border: "none", padding: "0px" }} value={this.state.mobileNo} onChange={(e) => this.handlechange(e)} className="form-control login-input" name="input-mobile-no" id="input-mobile-no" placeholder="Phone Number" maxLength="10" autoFocus autoComplete="off" ref={(input) => { this.nameInput = input; }} /> */}
                                            <input type="number" style={{ border: "none", padding: "0px" }} value={this.state.mobileNo} onChange={(e) => this.handlechange(e)} className="form-control login-input" name="input-mobile-no" id="input-mobile-no" placeholder="Phone Number" maxLength="10" autoComplete="off" ref={(input) => { this.nameInput = input; }} />
                                            {/* Added  by vijay on 04-11-2023 */}
                                            {this.state.mobileNo !== "" && (

                                                <svg style={{ marginRight: "auto" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" onClick={() => this.setState({ mobileNo: '' })}>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM4.36504 13.6276C4.01357 13.2761 4.01357 12.7062 4.36504 12.3548L7.72721 8.9926L4.36217 5.62756C4.0107 5.27609 4.0107 4.70624 4.36217 4.35477C4.71364 4.0033 5.28349 4.0033 5.63496 4.35477L9 7.71981L12.365 4.35477C12.7165 4.0033 13.2864 4.0033 13.6378 4.35477C13.9893 4.70624 13.9893 5.27609 13.6378 5.62756L10.2728 8.9926L13.635 12.3548C13.9864 12.7062 13.9864 13.2761 13.635 13.6276C13.2835 13.979 12.7136 13.979 12.3622 13.6276L9 10.2654L5.63784 13.6276C5.28636 13.979 4.71652 13.979 4.36504 13.6276Z" fill="#AFAFAF" />
                                                </svg>
                                            )}
                                        </div>
                                        <div style={{ marginBottom: "0px" }} className="col-xs-12 input_v2">
                                            <svg style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 3.33333C4.16667 2.44928 4.51786 1.60143 5.14298 0.976311C5.7681 0.351189 6.61594 0 7.5 0C8.38406 0 9.2319 0.351189 9.85702 0.976311C10.4821 1.60143 10.8333 2.44928 10.8333 3.33333C10.8333 4.21739 10.4821 5.06523 9.85702 5.69036C9.2319 6.31548 8.38406 6.66667 7.5 6.66667C6.61594 6.66667 5.7681 6.31548 5.14298 5.69036C4.51786 5.06523 4.16667 4.21739 4.16667 3.33333ZM4.16667 8.33333C3.0616 8.33333 2.00179 8.77232 1.22039 9.55372C0.438987 10.3351 0 11.3949 0 12.5C0 13.163 0.263392 13.7989 0.732233 14.2678C1.20107 14.7366 1.83696 15 2.5 15H12.5C13.163 15 13.7989 14.7366 14.2678 14.2678C14.7366 13.7989 15 13.163 15 12.5C15 11.3949 14.561 10.3351 13.7796 9.55372C12.9982 8.77232 11.9384 8.33333 10.8333 8.33333H4.16667Z" fill="#18A437" />
                                            </svg>
                                            <input type="test" style={{ "marginTop": "10px", "marginBottom": "10px", border: "none", padding: "0px" }} className="form-control login-input" name="input-guest-name" id="input-guest-name" placeholder="Name" maxLength="50" autoComplete="off" value={this.state.guestName} onChange={this.handleGuestNameChange} />
                                            {/* Added  by vijay on 04-11-2023 */}
                                            {this.state.guestName !== "" && (
                                                <svg style={{ marginRight: "auto" }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" onClick={this.handleClearText}>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM4.36504 13.6276C4.01357 13.2761 4.01357 12.7062 4.36504 12.3548L7.72721 8.9926L4.36217 5.62756C4.0107 5.27609 4.0107 4.70624 4.36217 4.35477C4.71364 4.0033 5.28349 4.0033 5.63496 4.35477L9 7.71981L12.365 4.35477C12.7165 4.0033 13.2864 4.0033 13.6378 4.35477C13.9893 4.70624 13.9893 5.27609 13.6378 5.62756L10.2728 8.9926L13.635 12.3548C13.9864 12.7062 13.9864 13.2761 13.635 13.6276C13.2835 13.979 12.7136 13.979 12.3622 13.6276L9 10.2654L5.63784 13.6276C5.28636 13.979 4.71652 13.979 4.36504 13.6276Z" fill="#AFAFAF" />
                                                </svg>
                                            )}

                                        </div>
                                    </div>

                                    <div className="submit-btn">
                                        <div className="col-12">
                                            {/* //added by dasprakash on 20-10-2023 jira-1645 */}
                                            <small id='errorMessage' style={{ color: "red", fontSize: "13px" }}>{this.state.errorMessage}</small>

                                            <Button variant="success" style={{ backgroundColor: "#18A437", width: "100%", borderRadius: "11px", marginTop: "10px" }} onClick={(e) => this.handleLoginClick(e)} id="input-btn-send-otp" disabled={loading} >Login</Button>
                                            {/* <button className="theme-button  login-send-btn" onClick={(e) => this.handleClick(e)} id="input-btn-send-otp" disabled={loading}>Login</button> */}
                                        </div>
                                    </div>
                                </form>
                                <div className="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Login;