import React, { Component, Fragment } from 'react';
import './myorders.scss';
import LoadingAnimation from '../../components/loadinganimation';
//import * as QueryString from "query-string"
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
import enmConfig from '../../data/enumConfig';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//added by dasprakash on 31-10-2023 jira-1689
import Button from 'react-bootstrap/Button';
import Currency from '../../components/currency';

//let ordersList = [];
//let billsList = [];
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
let loadcomfn = null;
let outletDetailsStr = null;
let outletDetails = {}
let mainMobileNo = '';
let guestLoggedIn = "false";
let urlPattern = '';
export default class MyOrders extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.goViewBill = this.goViewBill.bind(this);
        this.GetUpdateOrders = this.GetUpdateOrders.bind(this);
        this.handleProcessRequest = this.handleProcessRequest.bind(this);
        this.handleToDayOrder = this.handleToDayOrder.bind(this);
        this.handleTodayBill = this.handleTodayBill.bind(this);
        this.handleHistoryBill = this.handleHistoryBill.bind(this);
        this.goBill = this.goBill.bind(this);
        this.state = {
            cartValue: 1500,
            todayOrderList: [],
            todayBillList: [],
            historyBillsList: [],
            ordersList: [],
            billsList: [],
            loading: false,
            alertMessage: '',
            msgShow: false,
            orderMsg: '',
            orderErrorMsg: '',
            billMsg: '',
            billErrorMsg: '',
            refreshSpin: 0,
            billhidden: '',
            orderhidden: 'active',
            unBillAmount: 0.00,
            historyBillMsg: "",
            todayBillMsg: "",
            todayOrderMsg: "",
            totalOrders: 0,
            totalBills: 0,
            totalHistory: 0
        }
    }

    componentDidMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletDetailsStr = sessionStorage.getItem('user-outletDetails');
        mainMobileNo = sessionStorage.getItem('main-guest-no');
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        urlPattern = sessionStorage.getItem('url-pattern');

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
       
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDetailsStr === undefined || outletDetailsStr === "" || outletDetailsStr === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            outletDetails = JSON.parse(outletDetailsStr);
            enmConfig.checkOutTypewithPay = outletDetails.paymentVendors.length > 0 ? true : false;
        }

        const script = document.createElement("script");
        script.src = "/theme.js"; // it is not calling mentioned for to avoid null value
        script.async = true;
        script.onload = () => this.loadJS();
        document.body.appendChild(script);


        if (guestLoggedIn === "false") {
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, 200);
            return false;
        }



        // this.setState({ orderList: [],loading:false });
        // this.setState({ orderMsg: 'Fetching...',loading: true });
        //  this.setState({ orderErrorMsg: 'Looks like you have not made your menu yet.',orderMsg: "NO ORDERS FOUND",loading:false });
        this.handleToDayOrder();

        // let urlParam = "?MobileNo=" + mobileNo + "&NoOfRowsPerPage=100&CurrentPageNumber=1";
        // this.setState({ orderMsg: 'Fetching...',loading: true });
        // fetch(Config.basePOSUrl + 'GetGuestOrders' + urlParam, {
        //     method: 'GET',
        //     headers: {
        //         Authorization: "Bearer " + token,
        //         'content-type': 'application/json'
        //     }
        // })
        //     .then(res => res.json())
        //     .then(response => {
        //         if (response.errorCode === 0) {
        //             setTimeout(() => {
        //                 console.log(response);
        //                 orderList = response.response.bills;
        //                 if (orderList.length > 0)
        //                     this.setState({ orderList: orderList,loading:false });
        //                 else {
        //                     this.setState({ orderErrorMsg: 'Looks like you have not made your menu yet.',orderMsg: "NO ORDERS FOUND",loading:false });
        //                 }
        //             }, 2000);
        //         }
        //         else {
        //             this.showMessage(response.message, "danger");
        //             console.log(response.message);
        //         }
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         this.showMessage("An error occured", "danger");
        //     });

    }
    loadJS() {
        loadcomfn = window.myodersInitiate;
        loadcomfn();
    }
    goBack() {
        //commented by dasprakash on 31-10-2023 jira-1689
        // this.props.history.push('/items');
        //added by dasprakash on 31-10-2023 jira-1689
        if (sessionStorage.getItem('getmenuItems') === "" || sessionStorage.getItem('getmenuItems') === null || sessionStorage.getItem('getmenuItems') === undefined) {
            this.props.history.push('/home' + urlPattern);
        } else {
            this.props.history.push('/items' + urlPattern);
        }
    }
    goViewBill(billUrl, bAmount, isPay, billId, refBillId) {
        this.setState({ loading: true });
        //  console.log(refBillId);
        setTimeout(() => {
            this.props.history.push('/viewbill' + urlPattern, { eBillUrl: billUrl, billAmount: bAmount, isPay: isPay, posBillId: billId, refBillId: refBillId });
        }, Config.minMsgTimeout);
    }

    GetUpdateOrders() {
        window.scrollTo(0, 0);
        this.setState({ loading: true });
        this.setState({ todayOrderList: [], todayBillList: [], refreshSpin: 1, historyBillsList: [] });
        this.setState({ todayBillMsg: 'Fetching Records..', todayOrderMsg: 'Fetching Records..', historyBillMsg: 'Fetching Records..' });
        this.handleToDayOrder();
        this.handleTodayBill();
        this.handleHistoryBill();
    }

    handleProcessRequest() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.props.history.push('/processrequest' + urlPattern, { totalNetAmount: this.state.totalOrderAmount });
        }, Config.minMsgTimeout);
    }

    handleToDayOrder() {
        this.setState({ loading: true });
        let postObj = {
            OutletId: propertyId + outletCode,
            ServiceType: serviceType,
            GuestMobileCountryCode: "91",
            GuestMobileNumber: mobileNo,
            MainGuestMobileCountryCode: "91",
            //  MainGuestMobileNumber: mainMobileNo,
            MainGuestMobileNumber: (mainMobileNo === '' || mainMobileNo === "0000000000") ? mobileNo : mainMobileNo,
            TableNumber: serviceType !== "FINE-DINE" ? '' : tableNo,
            NoOfRowsPerPage: 100,
            CurrentPageNumber: 1

        }
        fetch(Config.basePOSUrl + 'GetGuestTodayOrders', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)

        })
            .then(res => res.json())
            .then(response => {
                //  console.log("response", response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        this.setState({ refreshSpin: 0 });
                        this.setState({ unBillAmount: response.response.unBilledAmount, todayOrderList: response.response.orders, loading: false, todayOrderMsg: "", totalOrders: response.response.orders.length });
                        setTimeout(() => {
                            if (response.response.orders.length === 0) {
                                this.setState({ todayOrderMsg: 'NO ORDERS FOUND' });
                            }
                        }, Config.minMsgTimeout);

                    }
                    else {
                        this.setState({ unBillAmount: 0.00, todayOrderList: [], todayOrderMsg: "Looks like you have not made your menu yet.", totalOrders: 0 });
                    }
                }
                else {

                    this.showMessage(response.message, "danger");
                    this.setState({ unBillAmount: 0.00, todayOrderList: [], todayOrderMsg: "Looks like you have not made your menu yet.", totalOrders: 0 });
                    //  console.log(response.message);
                }
            })
            .catch(err => {
                // console.log(err);
                this.showMessage("An error occured", "danger");
                this.setState({ todayOrderList: [], loading: false, unBillAmount: 0.00, todayOrderMsg: "Looks like you have not made your menu yet.", totalOrders: 0 });
            });
    }

    handleTodayBill() {
        this.setState({ loading: true });
        let postObj = {
            OutletId: propertyId + outletCode,
            ServiceType: serviceType,
            GuestMobileCountryCode: "91",
            // GuestMobileNumber: mainMobileNo,
            GuestMobileNumber: (mainMobileNo === '' || mainMobileNo === "0000000000") ? mobileNo : mainMobileNo,
            NoOfRowsPerPage: 50,
            CurrentPageNumber: 1
        }

        fetch(Config.basePOSUrl + 'GetGuestTodayBills', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)

        })
            .then(res => res.json())
            .then(response => {
                // console.log("billsssss", response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        // console.log(response);
                        this.setState({ refreshSpin: 0 });
                        this.setState({ todayBillList: response.response.bills, loading: false, todayBillMsg: "", totalBills: response.response.bills.length });

                        setTimeout(() => {
                            if (response.response.bills.length === 0) {
                                this.setState({ todayBillMsg: 'NO BILLS FOUND' });
                            }
                        }, Config.minMsgTimeout);
                    }
                    else {
                        this.setState({ todayBillList: [], todayBillMsg: "Looks like you have not made your menu yet.", totalBills: 0 });
                    }
                }
                else {

                    this.showMessage(response.message, "danger");
                    this.setState({ todayBillList: [], loading: false, todayBillMsg: "Looks like you have not made your menu yet.", totalBills: 0 });
                }
            })
            .catch(err => {
                // console.log(err);
                this.showMessage("An error occured", "danger");
                this.setState({ todayBillList: [], loading: false });
                this.setState({ todayBillMsg: "Looks like you have not made your menu yet.", loading: false, totalBills: 0 });
            });

    }

    handleHistoryBill() {
        this.setState({ loading: true });
        let postObj = {
            OutletId: propertyId + outletCode,
            ServiceType: serviceType,
            GuestMobileCountryCode: "91",
            GuestMobileNumber: mobileNo,
            NoOfRowsPerPage: 30,
            CurrentPageNumber: 1
        }
        fetch(Config.basePOSUrl + 'GetGuestHistory', {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            },
            body: JSON.stringify(postObj)

        })
            .then(res => res.json())
            .then(response => {
                console.log(response);
                if (response.errorCode === 0) {
                    if (response.response != null) {
                        this.setState({ refreshSpin: 0 });
                        this.setState({ historyBillsList: response.response.bills, loading: false, historyBillMsg: "", totalHistory: response.response.bills.length });
                        setTimeout(() => {
                            if (response.response.bills.length === 0) {
                                this.setState({ historyBillMsg: 'NO BILLS FOUND' });
                            }
                        }, Config.minMsgTimeout);
                    }
                    else {
                        this.setState({ historyBillsList: [], historyBillMsg: "Looks like you have not made your menu yet.", totalHistory: 0 });
                    }
                }
                else {
                    this.showMessage(response.message, "danger");
                    this.setState({ historyBillsList: [], loading: false, historyBillMsg: "Looks like you have not made your menu yet.", totalHistory: 0 });
                }
            })
            .catch(err => {
                // console.log(err);
                this.showMessage("An error occured", "danger");
                this.setState({ historyBillsList: [], loading: false });
                this.setState({ historyBillMsg: 'Looks like you have not made your menu yet.', loading: false, totalHistory: 0 });
            });


    }

    //added by dasprakash on 30-10-2023 jira-1689
    getTimeFromDateTime(dateTimeStr) {
        const parts = dateTimeStr.split(' '); // Split the string into date and time parts
        const timePart = parts[1] + ' ' + parts[2]; // Extract the time part

        return timePart;
    }

    cart(item) {
        console.log(item)
        const getmenuItems = JSON.parse(sessionStorage.getItem('getmenuItems'));
        item.forEach(itemObject => {
            const itemName = itemObject.itemName;
            const filteredItem = getmenuItems.filter(i => i.itemName === itemName);
            console.log(filteredItem);
        });
    }



    handleTodayOrderDom() {
        return (
            //style added by dasprakash on 30-10-2023 jira-1689
            <div >
                {/* //commented by dasprakash on 30-10-2023 jira-1689 */}
                {/* {outletDetails.enableViewBill &&
                    <div className="section-paynow">
                        <div>
                            <div className="unbill-txt">Unbill Amount</div>
                            <div className="unbill-amount">₹ {this.state.unBillAmount.toFixed(2)}</div>
                        </div>
                        {enmConfig.checkOutTypewithPay && <div className="btn-paynow" onClick={this.goBill}><img className="paybill" src="../assets/theme1/img/pay-bill.png" alt="lucid-rupee" />Pay Now</div>}
                        {!enmConfig.checkOutTypewithPay && <div className="btn-paynow" onClick={this.goBill}><img className="paybill" src="../assets/theme1/img/pay-bill.png" alt="lucid-rupee" />View Bill</div>}
                    </div>
                } */}
                {this.state.todayOrderList.map(ord =>
                    <div style={{ marginBottom: "32px" }} className="order-row">
                        {/* <div className="sdprofile">
                            <div className="sdp-left">
                                <img src="../assets/theme1/img/user-img.png" alt="profile" />
                            </div>
                            <div className="sdp-right">
                                <div className="sd-name">{ord.guestName === '' ? ' ' : ord.guestName}</div>
                                <div className="sd-status">{ord.guestMobileNumber === '' ? '-' : ord.guestMobileNumber}</div>
                                <div className="order-table-no">Table No: <span>{ord.tableNumber}</span></div>
                            </div>
                            <div className="repeat-order-btn">Repeat Order</div>

                            {ord.orderStatus === "Placed" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Accepted" && <div className="order-stauts status-0">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Preparing" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Prepared" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Food Ready" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "FoodReady" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Dispatched" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Completed" && <div className="order-stauts status-0">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Delivered" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Forward" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Rejected" && <div className="order-stauts status-3">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Cancelled" && <div className="order-stauts status-3">{ord.orderStatus}</div>}
                            <div className="order-status-date itm-cnt">{ord.orderStatusUpdatedDateTime}</div>
                            <div className="order-no">Order No: <span className="order-no-val">{ord.orderNo}</span></div>
                            <div className="order-date itm-cnt">Order Date: <span>{ord.orderDateTime}</span></div>
                            <div className="kot-no">KOT No: <span className="order-no-val">{ord.kotNo}</span></div>
                        </div> */}
                        {/* //added by dasprakash on 30-10-2023 jira-1689 */}
                        <div className='section-1-v2'>
                            <span className='ordered-time-v2'>{this.getTimeFromDateTime(ord.orderDateTime)}</span>
                            {ord.orderStatus === "Placed" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Accepted" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Preparing" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Prepared" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Food Ready" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "FoodReady" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Dispatched" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Completed" && <div className="order-stauts status-0">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Delivered" &&
                                //commented by dasprakash on 31-10-2023 jira-1689
                                // <div className="order-stauts status-1">{ord.orderStatus}</div>
                                //added by dasprakash on 31-10-2023 jira-1689
                                <div className="order-stauts status-0">{ord.orderStatus}</div>
                            }
                            {ord.orderStatus === "Forward" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Rejected" && <div className="order-stauts status-3">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Cancelled" && <div className="order-stauts status-3">{ord.orderStatus}</div>}
                        </div>
                        {/*  */}



                        <div className="sdprofile">
                            <div className="sdp-left">
                                {/* //commented by dasprakash on 31-10-2023 jira-1689 */}
                                {/* <img src="../assets/theme1/img/user-img.png" alt="profile" /> */}
                                {/* //added by dasprakash on 31-10-2023 jira-1689 */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M16.6667 15.3333C16.6667 13.9188 17.2286 12.5623 18.2288 11.5621C19.229 10.5619 20.5855 10 22 10C23.4145 10 24.771 10.5619 25.7712 11.5621C26.7714 12.5623 27.3333 13.9188 27.3333 15.3333C27.3333 16.7478 26.7714 18.1044 25.7712 19.1046C24.771 20.1048 23.4145 20.6667 22 20.6667C20.5855 20.6667 19.229 20.1048 18.2288 19.1046C17.2286 18.1044 16.6667 16.7478 16.6667 15.3333ZM16.6667 23.3333C14.8986 23.3333 13.2029 24.0357 11.9526 25.286C10.7024 26.5362 10 28.2319 10 30C10 31.0609 10.4214 32.0783 11.1716 32.8284C11.9217 33.5786 12.9391 34 14 34H30C31.0609 34 32.0783 33.5786 32.8284 32.8284C33.5786 32.0783 34 31.0609 34 30C34 28.2319 33.2976 26.5362 32.0474 25.286C30.7971 24.0357 29.1014 23.3333 27.3333 23.3333H16.6667Z" fill="#18A437" />
                                    <circle cx="22" cy="22" r="21" stroke="#18A437" strokeWidth="2" />
                                </svg>
                            </div>


                            <div className="sdp-right">
                                <div style={{ fontSize: "14px", fontWeight: "400", color: "#000" }} className="sd-name">{ord.guestName === '' ? ' ' : ord.guestName}</div>
                                <div style={{ fontSize: "14px", fontWeight: "400", color: "#000000C9" }} className="sd-status">{ord.guestMobileNumber === '' ? '-' : ord.guestMobileNumber}</div>
                                {/* //commented by dasprakash on 30-10-2023 jira-1689 */}
                                {/* <div className="order-table-no">Table No: <span>{ord.tableNumber}</span></div> */}
                            </div>

                            {/* <div className="repeat-order-btn">Repeat Order</div> */}

                            {/* //commented by dasprakash on 30-10-2023 jira-1689 */}
                            {/* {ord.orderStatus === "Placed" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Accepted" && <div className="order-stauts status-0">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Preparing" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Prepared" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Food Ready" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "FoodReady" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Dispatched" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Completed" && <div className="order-stauts status-0">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Delivered" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Forward" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Rejected" && <div className="order-stauts status-3">{ord.orderStatus}</div>}
                            {ord.orderStatus === "Cancelled" && <div className="order-stauts status-3">{ord.orderStatus}</div>} */}
                            {/*  */}


                            {/* //commented by dasprakash on 30-10-2023 jira-1689 */}
                            {/* <div className="order-status-date itm-cnt">{ord.orderStatusUpdatedDateTime}</div>
                            <div className="order-no">Order No: <span className="order-no-val">{ord.orderNo}</span></div>
                            <div className="order-date itm-cnt">Order Date: <span>{ord.orderDateTime}</span></div>
                            <div className="kot-no">KOT No: <span className="order-no-val">{ord.kotNo}</span></div> */}
                            {/*  */}

                        </div>

                        {/* //commented by dasprakash on 31-10-2023 jira-1689 */}
                        {/* <div>
                            <div className="accordion" id="accordion2">
                                <div className="accordion-heading">
                                    <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href={"#collapseOne" + ord.orderId}>
                                        <span className="view-item-detail">View Details</span>
                                        <i className="fa fa-angle-right left-arrow" ></i>
                                    </a>
                                </div>
                                <div id={"collapseOne" + ord.orderId} className="accordion-body collapse">
                                    <div className="accordion-inner">
                                        <table className="table">
                                            <thead className="thead-txt">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Qty</th>
                                                    <th className="text-right">Rate</th>
                                                    <th className="text-right">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tbody-txt">
                                                {
                                                    ord.orderDetails.map((oitm, i) => {
                                                        return (
                                                            <Fragment key={"orders-frg-tbl-itm-tr-" + (i + 1)}>
                                                                <tr key={"orders-tbl-itm-tr-" + (i + 1)}>
                                                                    <td>{oitm.itemName.toLowerCase()}</td>
                                                                    <td>x{oitm.quantity}</td>
                                                                    <td className="text-right">₹{oitm.rate.toFixed(2)}</td>
                                                                    <td className="text-right">₹{(oitm.rate * oitm.quantity).toFixed(2)}</td>
                                                                </tr>
                                                                {
                                                                    oitm.modifiers.map(gmd =>

                                                                        <tr>
                                                                            <td className="modifier-item-txt">{gmd.modifierName.toLowerCase()}</td>
                                                                            <td className="modifier-item-row">x{gmd.quantity}</td>
                                                                            <td className="text-right modifier-item-row">₹{gmd.rate.toFixed(2)}</td>
                                                                            <td className="text-right modifier-item-row">₹{(gmd.rate * gmd.quantity).toFixed(2)}</td>
                                                                        </tr>
                                                                    )

                                                                }
                                                                {
                                                                    oitm.instruction !== "" &&
                                                                    <tr>
                                                                        <td colSpan="4"> <div className="item-instruction"> Ins:[{oitm.instruction}]</div></td>
                                                                    </tr>
                                                                }
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/*  */}

                        {/* //added by dasprakash on 31-10-2023 jira-1689 */}
                        <div>
                            {ord.orderDetails.map((oitm, i) => (
                                <div className='ordered-items-v2' key={i}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <span>{oitm.itemName.toLowerCase()}</span>
                                        {oitm.modifiers.map(gmd =>
                                            <span style={{ marginLeft: "5px", color: "#6F7072", fontStyle: "italic" }}>{gmd.modifierName.toLowerCase()}</span>
                                        )
                                        }
                                    </div>
                                    <small>x{oitm.quantity}</small>
                                </div>
                            ))}
                        </div>

                        {/* //temporary commented by dasprakash on 31-10-2023 jira-1689 */}
                        {/* <div>
                            {(ord.orderInstruction !== '' && ord.reason !== '') && <div className="order-instruction">Ins: [{ord.orderInstruction}]  || Reason: [{ord.reason}]</div>}
                            {ord.orderInstruction !== '' && <div className="order-instruction">Ins: [{ord.orderInstruction}] </div>}
                            {ord.reason !== '' && <div className="order-instruction">Reason: [{ord.reason}]</div>}
                        </div> */}
                        {/*  */}

                        {/* //added by dasprakash on 31-10-2023 jira-1689 */}
                        <div onClick={this.cart.bind(this, ord.orderDetails)} className='Repeat-Order-v2'>
                            <Button variant="success">Repeat Order</Button>
                        </div>
                        {/*  */}


                    </div>
                )
                }
                {outletDetails.enableViewBill &&
                    //    style added by vijay on 07-11-2023
                    <div className="section-paynow" style={{ position: 'sticky' }}>
                        {/* //style added by dasprakash on 31-10-2023 jira-1689 */}
                        <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            {/* //commented by dasprakash on 31-10-2023 jira-1689 */}
                            {/* <div className="unbill-txt">Unbill Amount</div> */}
                            <div className="unbill-amount"><Currency /> {this.state.unBillAmount.toFixed(2)}</div>
                            {/* //style added by dasprakash on 31-10-2023 jira-1689 */}
                            <div style={{ fontSize: "16px", fontWeight: "500" }}>Total</div>
                        </div>
                        {/* //style added by dasprakash on 31-10-2023 jira-1689 */}
                        <div style={{ width: "50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            {enmConfig.checkOutTypewithPay &&
                                <div className="btn-paynow" onClick={this.goBill}>
                                    {/* <img className="paybill" src="../assets/theme1/img/pay-bill.png" alt="lucid-rupee" /> */}
                                    Pay Now
                                </div>
                            }
                            {!enmConfig.checkOutTypewithPay &&
                                <div className="btn-paynow" onClick={this.goBill}>
                                    {/* <img className="paybill" src="../assets/theme1/img/pay-bill.png" alt="lucid-rupee" /> */}
                                    View Bill
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>

        );
    }

    handleBillDom() {
        //console.log("handleBillDomsss", this.state.todayBillList)
        return (
            <div>
                {
                    this.state.todayBillList.map(bill =>
                        <div className="order-row bill-row">

                            {/* //added by dasprakash on 31-10-2023 jira-1689 */}
                            <div className='bills-details-v2'>
                                <div className='bills-details-section1-v2'>
                                    <p>Bill No:{bill.billNo}</p>
                                    <p>{bill.billDateTime}</p>
                                </div>
                                <div className='bills-details-section2-v2'>
                                    {bill.billStatus.toLowerCase() === "paid" && <div className="bill-stauts status-0">Paid</div>}
                                    {bill.billStatus.toLowerCase() === "cancelled" && <div className="bill-stauts status-3">Cancelled</div>}
                                    {bill.billStatus.toLowerCase() === "cancelled & refunded" && <div className="bill-stauts-mode status-3">Cancelled & Refunded</div>}
                                    {bill.billStatus.toLowerCase() === "notpaid" && <div className="bill-stauts status-1">NotPaid</div>}
                                    {bill.billStatus.toLowerCase() === "void" && <div className="bill-stauts status-1">Void</div>}
                                    {bill.billStatus.toLowerCase() === "not paid" && <div className="bill-stauts status-1">Not Paid</div>}

                                    {bill.paymentModes !== '' &&
                                        <div style={{ color: "#2E2E2E" }} >Pay Mode: <span>{bill.paymentModes}</span></div>}

                                    <div >Bill Amount: ₹<span className="bill-amount-val">{bill.billAmount.toFixed(2)}</span></div>
                                </div>
                            </div>
                            {/*  */}


                            {/* //commented by dasprakash on 31-10-2023 jira-1689 */}
                            {/* <div className="accordion" id="accordion2">
                                <div className="accordion-heading">
                                    <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href={"#collapseOne" + bill.crmBillId}>
                                        <span className="view-item-detail">View Orders</span>
                                        <i className="fa fa-angle-right left-arrow" ></i>
                                    </a>
                                </div>
                                <div id={"collapseOne" + bill.crmBillId} className="accordion-body collapse">
                                    <div className="accordion-inner">
                                        {
                                            bill.billDetails.map(ord =>
                                                <div className="order-row">
                                                    <div className="sdprofile">
                                                        <div className="sdp-left">
                                                            <img src="../assets/theme1/img/user-img.png" alt="profile" />
                                                        </div>
                                                        <div className="sdp-right">
                                                            <div className="sd-name">{ord.guestName === '' ? '-' : ord.guestName}</div>
                                                            <div className="sd-status">{ord.guestMobileNumber === '' ? '-' : ord.guestMobileNumber}</div>
                                                            <div className="order-table-no">Table No: <span>{ord.tableNumber}</span></div>
                                                        </div>




                                                        <div className="repeat-order-btn">Repeat Order</div>




                                                        {ord.orderStatus === "Placed" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Accepted" && <div className="order-stauts status-0">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Preparing" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Prepared" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Food Ready" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "FoodReady" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Dispatched" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Completed" && <div className="order-stauts status-0">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Delivered" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Forward" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Rejected" && <div className="order-stauts status-3">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Cancelled" && <div className="order-stauts status-3">{ord.orderStatus}</div>}
                                                        <div className="order-status-date itm-cnt">{ord.orderStatusUpdatedDateTime}</div>
                                                        <div className="order-no">Order No: <span className="order-no-val">{ord.orderNo}</span></div>
                                                        <div className="order-date itm-cnt">Order Date: <span>{ord.orderDateTime}</span></div>
                                                        <div className="kot-no">KOT No: <span className="order-no-val">{ord.kotNo}</span></div>
                                                    </div>
                                                    <div>
                                                        <div className="accordion" id="accordion2">
                                                            <div className="accordion-heading">
                                                                <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href={"#collapseOne" + ord.orderId}>
                                                                    <span className="view-item-detail">View Details</span>
                                                                    <i className="fa fa-angle-right left-arrow" ></i>
                                                                </a>
                                                            </div>
                                                            <div id={"collapseOne" + ord.orderId} className="accordion-body collapse">
                                                                <div className="accordion-inner">
                                                                    <table className="table">
                                                                        <thead className="thead-txt">
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Qty</th>
                                                                                <th className="text-right">Rate</th>
                                                                                <th className="text-right">Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="tbody-txt">
                                                                            {
                                                                                ord.orderDetails.map((oitm, i) => {
                                                                                    return (
                                                                                        <Fragment key={"orders-frg-tbl-itm-tr-" + (i + 1)}>
                                                                                            <tr key={"orders-tbl-itm-tr-" + (i + 1)}>
                                                                                                <td>{oitm.itemName.toLowerCase()}</td>
                                                                                                <td>x{oitm.quantity}</td>
                                                                                                <td className="text-right">₹{oitm.rate.toFixed(2)}</td>
                                                                                                <td className="text-right">₹{(oitm.rate * oitm.quantity).toFixed(2)}</td>
                                                                                            </tr>
                                                                                            {
                                                                                                oitm.modifiers.map(gmd =>

                                                                                                    <tr>
                                                                                                        <td className="modifier-item-txt">{gmd.modifierName.toLowerCase()}</td>
                                                                                                        <td className="modifier-item-row">x{gmd.quantity}</td>
                                                                                                        <td className="text-right modifier-item-row">₹{gmd.rate.toFixed(2)}</td>
                                                                                                        <td className="text-right modifier-item-row">₹{(gmd.rate * gmd.quantity).toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                )

                                                                                            }
                                                                                            {
                                                                                                oitm.instruction !== "" &&
                                                                                                <tr>
                                                                                                    <td colSpan="4"> <div className="item-instruction"> Ins:[{oitm.instruction}]</div></td>
                                                                                                </tr>
                                                                                            }
                                                                                        </Fragment>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {ord.orderInstruction !== '' && <div className="order-instruction">Ins: [{ord.orderInstruction}] </div>}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div> */}
                            {/*  */}

                            {/* //added by dasprakash on 31-10-2023 jira-1689 */}
                            <div className='view-bill-details-v2' onClick={this.goViewBill.bind(this, bill.billShortUrl, bill.billAmount, false, bill.crmBillId, bill.billId)}>
                                <span>View Bill Details</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                                    <path d="M1 1L8 8L1 15" stroke="#18A437" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </div>
                            {/*  */}


                            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }} >
                                {(bill.billStatus.toLowerCase() === "not paid" && bill.crmBillId !== "" &&
                                    enmConfig.checkOutTypewithPay && outletDetails.enableViewBill) &&
                                    <span style={{ backgroundColor: "#18A437", textAlign: "center", padding: "10px 0", borderRadius: "0px 0px 11px 11px", fontSize: "16px", fontWeight: "400", color: "#FFF" }} className="bill-pay-now-btn" onClick={this.goViewBill.bind(this, bill.billShortUrl, bill.billAmount, true, bill.crmBillId, bill.billId)}>
                                        {/* //commented by dasprakash on 31-10-2023 jira-1689 */}
                                        {/* <img className="paybill" src="../assets/theme1/img/pay-bill.png" alt="lucid-rupee" /> */}
                                        Pay Bill
                                    </span>
                                }
                                {(bill.billStatus.toLowerCase() === "not paid" && bill.crmBillId !== "" &&
                                    !enmConfig.checkOutTypewithPay && outletDetails.enableViewBill) &&
                                    <span className="bill-pay-now-btn" onClick={this.goViewBill.bind(this, bill.billShortUrl, bill.billAmount, false, bill.crmBillId, bill.billId)}>
                                        {/* //commented by dasprakash on 31-10-2023 jira-1689 */}
                                        {/* <img className="paybill" src="../assets/theme1/img/pay-bill.png" alt="lucid-rupee" /> */}
                                        View Bill
                                    </span>
                                }

                            </div>

                            {/* //temporary commented by dasprakash on 31-10-2023 jira-1689 */}
                            {/* {(bill.guestAddress != null && bill.serviceType === Config.serviceType.DELIVERY) &&
                                <div className="order-delivery-address">
                                    <span style={{ fontSize: "12px", fontWeight: "500" }}>Delivery:</span> {bill.guestAddress.addressLine1} {bill.guestAddress.addressLine2} {bill.guestAddress.addressLine3}  <br />
                                    {bill.guestAddress.area} <br />
                                    {bill.guestAddress.city} - {bill.guestAddress.zipCode} <br />
                                    Landmark: {bill.guestAddress.landmark}
                                </div>
                            }
                            {bill.reason !== '' && <div className="order-instruction">Reason: [{bill.reason}]</div>} */}
                            {/*  */}
                        </div>
                    )
                }
            </div>
        );
    }

    handleHistoryDom() {

        return (
            <div>
                {
                    this.state.historyBillsList.map((bill, i) =>
                        <div className="order-row bill-row" key={"order-row-bill-row" + i}>
                            <div className="sdprofile bill-sdprofile">
                                <div className="bill-no">Bill No: <span className="bill-no-val">{bill.billNo}</span></div>
                                <div className="bill-date itm-cnt">Bill Date: <span>{bill.billDateTime}</span></div>
                                {bill.paymentModes !== '' && <div className="bill-mode itm-cnt">Pay Mode: <span>{bill.paymentModes}</span></div>}
                                {bill.serviceType === Config.serviceType.TABLESERVICE && <div className="bill-table-no">Table No: <span>{bill.tableNumber}</span></div>}
                                <div className="bill-amount">Bill Amount: ₹<span className="bill-amount-val">{bill.billAmount.toFixed(2)}</span></div>
                                {/* <div className="bill-stauts-mode"><span>{bill.billStatus}</span> / <span>{bill.paymentModes}</span>  </div>*/}

                                {bill.billStatus.toLowerCase() === "paid" && <div className="bill-stauts status-0">Paid</div>}
                                {bill.billStatus.toLowerCase() === "cancelled" && <div className="bill-stauts status-3">Cancelled</div>}
                                {bill.billStatus.toLowerCase() === "cancelled & refunded" && <div className="bill-stauts-mode status-3">Cancelled & Refunded</div>}
                                {bill.billStatus.toLowerCase() === "notpaid" && <div className="bill-stauts status-1">NotPaid</div>}
                                {bill.billStatus.toLowerCase() === "void" && <div className="bill-stauts status-1">Void</div>}
                                {bill.billStatus.toLowerCase() === "not paid" && <div className="bill-stauts status-1">Not Paid</div>}
                                {(bill.billStatus.toLowerCase() === "not paid" && bill.crmBillId !== "" &&
                                    enmConfig.checkOutTypewithPay && outletDetails.enableViewBill) && <span className="bill-pay-now-btn" onClick={this.goViewBill.bind(this, bill.billShortUrl, bill.billAmount, true, bill.crmBillId, bill.billId)}><img className="paybill" src="/img/pay-bill.png" alt="lucid-rupee" />Pay Bill</span>
                                }
                                {(bill.billStatus.toLowerCase() === "not paid" && bill.crmBillId !== "" &&
                                    !enmConfig.checkOutTypewithPay && outletDetails.enableViewBill) && <span className="bill-pay-now-btn" onClick={this.goViewBill.bind(this, bill.billShortUrl, bill.billAmount, false, bill.crmBillId, bill.billId)}><img className="paybill" src="/img/pay-bill.png" alt="lucid-rupee" />View Bill</span>
                                }
                                <div className="bill-view-ebill" onClick={this.goViewBill.bind(this, bill.billShortUrl, bill.billAmount, false, bill.crmBillId, bill.billId)}>View Bill Details</div>

                            </div>
                            <div className="accordion" id="accordion2">
                                <div className="accordion-heading">
                                    <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href={"#collapseOne" + bill.crmBillId}>
                                        <span className="view-item-detail">View Orders</span>
                                        <i className="fa fa-angle-right left-arrow" ></i>
                                    </a>
                                </div>
                                <div id={"collapseOne" + bill.crmBillId} className="accordion-body collapse">
                                    <div className="accordion-inner">
                                        {
                                            bill.billDetails.map((ord, i) =>
                                                <div className="order-row" key={"div-order-now" + i}>
                                                    <div className="sdprofile">
                                                        <div className="sdp-left">
                                                            <img src="/img/user-img.png" alt="profile" />
                                                        </div>
                                                        <div className="sdp-right">
                                                            <div className="sd-name">{ord.guestName === '' ? '-' : ord.guestName}</div>
                                                            <div className="sd-status">{ord.guestMobileNumber === '' ? '-' : ord.guestMobileNumber}</div>
                                                            <div className="order-table-no">Table No: <span>{ord.tableNumber}</span></div>
                                                        </div>
                                                        {/* <div className="repeat-order-btn">Repeat Order</div> */}
                                                        {ord.orderStatus === "Placed" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Accepted" && <div className="order-stauts status-0">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Preparing" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Prepared" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Food Ready" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "FoodReady" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Dispatched" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Completed" && <div className="order-stauts status-0">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Delivered" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Forward" && <div className="order-stauts status-1">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Rejected" && <div className="order-stauts status-3">{ord.orderStatus}</div>}
                                                        {ord.orderStatus === "Cancelled" && <div className="order-stauts status-3">{ord.orderStatus}</div>}
                                                        <div className="order-status-date itm-cnt">{ord.orderStatusUpdatedDateTime}</div>
                                                        <div className="order-no">Order No: <span className="order-no-val">{ord.orderNo}</span></div>
                                                        <div className="order-date itm-cnt">Order Date: <span>{ord.orderDateTime}</span></div>
                                                        <div className="kot-no">KOT No: <span className="order-no-val">{ord.kotNo}</span></div>
                                                    </div>
                                                    <div>
                                                        <div className="accordion" id="accordion2">
                                                            <div className="accordion-heading">
                                                                <a className="accordion-toggle" data-toggle="collapse" data-parent="#accordion2" href={"#collapseOne" + ord.orderId}>
                                                                    <span className="view-item-detail">View Details</span>
                                                                    <i className="fa fa-angle-right left-arrow" ></i>
                                                                </a>
                                                            </div>
                                                            <div id={"collapseOne" + ord.orderId} className="accordion-body collapse">
                                                                <div className="accordion-inner">
                                                                    <table className="table">
                                                                        <thead className="thead-txt">
                                                                            <tr>
                                                                                <th>Name</th>
                                                                                <th>Qty</th>
                                                                                <th className="text-right">Rate</th>
                                                                                <th className="text-right">Amount</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="tbody-txt">
                                                                            {
                                                                                ord.orderDetails.map((oitm, i) => {
                                                                                    return (
                                                                                        <Fragment key={"orders-frg-tbl-itm-tr-" + (i + 1)}>
                                                                                            <tr key={"orders-tbl-itm-tr-" + (i + 1)}>
                                                                                                <td>{oitm.itemName.toLowerCase()}</td>
                                                                                                <td>x{oitm.quantity}</td>
                                                                                                <td className="text-right">₹{oitm.rate.toFixed(2)}</td>
                                                                                                <td className="text-right">₹{(oitm.rate * oitm.quantity).toFixed(2)}</td>
                                                                                            </tr>
                                                                                            {
                                                                                                oitm.modifiers.map((gmd, i) =>
                                                                                                    <tr key={"modifier-item-row" + i}>
                                                                                                        <td className="modifier-item-txt">{gmd.modifierName.toLowerCase()}</td>
                                                                                                        <td className="modifier-item-row">x{gmd.quantity}</td>
                                                                                                        <td className="text-right modifier-item-row">₹{gmd.rate.toFixed(2)}</td>
                                                                                                        <td className="text-right modifier-item-row">₹{(gmd.rate * gmd.quantity).toFixed(2)}</td>
                                                                                                    </tr>
                                                                                                )

                                                                                            }
                                                                                            {
                                                                                                oitm.instruction !== "" &&
                                                                                                <tr>
                                                                                                    <td colSpan="4"> <div className="item-instruction"> Ins:[{oitm.instruction}]</div></td>
                                                                                                </tr>
                                                                                            }
                                                                                        </Fragment>
                                                                                    );
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {ord.orderInstruction !== '' && <div className="order-instruction">Ins: [{ord.orderInstruction}] </div>}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            {(bill.guestAddress != null && bill.serviceType === Config.serviceType.DELIVERY) &&
                                <div className="order-delivery-address">
                                    <span style={{ fontSize: "12px", fontWeight: "500" }}>Delivery:</span> {bill.guestAddress.addressLine1} {bill.guestAddress.addressLine2} {bill.guestAddress.addressLine3}  <br />
                                    {bill.guestAddress.area} <br />
                                    {bill.guestAddress.city} - {bill.guestAddress.zipCode} <br />
                                    Landmark: {bill.guestAddress.landmark}
                                </div>
                            }
                            {bill.reason !== '' && <div className="order-instruction">Reason: [{bill.reason}]</div>}

                        </div>
                    )
                }
            </div>
        );
    }

    goBill() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.history.push('/mybills' + urlPattern);
        }, Config.minMsgTimeout);
    }

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };
    render() {
        return (
            <div>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                <div style={{ backgroundColor: "#F4F4F4" }} id="content" className="top">
                    <div className="content-warp">
                        <div className="section-myorders">
                            <div className="heading-page" ref="recommended">
                                <p style={{ fontSize: "16px", fontWeight: "500", textAlign: "center", color: "#000" }}>MyOrder</p>
                                {/* <span>My Order</span> */}
                                <div className="back-btn">
                                    <div className="icon-back" onClick={this.goBack}>
                                        {/* //commented by dasprakash on 30-10-2023 jira-1689 */}
                                        {/* <i className="fa fa-angle-left" ></i> */}

                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="22" viewBox="0 0 13 22" fill="none">
                                            <path d="M12 21L2 11L12 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                        </svg>

                                    </div>
                                </div>
                                {/* //added by dasprakash on 31-10-2023 jira-1689 */}
                                <svg onClick={this.GetUpdateOrders} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" className="refresh-icon-myorders">
                                    <path d="M20 10.5C20 15.744 15.744 20 10.5 20C5.256 20 2.0545 14.718 2.0545 14.718M2.0545 14.718H6.3485M2.0545 14.718V19.468M1 10.5C1 5.256 5.218 1 10.5 1C16.8365 1 20 6.282 20 6.282M20 6.282V1.532M20 6.282H15.782" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {/* <div className="refresh-icon" onClick={this.GetUpdateOrders}> <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i></div> */}
                            </div>
                            {/* <div className="content-from m-t-contact-form">
                                <div className="order-details">
                                    {
                                        this.state.billsList.map(ord =>
                                            <div className="content" key={"ord-div-" + ord.billId}><span className="orderno">Bill no : {ord.billNo}</span><a href={ord.shortUrl} target="_blank"><span className="btn-detail">Details</span></a>
                                                <div className="date"><span>{ord.billDateTime}</span></div>
                                                {ord.billStatus === "Paid" && <div className="status-1">PAID</div>}
                                                {ord.billStatus === "Cancelled" && <div className="status-3">CANCELLED</div>}
                                                {ord.billStatus === "NotPaid" && <div className="status-3">NotPaid</div>}
                                            </div>

                                        )
                                    }
                                    {this.state.ordersList.length === 0 &&
                                        <div className="content">
                                            <span className="orderno">{this.state.orderMsg}</span>
                                            <div className="status-1">{this.state.orderErrorMsg} </div>
                                        </div>
                                    }
                                </div>
                            </div> */}
                            <div>
                                <Tabs>
                                    {/* <TabList>
                                        <Tab>Today Orders</Tab>
                                        <Tab onClick={this.handleHistoryBill}>History {this.state.totalHistory > 0 && <span>({this.state.totalHistory})</span>}</Tab>
                                    </TabList> */}
                                    {/* <TabPanel> */}
                                    <Tabs>
                                        {/* //style added by dasprakash on 31-10-2023 jira-1689 */}
                                        <TabList style={{ backgroundColor: "#fff", position: "sticky", top: "49px", zIndex: "4" }}>
                                            {/* //style added by dasprakash on 31-10-2023 jira-1689 */}
                                            <Tab style={{ width: "50%", textAlign: 'center', fontSize: "16px", fontWeight: "500px", color: "#6F7072" }}>Orders
                                                {this.state.totalOrders > 0 && <span>({this.state.totalOrders})</span>}
                                            </Tab>
                                            {/* //style added by dasprakash on 31-10-2023 jira-1689 */}
                                            <Tab style={{ width: "50%", textAlign: 'center', fontSize: "16px", fontWeight: "500px", color: "#6F7072" }} onClick={this.handleTodayBill}>Bills
                                                {this.state.totalBills > 0 && <span>({this.state.totalBills})</span>}
                                            </Tab>
                                        </TabList>
                                        <TabPanel>
                                            {this.handleTodayOrderDom()}
                                            <div className="content history-result-msg status-1">
                                                <span>{this.state.todayOrderMsg}</span>
                                            </div>
                                        </TabPanel>
                                        <TabPanel>
                                            {this.handleBillDom()}
                                            <div className="content history-result-msg status-1">
                                                <span>{this.state.todayBillMsg}</span>
                                            </div>

                                        </TabPanel>
                                    </Tabs>
                                    {/* </TabPanel> */}
                                    <TabPanel>
                                        {this.handleHistoryDom()}
                                        <div className="content history-result-msg status-1">
                                            <span>{this.state.historyBillMsg}</span>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        );
    };
}