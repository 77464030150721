const Config = {
    siteTitle: "Lucid Order App", // Site title.
    siteTitleShort: "Lucid Contactless Order", // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
    siteTitleAlt: "Lucid Contactless Order", // Alternative site title for SEO.
    siteLogo: "/logos/logo-1024.png", // Logo used for SEO and manifest.
    siteUrl: "http://localhost:2016", // Domain of your website without pathPrefix.
    pathPrefix: "/lco",
    siteImagePathUrl: "../../assets/theme/img/store/logo/lucid-store-1.png",
    IsMobileAccess: false,
    appVersion: "v=1.2.0.32",

    // // // Live -- Start

    // baseGuestUrl: "https://apps.lucidpos.com/LUCIDPOSIntegrationAPI/V1/",
    // basePOSUrl: "https://apps.lucidpos.com/LUCIDPOSIntegrationAPI/V1/",
    // basePaymentUrl: "https://apps.lucidpos.com/LUCIDAPI/V1/",
    // baseCRMUrl: "https://apps.lucidpos.com/LUCIDCRMAPI/V1/",

    // // // // Place and Order
    // redirectUrl: "https://apps.lucidpos.com/LUCIDRedirectV2/Redirect/CallBackUrl",
    // redirectFailedUrl: "https://apps.lucidpos.com/LUCIDRedirectV2/Redirect/PaymentFailed",

    // // Bill and Settle
    // redirectPostPaidUrl: "https://apps.lucidpos.com/LUCIDRedirectV2/Redirect/PostPaidCallBackUrl",
    // redirectPostPaidFailedUrl: "https://apps.lucidpos.com/LUCIDRedirectV2/Redirect/PostPaidPaymentFailed",

    // //BillSettle
    // redirectBillPaidUrl: "https://apps.lucidpos.com/LUCIDRedirectV2/BillPaidCallBackUrl",
    // redirectBillPaidFailedUrl: "https://apps.lucidpos.com/LUCIDRedirectV2/Redirect/BillPaidPaymentFailed",


    baseGuestUrl: "https://dev.lucidits.com/LUCIDPOSIntegrationAPI/V1/",
    basePOSUrl: "https://dev.lucidits.com/LUCIDPOSIntegrationAPI/V1/",
    basePaymentUrl: "https://dev.lucidits.com/LUCIDAPI/V1/",
    baseCRMUrl: "https://dev.lucidits.com/LUCIDCRMAPI/V1/",

    //V2
    // // // // Place and Order
    redirectUrl: "https://link.lucidits.com/b/dev2.r/Redirect/CallBackUrl",
    redirectFailedUrl: "https://link.lucidits.com/b/dev2.r/Redirect/PaymentFailed",

    // //  // Bill and Settle
    redirectPostPaidUrl: "https://link.lucidits.com/b/dev2.r/Redirect/PostPaidCallBackUrl",
    redirectPostPaidFailedUrl: "https://link.lucidits.com/b/dev2.r/Redirect/PostPaidPaymentFailed",

    // //BillSettle
    redirectBillPaidUrl: "https://link.lucidits.com/b/dev2.r/Redirect/BillPaidCallBackUrl",
    redirectBillPaidFailedUrl: "https://link.lucidits.com/b/dev2.r/Redirect/BillPaidPaymentFailed",

    // Local

    // redirectUrl: "http://localhost:3019/Redirect/CallBackUrl",
    // redirectFailedUrl: "http://localhost:3019/Redirect/PaymentFailed",

    // redirectPostPaidUrl: "http://localhost:3019/Redirect/PostPaidCallBackUrl",
    // redirectPostPaidFailedUrl: "http://localhost:3019/Redirect/PostPaidPaymentFailed",

    // redirectBillPaidUrl: "http://localhost:3019/Redirect/BillPaidCallBackUrl",
    // redirectBillPaidFailedUrl: "http://localhost:3019/Redirect/BillPaidPaymentFailed",

    tokenKey: "A519618A-7218-4375-AC2B-34811ED6AD37",
    razorpayRedirectUrl: "https://api.razorpay.com/v1/checkout/embedded",
    urlAliveDurationMinutes: 15, // 15 Minitues
    ordercheckoutType: [
        { Id: 1, Name: "Place Order" },
        { Id: 2, Name: "Pay & Place Order" },
        { Id: 3, Name: "Place Order |  Pay & Place Order" }
    ],
    applicationCode: "CL-APP",
    cmsUserName: "lucid@cms",
    cmsPassword: "admin@cms[lucid]",
    linkUserName: "lucid@WebsiteShorturl",
    linkPassword: "web@Redirect[lucid]",
    baseAPIUrl: "https://link.lucidits.com/api-lucid/beta/V1/",
    // baseAPIUrl: "https://ap-lucid.com/api-lucid/V1/",
    bannerImageType: {
        Banner: 1,
        Logo: 2,
        Slider: 3,
        BackGroundImage: 4
    },
    pageIds: {
        home: 1,
        menu: 2,
        login: 3,
        splash: 4,
        cart: 5
    },
    pageType: {
        home: 1,
        menu: 2,
        gallery: 3,
        contact: 4,
        normal: 5,
        login: 6,
        cart: 7
    },
    pageSession: {
        Home: 1,
        Item: 2,
        Cart: 3,
        Login: 4,
        Verification: 5,
        JoinTabl: 6,
        PayBill: 7,
        MyOrders: 8,
        ChooseAddress: 9
    },
    qrCodeLevel: {
        Organization: 1,
        Property: 2,
        Outlet: 3,
        ServiceType: 4
    },
    roundOffType: {
        Nearest: 1,
        Higher: 2,
        Lower: 3,
    },
    serviceType: {
        NONE: "NONE",
        TABLESERVICE: "FINE-DINE",
        DINEIN: "DINE-IN",
        TAKEAWAY: "TAKE-AWAY",
        DELIVERY: "DELIVERY",
        ROOMSERVICE: "ROOM-SERVICE",
        DRIVETHRU: "DRIVE-THRU",
        BARTAB: "BAR-TAB"
    },
    formMode: {
        Add: 1,
        Edit: 2
    },
    GuestAddressCount: 5, //Max can add address    
    DefaultCurrencySymbol: "₹",
    secretkey: "$LuC!DCLB@)!^$",
    minMsgTimeout: 300,
    maxMsgTimeout: 1000,
    averageMsgTimeout: 800,
    verificationTimeout: 1200,
    imageBaseUrl: "/", // public
    defaultUrlPattern: "/v2/0/0", // default pattern
    appVersionNo: "v2",
};

module.exports = Config;