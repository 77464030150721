import React, { Component, Fragment } from 'react';
import './mybills.scss';
import LoadingAnimation from '../../components/loadinganimation';
//import * as QueryString from "query-string"
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
import enmConfig from '../../data/enumConfig';
//import { interpolate } from 'gsap';
let mobileNo = '';
let token = '';
let tableNo = '';
let serviceType = '';
let orgnationTd = '';
let propertyId = '';
let outletCode = '';
let loadcomfn = null;
let outletDetailsStr = null;
let outletDetails = {};
let urlPattern = '';
export default class MyOrders extends Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
        this.GetUpdateOrders = this.GetUpdateOrders.bind(this);
        this.handleProcessRequest = this.handleProcessRequest.bind(this);
        this.gotoShopping = this.gotoShopping.bind(this);
        this.state = {
            cartValue: 1500,
            loading: false,
            alertMessage: '',
            msgShow: false,
            billMsg: '',
            billErrorMsg: '',
            refreshSpin: 0,
            billhidden: '',
            orderhidden: 'active',
            totalOrderAmount: 0,
            totalOrderDetail: [],
            totalOrderSubTotal: {
                totalAmount: 0,
                totalChargeAmount: 0,
                totalTaxAmount: 0,
                netAmount: 0
            },
            totalOrderHeader: {
                tableNumber: '',
                outletImageUrl: '',
                organizationName: '',
                outletName: ''
            }


        }
    }

    componentDidMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }
        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        serviceType = sessionStorage.getItem('user-servicetype');
        orgnationTd = sessionStorage.getItem('user-orgnationid');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        outletDetailsStr = sessionStorage.getItem('user-outletDetails');
        urlPattern = sessionStorage.getItem('url-pattern');

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }

        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (orgnationTd === undefined || orgnationTd === "" || orgnationTd === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDetailsStr === undefined || outletDetailsStr === "" || outletDetailsStr === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        else {
            outletDetails = JSON.parse(outletDetailsStr);
            enmConfig.checkOutTypewithPay = outletDetails.paymentVendors.length > 0 ? true : false;
        }


        const script = document.createElement("script");
        script.src = "/theme.js"; // it is not calling mentioned for to avoid null value
        script.async = true;
        script.onload = () => this.loadJS();
        document.body.appendChild(script);



        // this.setState({ orderList: [],loading:false });
        // this.setState({ orderMsg: 'Fetching...',loading: true });
        //  this.setState({ orderErrorMsg: 'Looks like you have not made your menu yet.',orderMsg: "NO ORDERS FOUND",loading:false });
        this.GetUpdateOrders();
        // let urlParam = "?MobileNo=" + mobileNo + "&NoOfRowsPerPage=100&CurrentPageNumber=1";
        // this.setState({ orderMsg: 'Fetching...',loading: true });
        // fetch(Config.basePOSUrl + 'GetGuestOrders' + urlParam, {
        //     method: 'GET',
        //     headers: {
        //         Authorization: "Bearer " + token,
        //         'content-type': 'application/json'
        //     }
        // })
        //     .then(res => res.json())
        //     .then(response => {
        //         if (response.errorCode === 0) {
        //             setTimeout(() => {
        //                 console.log(response);
        //                 orderList = response.response.bills;
        //                 if (orderList.length > 0)
        //                     this.setState({ orderList: orderList,loading:false });
        //                 else {
        //                     this.setState({ orderErrorMsg: 'Looks like you have not made your menu yet.',orderMsg: "NO ORDERS FOUND",loading:false });
        //                 }
        //             }, 2000);
        //         }
        //         else {
        //             this.showMessage(response.message, "danger");
        //             console.log(response.message);
        //         }
        //     })
        //     .catch(err => {
        //         console.log(err);
        //         this.showMessage("An error occured", "danger");
        //     });

    }
    loadJS() {
        loadcomfn = window.myodersInitiate;
        loadcomfn();
    }
    goBack() {
        this.props.history.push('/myorders' + urlPattern);
    }
    gotoShopping() {
        this.props.history.push('/items' + urlPattern);
    }
    GetUpdateOrders() {
        window.scrollTo(0, 0);
        this.setState({ billMsg: 'Fetching...', loading: true });
        // Get Running Order Amount
        if (serviceType === "FINE-DINE") {
            let postObj = {
                OutletId: propertyId + outletCode,
                ServiceType: serviceType,
                TableNumber: tableNo
            }

            fetch(Config.basePOSUrl + 'GetOrderAmount', {
                method: "POST",
                headers:
                {
                    Authorization: "Bearer " + token,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postObj)
            })
                // fetch(Config.basePOSUrl + 'GetOrderAmount', {
                //     method: 'POST',
                //     headers: {
                //         Authorization: "Bearer " + token,
                //         'content-type': 'application/json'
                //     },
                //     body: JSON.stringify(postObj)
                // })
                .then(res => res.json())
                .then(response => {
                    if (response.errorCode === 0) {
                        this.setState({ loading: false });
                        this.setState({ totalOrderAmount: response.response.orderAmount })
                        this.setState({ totalOrderDetail: response.response.orderDetails.orderDetails })
                        this.setState({ totalOrderSubTotal: response.response.orderDetails.orderSubTotal })
                        this.setState({ totalOrderHeader: response.response.orderDetails.orderHeader })
                        this.setState({ billErrorMsg: response.message })
                    }
                    else {
                        // this.showMessage(response.message, "danger");
                        this.setState({ loading: false });
                        // this.showMessage(response.message, "danger");
                        this.setState({ totalOrderAmount: 0, billMsg: response.message })
                    }
                })
                .catch(err => {
                    // console.log(err);
                    this.setState({ totalOrderAmount: 0, billMsg: "Sorry!! nothing to show", loading: false });
                    this.showMessage(err, "danger");
                });
        }
        else {
            this.setState({ totalOrderAmount: 0, billMsg: "Sorry!! nothing to show" });
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.history.push('/items' + urlPattern);
            }, Config.minMsgTimeout);
        }

    }
    handleProcessRequest() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.props.history.push('/processrequest' + urlPattern, { totalNetAmount: this.state.totalOrderAmount });
        }, Config.minMsgTimeout);
    }
    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.minMsgTimeout);
    };
    render() {
        return (
            <div>
                {this.state.loading && <LoadingAnimation />}
                {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                {/*Added by prem */}
                <div id="content" className="top" style={{ backgroundColor: 'rgb(240, 240, 240)', paddingBottom: '10px' }}>
                    <div className="content-warp">
                        <div className="section-mybills">
                            {/* style modified by vijay on 04-11-2023 */}
                            <div className="heading-page" ref="recommended" style={{ textAlign: "center" }}>
                                {enmConfig.checkOutTypewithPay && <span>Pay Dining Bill </span>}
                                {!enmConfig.checkOutTypewithPay && <span>View Dining Bill </span>}
                                {/* commented by prem on 02-05-2024 <jira-></jira-> */}
                                {/* <div className="back-btn"> 
                                    <div className="icon-back" onClick={this.goBack}><i className="fa fa-angle-left" ></i></div>
                                </div> */}
                                {/* added by prem  on 02-05-2024 <jira-></jira-> */}
                                <div className="back-btn">
                                    <svg onClick={this.goBack} style={{ marginTop: "15px" }} xmlns="http://www.w3.org/2000/svg" width="11" height="22" viewBox="0 0 13 22" fill="none">
                                        <path d="M12 21L2 11L12 1" stroke="black" strokeWidth="2" strokeLinecap="round" />
                                    </svg>
                                </div>
                                {/* commented by prem on 02-05-2024 <jira-></jira-> */}
                                {/* <div className="refresh-icon" onClick={this.GetUpdateOrders}> <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i></div> */}
                                {/* added by prem  on 02-05-2024 <jira-></jira-> */}
                                <div className="refresh-icon" onClick={this.GetUpdateOrders} style={this.state.styleItemFilterRefreshDiv}>
                                    {/* <i className={this.state.refreshSpin === 0 ? "fas fa-sync-alt " : "fas fa-sync-alt "}></i> */}
                                    {/* level2 change */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 21 21" fill="none">
                                        <path d="M20 10.5C20 15.744 15.744 20 10.5 20C5.256 20 2.0545 14.718 2.0545 14.718M2.0545 14.718H6.3485M2.0545 14.718V19.468M1 10.5C1 5.256 5.218 1 10.5 1C16.8365 1 20 6.282 20 6.282M20 6.282V1.532M20 6.282H15.782" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            {this.state.totalOrderAmount > 0 &&
                                <div style={{ paddingTop: "1px" }} className="content-from">
                                    <div className="order-amount">Paying to</div>
                                    {/* <div className="order-amount">Total Order Amount <span style={{ color: "#d12f8b" }}>₹ {this.state.totalOrderAmount.toFixed(2)}</span></div> */}
                                    <div className='order-header'>
                                        <div>
                                            <img className="pay-to-img" src={this.state.totalOrderHeader.outletImageUrl} alt="" />
                                            <span className="orgname">{sessionStorage.getItem("user-propertyname")}</span><br />
                                            <span className="outlet-name">{sessionStorage.getItem("user-outletname")}</span>
                                        </div>
                                        <div className="total-amount">
                                            <span className="total-amount-txt">Total bill amount</span>
                                            <span className="total-amount-val"><span style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span>  {this.state.totalOrderAmount.toFixed(2)}</span>
                                        </div>
                                    </div>
                                    <div className='table-bills'>
                                        <table className="table">
                                            <thead className="thead-txt">
                                                <tr className='table-th-tr' style={{ fontSize: 16, fontWeight: 500, color: 'rgb(0, 0, 0)' }}>
                                                    <th className="item-name-col">Name</th>
                                                    <th style={{ paddingLeft: 8 }}>Qty</th>
                                                    <th className="text-right" style={{ paddingRight: 22 }}>Rate</th>
                                                    <th className="text-right" style={{ paddingRight: 8 }}>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tbody-txt">

                                                {
                                                    this.state.totalOrderDetail.map((oitm, i) => {
                                                        return (
                                                            <Fragment key={"orders-frg-tbl-itm-tr-" + oitm.itemId}>
                                                                <tr className='table-tbody-tr-ma' key={"orders-tbl-itm-tr-" + oitm.itemId} style={{ fontSize: 16, fontWeight: 500, color: 'rgb(0, 0, 0)' }}>
                                                                    <td className="item-name-col">{oitm.itemName.toLowerCase()}</td>
                                                                    <td><span style={{ textTransform: "lowercase" }}>x{oitm.quantity}</span></td>
                                                                    {/* <td className="text-right">₹{oitm.rate.toFixed(2)}</td> */}
                                                                    {/* <td className="text-right"><span className='table-tbody-td-span' style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span> {oitm.rate.toFixed(2)}</td> */}
                                                                    <td className="text-right">
                                                                        <div className="td-container"> {/* Wrapper div */}
                                                                            <span className='table-tbody-td-span' style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span>
                                                                            {oitm.rate.toFixed(2)}
                                                                        </div>
                                                                    </td>
                                                                    {/* <td className="text-right"><span className='table-tbody-td-span' style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span> {(oitm.rate * oitm.quantity).toFixed(2)}</td> */}
                                                                    <td className="text-right">
                                                                        <div className="td-container"> {/* Wrapper div */}
                                                                            <span className='table-tbody-td-span' style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span>
                                                                            {(oitm.rate * oitm.quantity).toFixed(2)}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                {
                                                                    oitm.modifiers.map(md =>
                                                                        <tr className='table-tbody-tr-mo' style={{ fontSize: '14px', fontStyle: 'italic', fontWeight: 400, color: 'rgb(111, 112, 114)' }}>
                                                                            <td className="modifier-group-txt item-name-col" style={{ fontSize: '14px', fontStyle: 'italic', fontWeight: 400, color: 'rgb(111, 112, 114)' }}>{md.modifierName.toLowerCase()}</td>
                                                                            <td><span style={{ textTransform: "lowercase" }}>x{md.quantity}</span></td>
                                                                            {/* <td className="text-right"><span className='table-tbody-td-span' style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span> {md.rate.toFixed(2)}</td>
                                                                            <td className="text-right"><span className='table-tbody-td-span' style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span> {(md.rate * md.quantity).toFixed(2)}</td> */}
                                                                            <td className="text-right">
                                                                                <div className="td-container"> {/* Wrapper div */}
                                                                                    <span className='table-tbody-td-span' style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span>
                                                                                    {md.rate.toFixed(2)}
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-right">
                                                                                <div className="td-container"> {/* Wrapper div */}
                                                                                    <span className='table-tbody-td-span' style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span>
                                                                                    {(md.rate * md.quantity).toFixed(2)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>

                                                                    )
                                                                }
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="bill-details">
                                        <div className='bill-footer'>
                                            <div className="heading">Bill Details</div>
                                            <div className="content"><span>Item Total</span><span className="price"><span style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span> {this.state.totalOrderSubTotal.totalAmount.toFixed(2)}</span></div>
                                            <div className="content"><span>Charges </span><span className="price"><span style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span> {this.state.totalOrderSubTotal.totalChargeAmount.toFixed(2)}</span></div>
                                            <div className="content tax"><span>Tax</span><span className="price"><span style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span> {this.state.totalOrderSubTotal.totalTaxAmount.toFixed(2)}</span></div>
                                            <div className="content total" style={{ color: '#000' }}><span>You need to pay</span><span className="price"><span style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span> {this.state.totalOrderSubTotal.netAmount.toFixed(2)}</span></div>
                                        </div>
                                    </div>
                                    <div className="error-msg"></div>
                                    {enmConfig.checkOutTypewithPay &&
                                        // <div className="pay-now" onClick={this.handleProcessRequest}> <span><img className="rupee" src="/img/rupee.png" alt="lucid-rupee" /></span> Proceed to Pay</div>
                                        <div className="pay-now" onClick={this.handleProcessRequest}> <span style={{ fontFamily: IntersectionObserver, fontSize: 16, fontWeight: 500 }}><span style={{ fontFamily: 'Inter', fontSize: '16px', fontWeight: 500 }}>₹</span> </span> Proceed to Pay</div>
                                    }
                                    {/* <div className="pay-now" onClick={this.handleProcessRequest}> <span><img className="rupee" src="../assets/theme1/img/rupee.png" alt="lucid-rupee" /></span> Pay</div> */}
                                </div>
                            }
                            {this.state.totalOrderAmount === 0 &&
                                <div>
                                    <img src="/img/table-logo-no-orders.png" className="no-order-img" alt="" />
                                    <span className="no-order-txt">{this.state.billMsg} </span>
                                    <div className="no-order-btn-div"><button className="theme-button btn no-order-btn" onClick={this.gotoShopping}>Continue Ordering</button></div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        );
    };
}