import React, { Component } from 'react';
// import React, { Component, useState, useEffect, useRef } from "react";
// import { gsap } from "gsap/all";
import Slider from "react-slick";
import defaultimg from '../items/default.png'
import LoadingAnimation from '../../components/loadinganimation';
import Loadingpage from '../../components/loadinganimation/loading-page';
//import loadjs from 'loadjs';
import Header from '../../components/Header';
import './home.scss';
//import { NavLink } from 'react-router-dom';
//import Cookies from 'universal-cookie';
import Config from '../../data/SiteConfig';
import ErrorAlert from '../../components/alertmessages';
import { isMobile } from 'react-device-detect';
//import sHelpers from '../../modules/helpers/statichelpers';
import Request from '../../components/request';
import Changeaccessmode from '../../components/Changeaccessmode';
import sHelpers from '../../modules/helpers/statichelpers';


const homeslidersettings = {
    autoplay: true,
    dots: true,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    adaptiveHeight: false
};



// const ymlcarousel = {
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//         {
//             breakpoint: 768,
//             settings: {
//                 slidesToShow: 4,
//                 slidesToScroll: 4
//             }
//         },
//         {
//             breakpoint: 480,
//             settings: {
//                 slidesToShow: 3,
//                 slidesToScroll: 3
//             }
//         }
//     ]
// };

// const groups = [
//     { grpId: 1, grpName: 'Biriyani', imgName: '../assets/theme1/img/biriyani-food-7.png', routePath: "/home" },
//     { grpId: 2, grpName: 'Soups', imgName: '../assets/theme1/img/soups.jpg', routePath: "/home" },
//     { grpId: 3, grpName: 'Burger', imgName: '../assets/theme1/img/fast-food-3.png', routePath: "/home" },
//     { grpId: 4, grpName: 'Hot Dog', imgName: '../assets/theme1/img/fast-food-4.png', routePath: "/home" },
//     { grpId: 5, grpName: 'Fry Chick', imgName: '../assets/theme1/img/fast-food-5.png', routePath: "/home" },
//     { grpId: 6, grpName: 'Shawarma', imgName: '../assets/theme1/img/fast-food-6.png', routePath: "/home" }
// ];
// added by dasprakash on 28-10-2023 jira-1677
const reqeustRef = React.createRef();

const accessModeRef = React.createRef();


// group pin added by dasprakash on 06-10-2023 jira-1490
let tablePins = '';

let loadcomfn = null;
let mobileNo = '';
let organizationId = '';

let token = '';
let tableNo = '';
let serviceType = '';
let propertyId = '';
let outletCode = '';

// let outletName = '';
let verifiedTable = '';
let statsId = '';
//let mainGuestMobileNo = '';
//let groupSession = false;

// group pin commented by dasprakash on 28-09-2023 jira-1490
// let groupPin = '';

//let olDetail = {};
//let outletDetails = null;
//let bstatic = "0";
let guestLoggedIn = "false";
let qrLevel = 5 // By Default Service type Mode
let mobileApp = "false";
let outletDet = [];
let isappOrders = true;

let useraccessmode = '';
let userSessionId = '';
//let sortedSliderImages = '';
let urlPattern = '';
export default class Home extends Component {

    constructor(props) {
        super(props);
        this.goToItems = this.goToItems.bind(this);
        // this.getDetails = this.getDetails.bind(this);
        // added by dasprakash on 28-10-2023 jira-1677
        this.callBackRequest = this.callBackRequest.bind(this);
        this.goToHelpRequest = this.goToHelpRequest.bind(this);



        this.state = {
            outletImg: "",
            redirectType: '',
            selectedClassificationIndex: "",
            getClassificationList: [],
            selectedClassification: [],
            loading: false,
            getMenus: [],
            getItems: [],
            getModifiers: [],
            getTaxes: [],
            getCharges: [],
            mobileNo: '',
            orgId: 0,
            propertyId: '',
            outletCode: '',
            tableNo: '',
            serviceType: '',
            alertMessage: '',
            msgType: '',
            msgShow: false,
            APIResponseMsg: '',
            outletDetails: null,

            classificationClicked: false,
            // added by dasprakash on 28-10-2023 jira-1677
            HelpRequestShow: false,
            accessModeShow: false,
            sliderImages: [
                // {
                //     altName: "",
                //     sortOrder: 0,
                //     bannerType: 0,
                //     height: 0,
                //     imageName: "",
                //     imagePathName: "",
                //     primaryImageType: "",
                //     secondaryImageType: "",
                //     bannerName: "Slide 1",
                //     primaryImageUrl: "../assets/theme1/img/slider/no-image.png",
                //     secondaryImageUrl: "",
                //     status: 0,
                //     width: 0
                // }
            ],
            groupMemberList: [],
            loggedIn: true,
            showOutlets: false,
            showServiceTypes: false,
            outletList: [],
            ServiceTypeList: [],
            pagePreview: "",
            pageContent: "",
            mobileApp: true,
            bannerImgs: [],
            pageTitle: '',
            pageLoading: false,
            tablePin: ''
        }

    }
    // added by dasprakash on 28-10-2023 jira-1677
    goToHelpRequest() {
        this.setState({ HelpRequestShow: true });
        setTimeout(() => {
            this.setState({ loading: false });
            this.handleRequest();
        }, Config.minMsgTimeout);

    };
    // added by dasprakash on 28-10-2023 jira-1677
    handleRequest(_this) {
        if (reqeustRef.current) {
            reqeustRef.current.handleRequest();
        }
    }
    // added by dasprakash on 28-10-2023 jira-1677
    callBackRequest(msg, type) {
        // ////console.log("from header");
        // ////console.log(msg + '-' + type);

        setTimeout(() => {
            if (type === 1) {
                // this.showMessage(msg, "success");
                this.setState({ HelpRequestShow: false });
            }
            else {
                // this.showMessage(msg, "danger");
                this.setState({ HelpRequestShow: false });
            }
        }, Config.minMsgTimeout);
    }
    // added by dasprakash on 7-11-2023 jira-1744
    handleAcessMode(_this) {
        if (accessModeRef.current) {
            accessModeRef.current.handleModifier();
        }
    }

    componentWillMount() {
        if (Config.IsMobileAccess) {
            if (!isMobile) {
                this.props.history.push('/deviceerror' + urlPattern);
                return false;
            }
        }


        //        

        // // Validate launch date time.
        // var _launchDateTime = sessionStorage.getItem("lnch-d-t");
        // var nowDT = new Date();

        // const isValidDate = !isNaN(Date.parse(_launchDateTime));

        // if (isValidDate == true) {
        //     const hasExpired = existingDate < nowDT;
        //     console.Console(hasExpired);
        //     //this.props.history.push('/');
        // }


        //$('.modal-backdrop').remove();
        //let cookie = new Cookies();
        // let token = cookie.get("token");
        // let mobileNo = cookie.get("user-mobileNo");
        // let tableNo = cookie.get("user-tableNo");
        // let serviceType = cookie.get("user-serviceType");


        // sessionStorage.removeItem('getMenuGroups');
        // sessionStorage.removeItem('getModifierGroups');
        // sessionStorage.removeItem('getTaxes');
        // sessionStorage.removeItem('getmenuItems');
        // sessionStorage.removeItem('getCharges');
        sessionStorage.removeItem("user-vendorOrderId");


        mobileNo = sessionStorage.getItem('user-mobileno');
        token = sessionStorage.getItem('user-token');
        tableNo = sessionStorage.getItem('user-tableno');
        organizationId = sessionStorage.getItem('user-orgnationid');
        serviceType = sessionStorage.getItem('user-servicetype');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        // mainGuestMobileNo = sessionStorage.getItem('main-guest-no') == null ? '' : sessionStorage.getItem('main-guest-no');

        // outletName = sessionStorage.getItem('user-outletname');

        verifiedTable = sessionStorage.getItem('user-table-verified');
        statsId = sessionStorage.getItem('user-statsid');
        // group pin added by dasprakash on 06-10-2023 jira-1490
        // tablePins = sessionStorage.getItem('table-pins') == null ? '' : sessionStorage.getItem('table-pins');
        tablePins = sHelpers.secureStorage.getItem('user-table-pin');
        //  groupSession = sessionStorage.getItem('group-session') == null ? false : sessionStorage.getItem('group-session');
        // group pin commended by dasprakash on 29-09-2023 jira-1493
        // groupPin = sessionStorage.getItem('group-pin') == null ? '' : sessionStorage.getItem('group-pin');
        // outletDetails = sessionStorage.getItem('user-outletDetails');
        //bstatic = sessionStorage.getItem('user-bstatic');
        guestLoggedIn = sessionStorage.getItem('user-loggedin') == null ? "false" : sessionStorage.getItem('user-loggedin');
        qrLevel = sessionStorage.getItem('user-qr-level') == null ? 5 : sessionStorage.getItem('user-qr-level');
        mobileApp = sessionStorage.getItem('mobile-app') == null ? "false" : sessionStorage.getItem('mobile-app');
        outletDet = JSON.parse(sessionStorage.getItem("user-outletDetails"));
        sessionStorage.setItem('dashboard-hide', (sessionStorage.getItem('dashboard-hide') == null ? "false" : sessionStorage.getItem('dashboard-hide')));
        userSessionId = sessionStorage.getItem('user-session-id') == null ? "" : sessionStorage.getItem('user-session-id');
        urlPattern = sessionStorage.getItem('url-pattern');
        useraccessmode = sessionStorage.getItem('user-access-mode')
        ////console.log("useraccessmode", useraccessmode)
        // //////console.log(token);
        // //////console.log(tableNo);
        // //////console.log(serviceType);
        // //////console.log(organizationId);
        // //////console.log(propertyId);
        //  //////console.log(guestLoggedIn);

        const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
        if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
            return false;
        }
        if (!urlPattern) {
            this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
            return false;
        }
        if (token === undefined || token === "" || token === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (mobileNo === undefined || mobileNo === "" || mobileNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (tableNo === undefined || tableNo === "" || tableNo === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (serviceType === undefined || serviceType === "" || serviceType === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (organizationId === undefined || organizationId === "" || organizationId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (propertyId === undefined || propertyId === "" || propertyId === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }
        if (outletCode === undefined || outletCode === "" || outletCode === null) {
            this.props.history.push('/' + urlPattern);
            return false;
        }

        if (outletDet !== null) {
            isappOrders = outletDet.enableGuestAppOrders;
            this.setState({ outletImg: outletDet.outletImageUrl });
        }
        // else {
        //     olDetail = JSON.parse(outletDetails);
        //     this.setState({ paymentVendorLogo: olDetail.outletImageUrl !== '' ? olDetail.outletImageUrl : "https://cdn.razorpay.com/logos/BUVwvgaqVByGp2_large.png" });
        // }      
        //  //////console.log(Config.qrCodeLevel.Property);


        if (serviceType === "FINE-DINE") {

            if (verifiedTable === undefined || verifiedTable === "" || verifiedTable === null || verifiedTable === false || verifiedTable === "false") {
                if (isappOrders === true) {
                    this.props.history.push('/' + urlPattern);
                    return false;
                }
            }
        }
        if (statsId === undefined || statsId === "" || statsId === null) {
            statsId = "0"
        }
        // var status = navigator.onLine;
        ////////console.log(guestLoggedIn);

        document.body.classList.remove('modal-open');
        let modalPopup = document.querySelector('.modal-backdrop');
        if (modalPopup !== null) {
            modalPopup.classList.remove('modal-backdrop', 'fade', 'show');
        }


        /// Session Validation for Fine Dine

        // Static Var
        // StaticValidation.StaticSwitchCtrl();
        // if (bstatic === "0") {     
        //else this.getDetails(false);
        // }
        // else {
        //     this.setState({ loading: true });
        //     setTimeout(() => {
        //         this.setState({ loading: false });
        //         this.props.history.push('/emhome');
        //     }, 1500);
        // }        
        // Check Auto Login

        // commented by dasprakash on 7-11-2023 jira-1744
        // if (parseInt(qrLevel) === Config.qrCodeLevel.Property) {
        //     ////console.log("console1")
        //     this.getOutletList();
        // }
        // else if (parseInt(qrLevel) === Config.qrCodeLevel.Outlet) {
        //     ////console.log("console2")

        //     this.getServiceTypeList(outletCode, outletName);
        // }
        // else {

        //     ////console.log("calling cms function")

        //     this.getCMSDetails(false); //Get CMS Page Details    // false - click history information will not update.  
        // }

        // added by dasprakash on 7-11-2023 jira-1744
        if (useraccessmode === null || useraccessmode === undefined || useraccessmode === "") {
            if (parseInt(qrLevel) === Config.qrCodeLevel.Property || parseInt(qrLevel) === Config.qrCodeLevel.Outlet) {
                // Added by dasprakash on 4-11-2023 jira-1744
                ////console.log("calling service mode function")
                this.setState({ accessModeShow: true })
                this.handleAcessMode();
            }
            else {
                ////console.log("calling cms function")
                this.getCMSDetails(false);
                this.setState({ accessModeShow: false })

            }
        }
        else {

            ////console.log("calling cms function")
            this.getCMSDetails(false); //Get CMS Page Details    // false - click history information will not update.  
            this.setState({ accessModeShow: false })

        }


    }
    componentDidMount() {
        //////console.log(this.state)
        //////console.log(sessionStorage.getItem('user-loggedin'))
        //////console.log(guestLoggedIn)

        const script = document.createElement("script");
        script.src = "/theme.js"; // it is not calling mentioned for to avoid null value
        script.async = true;
        script.onload = () => this.loadwindow();
        document.body.appendChild(script);

        this.loadClassficiation(false);

        if (guestLoggedIn === "true") {
            this.setState({ loggedIn: true });
        }

        if (mobileApp === "true") {

            this.setState({ mobileApp: true });
        }

        if (serviceType === "FINE-DINE") {
            // Comment line for avoid the logout automatically - Table Checkout Cases 
            // if (!sHelpers.sessionValidation()) {
            //     this.showMessage("Your session has expired, please scan once again", "danger");
            //     this.setState({ loading: true });
            //     setTimeout(() => {
            //         this.setState({ loading: false });
            //         this.props.history.push('/logout');
            //     }, 3000);
            //     return false;
            // }
            if (guestLoggedIn === "true") {
                // Condition added by vijay on 20-03-2024 /Due Digital menu mode 2 users have problem
                if (outletDet.enableGuestAppOrders === true) {
                    this.getGuestDashboardDetails();
                    this.handleCheckGuestTableStatus(mobileNo, tableNo);
                }

            }
            /// else this.getDetails(false);


        }
        // else {
        //     this.getDetails(false);
        // }

        // Handle Back Button -- Start
        // if (mobileApp === "true") {
        //     const { history } = this.props;
        //     history.listen((newLocation, action) => {

        //         //////console.log(newLocation.pathname);
        //         //////console.log(newLocation.search);
        //         //////console.log(action);

        //         //////console.log(this.currentPathname);
        //         //////console.log(this.currentSearch);
        //         //////console.log(this.action);

        //         // if (action === "PU5H") {
        //         //     if (
        //         //         newLocation.pathname !== this.currentPathname ||
        //         //         newLocation.search !== this.currentSearch
        //         //     ) {
        //         //         this.currentPathname = newLocation.pathname;
        //         //         this.currentSearch = newLocation.search;

        //         //         history.push({
        //         //             pathname: newLocation.pathname,
        //         //             search: newLocation.search
        //         //         });
        //         //     }
        //         //     else {
        //         //         //////console.log(newLocation.pathname);
        //         //         //////console.log(newLocation.search);
        //         //         //////console.log(action);

        //         //         // if (newLocation.pathname === '/home') {
        //         //         //     //////console.log(newLocation.pathname);
        //         //         //     this.props.history.push('/home');
        //         //         //     history.go(1);
        //         //         // }
        //         //         // else if (newLocation.pathname === '/items') {
        //         //         //     //////console.log('item');
        //         //         //     this.props.history.push('/items');
        //         //         // }
        //         //         // else if (newLocation.pathname === '/cart') {
        //         //         //     //////console.log('cart');
        //         //         //     this.props.history.push('/cart');
        //         //         // }
        //         //     }

        //         // } else {
        //         if (action === "POP") {
        //             //////console.log(newLocation.pathname);
        //             //////console.log(newLocation.search);
        //             //////console.log(action);

        //             alert('wait');

        //             // if (newLocation.pathname === '/home') {
        //             //     //////console.log(newLocation.pathname);
        //             //     this.props.history.push('/home');
        //             //     history.go(1);
        //             // }
        //             // else if (newLocation.pathname === '/items') {
        //             //     //////console.log('item');
        //             //     this.props.history.push('/items');
        //             // }
        //             // else if (newLocation.pathname === '/cart') {
        //             //     //////console.log('cart');
        //             //     this.props.history.push('/cart');
        //             // }
        //             // }
        //         }
        //     });
        // }
        // // Handle Back Button --  End

        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Use 'smooth' for smooth scrolling
            });
        }, Config.averageMsgTimeout);

    }
    // guestTableCheck() {

    //     if (outletCode !== 'NONE') {
    //         // //////console.log(outletCode);
    //         let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
    //         // // Get Outlet Details                    
    //         fetch(Config.basePOSUrl + 'GetOutletDetails' + urlParam, {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: "Bearer " + token,
    //                 'content-type': 'application/json'
    //             }
    //         })
    //             .then(res => res.json())
    //             .then(response => {
    //                 // //////console.log(response);
    //                 if (response.errorCode === 0) {
    //                     this.setState({ outletDetails: response.response.outletDetails });
    //                     // if (response.response.outletDetails.homePageSliderImages !== null && response.response.outletDetails.homePageSliderImages.length > 0) {
    //                     //     this.setState({ sliderImages: response.response.outletDetails.homePageSliderImages });
    //                     // }
    //                     sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
    //                     setTimeout(() => {
    //                         window.scrollTo(0, 0);
    //                     }, 1000);

    //                     //// Table Check 
    //                     if (guestLoggedIn === "true") {
    //                         if (serviceType === "FINE-DINE") {
    //                             let olDetail = this.state.outletDetails;
    //                             ////////console.log(olDetail);
    //                             let postObj = {
    //                                 OutletId: propertyId + outletCode,
    //                                 GuestMobileCountryCode: "91",
    //                                 GuestMobileNumber: this.state.loggedIn === true ? mainGuestMobileNo : '',
    //                                 TableNumber: serviceType !== "FINE-DINE" ? '' : tableNo
    //                             }

    //                             fetch(Config.basePOSUrl + 'GetGuestTableStatus', {
    //                                 method: 'POST',
    //                                 headers: {
    //                                     Authorization: "Bearer " + token,
    //                                     'content-type': 'application/json'
    //                                 },
    //                                 body: JSON.stringify(postObj)
    //                             })
    //                                 .then(res => res.json())
    //                                 .then(response => {
    //                                     if (response.errorCode === 0) {
    //                                         // //////console.log(response);
    //                                         if (!response.response.guestTableStatus.isCheckedIn) {
    //                                             //  //////console.log(olDetail);
    //                                             if (olDetail.autoCheckInWhenGuestContinueOrderInSameTable) {
    //                                                 let postObj = {
    //                                                     OutletId: propertyId + outletCode,
    //                                                     GuestName: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
    //                                                     MobileNo: this.state.loggedIn === true ? mainGuestMobileNo : '',
    //                                                     TableNumber: tableNo,
    //                                                     GroupCode: groupPin
    //                                                 }
    //                                                 fetch(Config.basePOSUrl + 'GuestCheckIn', {
    //                                                     method: 'POST',
    //                                                     headers: {
    //                                                         Authorization: "Bearer " + token,
    //                                                         'content-type': 'application/json'
    //                                                     },
    //                                                     body: JSON.stringify(postObj)
    //                                                 })
    //                                                     .then(res => res.json())
    //                                                     .then(response => {
    //                                                         if (response.errorCode === 0) {
    //                                                             setTimeout(() => {
    //                                                                 this.getDetails(true);
    //                                                             }, 500);
    //                                                         }
    //                                                         else {
    //                                                             this.showMessage("Your selected table has been checked out.", "danger");
    //                                                             setTimeout(() => {
    //                                                                 this.setState({ loading: false });
    //                                                                 this.props.history.push('/logout');
    //                                                             }, 3000);
    //                                                         }
    //                                                     })
    //                                                     .catch(err => {
    //                                                         sessionStorage.removeItem("user-token");
    //                                                         sessionStorage.removeItem("user-mobileno");
    //                                                         this.showMessage('Guest Check IN  - Failed to fetch - ' + err, "danger");
    //                                                     });
    //                                             }
    //                                             else {
    //                                                 this.showMessage('Your table has been Closed! , Please scan and login once again', "danger");
    //                                                 this.setState({ loading: true });
    //                                                 setTimeout(() => {
    //                                                     this.setState({ loading: false });
    //                                                     this.props.history.push('/');
    //                                                 }, 3000);
    //                                             }
    //                                         }
    //                                         else {
    //                                             if (tableNo !== response.response.guestTableStatus.currentTableNumber) {
    //                                                 this.showMessage("Your table has been transfer from " + tableNo + " to " + response.response.guestTableStatus.currentTableNumber, "danger");
    //                                                 sessionStorage.setItem('user-tableno', response.response.guestTableStatus.currentTableNumber);
    //                                                 tableNo = sessionStorage.getItem('user-tableno');
    //                                             }

    //                                             setTimeout(() => {
    //                                                 this.getDetails(true);
    //                                             }, 500);
    //                                         }
    //                                     }
    //                                     else {
    //                                         this.setState({ loading: false });
    //                                         this.showMessage(response.message, "danger");

    //                                     }
    //                                 })
    //                                 .catch(err => {
    //                                     this.setState({ loading: false });
    //                                     this.showMessage(err, "danger");
    //                                 });
    //                         } // End Fine Dine
    //                         else { // 
    //                             setTimeout(() => {
    //                                 this.getDetails(true);
    //                             }, 500);
    //                         }
    //                     } // End Check Logged In
    //                     else {
    //                         setTimeout(() => {
    //                             this.getDetails(true);
    //                         }, 500);
    //                     }
    //                 }

    //                 else {
    //                     this.setState({ APIResponseMsg: "" });
    //                 }
    //             })
    //             .catch(err => {
    //                 this.setState({ APIResponseMsg: "Outlet Details - Failed to fetch" })
    //                 //  //////console.log('Outlet Details - Failed to fetch - ' + err);
    //                 this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
    //             });

    //     } // End Outlet Valid
    //     else {
    //         setTimeout(() => {
    //             this.getDetails(true);
    //         }, 500);

    //     }
    // }

    getGuestDashboardDetails() {
        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType + '&TableNumber=' + tableNo + '&guestmobileNumber=' + mobileNo;
        ////////console.log(urlParam);
        fetch(Config.basePOSUrl + 'GetGuestAppDashBoardDetails' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //  console.log("getGuestDashboardDetails");
                //  console.log(response);
                if (response.errorCode === 0) {
                    // if (mobileNo === mainGuestMobileNo) {
                    //     // group pin commended by dasprakash on 29-09-2023 jira-1493
                    //     // this.setState({ groupPin: response.response.groupDetails.groupCode });
                    //     // groupPin = response.response.groupDetails.groupCode;

                    //     // group pin commended by dasprakash on 29-09-2023 jira-1493
                    //     // sessionStorage.setItem('group-pin', groupPin);
                    //     //Addded by vijay on 30-12-2023 Jira id -2075
                    //     this.setState({ tablePin: response.response.groupDetails.groupCode });
                    //     sHelpers.secureStorage.setItem('user-table-pin', response.response.groupDetails.groupCode);
                    // }
                    // else
                    //     this.setState({ groupPin: "" });

                    this.setState({ tablePin: response.response.groupDetails.groupCode });
                    tablePins = response.response.groupDetails.groupCode;
                    sHelpers.secureStorage.setItem('user-table-pin', response.response.groupDetails.groupCode);
                    this.setState({ groupMemberList: response.response.groupDetails.guestDetails });
                    // this.getDetails(false);

                }
                else {
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                //////////console.log(err);            
                this.showMessage('An error occured - ' + err, "danger");
            });
    }

    // getDetails(tableCheck) {

    //     this.setState({ APIResponseMsg: "Loading..." });


    //     // Guest Table Check - CheckIn / Check Out / Table Transfer - to Get latest Table Detail
    //     if (tableCheck === false) {
    //         this.guestTableCheck();
    //         return false;
    //     }
    //     this.setState({ APIResponseMsg: "" });

    // }

    loadItems() {

        // //////console.log(new Date())
        //  this.setState({ APIResponseMsg: "Loading..." });
        serviceType = sessionStorage.getItem('user-servicetype');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');

        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        fetch(Config.basePOSUrl + 'Getguestappmenus' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                ////console.log("loadItems api")
                // console.log("Getguestappmenus", response)
                if (response.errorCode === 0) {
                    // //////console.log(JSON.stringify(response.response.menuGroups));
                    //  //////console.log(JSON.stringify(response.response.menuItems));
                    // Filter active Menu Items
                    let activeMenuGroups = [];
                    let activeMenuItems = [];
                    // let sortMenus = [];
                    if (response.response.menuGroups.length > 0)
                        activeMenuGroups = response.response.menuGroups.filter(x => x.isActive === true).sort(function (a, b) {	// asc										 
                            return new Date(a.displayOrder) - new Date(b.displayOrder);
                        });

                    if (response.response.menuItems.length > 0) {
                        activeMenuItems = response.response.menuItems.filter(x => x.isActive === true).sort(function (a, b) {	// asc										 
                            return new Date(a.displayOrder) - new Date(b.displayOrder);
                        });
                        // let sortMenus = activeMenuItems.sort(function (a, b) {	// asc										 
                        //     return new Date(a.displayOrder) - new Date(b.displayOrder);
                        // });
                        //  //////console.log(activeMenuItems);
                    }
                    sessionStorage.setItem("getMenuGroups", JSON.stringify(activeMenuGroups));
                    sessionStorage.setItem("getmenuItems", JSON.stringify(activeMenuItems));
                    sessionStorage.setItem("getModifierGroups", JSON.stringify(response.response.modifierGroups));
                    sessionStorage.setItem("getTaxes", JSON.stringify(response.response.taxes));
                    sessionStorage.setItem("getCharges", JSON.stringify(response.response.charges));
                    sessionStorage.setItem("menuFetchDateTime", response.response.menuDateTime);
                    this.setState({ getMenus: activeMenuGroups });
                    this.setState({ getItems: activeMenuItems });
                    this.setState({ getModifiers: response.response.modifierGroups });
                    this.setState({ getTaxes: response.response.taxes });
                    this.setState({ getCharges: response.response.charges });
                    this.setState({ APIResponseMsg: "" });
                    // this.setState({ outletDetails: olDetail });

                    // if (olDetail.homePageSliderImages !== null && olDetail.homePageSliderImages.length > 0) {
                    //     this.setState({ sliderImages: olDetail.homePageSliderImages });
                    // }
                    // //  this.setState({ APIResponseMsg: "" });
                    // setTimeout(() => {
                    //     window.scrollTo(0, 0);
                    // }, 500);


                    // Check the static Page Redirection                   
                    // if (StaticValidation.enableGuestAppOrders === false && StaticValidation.enableDigitalMenu === true) {
                    //     this.setState({ loading: true });
                    //     setTimeout(() => {
                    //         this.setState({ loading: false });
                    //         this.props.history.push('/emhome');
                    //     }, 1500);
                    // }    

                    // // Get Outlet Details                    
                    // fetch(Config.basePOSUrl + 'GetOutletDetails' + urlParam, {
                    //     method: 'GET',
                    //     headers: {
                    //         Authorization: "Bearer " + token,
                    //         'content-type': 'application/json'
                    //     }
                    // })
                    //     .then(res => res.json())
                    //     .then(response => {
                    //         //  //////console.log(response);
                    //         if (response.errorCode === 0) {
                    //             this.setState({ outletDetails: response.response.outletDetails });
                    //             if (response.response.outletDetails.homePageSliderImages !== null && response.response.outletDetails.homePageSliderImages.length > 0) {
                    //                 this.setState({ sliderImages: response.response.outletDetails.homePageSliderImages });
                    //             }
                    //             sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                    //             this.setState({ APIResponseMsg: "" });
                    //             setTimeout(() => {
                    //                 window.scrollTo(0, 0);
                    //             }, 1000);

                    //         }
                    //         else {
                    //             this.setState({ APIResponseMsg: "Failed to fetch" });
                    //         }
                    //     })
                    //     .catch(err => {
                    //         this.setState({ APIResponseMsg: "Outlet Details - Failed to fetch" })
                    //         //  //////console.log('Outlet Details - Failed to fetch - ' + err);
                    //         this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
                    //     });
                    // setTimeout(() => {
                    //     this.setState({ loading: false })
                    //     this.props.history.push('/home', { focusGroup: "focus-mnugrp-focus-mnugrp-recommended" });
                    // }, 500);
                    setTimeout(() => {


                        this.setState({ loading: false })
                        // redirectType condition added by dasprakash on 30-08-2023 jira-1302 for redirection
                        if (this.state.redirectType === 1) {
                            this.props.history.push('/items' + urlPattern, { focusGroup: "focus-mnugrp-focus-mnugrp-recommended", selectedClassification: this.state.selectedClassification, selectedClassificationIndex: this.state.selectedClassificationIndex });

                        } else if (this.state.redirectType === 2) {
                            this.props.history.push('/itemsearch' + urlPattern, { selectedClassification: this.state.getClassificationList[0] });
                        }
                    }, Config.minMsgTimeout);
                }
                else {
                    this.setState({ APIResponseMsg: "Failed to fetch" })
                    this.showMessage(response.message, "danger");
                }
            })
            .catch(err => {
                this.setState({ APIResponseMsg: "Failed to fetch" })
                //////console.log(err);
                this.showMessage("An error occured", "danger");
            });
        // //////console.log(new Date())
    }

    loadClassficiation(isLoadiItem) {

        // //////console.log(new Date())
        serviceType = sessionStorage.getItem('user-servicetype');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');

        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        fetch(Config.basePOSUrl + 'GetClassificationList' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                //  console.log("loadClassficiation api")
                //  console.log(response)
                if (response.errorCode === 0) {
                    this.setState({ getClassificationList: response.response.classificationList });
                    sessionStorage.setItem("getclf", JSON.stringify(response.response.classificationList));
                    if (isLoadiItem) this.loadItems();
                    //Added by prem LDT-2751

                    const { location } = this.props;
                    const { guestAppLandingPage } = location.state || {};

                    if (guestAppLandingPage === 2) {
                        this.setState({ pageLoading: true });
                        if (response.response.classificationList.length > 0)
                            this.handleClassificationClick(response.response.classificationList[0]);
                    }
                    // return false;
                }
                else {
                    this.setState({ pageLoading: false });
                    this.setState({ APIResponseMsg: "Failed to fetch" })
                    this.showMessage(response.message, "danger");

                }
            })
            .catch(err => {
                this.setState({ pageLoading: false });
                this.setState({ APIResponseMsg: "Failed to fetch" })
                ////////console.log(err);
                // this.showMessage("An error occured", "danger");
            });
        // //////console.log("loadClassficiationEnd")
        // //////console.log(new Date())
    }
    getCMSDetails(IsUpdateLog) {

        // const storedData = sessionStorage.getItem("selectedClassification");
        // const parsedData = JSON.parse(storedData);
        if (this.state.selectedClassification.length === 0) {

            ////console.log(" cms function into  ")
            this.setState({ pageLoading: true });
            //let previewVal = "";
            //let bannerVal = [];
            this.setState({ APIResponseMsg: "Loading..." });

            let postobj = {
                organizationId: parseInt(organizationId),
                propertyId: propertyId,
                outletCode: outletCode,
                ServiceMode: serviceType,
                ApplicationCode: Config.applicationCode,
                PageId: Config.pageIds.home, // Default Home
                PageType: Config.pageType.home, // Home           
                RequestIp: "",
                username: Config.cmsUserName,
                password: Config.cmsPassword
            }

            ////console.log("postobj", postobj)


            fetch(Config.baseAPIUrl + 'PageDetails', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(postobj)
            })
                .then(res => res.json())
                .then(response => {
                    ////console.log("getCMSDetails api")
                    // console.log(response)
                    // //////console.log(this.state.selectedClassification)

                    if (response.isSuccessful) {
                        sessionStorage.setItem("pageCMSSettings", JSON.stringify(response));
                        this.setState({ pageTitle: response.page.pageTitle });
                        //this.getSliderImages();
                        // Slider                   
                        // if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0)
                        //     // this.setState({ sliderImages: response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });
                        //     // Added by vijay on 13-01-2024 -Testing Points Jira id -2075
                        //     if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0) {
                        //         sortedSliderImages = response.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).slice().sort((a, b) => a.sortOrder - b.sortOrder);
                        //         this.setState({ sliderImages: sortedSliderImages });
                        //     }
                        // if (response.banners.filter(i => i.bannerType === Config.bannerImageType.Banner) !== null && response.banners.filter(i => i.bannerType === Config.bannerImageType.Banner).length > 0) {
                        //     this.setState({ bannerImgs: response.banners.filter(i => i.bannerType === Config.bannerImageType.Banner) });
                        //     //  bannerVal = response.banners.filter(i => i.bannerType === Config.bannerImageType.Banner);
                        // }

                        if (response.page != null) {
                            if (response.page.preview !== '') {
                                this.setState({ pageContent: response.page.content, pagePreview: response.page.preview });
                              
                            }
                        }
                      
                    }

                    else {
                        // this.setState({ themeId: 1 }); // Default Theme Value
                        // this.showMessage(response.message, "danger");     
                        // //////console.log(response.message);

                        // this.getSliderImages();
                        if (sessionStorage.getItem("pageCMSSettings") != null) {
                            let cms = JSON.parse(sessionStorage.getItem("pageCMSSettings"));

                            // if (cms.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) !== null && cms.banners.filter(i => i.bannerType === Config.bannerImageType.Slider).length > 0)
                            //     this.setState({ sliderImages: cms.banners.filter(i => i.bannerType === Config.bannerImageType.Slider) });

                            if (cms.page.preview !== '') {
                                this.setState({ pageContent: cms.page.content, pagePreview: cms.page.preview });
                                // previewVal = cms.page.preview;
                            }

                        }
                         this.setState({ pageTitle: '' });
                        // // this.setState({ sliderImages: [],  });
                        // //Added by vijay on 28-12-2023 Jira id -2075
                        // this.setState({ sliderImages: [] });
                    }

                    /// Dashboard Hide
                    // if (previewVal === "" && bannerVal.length === 0) {
                    //     //////console.log(",m,mmmm")
                    //     if (parseInt(qrLevel) !== Config.qrCodeLevel.Property && parseInt(qrLevel) !== Config.qrCodeLevel.Outlet) {
                    //         this.setState({ loading: false, pageLoading: true });
                    //         sessionStorage.setItem('dashboard-hide', "true");
                    //         //////console.log("console2")
                    //         this.loadItems();
                    //     }
                    //     else {
                    //         if (parseInt(qrLevel) !== Config.qrCodeLevel.Outlet) {
                    //             this.setState({ pageLoading: false });
                    //             sessionStorage.setItem('dashboard-hide', "false");
                    //         }
                    //     }
                    // }
                    // else {
                    //     this.setState({ pageLoading: false });
                    //     sessionStorage.setItem('dashboard-hide', "false");
                    // }
                    // //////console.log(IsUpdateLog)
                     this.getSliderImages();
                    
                    this.setState({ pageLoading: false });
                    sessionStorage.setItem('dashboard-hide', "false");

                    if (IsUpdateLog) this.updateClickDetail();
                    this.setState({ APIResponseMsg: "" });

                })
                .catch(err => {
                    //////console.log(err);
                    this.setState({ sliderImages: [] })
                    this.setState({ APIResponseMsg: "", loading: false });
                    if (parseInt(qrLevel) !== Config.qrCodeLevel.Property && parseInt(qrLevel) !== Config.qrCodeLevel.Outlet) {
                        this.setState({ loading: false, pageLoading: true });
                        sessionStorage.setItem('dashboard-hide', "true");
                        this.loadItems();
                    }
                    else {
                        if (parseInt(qrLevel) !== Config.qrCodeLevel.Outlet) {
                            this.setState({ pageLoading: false });
                            sessionStorage.setItem('dashboard-hide', "false");
                        }
                    }

                    if (IsUpdateLog) this.updateClickDetail();
                    //this.showMessage("CMS - Failed to fetch", "danger");
                }
                );
        }
        if (this.state.selectedClassification.length !== 0) {
            ////console.log("this.state.selectedClassification else")
            if (IsUpdateLog) this.updateClickDetail();
        }
    }
    // Added by vijay on 06-05-2023  // Jira id - 2647     
    getSliderImages() {
        let urlParam = 'OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType + '&PageType=' + Config.pageType.home;
        //  console.log(urlParam);
        fetch(Config.basePOSUrl + 'GetSliderImages?' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(sliderResponse => {
                if (sliderResponse.errorCode === 0) {
                    const imageList = sliderResponse.response.imageList;

                    if (imageList && imageList.length > 0) {
                        const sortedImageList = imageList.sort((a, b) => a.displayOrder - b.displayOrder);

                        const sliderImages = sortedImageList.filter(item => item.imageUrl); // Corrected filter condition
                        //  console.log("sliderImages:", sliderImages);
                        if (sliderImages.length > 0) {
                            this.setState({ sliderImages });
                        } else {
                            this.setState({ sliderImages: [], bannerImgs: [] });
                        }
                    } else {
                        this.setState({ sliderImages: [], bannerImgs: [] });
                    }
                } else {
                    this.showMessage(sliderResponse.response.message, "danger");
                }
            })
            .catch(err => {
                this.showMessage('An error occured - ' + err, "danger");
                this.setState({ sliderImages: [], bannerImgs: [] });
            });
    }

    updateClickDetail() {
        // const storedData = sessionStorage.getItem("getclf");
        // const getclf = JSON.parse(storedData);
        // const storedMenugroup = sessionStorage.getItem("getMenuGroups");
        // const getMenuGroups = JSON.parse(storedMenugroup);
        // //////console.log(getclf)
        let notes = {
            guestname: sessionStorage.getItem("guest-name") === null ? ' ' : sessionStorage.getItem("guest-name"),
            modetype: outletDet != null ? isappOrders === true ? 1 : 0 : 0
        }
        let posturlObj = {
            organizationId: parseInt(organizationId),
            propertyId: propertyId,
            outletCode: outletCode,
            ServiceMode: serviceType,
            ContactNo: mobileNo,
            StatsId: parseInt(statsId),
            numofMenuAccess: 1,
            username: Config.linkUserName,
            password: Config.linkPassword,
            notes: JSON.stringify(notes)
        }
        // //////console.log(posturlObj);
        // Update Contact Detail
        fetch(Config.baseAPIUrl + 'ClickURLDetail', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(posturlObj)
        })
            .then(res => res.json())
            .then(response => {
                ////console.log("updateClickDetail api")
                //     console.log(response);
                this.loadItems();

                // setTimeout(() => {
                //     this.setState({ loading: false })
                //     // redirectType condition added by dasprakash on 30-08-2023 jira-1302 for redirection
                //     if (this.state.redirectType === 1) {
                //         this.props.history.push('/items', { focusGroup: "focus-mnugrp-focus-mnugrp-recommended", selectedClassification: this.state.selectedClassification, selectedClassificationIndex: this.state.selectedClassificationIndex });
                //     } else {
                //         this.props.history.push('/itemsearch', { selectedClassification: this.state.getClassificationList[0] });
                //     }
                // }, 1500);
            })
            .catch(err => {
                //////console.log(err);
                // setTimeout(() => {
                this.showMessage("CMS - Failed to fetch", "danger");
                this.loadItems();

                //     this.setState({ loading: false })
                //     this.props.history.push('/items', { focusGroup: "focus-mnugrp-focus-mnugrp-recommended" });
                // }, 1500);
                // commented by dasprakash on 26-09-2023 jira-1302 for stop calling the classi when click on clesification
                // this.loadClassficiation(true);
                // setTimeout(() => {
                //     this.setState({ loading: false })
                //     // redirectType condition added by dasprakash on 30-08-2023 jira-1302 for redirection
                //     if (this.state.redirectType === 1) {
                //         this.props.history.push('/items', { focusGroup: "focus-mnugrp-focus-mnugrp-recommended", selectedClassification: this.state.selectedClassification, selectedClassificationIndex: this.state.selectedClassificationIndex });
                //     } else {
                //         this.props.history.push('/itemsearch', { selectedClassification: this.state.getClassificationList[0] });
                //     }
                // }, 1500);
            });
    }

    goToItems() {
        this.setState({ loading: true });

        // Check if the user is not logged in as a guest
        if (guestLoggedIn === "false") {
            if (!outletDet.guestLoginAtTheTimeOfPlaceOrder) {
                sessionStorage.setItem("login-page-session", 2); // Item Session

                setTimeout(() => {
                    if (isappOrders === true) {
                        this.props.history.push('/login' + urlPattern);
                        this.setState({ loading: false });
                    }
                    return false;  // Due to setimeout is not working top of the switch
                }, Config.minMsgTimeout);

            }

        }

        this.getCMSDetails(true);
    };

    showMessage(msg, type) {
        window.scrollTo(0, 0);
        this.setState({ msgShow: true });
        this.setState({ msgType: type });
        this.setState({ alertMessage: msg });
        this.setState({ loading: false });
        setTimeout(() => {
            this.setState({ msgShow: false });
        }, Config.averageMsgTimeout);
    };

    loadwindow() {
        loadcomfn = window.initiate;
        loadcomfn();
    }

    getOutletList() {
        // GetOutlet
        fetch(Config.basePOSUrl + 'GetOutletList', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                // //////console.log("getOutletList");
                // //////console.log(response);
                this.setState({ showOutlets: true });
                if (response.errorCode === 0) {
                    this.setState({ outletList: response.response.outletList, serviceTypeList: [] });
                }
                else {
                    this.showMessage(response.message, "danger");
                    this.setState({ outletList: [], serviceTypeList: [] });
                }
            })
            .catch(err => {
                // //////console.log(err);
                this.showMessage('An error occured - ' + err, "danger");
                this.setState({ outletList: [], serviceTypeList: [] });
            });
    }

    getServiceTypeList(outleCode, outletName) {
        sessionStorage.setItem('user-outletcode', outleCode);
        sessionStorage.setItem('user-outletname', outletName);

        sessionStorage.removeItem('cartList');
        sessionStorage.removeItem('modifierCartList');
        sessionStorage.removeItem('taxCartList');
        sessionStorage.removeItem('chargeCartList');

        // GetService Type       
        fetch(Config.basePOSUrl + 'GetOutletServiceTypeList?OutletCode=' + outleCode + '', {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                ////console.log("getServiceTypeList");
                ////console.log(response);
                if (response.errorCode === 0) {
                    if (response.response.serviceModeList.length === 1) {
                        if (parseInt(qrLevel) === Config.qrCodeLevel.Outlet) {
                            sessionStorage.setItem('dashboard-hide', "true");
                            this.handleClickServiceType(response.response.serviceModeList[0].serviceType);
                        }
                        else {
                            setTimeout(() => {
                                this.setState({ serviceTypeList: response.response.serviceModeList });
                                this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                                sessionStorage.setItem('dashboard-hide', "false");
                            }, Config.minMsgTimeout);
                        }
                    }
                    else if (response.response.serviceModeList.length > 1) {
                        setTimeout(() => {
                            this.setState({ serviceTypeList: response.response.serviceModeList });
                            this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                        }, Config.minMsgTimeout);
                    }
                    else {
                        this.showMessage('Service not available', "danger");
                        this.setState({ serviceTypeList: [], showOutlets: false, showServiceTypes: true, loading: false });
                    }
                }
                else {
                    this.showMessage(response.message, "danger");
                    setTimeout(() => {
                        this.setState({ serviceTypeList: [] });
                        this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                    }, Config.minMsgTimeout);
                }
            })
            .catch(err => {
                // //////console.log(err);
                this.showMessage('An error occured - ' + err, "danger");
                setTimeout(() => {
                    this.setState({ serviceTypeList: [] });
                    this.setState({ showOutlets: false, showServiceTypes: true, loading: false });
                }, Config.minMsgTimeout);
            });
    }

    outletListDom() {
        ////console.log(this.state.outletList)
        return (
            <>
                <div className="clear clear-bottom"></div>
                <div className="outlet-group">
                    <h6>Outlets</h6>
                    <ul>
                        <div className="outlet-list" style={{ padding: "4px 9px" }}>
                            {this.state.outletList.map(o =>
                                <li key={o.outletCode} onClick={this.showOutletServiceType.bind(this, false, o.outletCode, o.outletName)}>
                                    <div className="sol-left">
                                        {o.imageUrl === "" &&
                                            <img src="/img/no-image/no-outlet.png" alt="profile" />
                                        }
                                        {o.imageUrl !== "" &&
                                            <img src={o.imageUrl} alt="lucid-outlet-contact-less" />
                                        }
                                    </div>
                                    <div className="sol-right">
                                        <div className="outlet-name">{o.outletName}</div>
                                        <div className="outlet-detail">{o.cuisineTypes.toString()}
                                        </div>
                                        {o.isVegOutlet &&
                                            <div className="food-type">
                                                <div className="veg">  <img className="food-type-img" src={"/img/f-t-veg.png"} alt="lucid-contactless-app-pos" />  Only Veg</div>
                                            </div>
                                        }
                                        {!o.isVegOutlet &&
                                            <div className="food-type">
                                                <div className="veg">  <img className="food-type-img" src={"/img/f-t-nonveg.png"} alt="lucid-contactless-app-pos" />  Non-Veg</div>
                                            </div>
                                        }
                                    </div>
                                </li>
                            )}

                        </div>
                    </ul>

                </div>
            </>
        )
    }

    showOutletServiceType(outlet, outletCode, outletName) {
        this.setState({ loading: true });
        if (!outlet) this.getServiceTypeList(outletCode, outletName);
        else {
            setTimeout(() => {
                this.setState({ serviceTypeList: [] });
                this.setState({ showOutlets: true, showServiceTypes: false, loading: false });
            }, Config.minMsgTimeout);
        }
    }

    serviceTypeListDom() {
        return (
            <>
                <div className="clear clear-bottom"></div>
                <div className="service-type-group">
                    {parseInt(qrLevel) === Config.qrCodeLevel.Property && <div className="heading-text" onClick={this.showOutletServiceType.bind(this, true)}><i className="fa fa-angle-left" ></i> Back To Outlet</div>}
                    <ul>
                        <div className="service-type-group-list">
                            {this.state.serviceTypeList.map(s =>
                                <li key={s.serviceType} className="order-now" onClick={this.handleClickServiceType.bind(this, s.serviceType, s.serviceTypeName)}>
                                    {s.serviceTypeName}
                                </li>
                            )}
                        </div>
                    </ul>
                </div>
            </>
        )
    }

    handleClickServiceType(serviceType) {

        sessionStorage.setItem('user-servicetype', serviceType);
        sessionStorage.removeItem('cartList');
        sessionStorage.removeItem('modifierCartList');
        sessionStorage.removeItem('taxCartList');
        sessionStorage.removeItem('chargeCartList');

        this.setState({ APIResponseMsg: "Loading...", loading: true, pageLoading: true });
        this.getoutletDetails();
    }

    getoutletDetails() {
        serviceType = sessionStorage.getItem('user-servicetype');
        outletCode = sessionStorage.getItem('user-outletcode');

        let urlParam = '?OutletId=' + propertyId + outletCode + '&ServiceType=' + serviceType;
        // // Get Outlet Details                    
        fetch(Config.basePOSUrl + 'GetOutletDetails' + urlParam, {
            method: 'GET',
            headers: {
                Authorization: "Bearer " + token,
                'content-type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(response => {
                ////console.log("getoutletDetails");
                ////console.log(response);
                if (response.errorCode === 0) {
                    this.setState({ outletDetails: response.response.outletDetails });
                    // if (response.response.outletDetails.homePageSliderImages !== null && response.response.outletDetails.homePageSliderImages.length > 0) {
                    //     this.setState({ sliderImages: response.response.outletDetails.homePageSliderImages });
                    // }
                    sessionStorage.setItem("user-outletDetails", JSON.stringify(response.response.outletDetails));
                    outletDet = JSON.parse(sessionStorage.getItem("user-outletDetails"));
                    isappOrders = response.response.outletDetails.enableGuestAppOrders;
                    setTimeout(() => {
                        window.scrollTo(0, 0);
                    }, Config.minMsgTimeout);
                    // Digital Menu
                    if (response.response.outletDetails.enableGuestAppOrders === false && response.response.outletDetails.enableDigitalMenu === true) {

                        if (response.response.outletDetails.guestDetailsMandatoryForDigitalMenu === false) {
                            sessionStorage.setItem('user-table-verified', true);
                            sessionStorage.setItem('user-digitalMenu', true);
                            //sessionStorage.setItem('user-mobileno', "0000000000");                           

                            setTimeout(() => {
                                // this.props.history.push('/emhome');
                                this.props.history.push('/home' + urlPattern);
                            }, Config.minMsgTimeout);
                            return false;
                        }
                        if (guestLoggedIn === "false") {
                            setTimeout(() => {
                                this.props.history.push('/login' + urlPattern);
                                this.setState({ loading: false });
                            }, Config.minMsgTimeout);
                            return false;
                        }
                    }

                    // guestLoginAtTheTimeOfPlaceOrder
                    if (guestLoggedIn === "false") {
                        if (!response.response.outletDetails.guestLoginAtTheTimeOfPlaceOrder) {
                            sessionStorage.setItem("login-page-session", 2); // Item Session      
                            setTimeout(() => {
                                this.props.history.push('/login' + urlPattern);
                                this.setState({ loading: false });
                            }, Config.minMsgTimeout);
                            return false;
                        }
                    }

                    //// Table Check                    
                    setTimeout(() => {
                        this.getCMSDetails(true);
                    }, Config.minMsgTimeout);
                }
                else {

                    this.setState({ APIResponseMsg: "Failed to fetch", loading: false });
                }

            })
            .catch(err => {
                this.setState({ APIResponseMsg: "Outlet Details - Failed to fetch", loading: false })
                //  //////console.log('Outlet Details - Failed to fetch - ' + err);
                this.showMessage('Outlet Details - Failed to fetch - ' + err, "danger");
            });
    }
    itemsearch = () => {

        /// user Session Validation        
        if (!sHelpers.userSessionValidation(userSessionId)) {
            this.showMessage("Your session has been expired, please try again", "danger");
            setTimeout(() => {
                window.location.reload(true);
            }, Config.minMsgTimeout);
            return false;
        }
        this.setState({ redirectType: 2 })
        this.goToItems();
        // window.location.href = '/itemsearch';
    }

    // componentWillMount() {
    //     loadjs('../../ assets/js/jquery341.min.js', function () {
    //         loadjs('../../assets/js/theme.js');
    //     });
    // }
    handleClassificationClick = (item) => {
        /// user Session Validation        
        if (!sHelpers.userSessionValidation(userSessionId)) {
            this.showMessage("Your session has been expired, please try again", "danger");
            setTimeout(() => {
                window.location.reload(true);
            }, 3000);
            return false;
        }

        // //////console.log("Function starts");
        // //////console.log(item);
        // Parse the stored JSON data from sessionStorage
        const storedData = JSON.parse(sessionStorage.getItem('getclf'));
        // Find the index of the clicked item in the stored data
        const indexInGetMenus = storedData.findIndex(i => i.classificationName === item.classificationName);
        // //////console.log("Index in getclf:", indexInGetMenus);

        this.setState({ selectedClassification: item, selectedClassificationIndex: indexInGetMenus, redirectType: 1, classificationClicked: true }, () => {
            // //////console.log(this.state.selectedClassification, this.state.selectedClassificationIndex,);
            // this.props.history.push('/items', { selectedClassification: this.state.selectedClassification });
            // Call the goToItems function here
            this.goToItems();

        });
        sessionStorage.setItem("selectedClassification", JSON.stringify(item));

    }
    /* added by dasprakash on 29-09-2023 jira-1302 */
    isValidUrl = (string) => {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }
    /* added by dasprakash on 6-11-2023 jira-1744 */
    callbackAccessMode = () => {
        ////console.log('Callback function called from Changeaccessmode component');
        propertyId = sessionStorage.getItem('user-propertyid');
        outletCode = sessionStorage.getItem('user-outletcode');
        this.getCMSDetails(true);
        this.loadClassficiation(false);
        outletDet = JSON.parse(sessionStorage.getItem("user-outletDetails"));
        this.setState({
            outletImg: outletDet.outletImageUrl,
        });
    }

    handleCheckGuestTableStatus(mobileNo, ptableNo) {
        let postObj = {
            OutletId: propertyId + outletCode,
            GuestMobileCountryCode: "91",
            GuestMobileNumber: mobileNo,
            TableNumber: ptableNo,
        };
        fetch(Config.basePOSUrl + "GetGuestTableStatus", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                "content-type": "application/json",
            },
            body: JSON.stringify(postObj),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.errorCode === 0) {
                    if (!response.response.guestTableStatus.isCheckedIn) {
                        this.showMessage(
                            "Your selected table has been checked out.",
                            "danger"
                        );
                        this.setState({
                            errorMessage: "Your selected table has been checked out.",
                        });
                        this.setState({ loading: true });
                        setTimeout(() => {
                            this.props.history.push("/app-error" + urlPattern, {
                                errorMessage: this.state.errorMessage,
                            });
                        }, Config.averageMsgTimeout);
                        return false;
                    } else {
                        // check in true
                        if (
                            ptableNo !== response.response.guestTableStatus.currentTableNumber
                        ) {
                            // added by dasprakash on 21-10-2023 jira-1644
                            this.setState({
                                errorMessage:
                                    "Your table has been transfer from " +
                                    ptableNo +
                                    " to " +
                                    response.response.guestTableStatus.currentTableNumber,
                            });
                            this.showMessage("Your table has been transfer from " + ptableNo + " to " + response.response.guestTableStatus.currentTableNumber, "danger");
                            sessionStorage.setItem(
                                "user-tableno",
                                response.response.guestTableStatus.currentTableNumber
                            );
                            tableNo = sessionStorage.getItem("user-tableno");
                            this.getGuestDashboardDetails();
                        }
                        return false;
                    }
                } else {
                    this.setState({ errorMessage: response.message });
                }
            })
            .catch((err) => {
                this.setState({ errorMessage: err });
            });
    };


    render() {
        return (
            <>
                {this.state.loading && <LoadingAnimation />}
                {this.state.pageLoading && <Loadingpage />}
                <>
                    <div id="content">
                        {this.state.msgShow && <ErrorAlert alertMsg={this.state.alertMessage} msgType={this.state.msgType} msgShow={this.state.msgShow} />}
                        <Header loggedIn={guestLoggedIn}
                            mobileApp={this.state.mobileApp}
                            clmenu={true}
                            pageTitle={this.state.pageTitle}
                            activPageName="Home"
                            ref={accessModeRef}
                            callbackAccessModeHome={this.callbackAccessMode}
                        />

                        {/* added by dasprakash on 28-08-2023 jira-1302 */}
                        <div className='header-content_v2'>
                            {/* // Added by vijay on 20-03-2024 -Jira id - 2587-Broken Logo showing if its false */}
                            {this.state.outletImg ? (
                                <img style={{ height: "60px" }} src={this.state.outletImg} alt='logo' />
                            ) : (
                                /* If this.state.outletImg is falsy, you can render an empty space or placeholder */
                                <div style={{ height: "60px" }}>
                                    {/* You can add text or other content here */}
                                </div>
                            )}
                        </div>
                        <div style={{ position: "sticky", top: "60px", zIndex: "4", background: "#fff" }} >
                            {/* //Added by vijay on 25-11-2023 */}
                            {(serviceType === "FINE-DINE") && (
                                <div style={{ float: "right" }}>
                                    {/* // added by dasprakash on 28-09-2023 jira-1677 */}
                                    {(guestLoggedIn === "true" && outletDet.enableHelp) &&
                                        <small onClick={this.goToHelpRequest} style={{ float: "right", marginTop: "-35px", fontSize: "14px", fontWeight: "500", color: "#18A437", marginRight: "10px" }}>
                                            Help
                                        </small>
                                    }
                                </div>
                            )
                            }

                            <div style={{ display: "flex", justifyContent: "center", height: "40px", alignItems: "center" }}>
                                {sessionStorage.getItem("user-servicetype") === "FINE-DINE" && <div className='tbl-no-group-pin'> <div style={{ padding: "4px", display: "inline", marginRight: "6px" }}> Table No:{sessionStorage.getItem("user-tableno")} </div> </div>}

                                { // group pin added by dasprakash on 28-09-2023 jira-1493
                                    sessionStorage.getItem("user-servicetype") === "FINE-DINE" && tablePins !== '' && (
                                        tablePins &&
                                        <div className='tbl-no-group-pin'>
                                            {/* Uncomment this section if you want to display the table number  */}
                                            {/* <div style={{ padding: "4px", display: "inline", marginRight: "6px" }}>
                                            Table No: {sessionStorage.getItem("user-tableno")}
                                        </div>  */}
                                            {/* added by dasprakash on 29-08-2023 jira-1302 enableGroupOrdering  */}
                                            {/* condition Added by vijay on 28-12-2023    Jira id-2075     */}
                                            {outletDet.enableGroupOrdering && tablePins &&
                                                <div style={{ padding: "4px", marginTop: "-9px", display: "inline" }}>
                                                    <span>Table Pin:{tablePins}</span>

                                                </div>
                                            }
                                        </div>
                                    )

                                }
                            </div>
                                   {
                                     this.state.pagePreview !== "" &&
                                     <div className="item-content-msg" dangerouslySetInnerHTML={{ __html: this.state.pagePreview }}></div>
                                    }
                            <div className="search-wrapper " style={{ padding: "14px 18px" }}>
                                <input style={{ outline: "none", borderRadius: "11px" }} id="search" autoComplete="Off" ref={(input) => { this.searchInp = input; }} placeholder="Search your favourite dishes" onClick={this.itemsearch} />
                                {/* comment by dasprakash 16-08-2023 jira-1302 */}
                                {/* <button className="ssubmit" type="button" name="search_submit" onClick={this.handleClearText.bind(this)}><i className="fas fa-times"></i></button> */}
                                {/* icon added by dasprakash on 19-08-2023 jira-13 */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                                    <rect x="1" y="1" width="33" height="33" fill="#728EB8" fillOpacity="0.25" />
                                    <rect x="0.75" y="0.75" width="33.5" height="33.5" stroke="#666666" strokeOpacity="0.03" strokeWidth="0.5" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M22.6288 21.4783C22.6089 21.4586 22.6058 21.4271 22.6228 21.4048C23.6111 20.1101 24.0757 18.4899 23.9225 16.8671C23.7678 15.2277 22.9944 13.7089 21.7595 12.6195C20.5247 11.5302 18.9213 10.9522 17.2754 11.0031C15.6295 11.054 14.0649 11.7301 12.8998 12.8937C11.7336 14.0581 11.0554 15.6233 11.0032 17.2705C10.9511 18.9176 11.529 20.5226 12.6191 21.7584C13.7092 22.9943 15.2296 23.768 16.8703 23.9218C18.4944 24.074 20.1153 23.6077 21.4097 22.6171C21.4324 22.5997 21.4646 22.602 21.4844 22.6227L24.9176 26.0567C24.9928 26.1319 25.0821 26.1916 25.1803 26.2323C25.2786 26.273 25.3839 26.2939 25.4902 26.2939C25.5966 26.2939 25.7019 26.273 25.8001 26.2323C25.8983 26.1916 25.9876 26.1319 26.0628 26.0567C26.138 25.9815 26.1977 25.8923 26.2384 25.794C26.279 25.6958 26.3 25.5905 26.3 25.4841C26.3 25.3778 26.279 25.2725 26.2384 25.1742C26.1977 25.076 26.138 24.9867 26.0628 24.9115L22.6288 21.4783ZM20.9122 14.0389C21.3692 14.4885 21.7326 15.024 21.9815 15.6148C22.2304 16.2055 22.3599 16.8397 22.3625 17.4807C22.3651 18.1217 22.2408 18.7569 21.9967 19.3496C21.7526 19.9424 21.3935 20.4809 20.9403 20.9342C20.487 21.3875 19.9485 21.7465 19.3557 21.9906C18.763 22.2347 18.1278 22.359 17.4868 22.3564C16.8458 22.3538 16.2116 22.2243 15.6209 21.9754C15.0301 21.7265 14.4945 21.3631 14.045 20.9061C13.1465 19.9929 12.6454 18.7617 12.6506 17.4807C12.6558 16.1997 13.167 14.9726 14.0728 14.0667C14.9787 13.1609 16.2057 12.6497 17.4868 12.6445C18.7678 12.6393 19.999 13.1405 20.9122 14.0389Z" fill="#454545" />
                                </svg>
                            </div>
                        </div>


                        <div className="content-warp">
                            {/* radious added by dasprakash on 28-08-2023 jira-1302 */}
                            {/* <div style={{ padding: "10px 18px" }} */}
                            <div style={{ padding: this.state.sliderImages.length > 0 ? "10px 18px" : "0px" }}>
                                <Slider style={{ borderRadius: "11px" }}  {...homeslidersettings} className="img-hero">
                                    {this.state.sliderImages.map(img =>
                                        <div key={"slider-img-" + img.sort}>
                                            <img className='sliderImg_v2' style={{ borderRadius: "11px" }} src={img.imageUrl} alt="lucid-slider-contact-less-order" />
                                        </div>
                                    )}
                                    {/* <div>
                                <img src="../assets/theme1/img/slide1.jpg" alt="slider" />
                                   </div>
                                       <div>
                                <img src="../assets/theme1/img/slide2.jpg" alt="slider" />
                                  </div>
                                 <div>
                                <img src="../assets/theme1/img/slide3.jpg" alt="slider" />
                                  </div> */}
                                </Slider>
                            </div>
                                    

                            {/* commented by dasprakash on 28-08-2023 jira-1302 */}
                            {/* {isappOrders === true &&
                            <div className="section-subscribe">

                                <div className="subcontainer">
                                    <div className="subrow">

                                        <div className="subcol">

                                            {sessionStorage.getItem("user-servicetype") !== "NONE" && <p className="textsub home-param-txt">Service Type  : <span className="home-param-value-txt">{sessionStorage.getItem("user-servicetype") === "FINE-DINE" ? 'Table Service' : sessionStorage.getItem("user-servicetype")}</span></p>}
                                            {sessionStorage.getItem("user-servicetype") === "FINE-DINE" && <p className="textsub home-param-txt">Table No: <span className="home-param-value-txt">{sessionStorage.getItem("user-tableno")}</span></p>}
                                            {(this.state.groupPin !== "" && this.state.groupPin != null) && <p className="textsub home-param-txt">Group Pin: <span className="home-param-value-txt"> {this.state.groupPin}</span></p>}

                                        </div>
                                    </div>
                                </div >
                            </div >
                        } */}
                            {/* <div className="section-title">Popular Categories</div> */}
                            {/* <p className="textsub">Casual Dining - Andhra, Biryani, North Indian, Chinese</p> */}


                            {/* added by dasprakash on 29-08-2023 jira-1302 */}
                            <div className='classifications_v2'>
                                {this.state.getClassificationList.length > 0 ?
                                    <div>
                                        <p className='classifications_v2-para' style={{ marginLeft: "7px", marginBottom: "20px" }}>Eat what Makes you happy</p>
                                        <div style={{ width: "100%" }} className='classification-media_v2'>
                                            <div className='classification-row-v2' style={{ display: "flex", flexWrap: "wrap" }}>
                                                {this.state.getClassificationList.map((classificationItem, index) => (
                                                    <div className='classification-image_v2' key={index} onClick={() => this.handleClassificationClick(classificationItem)} style={{ flexDirection: "column", alignItems: "center", width: "48%", height: "204.527px", paddingTop: "12px", marginRight: "5px", marginBottom: "-1px", marginTop: "-20px" }} >
                                                        <div className='classification-img-ctrl'>
                                                            {classificationItem.imageUrl === '' || classificationItem.imageUrl === null ?
                                                                <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={defaultimg} alt={`classification ${classificationItem.classificationName}`} />
                                                                :
                                                                <img style={{ borderRadius: "11px", width: "100%", height: "100%", objectFit: "cover" }} src={this.isValidUrl(classificationItem.imageUrl) ? classificationItem.imageUrl : defaultimg} alt={`classification ${classificationItem.classificationName}`} />
                                                            }
                                                        </div>
                                                        {/* lev6 change */}
                                                        <p className={classificationItem.imageUrl === '' ? 'itemName-default' : 'itemName'}>{classificationItem.classificationName}</p>
                                                    </div>))}





                                                {/* <Slider style={{ borderRadius: "11px" }} {...clflidersettings} className="img-hero">
                                                {(() => {
                                                    const slides = [];
                                                    const items = this.state.getClassificationList;
                                                    for (let i = 0; i < items.length; i += 4) {
                                                        slides.push(
                                                            <div key={i}>
                                                                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                                                    {items.slice(i, i + 4).map((item, index) => (
                                                                        <div onClick={() => this.handleClassificationClick(item)} style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "48%", marginBottom: "10px", height: "204.527px" }} key={`slider-item-${item.sort}-${index}`}>
                                                                            <img style={{ borderRadius: "11px", width: "100%", height: "100%" }} src={item.imageUrl} alt={`slider-${item.sort}`} />
                                                                            <p className='itemName'>{item.classificationName}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return slides;
                                                })()}
                                            </Slider> */}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <p className='classifications_v2-noMenu'>No menu available</p>
                                    </div>
                                }
                            </div>


                            <div className="home-menu box-menu">
                                <ul className="list-unstyled">
                                    {/* <span style={{textAlign:"center",color: "#6c757d87",fontSize:"12px"}}><h1>Loading...</h1></span> */}
                                    {this.state.getMenus.length === 0 &&
                                        <span style={{ textAlign: "center", color: "#6c757d87", fontSize: "12px" }}>
                                            <h1>
                                                <div>{this.state.APIResponseMsg}</div>
                                                <div>
                                                    {/* {this.state.APIResponseMsg === "Failed to fetch" ? <div onClick={this.getDetails} style={{ marginLeft: "18px", fontSize: "20px", width: "50%", float: "right", marginTop: "-22px" }} ><i className="fas fa-sync-alt"></i></div> : <div style={{ marginLeft: "18px", fontSize: "20px", width: "50%", float: "right", marginTop: "-22px" }}><i className="fas fa-sync-alt fa-spin"></i></div>} */}
                                                </div>
                                            </h1>
                                        </span>
                                    }
                                    {/* {
                                    this.state.APIResponseMsg === "" && serviceType !== "NONE" &&
                                    <div className={"order-now" + (isappOrders === false ? " order-now-dm" : "")} onClick={() => this.goToItems()}>{(isappOrders === true) ? "Order Now" : "Go To Menu"}</div>
                                } */}
                                    {/* {
                                     this.state.pagePreview !== "" &&
                                     <div className="item-content-msg" dangerouslySetInnerHTML={{ __html: this.state.pagePreview }}></div>
                                    }  */}

                                    {/* commented by dasprakash on 4-11-2023 jira-1744 */}
                                    {/* {this.state.showOutlets && this.outletListDom()}
                                {this.state.showServiceTypes && this.serviceTypeListDom()} */}



                                    {/* // commented by dasprakash on 26-10-2023 jira-1677 */}
                                    {/* {this.state.groupMemberList.length > 0 &&
                                    <div className="group-content">
                                        <h6>Group Members</h6>
                                        <div className="group-list">
                                            <ul>
                                                {
                                                    this.state.groupMemberList.map(m =>
                                                        <li className="people" key={m.guestMobileNumber}>
                                                            {m.guestName === '' && <div> <i className="fas fa-mobile-alt"></i> <span className="people-content">{m.guestMobileNumber}{m.isMainGuest && <span className="people-main"> (Main)</span>}{(m.guestMobileNumber === mobileNo && !m.isMainGuest) && <span> (You)</span>}</span></div>}
                                                            {m.guestName !== '' && <div> <i className="fas fa-user-alt"></i> <span className="people-content">{m.guestName}{m.isMainGuest && <span className="people-main"> (Main)</span>}{(m.guestMobileNumber === mobileNo && !m.isMainGuest) && <span> (You)</span>}</span></div>}
                                                        </li>

                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                } */}
                                    {/* {
                                        
                                    this.state.getMenus.map(grp =>
                                        <li key={grp.menuGroupId} onClick={() => this.goToItems(grp.menuGroupId, grp.menuGroupName)}>
                                            <NavLink to="/items" className="dish-list-link">
                                                <div className="dish-list">
                                                    <div className="dish-list-tbl-cell">
                                                        <img src="../assets/theme1/img/item/menu-icon.png" className="img-fluid" alt="dish-menu" />
                                                        <div className="dish-list-text">
                                                            <h4>{grp.menuGroupName}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </NavLink>
                                        </li>
                                    )} */}

                                    {/* {this.state.bannerImgs.length > 0 &&
                                    this.state.bannerImgs.map(img =>
                                        <div className="home-banner" key={"bannerh-" + img.imageId}>
                                            <img src={img.primaryImageUrl} alt="banner" key={"banner-" + img.imageId} />
                                        </div>
                                    )
                                } */}
                                </ul>
                            </div>

                        </div >
                    </div >
                    {this.state.HelpRequestShow &&
                        <Request
                            ref={reqeustRef}
                            callBackRequest={this.callBackRequest}
                            token={sessionStorage.getItem('user-token')}
                            mobileNo={sessionStorage.getItem('user-mobileno')}
                            propertyId={sessionStorage.getItem('user-propertyid')}
                            outletCode={sessionStorage.getItem('user-outletcode')}
                            tableNo={sessionStorage.getItem('user-tableno')}
                            serviceType={sessionStorage.getItem('user-servicetype')}
                        ></Request>
                    }
                    {/* // added by dasprakash on 11-11-2023 jira-1744 */}

                    {this.state.accessModeShow && (

                        <Changeaccessmode
                            ref={accessModeRef}
                            accessModeShow={this.state.accessModeShow}
                            callbackAccessMode={this.callbackAccessMode}
                            clickingServicemode={"false"}
                        />
                    )}
                    {/* added by dasprakash on 29-08-2023 jira-1302 */}
                    <div div style={{ borderTop: "1px solid rgba(0, 0, 0, 0.09" }}>

                        <p style={{ marginLeft: "10px", color: "#7E7E7E", fontSize: "10px", marginTop: "1px" }}>Powered By</p>
                        <p style={{ textAlign: "center", marginTop: "-30px", color: "red", fontSize: "13px" }}>LUCID</p>
                    </div >
                </>


            </>
        )
    }
}