import React, { Component } from 'react';
import './logout.scss';
import LoadingAnimation from '../../components/checkmarkanimation';
import Config from '../../data/SiteConfig';
let outletDetails = null;  // Added by vijay on 04-11-2023-start
let urlPattern = '';

export default class Logout extends Component {
 constructor(props){
    super(props);
    this.state ={
        outLetImage: '', // Added by vijay on 04-11-2023-start
    }
 }
    componentWillMount() {

         const sessionDt = sessionStorage.getItem("u-l-v-url-dt");
            if (!sessionDt) {
            this.props.history.push('/sessionerror' + urlPattern);
           return false;
         }

         urlPattern = sessionStorage.getItem('url-pattern');      
         if (!urlPattern) {
             this.props.history.push('/qrcodeerror' + Config.defaultUrlPattern);
             return false;
         }

        outletDetails = sessionStorage.getItem('user-outletDetails');   // Added by vijay on 04-11-2023-start
        let  parsedOutletDetails = JSON.parse(outletDetails);
        this.setState({ outLetImage: parsedOutletDetails.outletImageUrl })
        // sessionStorage.removeItem('user-orgnationid');
        // sessionStorage.removeItem('user-propertyid');
        // sessionStorage.removeItem('user-propertyname');
        // sessionStorage.removeItem('user-outletcode');
        // sessionStorage.removeItem('user-tableno');
        // sessionStorage.removeItem('user-servicetype');
        // sessionStorage.removeItem('user-organizationname');
        // sessionStorage.removeItem('user-outletname');
        // sessionStorage.removeItem('user-mobileno');
        // sessionStorage.removeItem('user-token');
        // sessionStorage.removeItem('getMenuGroups');
        // sessionStorage.removeItem('getModifierGroups');
        // sessionStorage.removeItem('getTaxes');
        // sessionStorage.removeItem('getmenuItems');
        // sessionStorage.removeItem('getCharges');


        sessionStorage.removeItem('cartList');
        sessionStorage.removeItem('modifierCartList');
        sessionStorage.removeItem('taxCartList');
        sessionStorage.removeItem('chargeCartList');
        // group pin added by dasprakash on 06-10-2023 jira-1490
        sessionStorage.removeItem('table-pins')

        sessionStorage.removeItem("paymentInitiatedId");
        sessionStorage.removeItem("vendorTransactionId");
        sessionStorage.removeItem("vendorId");
        sessionStorage.removeItem("vendorName");
        sessionStorage.removeItem("paymentStatus");
        sessionStorage.removeItem("order-instruction");
        sessionStorage.removeItem('otpReferenceId');
        sessionStorage.removeItem("user-vendorOrderId");
        // sessionStorage.removeItem("user-table-verified");
        // sessionStorage.removeItem("user-outletDetails");
        sessionStorage.removeItem("user-loggedin-date");
        // sessionStorage.removeItem('user-digitalMenu');
        sessionStorage.removeItem("ebillUrl");
        sessionStorage.removeItem("isPay");
        sessionStorage.removeItem("posBillId");
        sessionStorage.removeItem('otp-exp-duration');
        // sessionStorage.removeItem('user-statsid');
        sessionStorage.removeItem('main-guest-mask-no');
       //  sessionStorage.removeItem('main-guest-no');
        // sessionStorage.removeItem('group-session');
        sessionStorage.removeItem('group-pin');

        sessionStorage.removeItem('guest-name');
        sessionStorage.removeItem('user-loggedin');
        sessionStorage.removeItem("login-page-session"); // Cart Session
        sessionStorage.removeItem("on-click-mode"); // Cart Session
        sessionStorage.removeItem('main-guest-name');
        sessionStorage.removeItem('ex-main-guest-no');
        sessionStorage.removeItem('cms-logo');
        sessionStorage.removeItem('user-CurrentAddress');
        // sessionStorage.removeItem('url-remarks');
        sessionStorage.removeItem('user-GuestDetails');
        // sessionStorage.removeItem('pageCMSSettings');
        // sessionStorage.removeItem('getclf');
        // sessionStorage.removeItem('app-ContactNo');
        sessionStorage.removeItem('order-charge-Tax-Amount');
        // sessionStorage.removeItem('app-CountryCode');
        sessionStorage.removeItem('order-charge-List');
        // sessionStorage.removeItem('dashboard-hide');
        // sessionStorage.removeItem('mobile-app');
        sessionStorage.removeItem('order-charge-Amount');
        // sessionStorage.removeItem('user-loggedin');
        sessionStorage.removeItem("login-page-session"); // Cart Session
        sessionStorage.removeItem("on-click-mode"); // Cart Session
        sessionStorage.removeItem('main-guest-name');
        // sessionStorage.removeItem('user-qr-level'); //commented by vijay on 25-11-2023 
        // sessionStorage.removeItem('url-remarks');
        // sessionStorage.removeItem('menuFetchDateTime');
        sessionStorage.removeItem('user-GuestDetails');
        sessionStorage.removeItem('user-addressId');
        sessionStorage.removeItem('user-CurrentAddress');
        sessionStorage.removeItem('prepaidCardAccountId');
        sessionStorage.removeItem('mobile-app');
        sessionStorage.removeItem('app-CountryCode');
        sessionStorage.removeItem('order-track-status');
          // Added by vijay on 04-11-2023
        sessionStorage.removeItem('entered-mobile-no');
        sessionStorage.removeItem('entered-name');     
      //  sessionStorage.removeItem('url-pattern'); 
      //  sessionStorage.removeItem('u-l-v-url-dt');

        setTimeout(() => {
            // this.setState({ loading: false });
            this.props.history.push('/home' + urlPattern , { guestAppLandingPage: parsedOutletDetails.guestAppLandingPage });
        }, 2000);
    }
    render() {
        return (
            <div>
                {/* {loading && <LoadingAnimation />}*/}
                <div id="login-content qrcode-err">
                    <div className="content-wrap qrcode_bg">
                        {/* <img src={LoadingImg} className="svg" alt="" />  */}
                        {/* <img src="../assets/theme1/img/error/qrcode-error.png" className="qrcode-img-center" alt="lucid-contactless-food-ordering" /> */}
                        <div className="logo-v2-logout">   
                            <img style={{ width: "220px", marginLeft: "12px", marginBottom: "30px" }} src={this.state.outLetImage} alt="this.state.outLetImage" />
                        </div>



                        <div className="qrcode-txt-center">
                           
                            <div className="qrcode--txt-not-found">You have logged out successfully. </div>
                            <div><LoadingAnimation /></div>
                            {/* // Commended by vijay  */}
                            {/* <div className="qrcode-msg-txt">Thank You!</div> */}
                        </div>
                    </div>
                    {/* <img src={LoadingImg} className="svg screen-img-center loading_img" alt="" /> */}
                </div>
            </div>
        )
    }
}